import React, { FunctionComponent } from 'react';
import { ProductsTemplatePropsType } from '@components/seller/event/types/products-template-props.type';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowForward, BrokenImage } from '@mui/icons-material';

const CatalogProductsTemplate: FunctionComponent<ProductsTemplatePropsType> = ({ product, onClick }) => {
	return (
		<Box
			p={3}
			borderBottom="1px solid #e8e8e8"
			width="100%"
			display="flex"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box display="flex" alignItems="center">
				{product?.img ? <img width="50px" src={product.img} alt="" /> : <BrokenImage />}
				<Box ml={3}>
					<Typography>
						<b>{product.name}</b> <span>{product.type}</span>
					</Typography>
					<Box sx={{ overflow: 'hidden', maxHeight: 42 }}>
						<Typography>
							<small>{product.details}</small>
						</Typography>
					</Box>
				</Box>
			</Box>
			<Box display="flex" alignItems="center" height="80px">
				<Box mr={2} display="flex" flexDirection="column" height="100%">
					<Box>
						<Typography textAlign="right">
							<b>
								{product?.price}
								{product?.currency === 'EUR' ? '€' : product?.currency}/L
							</b>
						</Typography>
					</Box>
					<Box mt="auto">
						<Typography variant="h6" textAlign="right">
							<b>
								{product.quantity}
								{product.quantityUnit}
							</b>
						</Typography>
					</Box>
				</Box>

				<IconButton size="small" onClick={() => onClick(product.id)}>
					<ArrowForward color="primary" fontSize="large" />
				</IconButton>
			</Box>
		</Box>
	);
};

export default CatalogProductsTemplate;
