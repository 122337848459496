import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useEventDetailModalStyle = makeStyles((theme: Theme) =>
	createStyles({
		conditioning: {
			textAlign: 'center',
			color: theme.palette.primary.main,
			background: theme.palette.background.paper,
			borderBottom: `1px solid ${theme.palette.primary.main}`,
			fontWeight: 400,
		},
		image: {
			maxWidth: '100%',
			maxHeight: '100%',
			height: 'auto',
		},
	})
);
