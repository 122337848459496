import React, { FunctionComponent } from 'react';
// utils
import { useTranslation } from 'react-i18next';
// Component
import ProfileTemplate from './partials/profile-template';
import { Box, Button, Grid, Typography } from '@mui/material';
import { PowerSettingsNew as PowerSettingsNewIcon } from '@mui/icons-material';
// Styles
import { useUserContext } from '@tmds-io/kore';
import { useDebugContext } from '@core/debug/debugContext';

const SharedProfile: FunctionComponent = () => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { enableListener } = useDebugContext();
	const { logout } = useUserContext();

	const handleLogout = () => {
		logout();
	};

	return (
		<Box>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Typography variant="h1" color="primary" onDoubleClick={enableListener}>
						{t('profile.title')}
					</Typography>
				</Grid>
				<Grid item>
					<Button
						startIcon={<PowerSettingsNewIcon fontSize="large" />}
						color="primary"
						variant="outlined"
						onClick={handleLogout}
					>
						{t('profile.logout')}
					</Button>
				</Grid>
			</Grid>
			<Box mt={2}>
				<ProfileTemplate />
			</Box>
		</Box>
	);
};

export default SharedProfile;
