import * as Yup from 'yup';

export const addUsersFormSchema = Yup.object().shape({
	users: Yup.array().of(
		Yup.object().shape({
			firstName: Yup.string().required('First Name is required'),
			lastName: Yup.string().required('Last Name is required'),
			email: Yup.string().email('Email is invalid').required('Email is required'),
		})
	),
});
