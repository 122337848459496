import React from 'react';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDateUtils } from '@core/utils/date.util';
import { format } from 'date-fns';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { CartProduct } from '@core/services/cart/cart.type';
import { BrokenImage } from '@mui/icons-material';

interface OrderNotificationProps {
	date: Date;
	productList: CartProduct[];
	onAdd?: (products: CartProduct[]) => void;
}

export const OrderNotification = ({ date, productList, onAdd }: OrderNotificationProps): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { dateFormat } = useDateUtils();
	const currentLanguage = useCurrentLanguage();

	const handleAddLastOrderProducts = () => {
		onAdd?.(productList);
	};

	return (
		<Paper elevation={0} sx={{ p: 2 }}>
			<Grid container alignItems="center" spacing={3}>
				<Grid item>
					<>
						<Typography>
							<b>
								{t('order-notification.title')} ({format(new Date(date), dateFormat)})
							</b>
						</Typography>
						<Grid container>
							{productList.map(({ image, volume, title }, index) => (
								<Grid item key={index}>
									<Box display="flex" alignItems="center">
										{image ? (
											<img
												width={25}
												height={25}
												src={image}
												alt={title[currentLanguage]}
												title={title[currentLanguage]}
											/>
										) : (
											<Box
												sx={{
													width: 25,
													height: 25,
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<BrokenImage />
											</Box>
										)}

										<Typography sx={{ ml: 1 }} variant="caption">
											{volume}L
										</Typography>
									</Box>
								</Grid>
							))}
						</Grid>
					</>
				</Grid>
				<Grid item>
					<Button variant="contained" color="primary" onClick={handleAddLastOrderProducts}>
						{t('order-notification.button')}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
};
