import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Typography } from '@mui/material';
import { RoleSelect } from '@components/Members/RoleSelect';
import { useTranslation } from 'react-i18next';

interface MemberEditRoleModalContentProps {
	firstName: string;
	lastName: string;
	onSubmit: (role: string) => void;
	value: string;
	options: { label: string; value: string }[];
}

const getValidationSchema = (t: (value: string) => string): ReturnType<typeof Yup.object> =>
	Yup.object().shape({
		role: Yup.string().required(t('form.role.required')),
	});

export const MemberEditRoleModalContent: FC<MemberEditRoleModalContentProps> = ({
	onSubmit,
	value,
	options,
	firstName,
	lastName,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const form = useFormik<{ role: string }>({
		initialValues: {
			role: value,
		},
		onSubmit: (values) => onSubmit(values.role),
		validationSchema: getValidationSchema(t),
	});

	return (
		<Box sx={{ minWidth: 400 }}>
			<Typography sx={{ mb: 3 }}>{`${firstName} ${lastName}`}</Typography>
			<RoleSelect
				error={form.errors.role}
				touched={form.touched.role}
				value={form.values.role}
				onChange={form.handleChange}
				options={options}
			/>
			<Box pt={3} display="flex" justifyContent="center">
				<Button color="primary" variant="contained" onClick={() => form.handleSubmit()}>
					{t('shared.save')}
				</Button>
			</Box>
		</Box>
	);
};
