import { Store } from '../../generated/graphql';

export const sortRaces = <T extends Partial<Store>>(races: T[]): T[] => {
	return races.sort((track, nextTrack) => {
		if (track && nextTrack && new Date(track.visible_from).getTime() < new Date(nextTrack.visible_from).getTime()) {
			return -1;
		}
		return 0;
	});
};
