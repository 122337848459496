import { useMemo } from 'react';
import { Group, UseGroupEditionCreateStore } from './useGroupEdition.type';
import { useCreateGroupStoreMutation } from '../../../../generated/graphql';
import { formatStoreForGroupEdition } from './groupEdition.core';

import { useCurrentLanguage } from '@core/utils/language.utils';
import { GroupProduct } from '@core/hooks/group/group.type';

export const useGroupEditionCreateStore = (): UseGroupEditionCreateStore => {
	const [createStore, { data, error, loading }] = useCreateGroupStoreMutation();
	const currentLanguage = useCurrentLanguage();

	const handleCreateStore = async (
		{ title, sapPriceList, entryScore, referringCustomer }: Omit<Group, 'id'>,
		productList: GroupProduct[],
		defaultProductList: string[]
	): Promise<Group | undefined> => {
		const result = await createStore({
			variables: {
				data: {
					code: title.en,
					name: title.en,
					hidden: false,
					title,
					data: {
						entry_score: entryScore,
						sap_price_list: sapPriceList,
						default_products_sku: defaultProductList,
						referring_customer: referringCustomer,
					},
					business_unit: 'resellers',
				},
			},
		});
		if (result.data?.createStore) {
			return formatStoreForGroupEdition(result.data.createStore, currentLanguage);
		}

		return;
	};

	return useMemo(
		() => [
			handleCreateStore,
			{
				data: data?.createStore ? formatStoreForGroupEdition(data.createStore, currentLanguage) : undefined,
				error: !!error,
				loading,
			},
		],
		[data, error, loading]
	);
};
