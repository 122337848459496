import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Button, Card } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { format } from 'date-fns';
import { useDateUtils } from '@core/utils/date.util';
import { ROUTES_URI } from '../../../app/router/routes';
import { useOrdersContext } from '@components/shared/order/context/orders.context';
import { Filter } from '../../../../generated/graphql';

const OrdersTable = ({ filters }: { filters: Filter[] }): JSX.Element => {
	const { orderList, pagination, get, rowPerPage } = useOrdersContext();

	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number): void => {
		get({ limit: { page_number: pageNumber + 1, count: rowPerPage }, filters });
	};

	const handleRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		get({ limit: { page_number: 1, count: +evt.target.value }, filters });
	};

	const { dateFormat } = useDateUtils();
	return (
		<Card square={true}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('shared.date')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.first-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.last-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('orders.quantity')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('orders.ht-amount')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('orders.rib-informed')}</Box>
						</TableCell>
						<TableCell align="right" />
					</TableRow>
				</TableHead>
				<TableBody>
					{orderList?.map((order) => (
						<TableRow key={order.id}>
							<TableCell>{format(new Date(order.creationDate), dateFormat)}</TableCell>
							<TableCell>{order.firstname}</TableCell>
							<TableCell>{order.lastname}</TableCell>
							<TableCell>{order.volume}L</TableCell>
							<TableCell>{order.total}</TableCell>
							<TableCell>{t('shared.no')}</TableCell>
							<TableCell align="right">
								<Button
									component={Link}
									to={generatePath(ROUTES_URI.ORDERS_DETAILS, { id: order.id })}
									color="primary"
									startIcon={<ArrowRightIcon fontSize="small" />}
									variant="outlined"
								>
									{t('shared.see-details')}
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				rowsPerPage={rowPerPage}
				count={pagination.total_result}
				page={pagination.current_page ? pagination.current_page - 1 : 0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleRowsPerPageChange}
			/>
		</Card>
	);
};

export default OrdersTable;
