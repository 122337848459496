import React, { FC, useState } from 'react';
import i18next from 'i18next';
import { useCartContext } from '@core/services/cart/cart.context';
import { EventProductDetailModal } from '@components/customer/event/types/event-detail-modal-props.type.';
import { StoreProductVariationsPriceFieldsFragment } from '../../../generated/graphql';
import { getMediaUrl } from '@core/services/media';
import { Grid } from '@mui/material';
import { CustomerProductCard } from '@components/customer/CustomerProductCard';
import { ProductDetailModal } from '@components/customer/ProductDetailModal/ProductDetailModal';
import { ProductListPropsType } from '@components/customer/event/event-details/event-details-props.type';

export const CustomerProductList: FC<ProductListPropsType> = ({ products, raceName, storeId }) => {
	const currentLanguage = i18next.language || 'en';
	const { add } = useCartContext();

	const [modalProduct, setModalProduct] = useState<EventProductDetailModal | undefined>();

	const handleOpenModal = (
		product: StoreProductVariationsPriceFieldsFragment['product'],
		variation: StoreProductVariationsPriceFieldsFragment['variation'],
		index: number
	) => {
		setModalProduct({
			id: variation.id,
			title: product.title,
			index,
			image: product.image && getMediaUrl(product.image),
			description: product.description,
			specifications: product.data.specifications,
			conditioning: variation.options.map(({ value }) => value.split('_').join('x') + 'L'),
		});
	};
	const handleCloseModal = () => {
		setModalProduct(undefined);
	};

	const handleAddProduct = (id: string, qty: number): void => {
		const { product, variation, price, inventoryId } = products.find(({ variation }) => variation.id === id);
		const [increment = 0, quantity = 0] = variation?.options?.[0].value.split('_') || [];
		add({
			quantity: qty,
			inventoryId,
			sku: variation.sku,
			price: price.custom_price || price.price,
			volume: +quantity,
			storeId,
			incrementVolume: +increment,
			description: raceName,
			image: product.image,
			title: product.title,
			qtyIncrement: variation.qty_increment,
		});
	};

	return (
		<Grid container spacing={2}>
			{products?.map(({ product, variation, price }, index) => {
				const [increment = 0, quantity = 0] = variation?.options?.[0].value.split('_') || [];

				return (
					<Grid item key={index} sm={12} md={6} lg={4}>
						<CustomerProductCard
							id={variation.id}
							title={product.title[currentLanguage]}
							image={product.image}
							type={product.categories.reduce(
								(concatTitle, { title }) => `${concatTitle} ${title[currentLanguage]}`,
								''
							)}
							onClick={() => handleOpenModal(product, variation, index)}
							currency={'€'}
							onSubmit={handleAddProduct}
							price={price.custom_price || price.price}
							volume={{ increment: +increment, quantity: +quantity }}
							volumeUnit={'L'}
						/>
					</Grid>
				);
			})}
			{modalProduct && (
				<ProductDetailModal
					open={!!modalProduct}
					product={modalProduct}
					onClose={handleCloseModal}
					onAddProductToCart={handleAddProduct}
				/>
			)}
		</Grid>
	);
};
