import { GetProductTypeQuery, useGetProductTypeQuery } from '../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { DataHook } from '../../types/dataHook.type';
import { ProductType } from '../../types/product.types';

const formatProductTypeListFromStore = (
	currentLanguage: string,
	data?: GetProductTypeQuery['categories']['categories']
): ProductTypeData[] => {
	return (
		data?.[0].childs?.map(({ id, title, name }) => ({
			label: title[currentLanguage],
			id,
			name: name as ProductType,
		})) || []
	);
};

export interface ProductTypeData {
	label: string;
	id: string;
	name: ProductType;
}

type UseProductType = DataHook<ProductTypeData[], undefined>;

export const useProductType = (): UseProductType => {
	const currentLanguage = useCurrentLanguage();
	const {
		data: productTypeData,
		loading,
		error,
	} = useGetProductTypeQuery({
		variables: {
			filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'name', value: 'productType' }],
		},
	});
	const productType = productTypeData
		? formatProductTypeListFromStore(currentLanguage, productTypeData?.categories.categories)
		: undefined;
	return { data: productType, loading, error: !!error };
};
