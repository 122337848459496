// react
import React, { FunctionComponent } from 'react';
// Form
import { useFormik } from 'formik';
import { contactDetailsFormSchema } from './contactDetailsForm.schema';
//Type
import { FormDataType } from '../shared/types/formData.type';
// Components
import { ContactDetailsFieldsTemplate } from '../../shared/ContactDetailsFieldsTemplate';
// Utils
import { useTranslation } from 'react-i18next';
// Mui
import { Checkbox, FormControlLabel, Button, Typography, Box } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';

const ContactDetailsForm: FunctionComponent<FormDataType> = ({ formData, next }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			receiveEmail: false,
		},
		validationSchema: contactDetailsFormSchema,
		onSubmit: (values) => {
			formData.owner = {
				profile_data: {
					first_name: values.firstName,
					last_name: values.lastName,
					mail: values.email,
				},
				optin: values.receiveEmail,
			};
			next();
		},
	});

	return (
		<>
			<Box mb={3}>
				<Typography variant="h3" align={'center'} color={'primary'}>
					{t('register.contact-details.title')}
				</Typography>
			</Box>

			<Typography sx={{ mb: 3 }} paragraph color={'primary'}>
				{t('register.contact-details.details')}
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<ContactDetailsFieldsTemplate
					firstName="firstName"
					lastName="lastName"
					email="email"
					errors={formik.errors}
					formik={formik}
					touched={formik.touched}
					valueFirstName={formik.values.firstName}
					valueLastName={formik.values.lastName}
					valueEmail={formik.values.email}
				/>

				<FormControlLabel
					sx={{ mb: 3 }}
					control={
						<Checkbox
							checked={formik.values.receiveEmail}
							onChange={formik.handleChange}
							name="receiveEmail"
							color="primary"
						/>
					}
					label={t<string>('register.contact-details.form.send-mails')}
				/>

				<Box display="flex" justifyContent="flex-end" alignItems="center">
					<Button
						startIcon={<ArrowRightIcon fontSize="small" />}
						color="primary"
						type="submit"
						variant="contained"
					>
						{t('shared.validate')}
					</Button>
				</Box>
			</form>
		</>
	);
};

export default ContactDetailsForm;
