import React, { FC, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useParams } from 'react-router-dom';
import OrdersTable from '../event/partials/orders-table';
import { Box, Button, Container, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { RaceDetails } from '@components/seller/event/types/race-details.type';
import EventDetailsHeader from '@components/shared/event/event-details-header';
import {
	Filter,
	useGetRaceCategoryQuery,
	useGetRaceInventoriesLazyQuery,
	useGetStoreProductVariationsWithPricesLazyQuery,
} from '../../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { ProductList } from '@components/seller/event-details/partials/product-list';
import { ROUTES_URI } from '../../app/router/routes';
import { OrdersProvider } from '@components/shared/order/context/orders.provider';
import { useOrdersContext } from '@components/shared/order/context/orders.context';

const EventDetails: FunctionComponent<{ eventName: string; races: RaceDetails[] }> = ({ eventName, races }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { id } = useParams();
	const [trackIndex, setTrackIndex] = useState(0);
	const currentStore = races[trackIndex];

	const [getInventories, { data: eventInventoryData }] = useGetRaceInventoriesLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [getEventProducts, { data: productVariationsData }] = useGetStoreProductVariationsWithPricesLazyQuery({
		fetchPolicy: 'network-only',
	});

	const { data: storeCategoriesData } = useGetRaceCategoryQuery({
		fetchPolicy: 'network-only',
		variables: {
			filter: [
				{ comparison: FILTER_COMPARISON.EQUAL, value: races[trackIndex].id, key: 'store_id' },
				{ comparison: FILTER_COMPARISON.IN, value: ['default', 'optional'], key: 'name' },
			],
		},
	});

	const eventProducts =
		productVariationsData?.storeProductVariationsWithPrices?.sort((a, b) =>
			a.variation.sku > b.variation.sku ? -1 : 1
		) || [];
	const defaultProductVariations = storeCategoriesData?.categories.categories
		.find(({ name }) => name === 'default')
		?.variations?.sort((a, b) => (a > b ? -1 : 1));
	const optionalProductVariations = storeCategoriesData?.categories.categories
		.find(({ name }) => name === 'optional')
		?.variations?.sort((a, b) => (a > b ? -1 : 1));

	const prev = trackIndex !== 0;
	const next = trackIndex !== races.length - 1;

	useEffect(() => {
		if (trackIndex > -1) {
			getInventories({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, value: races[trackIndex].id, key: 'store_id' }],
				},
			});
		}
	}, [trackIndex]);

	useEffect(() => {
		if (eventInventoryData && eventInventoryData.inventories.inventories.length)
			getEventProducts({
				variables: {
					store_id: currentStore.id,
				},
			});
	}, [eventInventoryData]);

	const filters = useMemo(
		() => [{ comparison: FILTER_COMPARISON.EQUAL, value: races[trackIndex].id, key: 'store_id' }],
		[trackIndex, races]
	);

	return (
		<>
			<EventDetailsHeader
				eventName={eventName}
				tracks={races}
				setTrackIndex={setTrackIndex}
				trackIndex={trackIndex}
				prev={prev}
				next={next}
				sellerData={{
					percentageOrder: '40%', // todo remove mock
				}}
			/>

			<Box pt={4}>
				<Container>
					<ProductList
						allProducts={eventProducts}
						filterProducts={defaultProductVariations}
						title={t('event-details.products')}
						titleColorPrimary
					/>

					{optionalProductVariations?.length ? (
						<Box mt={4}>
							<ProductList
								allProducts={eventProducts}
								filterProducts={optionalProductVariations}
								title={t('event-details.seller-total-products-optional')}
							/>
						</Box>
					) : null}

					<OrdersProvider
						filters={[]}
						limit={{ count: 10, page_number: 1 }}
						sort={{ keys: ['created_at'], sort: -1 }}
					>
						<Orders filters={filters} />
					</OrdersProvider>

					<Box textAlign="right" mb={3}>
						<Button
							component={Link}
							to={generatePath(ROUTES_URI.TRACK_EDIT, { id, trackId: races[trackIndex]?.id })}
							startIcon={<EditIcon fontSize="small" />}
							color="primary"
							variant="contained"
						>
							{t('shared.edit')}
						</Button>
					</Box>
				</Container>
			</Box>
		</>
	);
};

const Orders: FC<{ filters: Filter[] }> = ({ filters }) => {
	const { orderList, get } = useOrdersContext();
	const { t } = useTranslation('acs', { useSuspense: false });

	useEffect(() => {
		if (filters) {
			get({ filters, limit: { count: 10, page_number: 1 } });
		}
	}, [filters]);

	return orderList ? (
		<>
			<Box mb={3} mt={3}>
				<Typography variant="h2" color="primary">
					{t('shared.orders')}
				</Typography>
			</Box>

			<Box mb={3}>
				<OrdersTable filters={filters} />
			</Box>
		</>
	) : null;
};

export default EventDetails;
