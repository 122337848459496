// react
import React, { useEffect, useState } from 'react';
// Mui
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// Data fetch
import { useUserProductsEdition, useUpdateCustomerProduct } from '@core/hooks/group/useUserProductsEdition';
import { useProductType } from '@core/hooks/useProductType';
// Component
import { Loader } from '@components/shared/loader';
import { TransferProduct, TransferProductColumns } from '@components/TransferProduct';
import { ProductRow } from '@components/TransferProduct/partials';
import { ArrowBackIos } from '@mui/icons-material';
// Type
import { GroupProduct } from '@core/hooks/group/group.type';
import { TransferListProps } from '@tmds-io/kore';
import { ProductType } from '../../types/product.types';
// Hooks
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

const customList: TransferListProps<GroupProduct>['customList'] = ({ data, column, onClick }) => {
	return (
		<>
			{data
				.filter(({ isHide }) => !isHide)
				.map((props) => (
					<ProductRow key={props.id} onClick={() => onClick(props.id)} {...props} column={column} />
				))}
		</>
	);
};

/**
 * UserProductsEdition components -
 */
export const UserProductsEdition = (): JSX.Element => {
	const { id } = useParams();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	// Hook query mutation
	const { data, loading, error } = useUserProductsEdition();
	const [updateCustomerProducts, { data: customerContractUpdated, error: updateError }] = useUpdateCustomerProduct();
	const { data: productTypes } = useProductType();
	// Initial selected product list
	const [initialProductIdList, setInitialProductIdList] = useState<{ id: string; sku: string }[]>([]);

	const handleSelectItem = (columns: TransferProductColumns<GroupProduct>): void => {
		updateCustomerProducts(
			id,
			[...initialProductIdList],
			columns.right.map(({ id, sku }) => ({ id, sku }))
		);
		setInitialProductIdList(columns.right.map(({ id, sku }) => ({ id, sku })));
	};

	useEffect(() => {
		if (data) {
			setInitialProductIdList(
				data.availableProducts
					.filter(({ id }) => data.selectedProductIds.includes(id))
					.map(({ id, sku }) => ({ id, sku }))
			);
		}
	}, [data]);

	useEffect(() => {
		if (customerContractUpdated) {
			enqueueSnackbar(t('user-product-edition.contract-products-updated-success'), { variant: 'success' });
		}
	}, [customerContractUpdated]);

	useEffect(() => {
		if (updateError) {
			enqueueSnackbar(t('user-product-edition.contract-products-updated-error'), { variant: 'error' });
		}
	}, [updateError]);

	if (loading) {
		return <Loader />;
	}

	if (error) {
		return (
			<Box>
				<Typography>{t('user-products-edition.error')}</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Typography variant="h1" color="primary">
				{t('user-products-edition.title')} {data.user.userName}
			</Typography>
			<Box pt={3}>
				<Typography sx={{ mb: 3 }} variant="h2">
					{t('user-products-edition.subtitle')}
				</Typography>
				<TransferProduct
					itemList={data.availableProducts}
					selectedItem={data.selectedProductIds}
					itemTypeList={[{ name: ProductType.ALL }, ...productTypes]}
					onChange={handleSelectItem}
					customList={customList}
				/>
			</Box>
			<Box pt={3} display="flex" justifyContent="flex-end">
				<Button
					color="primary"
					variant="contained"
					startIcon={<ArrowBackIos fontSize="small" />}
					onClick={() => navigate(-1)}
				>
					{t('shared.back')}
				</Button>
			</Box>
		</Box>
	);
};
