// react
import React, { ChangeEvent, FC, useState } from 'react';
import { Box, Button, Card, CardContent, Grid, IconButton, Link as MuiLink, Typography } from '@mui/material';
import UserKeyValueDetail from '@components/seller/user/partials/users-details-colums';
import { Cancel, Edit, GetApp as DownloadIcon } from '@mui/icons-material';
import { FileInput, IbanField } from '@tmds-io/kore';
import { getMediaUrl, uploadMedia } from '@core/services/media';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

// Components properties
interface IbanCardProps {
	values: BankAccountFormValues;
	onSubmit: (values: BankAccountFormValues) => void;
}

export const bankAccountFormSchema = Yup.object().shape({
	rib: Yup.string().required(),
	iban: Yup.string().required(),
	swift: Yup.string().required(),
});

export interface BankAccountFormValues {
	rib: string;
	iban: string;
	swift: string;
}

/**
 * IbanCard components -
 */
export const IbanCard: FC<IbanCardProps> = ({ values: initialValues, onSubmit }) => {
	const [editRib, setEditRib] = useState<boolean>(false);
	const [uploadNewRib, setUploadNewRib] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('acs', { useSuspense: false });
	const form = useFormik<BankAccountFormValues>({
		initialValues,
		onSubmit: (values) => {
			setUploadNewRib(false);
			onSubmit(values);
		},
	});

	const handleCancelRibUpdate = (): void => {
		setEditRib(false);
		form.setFieldValue('iban', form.initialValues.iban);
	};

	const uploadRib = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const result = await uploadMedia(evt.target.files[0]);

		if (result) {
			void form.setFieldValue('rib', `!${result.key}`);
		} else {
			enqueueSnackbar('The file is not uploaded', { variant: 'error' });
		}
	};

	return (
		<Card>
			<CardContent>
				<Typography paragraph variant="h6" color="primary">
					<b>{t('user-details.iban')}</b>
				</Typography>

				<Grid container spacing={2} sx={{ mb: 3 }}>
					<Grid item xs={12}>
						{!editRib ? (
							<Box display="flex" alignItems="center">
								<UserKeyValueDetail
									editMode
									label={t('IBAN')}
									detailKey={'iban'}
									onCancel={form.setFieldValue}
									detailValue={form.values.iban}
									onChange={form.handleChange}
									error={form.touched?.iban && form.errors?.iban}
								/>
								<IconButton sx={{ ml: 2, mb: 2 }} onClick={() => setEditRib(true)} size="small">
									<Edit />
								</IconButton>
							</Box>
						) : (
							<Box display="flex" alignItems="center">
								<IbanField
									label="IBAN"
									InputProps={{ disableUnderline: true }}
									name="iban"
									error={form.touched?.iban && !!form.errors?.iban}
									value={form.values.iban}
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									helperText={form.touched?.iban && form.errors?.iban}
								/>
								<IconButton sx={{ ml: 2 }} onClick={handleCancelRibUpdate} size="small">
									<Cancel />
								</IconButton>
							</Box>
						)}
					</Grid>
					<Grid item xs={12}>
						<UserKeyValueDetail
							editMode
							editable
							label={t('Swift')}
							detailKey={'swift'}
							onCancel={form.setFieldValue}
							detailValue={form.values.swift}
							onChange={form.handleChange}
							error={form.touched?.swift && form.errors?.swift}
						/>
					</Grid>
				</Grid>

				{form.values.rib && !uploadNewRib ? (
					<>
						<Button
							component={MuiLink}
							href={getMediaUrl(form.values.rib)}
							download
							target="_blank"
							rel="noreferrer"
							startIcon={<DownloadIcon />}
							color="primary"
							variant="contained"
						>
							{t('user-details.download-iban')}
						</Button>
						<IconButton onClick={() => setUploadNewRib(true)}>
							<Edit />
						</IconButton>
					</>
				) : (
					<>
						{!form.values.rib && (
							<Typography paragraph color="primary">
								{t('user-details.iban-info')}
							</Typography>
						)}

						<FileInput
							name="file"
							variant="contained"
							color="primary"
							label={t('user-details.upload-iban')}
							onChange={uploadRib}
						/>
					</>
				)}
				<Typography variant="caption">{t('customer.user-profile.rib-info')}</Typography>
			</CardContent>
			<Box display="flex" justifyContent="center" p={2}>
				<Button color="primary" variant="contained" onClick={() => form.handleSubmit()}>
					{t('shared.save')}
				</Button>
			</Box>
		</Card>
	);
};
