// React
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
// Helper
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { useErrorNotification } from '@core/hooks/useErrorNotification';
// Routes
import { ROUTES_URI } from '../app/router/routes';
import { generatePath, useNavigate, useParams, Link } from 'react-router-dom';
// Type
import { ModalPropsType } from '@components/shared/modal/types/modal-props.type';
// Components
import { FileCopyOutlined as CopyIcon, Add as AddIcon } from '@mui/icons-material';
import { DialogWithIcon } from '@tmds-io/kore';
import { Box, Button, ButtonBase, Card, CircularProgress, Typography } from '@mui/material';
// Data fetching
import { useSendThirdPartMessageMutation } from '../../generated/graphql';

interface Store {
	id: string;
	status: string;
	title: Record<string, string>;
	children?: Store[];
}

interface RowProps {
	onClick: () => void;
	active?: boolean;
	title: string;
	endComponent?: ReactElement;
}

const Row = ({ onClick, active, title, endComponent }: RowProps) => {
	return (
		<ButtonBase onClick={onClick} sx={{ minHeight: 40, width: 500 }}>
			<Card
				square
				sx={{
					width: '100%',
					p: 1,
					borderBottom: `2px solid ${active ? 'red' : 'gray'}`,
				}}
			>
				<Box display="flex" alignItems="center" justifyContent="space-between" alignContent="center">
					<Typography color={active ? 'primary' : 'inherit'}>
						<Box fontWeight="bold" component="span">
							{title}
						</Box>
					</Typography>

					{endComponent}
				</Box>
			</Card>
		</ButtonBase>
	);
};

const StoreCloneModal: FunctionComponent<ModalPropsType & { storeList: Store[] }> = ({ onClose, open, storeList }) => {
	const currentLanguage = useCurrentLanguage();
	const { id } = useParams();
	const sendErrorNotification = useErrorNotification();
	const navigate = useNavigate();
	const { t } = useTranslation('acs', { useSuspense: false });
	const [copyStore, { data: storeCopied, error: storeCopyError, loading: storeCopyLoading }] =
		useSendThirdPartMessageMutation();
	const [selectedStoreId, setSelectedStoreId] = useState<string | undefined>();

	const handleClose = () => {
		onClose();
	};

	const handleSelectEvent = (id?: string) => () => {
		if (id === selectedStoreId) {
			setSelectedStoreId(undefined);
		} else {
			setSelectedStoreId(id);
		}
	};

	const handleCopyStore = (): void => {
		void copyStore({
			variables: {
				code: 'duplicate_race',
				data: {
					store_id: selectedStoreId,
				},
				need_response: true,
				timeout: 30,
			},
		});
	};

	useEffect(() => {
		if (storeCopyError || storeCopied?.sendThirdPartMessage?.failed) {
			sendErrorNotification('event.new-modal-copy-error', storeCopyError ? 'Store copy error' : 'Store copy failed');
		}
	}, [storeCopyError, storeCopied?.sendThirdPartMessage?.failed]);

	useEffect(() => {
		if (storeCopied && storeCopied.sendThirdPartMessage?.failed === false) {
			navigate(generatePath(ROUTES_URI.TRACK_EDIT, { id, trackId: storeCopied.sendThirdPartMessage.data.store_id }));
		}
	}, [storeCopied]);

	return (
		<DialogWithIcon
			icon={<CopyIcon fontSize="large" color="primary" />}
			title={t('event.add-track-modal-title')}
			content={
				<Box mb={2} width="100%" position="relative" display="flex">
					<Box display="flex" alignItems="center" flexDirection="column" width="100%">
						{storeList.map((store) => (
							<Row
								key={store.id}
								active={store.id === selectedStoreId}
								onClick={handleSelectEvent(store.id)}
								title={store.title[currentLanguage]}
							/>
						))}
					</Box>
				</Box>
			}
			open={open}
			onClose={handleClose}
			actionsContent={
				<Box display="flex" px={2}>
					<Button
						sx={{ mr: 2 }}
						variant="contained"
						disabled={!selectedStoreId || storeCopyLoading}
						startIcon={storeCopyLoading ? <CircularProgress size={16} /> : <CopyIcon fontSize="small" />}
						color="primary"
						onClick={handleCopyStore}
					>
						{t('event.duplicate-track-action')}
					</Button>
					<Button
						variant="outlined"
						startIcon={<AddIcon fontSize="small" />}
						color="primary"
						component={Link}
						to={generatePath(ROUTES_URI.TRACK_ADD, { id })}
					>
						{t('event.add-track-action')}
					</Button>
				</Box>
			}
		/>
	);
};

export default StoreCloneModal;
