// React
import React, { FunctionComponent, useEffect } from 'react';
// Form
import { useFormik } from 'formik';
// Types
import { FormDataType } from '../shared/types/formData.type';
// Utils
import { useTranslation } from 'react-i18next';
// Mui
import { Button, Box, Typography } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
// Shared components
import EventSelectionForm from '@components/shared/event/event-selection-form';
// Gql
import { Store, useGetThirdPartDataMutation } from '../../../generated/graphql';

const ChampionshipsForm: FunctionComponent<FormDataType> = ({ formData, next }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const [getEvents, { data: response }] = useGetThirdPartDataMutation({
		variables: {
			code: 'championships',
			needResponse: true,
			timeout: 30,
		},
	});

	useEffect(() => {
		void getEvents();
	}, []);

	const formik = useFormik<{ championships: { name: string; id: string }[] }>({
		initialValues: {
			championships: [{ name: '', id: '' }],
		},
		onSubmit: (values) => {
			formData.stores = values.championships.map((c) => c.id).filter((eventId) => eventId);
			next();
		},
	});

	return (
		<>
			<Box mb={3}>
				<Typography variant="h3" align="center" color="primary">
					{t('register.championships.form.title')}
				</Typography>
			</Box>

			<Typography sx={{ mb: 3 }} paragraph>
				{t('register.championships.form.details')}
			</Typography>

			<EventSelectionForm
				data={response?.sendThirdPartMessage?.data?.results as Store[]}
				formik={formik}
				width="100%"
			/>

			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Button startIcon={<ArrowRightIcon fontSize="small" />} onClick={() => next()}>
					{t('shared.skip')}
				</Button>
				<Button
					disabled={!formik.dirty}
					variant="contained"
					startIcon={<ArrowRightIcon fontSize="small" />}
					color="primary"
					onClick={() => formik.handleSubmit()}
				>
					{t('shared.validate')}
				</Button>
			</Box>
		</>
	);
};

export default ChampionshipsForm;
