import React, { FunctionComponent, useState } from 'react';
import EventPriceModificationModal from '@components/seller/event/modals/event-price-modification-modal';
import { ProductsTemplatePropsType } from '@components/seller/event/types/products-template-props.type';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ArrowBack, BrokenImage, CreateOutlined as EditIcon, DeleteOutline as DeleteIcon } from '@mui/icons-material';

const OrderedProductsTemplate: FunctionComponent<ProductsTemplatePropsType> = ({ product, onClick, onPriceChange }) => {
	const [showModal, setShowModal] = useState(false);

	const handlePriceSubmit = (price: number): void => {
		onPriceChange?.(product.priceId, price);
	};

	const handleRemoveCustomPrice = (): void => {
		onPriceChange?.(product.priceId, 0);
	};

	return (
		<Grid container borderBottom="1px solid #e8e8e8" width="100%" alignItems="center" sx={{ py: 2 }}>
			<Grid container item alignItems="center" spacing={2}>
				<Grid item>
					<IconButton size="small" onClick={() => onClick(product.sku)}>
						<ArrowBack color="primary" fontSize="large" />
					</IconButton>
				</Grid>
				<Grid item>{product?.img ? <img width="50px" src={product.img} alt="" /> : <BrokenImage />}</Grid>

				<Grid item xs>
					<Typography>
						<b>{product.name}</b> <span>{product.type}</span>
					</Typography>
					<Box sx={{ overflow: 'hidden', maxHeight: 42 }}>
						<Typography>
							<small>{product.details}</small>
						</Typography>
					</Box>
				</Grid>
				<Grid item container flexDirection="column" height="80px" xs="auto">
					<Typography>
						<Box component="b" sx={{ mr: 1 }}>
							{product.customPrice || product.price}
							{product?.currency === 'EUR' ? '€' : product?.currency}/L
						</Box>
						<IconButton size="small" onClick={() => setShowModal(true)}>
							<EditIcon color="primary" />
						</IconButton>
						{product.customPrice && (
							<IconButton size="small" onClick={handleRemoveCustomPrice}>
								<DeleteIcon color="primary" />
							</IconButton>
						)}
					</Typography>
					<Box mt="auto">
						<Typography variant="h6">
							<b>
								{product.quantity}
								{product.quantityUnit}
							</b>
						</Typography>
					</Box>
				</Grid>
			</Grid>

			<EventPriceModificationModal
				onClose={() => setShowModal(false)}
				open={showModal}
				price={product.price}
				onSubmit={handlePriceSubmit}
			/>
		</Grid>
	);
};

export default OrderedProductsTemplate;
