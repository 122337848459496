// react
import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { ApolloProvider, ApolloClient, from, InMemoryCache, HttpLink, ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const httpLink = new HttpLink({
	uri: window._env_.API_ORIGIN,
	credentials: window._env_.CLIENT_FETCH_CREDENTIALS ?? 'same-origin',
});

const errorLink = (handleError: (errorCode: number) => void) =>
	onError(({ graphQLErrors, networkError, response, operation }) => {
		if (networkError) {
			console.error(`[Network error]: ${networkError}, Operation: ${operation.operationName}`);
			handleError((networkError as ServerError)?.statusCode || 500);
		}

		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) => {
				console.error(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Operation: ${operation.operationName}`
				);
				if (message.includes('forbidden')) {
					handleError(403);
				} else if (message.includes('internal')) {
					// handleError(500);
				} else if (!response || !response.data) {
					// handleError(422);
				}
			});
	});

/**
 * ApolloProvider components -
 */
export const TmdsApolloProvider: FC = ({ children }) => {
	const [error, setError] = useState<number | undefined>();
	const handleError = (code: number): void => {
		setError(code);
	};

	const apolloClient = useMemo(
		() =>
			new ApolloClient({
				link: from([errorLink(handleError), httpLink]),
				cache: new InMemoryCache(),
				defaultOptions: {
					query: {
						errorPolicy: 'all',
					},
					mutate: {
						errorPolicy: 'all',
					},
					watchQuery: {
						errorPolicy: 'all',
					},
				},
			}),
		[]
	);

	return (
		<ApolloProvider client={apolloClient}>
			<TmdsApolloContext.Provider value={{ error }}>{children}</TmdsApolloContext.Provider>
		</ApolloProvider>
	);
};

const TmdsApolloContext = createContext<{ error: number | undefined }>({ error: undefined });
export const useApolloError = (): { error: number | undefined } => useContext(TmdsApolloContext);
