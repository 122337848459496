import * as Yup from 'yup';

export const businessFormSchema = Yup.object().shape({
	socialReason: Yup.string().required('Social reason is required'),
	country: Yup.string().required('Country is required'),
	address: Yup.string().required('Address is required'),
	phone: Yup.string().required('Phone number is required'),
	zipCode: Yup.string().required('Zip code is required'),
	city: Yup.string().required('City is required'),
	companyNumber: Yup.string().required('Company number is required'),
	haveAdditionalVat: Yup.boolean().required(),
	vatNumber: Yup.lazy((value, resolve) => {
		if (resolve.parent.country === 'fr') {
			return Yup.string().required('business-form.vat-required');
		}
		return Yup.string();
	}),
	additionalVat: Yup.lazy((value: string[], resolve) => {
		if (resolve.parent.haveAdditionalVat && value.filter((val) => val === '').length > 1) {
			return Yup.array().of(Yup.string().required('business-form.intra-community-vat-required'));
		}
		return Yup.array().of(Yup.string());
	}),
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	email: Yup.string().email('Email is invalid').required('Email is required'),
});
