// Core
import React from 'react';
// utils
import i18next from 'i18next';
// Routing
import { ROUTES_URI } from '../../../app/router/routes';
// Types
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { ProductType } from '../../../../types/product.types';
import { ProductValues } from '@components/seller/product/types/products.type';
import { ProductCardProps } from '@components/seller/product/types/product-card.type';
// gql
import {
	GetProductsQuery,
	GetProductTypeQuery,
	Product,
	ProductInput,
	Filter,
	GetCategoryProductsQuery,
} from '../../../../generated/graphql';
// utils
import { generatePath } from 'react-router-dom';
import { getMediaUrl } from '@core/services/media';
// Components
import { BrokenImage as BrokenImageIcon } from '@mui/icons-material';

export const formatProductFragmentToProductCard = (product: Product): ProductCardProps => {
	const currentLanguage = i18next.language || 'en';
	return {
		type: product.categories?.[0]?.title[currentLanguage || 'en'] || '',
		name: product.title[currentLanguage || 'en'],
		id: product.sku,
		restricted: product.restricted_product,
		conditioningList:
			product?.variations
				?.map(({ options }) => {
					return options?.[0].value.split('_').join('x') + 'L';
				})
				.filter((val) => val) || [],

		detail: product.description[currentLanguage || 'en'],
		img: product?.image ? getMediaUrl(product.image) : <BrokenImageIcon fontSize="large" />,

		href: generatePath(ROUTES_URI.PRODUCTS_DETAILS, { id: product?.id }),
	};
};

export const getFilter = (name?: string, idList?: string[]): Filter[] => {
	const keyFilter = idList && { comparison: FILTER_COMPARISON.IN, key: '_key', value: idList };
	const filter: Filter[] = name ? [{ comparison: FILTER_COMPARISON.LIKE, key: 'name', value: `%${name}%` }] : [];
	if (keyFilter) {
		filter.push(keyFilter);
	}
	return filter;
};

export const formatProductValuesToProductInput = (values: ProductValues): ProductInput => ({
	sku: values.sku,
	name: values.productName,
	image: values.image,
	documents: values.documents,
	product_type: values.sapProductType,
	title: {
		fr: values.productNameFR || values.productName,
		en: values.productName,
	},
	categories: [values.productType],
	description: {
		fr: values.productDescriptionFR || values.productDescription,
		en: values.productDescription,
	},
	currency: '€',
	qty_unit: 'L',
	data: {
		specifications: {
			fr: values.productSpecificationsFR || values.productSpecifications,
			en: values.productSpecifications,
		},
	},
	restricted_product: values.productRestricted,
});

export const formatProductTypeListFromStore = (
	currentLanguage: string,
	data?: GetProductTypeQuery['categories']['categories']
): { label: string; value: string; name: string }[] => {
	return (
		data?.[0].childs?.map(({ id, title, name }) => ({
			label: title[currentLanguage],
			value: id,
			name,
		})) || []
	);
};

export const getProductList = (
	filter: string,
	products?: GetProductsQuery,
	productFromCategory?: GetCategoryProductsQuery,
	search?: string
): ProductCardProps[] | undefined => {
	if (filter === ProductType.ALL) {
		return (products?.products.products as Product[])?.map(formatProductFragmentToProductCard);
	}
	const productList = (productFromCategory?.category.products as Product[])?.map(formatProductFragmentToProductCard);
	if (search && productList) {
		return productList.filter((product) => product.name.toLowerCase().includes(search.toLowerCase()));
	}
	return productList?.sort((a, b) => (a.id > b.id ? -1 : 1)) || [];
};
