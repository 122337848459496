import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useTextFieldStyle = makeStyles((theme: Theme) =>
	createStyles({
		light: {
			backgroundColor: theme.palette.background.paper,
		},
	})
);
