// React
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CartProps } from '@components/customer/cart/cart-props.type';
import { Box, Typography, Card, Button, CardContent, Grid, IconButton, Paper, CircularProgress } from '@mui/material';
import { useCartContext } from '@core/services/cart/cart.context';
import { FILTER_COMPARISON, NumberRangeInput, useUserContext } from '@tmds-io/kore';
import { BrokenImage, DeleteOutline, Lock } from '@mui/icons-material';
import i18next from 'i18next';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
import { CartProduct } from '@core/services/cart/cart.type';
import { useGetGroupContractsQuery } from '../../../generated/graphql';
import { Loader } from '@components/shared/loader';
import { useUserHelper } from '@core/hooks/useUserHelper';

interface ProductGroup {
	title: Record<string, string>;
	products: CartProduct[];
}

const groupProductByRace = (products: CartProduct[]): ProductGroup[] => {
	return products.reduce((groupList, product) => {
		const groupIndex = groupList.findIndex(({ store_id }) => store_id === product.storeId);
		if (groupIndex === -1) {
			return [
				...groupList,
				{
					store_id: product.storeId,
					products: [{ ...product, quantity: product.quantity / product.qtyIncrement }],
					title: product.description,
				},
			];
		}
		const newGroupList = [...groupList];
		newGroupList[groupIndex].products.push({ ...product, quantity: product.quantity / product.qtyIncrement });
		return newGroupList;
	}, []);
};

export const Cart: FC<CartProps> = ({ currency = '€' }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const [isValidating, setIsValidating] = useState<boolean>(false);
	const { validate } = useCartContext();
	const { id } = useUserContext();
	const { isReseller } = useUserHelper();
	const currentLanguage = i18next.language || 'en';
	const {
		data: contractData,
		loading,
		error,
	} = useGetGroupContractsQuery({
		variables: {
			filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: id }],
		},
	});

	const { products, update, remove, total, totalQuantity } = useCartContext();

	const groupList = groupProductByRace(products);

	const orderQuantityLimit = contractData?.contracts.contracts?.[0].min_quote_orderable_qty || 0;
	const validationLock = isReseller ? totalQuantity < orderQuantityLimit : false;
	const validationDisabled = isValidating || validationLock;

	const handleValidate = (): void => {
		if (!isValidating) {
			setIsValidating(true);
			validate();
		}
	};

	const handleQuantityChange = (inventoryId: string) => (evtOrValue: ChangeEvent<HTMLInputElement> | string) => {
		let value: number;
		if (typeof evtOrValue === 'string') {
			value = +evtOrValue;
		} else {
			value = +evtOrValue.target.value;
		}
		update(inventoryId, value);
	};

	const handleDelete = (id: string) => (): void => {
		remove(id);
	};

	useEffect(() => {
		if (error) {
			throw Error('No contract for user');
		}
	}, [error]);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			<Typography variant="h1">{t('cart.page.title')}</Typography>
			{groupList.length ? (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{groupList.map(({ title, products }) => (
							<Box key={title[currentLanguage]} mt={2}>
								{!isReseller ? <Typography variant="h2">{t(title[currentLanguage])}</Typography> : null}
								<Box mt={2}>
									{products.map(
										(
											{
												sku,
												quantity,
												title,
												image,
												price,
												inventoryId,
												volume,
												incrementVolume,
												qtyIncrement,
											},
											index
										) => (
											<Card sx={{ mb: 2 }} key={sku + index}>
												<CardContent>
													<Grid container spacing={2}>
														<Grid item>
															<Box
																width={64}
																height={64}
																display="flex"
																justifyContent="center"
																alignItems="center"
															>
																{image ? (
																	<img width="100%" src={image} alt="" />
																) : (
																	<BrokenImage fontSize="large" />
																)}
															</Box>
														</Grid>
														<Grid item xs>
															<Typography>{title[currentLanguage]}</Typography>
															<Typography>
																{incrementVolume}x{volume}L
															</Typography>
															<Typography>
																<em>{price.toFixed(2)}€/L</em>
															</Typography>
														</Grid>
														<Grid item>
															<Box display="flex" alignItems="center" height="100%">
																<Box mt={-2} mr={2}>
																	<NumberRangeInput
																		min={0}
																		disabled={isValidating}
																		name="quantity"
																		onChange={handleQuantityChange(inventoryId)}
																		value={quantity}
																	/>
																</Box>
																<Typography style={{ whiteSpace: 'nowrap' }}>
																	<b>
																		{quantity * incrementVolume * volume}L:{' '}
																		{(quantity * qtyIncrement * price).toFixed(2)}€
																	</b>{' '}
																	HT
																</Typography>
																<Box ml={2}>
																	<IconButton
																		onClick={handleDelete(inventoryId)}
																		disabled={isValidating}
																	>
																		<DeleteOutline color="primary" />
																	</IconButton>
																</Box>
															</Box>
														</Grid>
													</Grid>
												</CardContent>
											</Card>
										)
									)}
								</Box>
								<Box display="flex" justifyContent="flex-end">
									<Typography variant="h3">
										{t('cart.page.subtotal')} :{' '}
										<Typography component="span" sx={{ fontWeight: 'normal' }}>
											{products
												.reduce(
													(sum, { quantity, price, qtyIncrement }) =>
														parseFloat((sum + quantity * qtyIncrement * price).toFixed(2)),
													0
												)
												.toFixed(2)}
											€
										</Typography>
									</Typography>
								</Box>
							</Box>
						))}
					</Grid>
				</Grid>
			) : null}
			{!groupList.length ? (
				<Typography variant="h2" sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
					<ShoppingCartIcon fontSize="large" sx={{ mr: 1 }} />
					<Box component="span" fontWeight={400}>
						{t('cart.page.empty-cart')}
					</Box>
				</Typography>
			) : (
				<>
					{validationLock ? (
						<Paper sx={{ py: 2, px: 3, maxWidth: 350, margin: 'auto' }}>
							<Grid container spacing={3}>
								<Grid item>
									<Lock color="primary" fontSize="large" />
								</Grid>
								<Grid item xs>
									<Typography
										dangerouslySetInnerHTML={{ __html: t('cart.lock1', { quantity: orderQuantityLimit }) }}
									/>
									<Typography
										dangerouslySetInnerHTML={{
											__html: t('cart.lock2', { quantity: orderQuantityLimit - totalQuantity }),
										}}
									/>
								</Grid>
							</Grid>
						</Paper>
					) : null}

					<Box pt={4}>
						<Box display="inline-block">
							<Card>
								<Box p={2} display="inline-block">
									{t('cart.page.total')} :{' '}
									<Box component="span">
										{total.toFixed(2)} {currency}
									</Box>
								</Box>
							</Card>
						</Box>

						<Box py={3} display="flex" justifyContent="flex-end">
							<Button
								variant="outlined"
								color="primary"
								size="large"
								component={Link}
								sx={{ mr: 3 }}
								to={generatePath(ROUTES_URI.PRODUCTS)}
							>
								{t('cart.btn.back-to-products')}
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={handleValidate}
								disabled={validationDisabled}
								sx={{ display: isValidating ? 'none' : undefined }}
							>
								{t('shared.validate')}
							</Button>
							<Button
								variant="contained"
								color="primary"
								size="large"
								disabled={validationDisabled}
								startIcon={<CircularProgress size={25} />}
								sx={{ display: isValidating ? undefined : 'none' }}
							>
								{t('shared.validate')}
							</Button>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};
