import { GroupProduct } from '@core/hooks/group/group.type';
import { useMemo, useState } from 'react';
import { Group, GroupInventory, UseGroupEditionUpdateGroup } from './useGroupEdition.type';
import { useGroupEditionCreateInventory } from './useGroupEditionCreateInventory';
import { useGroupEditionDeleteInventory } from './useGroupEditionDeleteInventory';
import { useGroupEditionUpdateStore } from './useGroupEditionUpdateStore';
import { useSendThirdPartMessageMutation } from '../../../../generated/graphql';

interface State {
	loading: boolean;
	error: boolean;
	data?: Group;
}

const getInventoriesDiff = (
	inventories: GroupInventory[],
	newProductList: GroupProduct[]
): [GroupProduct[], GroupInventory[]] => {
	const newInventoriesProducts = newProductList.filter(({ sku, parentSku }) => {
		return !inventories.find(({ productSku: iSku, parentSku: iPSku }) => sku === iSku && parentSku === iPSku);
	});
	const deleteInventories = inventories.filter(({ productSku: iSku, parentSku: iPSku }) => {
		return !newProductList.find(({ sku, parentSku }) => sku === iSku && parentSku === iPSku);
	});
	return [newInventoriesProducts, deleteInventories];
};

export const useGroupEditionUpdateGroup = (): UseGroupEditionUpdateGroup => {
	const [updateGroup, { error: groupError, loading: groupLoading }] = useGroupEditionUpdateStore();
	const [createInventory, { error: inventoryError, loading: inventoryLoading }] = useGroupEditionCreateInventory();
	const [deleteInventory, { error: inventoryDeleteError, loading: inventoryDeleteLoading }] =
		useGroupEditionDeleteInventory();
	const [assignDefaultProduct] = useSendThirdPartMessageMutation();
	const [state, setState] = useState<State>({ loading: false, error: false, data: undefined });

	const handleAssignDefaultProductToUsers = async (storeId: string, productSku: string): Promise<boolean> => {
		const result = await assignDefaultProduct({
			variables: {
				code: 'new_default_product',
				data: {
					store_id: storeId,
					sku: productSku,
				},
				need_response: true,
				timeout: 30,
			},
		});
		if (result.data.sendThirdPartMessage.failed) {
			console.error('Error: cannot add default product to user group; store %s, product: %s', storeId, productSku);
			return false;
		}
		return true;
	};

	const handleUpdateGroup = async (
		group: Group,
		inventories: GroupInventory[],
		productList: GroupProduct[],
		defaultProductList: string[]
	): Promise<void> => {
		setState({ loading: true, error: false, data: undefined });
		const [newProductList, inventoriesToDelete] = getInventoriesDiff(inventories, productList);

		const groupPromise = updateGroup(group, productList, defaultProductList);
		const inventoriesDeletionPromises = inventoriesToDelete.map(({ id }) => deleteInventory(id));
		const inventoriesCreationPromises = newProductList.map((product) =>
			createInventory(product, group.id, product.price)
		);
		const defaultProductAssignToUsersPromises = defaultProductList.map((productSku) =>
			handleAssignDefaultProductToUsers(group.id, productSku)
		);

		Promise.all([
			groupPromise,
			...inventoriesDeletionPromises,
			...inventoriesCreationPromises,
			...defaultProductAssignToUsersPromises,
		])
			.then(([groupData]) => {
				setState({ loading: false, error: false, data: groupData });
			})
			.catch(() => {
				setState({ loading: false, error: true, data: undefined });
			});
	};

	return useMemo(
		() => [
			handleUpdateGroup,
			{
				data: state.data,
				error: groupError || inventoryError || state.error || inventoryDeleteError,
				loading: groupLoading || inventoryLoading || state.loading || inventoryDeleteLoading,
			},
		],
		[groupError, inventoryError, groupLoading, inventoryLoading, state, inventoryDeleteError, inventoryDeleteLoading]
	);
};
