export enum ORDER_STATUS {
	WAITING_FOR_PAYMENT = 'waiting_for_payment',
	WAITING_FOR_PAYMENT_RECEPTION = 'waiting_for_payment_reception',
	CONFIRMED = 'confirmed',
	PAID = 'paid',
	INVOICED = 'invoiced',
	NEW = 'new',
	CREATED = 'created',
	NO_STATUS = 'empty',
	CANCELLED = 'canceled',
}
