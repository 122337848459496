import React, { FC, useEffect, useRef } from 'react';
import { OrdersContext } from '@components/shared/order/context/orders.context';
import { Filter, Limit, Sort } from '../../../../generated/graphql';
import { useLazyOrders } from '@core/hooks/order/useOrders';

export interface OrdersProviderProps {
	filters?: Filter[];
	sort?: Sort;
	limit?: Limit;
	lazy?: boolean;
}

export const OrdersProvider: FC<OrdersProviderProps> = ({
	children,
	filters: initialFilters,
	sort: initialSort,
	limit: initialLimit,
	lazy,
}) => {
	const [fetch, { data }] = useLazyOrders();

	const orderList = data ? data.orders : [];
	const pagination = data
		? data.pagination
		: {
				total_result: 0,
				current_page: 0,
				result_count: 0,
				last_page: false,
				total_pages: 0,
		  };
	const lastFilter = useRef<Filter[]>([]);
	const lastLimit = useRef<Limit>(initialLimit);
	const lastSort = useRef<Sort>(initialSort);

	const handleFetch = ({
		filters = [],
		limit = undefined,
		sort = undefined,
	}:
		| {
				filters?: Filter[];
				limit?: Limit;
				sort?: Sort;
		  }
		| undefined) => {
		const newLimit = limit || lastLimit.current;
		const newSort = sort || lastSort.current;
		const newFilters = filters || lastFilter.current;
		void fetch({
			filters: [...initialFilters, ...newFilters],
			limit: newLimit,
			sort: newSort,
		});
		lastFilter.current = newFilters;
		lastLimit.current = newLimit;
		lastSort.current = newSort;
	};

	useEffect(() => {
		if (!lazy) {
			fetch({
				filters: initialFilters,
				sort: initialSort,
				limit: initialLimit,
			});
		}
	}, [lazy]);

	return (
		<OrdersContext.Provider
			value={{
				orderList,
				get: handleFetch,
				pagination,
				rowPerPage: lastLimit.current.count,
			}}
		>
			{children}
		</OrdersContext.Provider>
	);
};
