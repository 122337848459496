// react
import React, { FC } from 'react';
// Mui
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
// Routing
import { Link } from 'react-router-dom';

/**
 * Layout profile component properties
 */
interface LayoutProps {
	title: string;
	backLink: string;
}

/**
 * Layout profile components is profile page content wrapper
 */
export const Layout: FC<LayoutProps> = ({ children, title, backLink }) => {
	return (
		<Box pb={4}>
			<Box display="flex" alignItems="center" mb={4}>
				<Box mr={1}>
					<IconButton component={Link} size="small" to={backLink}>
						<ChevronLeftIcon color="secondary" fontSize="large" />
					</IconButton>
				</Box>
				<Typography variant="h1">{title}</Typography>
			</Box>
			<Box display="flex" justifyContent="center" m="auto" width={600}>
				{children}
			</Box>
		</Box>
	);
};
