// react
import React from 'react';
import { render } from 'react-dom';

// Components
import { App } from '@components/app/app';
import { TealiumProvider, theme as koreTheme } from '@tmds-io/kore';
import i18n from './i18n';
import { DebugProvider } from '@core/debug/debugContext';
import { TmdsApolloProvider } from '@core/services/apollo';
import { HelmetProvider } from 'react-helmet-async';

// Styles
import { ThemeProvider as LegacyThemeProvider } from '@mui/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Monitoring
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const getEnvironment = (): { env: string; tracesSampleRate: number } => {
	const { hostname } = window.location;
	switch (hostname) {
		case 'front-dev.tmds.local':
			return { env: 'local', tracesSampleRate: 1.0 };
		case 'front.tmds.local':
			return { env: 'development', tracesSampleRate: 1.0 };
		case 'cm.pprod-acs.tmds.io':
			return { env: 'preprod', tracesSampleRate: 0.75 };
		default:
			return { env: 'production', tracesSampleRate: 0.5 };
	}
};

if (['preprod', 'production', 'development'].includes(getEnvironment().env)) {
	Sentry.init({
		release: 'front-racing-line@1.51.10',
		dsn: 'https://6308e01d680e4e3da2b9cab80244eff7@o1173761.ingest.sentry.io/6567169',
		integrations: [new BrowserTracing()],
		environment: getEnvironment().env,
		tracesSampleRate: getEnvironment().tracesSampleRate,
	});
}

i18n.init();

(() => {
	render(
		<React.StrictMode>
			<DebugProvider>
				<HelmetProvider>
					<TealiumProvider utagUri={window._env_.TEALIUM_URI}>
						<TmdsApolloProvider>
							<ThemeProvider theme={koreTheme}>
								<LegacyThemeProvider theme={koreTheme}>
									<CssBaseline />
									<App />
								</LegacyThemeProvider>
							</ThemeProvider>
						</TmdsApolloProvider>
					</TealiumProvider>
				</HelmetProvider>
			</DebugProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
})();
