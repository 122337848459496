import Users from '../../seller/user/users';
import UserDetails from '@components/seller/user-details';
import SharedProfile from '@components/shared/profile/profile';
import Events from '@components/seller/event/events';
import EventEdit from '@components/seller/event-edit/edit';
import EventCreate from '@components/seller/event/create';
import EditTrack from '@components/seller/event/edit-track';
import AddTrack from '@components/seller/event/add-track';
import { Products } from '@components/seller/product';
import { ProductEdition } from '@components/seller/product-edition';
import { Cart } from '@components/customer/cart';
import { Payment } from '@components/customer/payment/payment';
import { EventDetails as SellerEventDetails } from '@components/seller/event-details';
import { ProductDetails } from '@components/seller/product-details';
import { HeaderMenu } from '@components/shared/common/header/types/header-props.type';

import EventDetails from '@components/customer/event/event-details';
import { Profile } from '../../../pages/customer/Profile';
import { CompanyAndMainContact } from '../../../pages/customer/Profile/CompanyAndMainContact';
import { OrderDetails } from '@components/customer/orders';
import { Orders as SellerOrders } from '@components/seller/orders';
import { CustomerSelection } from '@components/seller/customer-selection';
import { CallbackPayment } from '@components/customer/payment/callback-payment';
import { Register } from '../../../pages/Register';
import { Orders as CustomerOrders } from '@components/customer/orders/orders';
import { FailPayment } from '../../../pages/customer/FailPayment';
import { CompanyAndMainContactEdit } from '../../../pages/customer/Profile/CompanyAndMainContact/edit';
import { GroupsListing } from '../../../pages/seller/GroupsListing';
import { generatePath } from 'react-router-dom';
import { GroupDetails } from '../../../pages/seller/GroupDetails';
import { GroupEdition } from '../../../pages/seller/GroupEdition';
import { Members } from 'pages/seller/Members';
import { Members as CustomerMembers } from 'pages/customer/Members';
import { PaymentDetails } from '../../../pages/customer/Profile/PaymentDetails';
import { Products as CustomerProductsPage } from '../../../pages/customer/Products';
import { UserProductsEdition } from '../../../pages/seller/UserProductsEdition';
import { ResellerCustomer } from '../../../pages/seller/ResellerCustomer';
import { ResellerProfile } from '../../../pages/customer/ResellerProfile';

export enum ROUTES_URI {
	HOME = '/',

	INFOS = '/infos',
	PROFILE = '/profile/*',
	PROFILE_DETAILS = '/profile/company-and-main-contact',
	PROFILE_PAYMENT = '/profile/payment-details',
	PROFILE_EDIT = '/profile/company-and-main-contact/edit',

	USERS = '/users/*',
	USER_DETAILS = '/users/:id/details',
	USER_PRODUCTS = '/users/:id/products',
	CUSTOMER_ADD = '/customer/create',
	CUSTOMER_EDIT = '/customer/edit/:id',

	EVENTS = '/events',
	EVENT_ADD = '/event/add',
	EVENT_DETAILS_DEFAULT = '/event/:id/details/*',
	EVENT_DETAILS = '/event/:id/details/:trackId',
	EVENT_EDIT = '/event/:id/edit',

	TRACK_ADD = '/event/:id/add',
	TRACK_EDIT = '/event/:id/edit/:trackId',

	PRODUCTS = '/products/*',
	PRODUCTS_DETAILS = '/products/details/:id',
	PRODUCTS_EDIT = '/products/edit/:id',
	PRODUCTS_CREATE = '/products/create',
	REGISTER = '/register',
	CART = '/cart',
	PAYMENT = '/payment/:id',
	CALLBACK_PAYMENT_SUCCESS = '/payment/success',
	CALLBACK_PAYMENT_FAILURE = '/payment/failure',
	ORDERS = '/orders',
	ORDERS_DETAILS = '/orders/:id/details',

	CUSTOMER_SELECTION = '/products/details/:id/user-selection',

	GROUPS = '/groups/*',
	GROUP_DETAILS = '/groups/:id',
	GROUP_CREATE = '/groups/create',
	GROUP_EDIT = '/groups/:id/edit',

	MEMBERS = '/members',
}

export const PublicRoutes = [
	{
		path: ROUTES_URI.REGISTER,
		exact: true,
		component: Register,
	},
];

export const getCustomerRoutes = (isReseller?: boolean): HeaderMenu[] => [
	{
		path: ROUTES_URI.CART,
		exact: true,
		component: Cart,
	},
	{
		path: ROUTES_URI.CALLBACK_PAYMENT_SUCCESS,
		exact: true,
		component: CallbackPayment,
	},
	{
		path: ROUTES_URI.CALLBACK_PAYMENT_FAILURE,
		exact: true,
		component: FailPayment,
	},
	{
		path: ROUTES_URI.PAYMENT,
		exact: true,
		component: Payment,
	},
	{
		path: ROUTES_URI.PRODUCTS,
		name: 'menu.products',
		exact: true,
		component: CustomerProductsPage,
	},
	{
		path: ROUTES_URI.EVENT_DETAILS,
		exact: true,
		component: EventDetails,
	},
	{
		path: ROUTES_URI.EVENT_DETAILS_DEFAULT,
		exact: true,
		component: EventDetails,
	},
	{
		path: ROUTES_URI.PROFILE,
		exact: true,
		component: SharedProfile,
	},
	{
		path: ROUTES_URI.PROFILE_DETAILS,
		exact: true,
		component: CompanyAndMainContact,
	},
	{
		path: ROUTES_URI.PROFILE_PAYMENT,
		exact: true,
		component: PaymentDetails,
	},
	{
		path: ROUTES_URI.PROFILE_EDIT,
		exact: true,
		component: CompanyAndMainContactEdit,
	},
	{
		path: ROUTES_URI.ORDERS,
		name: 'menu.orders',
		exact: true,
		component: CustomerOrders,
	},
	{
		path: ROUTES_URI.ORDERS_DETAILS,
		exact: true,
		component: OrderDetails,
	},
	{
		path: generatePath(ROUTES_URI.INFOS),
		exact: true,
		name: 'menu.infos',
		component: isReseller ? ResellerProfile : Profile,
	},
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getSellerRoutes = (_debug?: boolean): HeaderMenu[] => [
	{
		path: ROUTES_URI.CUSTOMER_ADD,
		component: ResellerCustomer,
	},
	{
		path: ROUTES_URI.CUSTOMER_EDIT,
		component: ResellerCustomer,
	},
	{
		path: ROUTES_URI.USERS,
		name: 'shared.users',
		exact: true,
		component: Users,
	},
	{
		path: generatePath(ROUTES_URI.GROUPS),
		name: 'menu.groups',
		exact: true,
		component: GroupsListing,
	},
	{
		path: ROUTES_URI.GROUP_DETAILS,
		exact: true,
		component: GroupDetails,
	},
	{
		path: ROUTES_URI.GROUP_CREATE,
		component: GroupEdition,
	},
	{
		path: ROUTES_URI.GROUP_EDIT,
		component: GroupEdition,
	},
	{
		path: ROUTES_URI.PRODUCTS_CREATE,
		component: ProductEdition,
	},
	{
		path: ROUTES_URI.USER_PRODUCTS,
		component: UserProductsEdition,
	},
	{
		path: ROUTES_URI.PRODUCTS_EDIT,
		component: ProductEdition,
	},

	{
		path: ROUTES_URI.PRODUCTS_DETAILS,
		exact: true,
		component: ProductDetails,
	},
	{
		path: ROUTES_URI.CUSTOMER_SELECTION,
		component: CustomerSelection,
	},
	{
		path: ROUTES_URI.EVENT_DETAILS_DEFAULT,
		exact: true,
		component: SellerEventDetails,
	},
	{
		path: ROUTES_URI.PRODUCTS,
		name: 'menu.products',
		exact: true,
		component: Products,
	},
	{
		path: ROUTES_URI.USER_DETAILS,
		exact: true,
		component: UserDetails,
	},
	{
		path: ROUTES_URI.EVENTS,
		name: 'shared.events',
		exact: true,
		component: Events,
	},
	{
		path: ROUTES_URI.EVENT_ADD,
		exact: true,
		component: EventCreate,
	},
	{
		path: ROUTES_URI.TRACK_ADD,
		exact: true,
		component: AddTrack,
	},
	{
		path: ROUTES_URI.TRACK_EDIT,
		exact: true,
		component: EditTrack,
	},
	{
		path: ROUTES_URI.EVENT_DETAILS,
		exact: true,
		component: EventDetails,
	},
	{
		path: ROUTES_URI.EVENT_EDIT,
		exact: true,
		component: EventEdit,
	},
	{
		path: ROUTES_URI.PROFILE,
		exact: true,
		component: SharedProfile,
	},
	{
		path: ROUTES_URI.ORDERS,
		name: 'menu.orders',
		exact: true,
		component: SellerOrders,
	},
	{
		path: ROUTES_URI.ORDERS_DETAILS,
		exact: true,
		component: OrderDetails,
	},
];

export const getSellerAdminRoutes = (debug?: boolean): HeaderMenu[] => [
	...getSellerRoutes(debug),
	{
		path: ROUTES_URI.MEMBERS,
		name: 'shared.members',
		component: Members,
	},
];

export const getCustomerAdminRoutes = (isReseller?: boolean): HeaderMenu[] => [
	...getCustomerRoutes(isReseller),
	{
		path: ROUTES_URI.MEMBERS,
		name: 'shared.members',
		component: CustomerMembers,
	},
];
