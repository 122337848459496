import React, { forwardRef, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { FormSubmitProps } from '../types/form-submit-props.type';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { ROUTES_URI } from '../../../app/router/routes';
import { useSendThirdPartMessageMutation } from '../../../../generated/graphql';

/**
 * Form payment submit, generate html form with input data for payment post redirection
 * @param paymentButton
 * @param buttonText
 * @param disabled
 * @param paymentId
 * @param paymentType
 * @param async
 */
export const FormSubmit = forwardRef<HTMLButtonElement, FormSubmitProps>(
	({ paymentButton, buttonText, disabled, paymentId, paymentType, async = false }, ref) => {
		const { t } = useTranslation('acs', { useSuspense: false });
		const { enqueueSnackbar } = useSnackbar();
		const navigate = useNavigate();
		const [paymentValidation] = useSendThirdPartMessageMutation();
		const [loading, setLoading] = useState<boolean>(false);

		const handlePaymentMethod = async (): Promise<boolean> => {
			await paymentValidation({
				variables: {
					code: 'payment_method',
					data: {
						payment_id: paymentId,
						payment_type: paymentType,
					},
					need_response: true,
					timeout: 30,
				},
			});
			return true;
		};

		const handleSyncPayment = async (): Promise<void> => {
			setLoading(true);
			await handlePaymentMethod();
			const form = document.createElement('form');
			document.body.appendChild(form);
			form.method = paymentButton.method;
			form.action = paymentButton.url;
			paymentButton.body.forEach(({ name, value, type }) => {
				const input = document.createElement('input');
				input.type = type;
				input.name = name;
				input.value = value;
				form.appendChild(input);
			});
			form.submit();
		};

		const handleAsyncPayment = async (): Promise<void> => {
			setLoading(true);
			await handlePaymentMethod();

			const data = await fetch(paymentButton.url, {
				method: paymentButton.method,
				body: paymentButton.body.reduce(
					(body, { name, value }, index) => (index !== 0 ? '&' : '') + body + name + '=' + value,
					''
				),
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				credentials: 'include',
			});

			if (data.ok) {
				enqueueSnackbar(t('payment.delay-payment.success'), { variant: 'success' });
				setLoading(false);
				navigate(ROUTES_URI.ORDERS);
			} else {
				enqueueSnackbar(t('payment.delay-payment.error'), { variant: 'error' });
				setLoading(false);
			}
		};

		if (!paymentButton) {
			return (
				<Button color="primary" variant="contained" disabled>
					{buttonText}
				</Button>
			);
		}

		return (
			<>
				<Button
					ref={ref}
					color="primary"
					variant="contained"
					type="submit"
					disabled
					startIcon={<CircularProgress size={16} />}
					sx={{ display: loading ? undefined : 'none' }}
				>
					{buttonText}
				</Button>
				<Button
					ref={ref}
					color="primary"
					variant="contained"
					type="submit"
					disabled={disabled || loading}
					onClick={async ? handleAsyncPayment : handleSyncPayment}
					sx={{ display: loading ? 'none' : undefined }}
				>
					{buttonText}
				</Button>
			</>
		);
	}
);
