import { createStyles, makeStyles } from '@mui/styles';

export const usePictureProductUploadStyle = makeStyles(() =>
	createStyles({
		pictureWrapper: {
			position: 'relative',
			width: 100,
			height: 100,
			background: '#ffffff',
			margin: 'auto',
			border: '1px solid #cccccc',
			'& img': {
				maxWidth: '100%',
				maxHeight: '100%',
				display: 'block',
				margin: 'auto',
			},
		},
		uploadIcon: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
		},
		picture: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%)',
		},
	})
);
