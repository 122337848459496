// react
import React, { FC, useEffect, useMemo, useState } from 'react';
// Type
import { FILTER_COMPARISON, useUserContext } from '@tmds-io/kore';
// Components
import { EventDetails } from './event-details';
import {
	Filter,
	useGetCustomerProductsLazyQuery,
	useGetRaceCategoryLazyQuery,
	useGetRaceInventoriesLazyQuery,
} from '../../../../generated/graphql';
import { getRaceWithStatus } from '@components/seller/event/utils/track-format';
import { useEventDetailsData } from '@core/hooks/event-detail-data-wrapper';
import { Loader } from '@components/shared/loader';
import { formatEventProduct } from '@components/customer/event/event-details/format-event-product';
import i18next from 'i18next';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '@mui/icons-material';
import OrdersTable from '@components/seller/event/partials/orders-table';
import { sortRaces } from '@core/utils/sortRaces';
import { useOrdersContext } from '@components/shared/order/context/orders.context';
import { OrdersProvider } from '@components/shared/order/context/orders.provider';

const EventDetailsHOC = (): JSX.Element => {
	const currentLanguage = i18next.language || 'en';
	const { id: userId } = useUserContext();
	const { t } = useTranslation('acs', { useSuspense: false });
	const navigate = useNavigate();
	// Hooks
	const [data, loading] = useEventDetailsData();

	const [currentTrack, setCurrentTrack] = useState<number>(0);
	const [getInventories, { data: inventoriesData }] = useGetRaceInventoriesLazyQuery({
		fetchPolicy: 'network-only',
	});
	const inventories = inventoriesData?.inventories.inventories;

	const [getEventProducts, { data: productVariationsData }] = useGetCustomerProductsLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [getOptionalProductIds, { data: optionalProductsData }] = useGetRaceCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});
	const [getDefaultProductIds, { data: defaultProductsData }] = useGetRaceCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const currentRaceId = data?.races ? getRaceWithStatus(sortRaces([...data.races]))[currentTrack].id : undefined;

	const optionalProductVariations = optionalProductsData?.categories.categories[0]?.variations;
	const defaultProductVariations = defaultProductsData?.categories.categories[0]?.variations;

	const defaultProducts =
		inventories &&
		productVariationsData?.customerProducts
			?.sort((a, b) => (a.variation.sku > b.variation.sku ? -1 : 1))
			.filter(({ variation }) => defaultProductVariations?.includes(variation.sku))
			.map((data) => formatEventProduct(data, inventories));

	const optionalProducts =
		inventories &&
		productVariationsData?.customerProducts
			?.sort((a, b) => (a.variation.sku > b.variation.sku ? -1 : 1))
			.filter(({ variation }) => optionalProductVariations?.includes(variation.sku))
			.map((data) => formatEventProduct(data, inventories));

	useEffect(() => {
		if (data?.races) {
			getDefaultProductIds({
				variables: {
					filter: [
						{ comparison: FILTER_COMPARISON.EQUAL, value: currentRaceId, key: 'store_id' },
						{ comparison: FILTER_COMPARISON.EQUAL, value: 'default', key: 'name' },
					],
				},
			});
			getOptionalProductIds({
				variables: {
					filter: [
						{ comparison: FILTER_COMPARISON.EQUAL, value: currentRaceId, key: 'store_id' },
						{ comparison: FILTER_COMPARISON.EQUAL, value: 'optional', key: 'name' },
					],
				},
			});
			getInventories({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.EQUAL,
							value: currentRaceId,
							key: 'store_id',
						},
					],
				},
			});
		}
	}, [currentTrack, currentRaceId]);

	useEffect(() => {
		if (inventories && inventories.length) {
			getEventProducts({
				variables: {
					store_ids: data ? [currentRaceId] : [],
				},
			});
		}
	}, [inventories]);

	const filters = useMemo(
		() => [
			{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: userId },
			{ comparison: FILTER_COMPARISON.EQUAL, key: 'store_id', value: currentRaceId },
		],
		[currentRaceId, userId]
	);

	if (!loading && !data?.races) {
		return (
			<Box p={5} display="flex" alignItems="center" flexDirection="column">
				<Typography>{t('event-details.no-race')}</Typography>
				<Box p={5}>
					<Button variant="outlined" startIcon={<ArrowRight />} color="primary" onClick={() => navigate(-1)}>
						{t('shared.back')}
					</Button>
				</Box>
			</Box>
		);
	}

	return !loading && data?.races ? (
		<>
			<EventDetails
				tracks={getRaceWithStatus(sortRaces([...data.races]))}
				eventName={data.title[currentLanguage]}
				optionalProducts={optionalProducts}
				defaultProducts={defaultProducts}
				currentTrack={currentTrack}
				setCurrentTrack={setCurrentTrack}
			/>
			<OrdersProvider
				filters={[]}
				limit={{ count: 10, page_number: 1 }}
				sort={{ keys: ['created_at'], sort: -1 }}
				lazy
			>
				<Orders filters={filters} />
			</OrdersProvider>
		</>
	) : (
		<Loader />
	);
};

const Orders: FC<{ filters: Filter[] }> = ({ filters }) => {
	const { orderList, get } = useOrdersContext();
	const { t } = useTranslation('acs', { useSuspense: false });

	useEffect(() => {
		if (filters) {
			get({ filters, limit: { count: 10, page_number: 1 } });
		}
	}, [filters]);

	return (
		orderList && (
			<>
				<Box mb={3} mt={3}>
					<Typography variant="h2" color="primary">
						{t('shared.orders')}
					</Typography>
				</Box>

				<Box mb={3}>
					<OrdersTable filters={filters} />
				</Box>
			</>
		)
	);
};

export default EventDetailsHOC;
