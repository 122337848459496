import { Response } from '@core/utils/apollo/request';
import {
	ConditioningRowType,
	SaleAreaPrice,
} from '@components/seller/product-edition/partials/conditioning-section/partials/conditioning-row/conditioning-props.type';
import { updateProductPrice } from '@components/seller/product/services/price.service';
import { gql } from '@apollo/client';
import { ProductVariationInput, PriceInput } from '../../../../generated/graphql';
import { ADD_PRODUCT_VARIATION, UPDATE_PRODUCT_VARIATION } from '../../../../gql/productVariation/mutation.gql';
import { ADD_PRICES } from '../../../../gql/price/mutation.gql';
import { client } from '@tmds-io/kore';

/**
 * Format conditioning object to product variation for api save
 * @param conditioning
 */
const formatProductVariation = (conditioning: ConditioningRowType): ProductVariationInput => ({
	sku: conditioning.sap,
	options: [{ value: `${conditioning.multiplier}_${conditioning.quantity}` }],
	enable_qty_increment: true,
	qty_increment: conditioning.multiplier * conditioning.quantity,
});

export const addProduct = async (
	productId: string,
	conditioning: ConditioningRowType
): Promise<Response<ConditioningRowType>> => {
	const productVariation = await addProductVariation(productId, formatProductVariation(conditioning));
	if (productVariation.error) {
		return { error: true };
	}

	const pricesInput = formatSalesArea(conditioning.saleAreaPriceList, conditioning.sap);

	const pricesVariation = await addProductVariationPrices(pricesInput);

	if (pricesVariation.error) {
		return { error: true };
	}
	const newSaleAreaList = mapPriceIdOnSalesAreaList(conditioning.saleAreaPriceList, pricesVariation.data);

	const newConditioning = {
		...conditioning,
		id: productVariation.data[productVariation.data.length - 1].id,
		saleAreaList: newSaleAreaList,
	};
	return { error: false, data: newConditioning };
};

const formatSalesArea = (saleAreaList: SaleAreaPrice[], product_sku: string) =>
	saleAreaList.map((saleArea) => ({
		price: saleArea.value,
		product_sku,
		sale_area_id: saleArea.id,
	}));

const mapPriceIdOnSalesAreaList = (
	saleAreaList: SaleAreaPrice[],
	priceList: { id: string; sale_area_id: string[] }[]
): SaleAreaPrice[] =>
	saleAreaList.map((saleArea) => {
		const price = priceList.find(({ sale_area_id }) => sale_area_id.includes(saleArea.id));
		return { ...saleArea, id: price.id };
	});

const getProductVariationPriceUpdate = (
	conditioning: ConditioningRowType,
	newConditioning: ConditioningRowType
): { id: string; price: number; saleAreaId: string; sku: string }[] => {
	return newConditioning.saleAreaPriceList
		.map((salesArea, index) => {
			return salesArea.value !== conditioning.saleAreaPriceList[index].value
				? {
						id: newConditioning.saleAreaPriceList[index].priceId,
						price: newConditioning.saleAreaPriceList[index].value,
						saleAreaId: newConditioning.saleAreaPriceList[index].id,
						sku: newConditioning.sap,
				  }
				: false;
		})
		.filter((update) => !!update) as { id: string; price: number; saleAreaId: string; sku: string }[];
};

export const updateProduct = async (
	productId: string,
	conditioning: ConditioningRowType,
	newConditioning: ConditioningRowType
): Promise<Response<ConditioningRowType>> => {
	// if (checkProductVariationHaveUpdate(conditioning, newConditioning)) {
	const productVariation = await updateProductVariation(
		productId,
		conditioning.id,
		formatProductVariation(newConditioning)
	);
	if (productVariation.error) {
		return productVariation;
	}
	// }
	const productVariationPriceUpdateList = getProductVariationPriceUpdate(conditioning, newConditioning);
	try {
		await Promise.all(
			productVariationPriceUpdateList.map(({ id, price, saleAreaId, sku }) =>
				updateProductPrice(id, {
					price,
					sale_area_id: saleAreaId,
					product_sku: sku,
				})
			)
		);
		return { error: false, data: newConditioning };
	} catch (error) {
		console.error(error);
		return { error: true };
	}
};

export const addProductVariation = async (
	productId: string,
	input: ProductVariationInput
): Promise<Response<{ id: string; sku: string }[]>> => {
	try {
		const { data, errors } = await client.mutate({
			mutation: ADD_PRODUCT_VARIATION,
			variables: { product_id: productId, data: input },
		});
		if (errors) {
			console.error('product.request.fail.add-product-variation');
			return { error: true };
		}

		return { error: false, data: data.addProductVariation.variations };
	} catch (error) {
		console.error(error);
		return { error: true, data: undefined };
	}
};

export const updateProductVariation = async (
	productId: string,
	variationId: string,
	variationData: ProductVariationInput
): Promise<Response<{ sku: string }>> => {
	const { data, errors } = await client.mutate({
		mutation: UPDATE_PRODUCT_VARIATION,
		variables: { product_id: productId, variation_id: variationId, data: variationData },
	});
	if (errors) {
		console.error('product.request.fail.update-product-variation');
		return { error: true };
	}

	return { error: false, data: data.updateProductVariation };
};

export const removeProductVariation = async (product_id: string, variation_id: string): Promise<Response<null>> => {
	const { errors } = await client.mutate({
		mutation: gql`
			mutation DeleteProductVariation($product_id: ID!, $variation_id: ID!) {
				deleteProductVariation(product_id: $product_id, variation_id: $variation_id) {
					id
				}
			}
		`,
		variables: { product_id, variation_id },
	});
	if (errors) {
		console.error('product.request.fail.delete-product-variation');
		return { error: true };
	}
	return { error: false, data: null };
};

export const addProductVariationPrices = async (
	priceInput: PriceInput[]
): Promise<Response<{ id: string; sale_area_id: string[] }[]>> => {
	const { data, errors } = await client.mutate({
		mutation: ADD_PRICES,
		variables: { data: priceInput },
	});
	if (errors) {
		console.error('product.request.fail.add-product-variation-price');
		return { error: true };
	}

	return { error: false, data: data.addPrices };
};
