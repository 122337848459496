import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ChampionshipsFormModalPropsType } from '../types/championships-form-modal-props.type';
import { Dialog } from '@tmds-io/kore';
import { Button } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import EventSelectionForm from '@components/shared/event/event-selection-form';
import { useFormik } from 'formik';
import { GetSellerEventsQuery, Store } from '../../../../generated/graphql';

const ChampionshipsFormModal: FunctionComponent<
	ChampionshipsFormModalPropsType & {
		eventsIds: string[];
		events: GetSellerEventsQuery['paginatedStores']['stores'];
		onSubmit: (stores: GetSellerEventsQuery['paginatedStores']['stores']) => void;
	}
> = ({ setShowModal, showModal, events, eventsIds, onSubmit }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const formik = useFormik<{ championships: { id: string; name: string }[] }>({
		initialValues: {
			championships: [{ id: '', name: '' }],
		},
		onSubmit: (values) => {
			const mapEvents = values.championships.map((event) => event.id);
			const newEvents = events.filter((event) => mapEvents.includes(event.id) && !eventsIds.includes(event.id));
			onSubmit(newEvents);
		},
	});

	const handleClose = () => {
		setShowModal(false);
	};

	return (
		<Dialog
			title={t('events.add')}
			open={showModal}
			onClose={handleClose}
			actionsContent={
				<>
					<Button startIcon={<ArrowRightIcon fontSize="small" />} onClick={handleClose} type="button">
						{t('shared.cancel')}
					</Button>
					<Button
						disabled={!formik.dirty}
						variant="contained"
						startIcon={<ArrowRightIcon fontSize="small" />}
						color="primary"
						type="submit"
						onClick={() => formik.handleSubmit()}
					>
						{t('shared.validate')}
					</Button>
				</>
			}
			content={<EventSelectionForm formik={formik} width="400px" data={events as Store[]} eventsIds={eventsIds} />}
		/>
	);
};

export default ChampionshipsFormModal;
