// react
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
	ProductsColumns,
	RaceProductWrapper,
} from '@components/seller/event/partials/race-products/race-products.type';
import { RaceProducts as RaceProductsComp } from '@components/seller/event/partials/race-products/race-products';
import {
	useAddVariationToTrackMutation,
	useCreateInventoryWithPriceMutation,
	useRemoveVariationsToCategoriesMutation,
	useRemoveVariationToInventoryMutation,
	useUpdatePriceMutation,
} from '../../../../../generated/graphql';
import { formatApiVariationToProductEvent } from '@components/seller/event/partials/transfert-list/transfer-list.core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getProductsColumns } from '@components/seller/event/partials/race-products/race-products-core';
import { useCurrentLanguage } from '@core/utils/language.utils';

export const RaceProducts: FC<RaceProductWrapper> = ({ raceId, salesAreaId, raceProducts, refetchRaceProducts }) => {
	const currentLanguage = useCurrentLanguage();
	const init = useRef<boolean>(false);
	const actionRef = useRef<string>();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const { inventories, optionalRaceCategory, defaultRaceCategory, allProducts } = raceProducts;
	const { refetchInventories, refetchProductOptional, refetchProductDefault, refetchAllProducts } =
		refetchRaceProducts;

	const { enqueueSnackbar } = useSnackbar();

	const [updatePrice, { data: priceUpdated, error: errorPriceUpdate }] = useUpdatePriceMutation();
	const [removeVariationsToCategories, { data: removedFromCategories }] = useRemoveVariationsToCategoriesMutation();
	const [addVariations, { data: addedToCategories }] = useAddVariationToTrackMutation();
	const [createInventoryWithPrice, { data: addedToInventory }] = useCreateInventoryWithPriceMutation();
	const [removeVariationsToInventory, { data: removeFromInventory }] = useRemoveVariationToInventoryMutation();

	// Constants
	const selectedProductDefaultIds = useMemo(
		() => defaultRaceCategory.variations || [],
		[defaultRaceCategory.variations]
	);
	const selectedProductOptionalIds = useMemo(
		() => optionalRaceCategory.variations || [],
		[optionalRaceCategory.variations]
	);

	const catalogProducts = useMemo(
		() =>
			allProducts.map((product) => {
				if (!product.prices) {
					enqueueSnackbar(
						`${t('events.race-products.error-no-prices')} ${product.product.title[currentLanguage]} ${
							product.variation.sku
						}`,
						{ variant: 'error' }
					);
				}
				return formatApiVariationToProductEvent(
					{ ...product, prices: product?.prices || [] },
					raceId,
					salesAreaId,
					currentLanguage
				);
			}),
		[allProducts]
	);

	// States
	const [defaultProducts, setDefaultProducts] = useState<ProductsColumns>(
		getProductsColumns(catalogProducts, selectedProductDefaultIds, selectedProductOptionalIds)
	);

	const [optionalProducts, setOptionalProducts] = useState<ProductsColumns>(
		getProductsColumns(catalogProducts, selectedProductOptionalIds, selectedProductDefaultIds)
	);

	useEffect(() => {
		setDefaultProducts(getProductsColumns(catalogProducts, selectedProductDefaultIds, selectedProductOptionalIds));
		setOptionalProducts(getProductsColumns(catalogProducts, selectedProductOptionalIds, selectedProductDefaultIds));
	}, [selectedProductDefaultIds, selectedProductOptionalIds, catalogProducts]);

	useEffect(() => {
		if (addedToInventory || removeFromInventory) {
			void refetchInventories();
			void refetchAllProducts();
		}
	}, [addedToInventory, removeFromInventory]);
	//
	useEffect(() => {
		if (addedToCategories || removedFromCategories) {
			if (actionRef.current === 'default') {
				void refetchProductDefault();
			} else {
				void refetchProductOptional();
			}
		}
	}, [removedFromCategories, addedToCategories]);

	const handleAddProduct =
		(type: string) =>
		async (id: string): Promise<void> => {
			const product = catalogProducts.find(({ id: prodId }) => prodId === id);
			actionRef.current = type;
			const list = type === 'default' ? defaultProducts : optionalProducts;
			const categoryId = type === 'default' ? defaultRaceCategory.id : optionalRaceCategory.id;
			await addVariations({
				variables: { variations: [product.sku], categories: [categoryId] },
			});
			await createInventoryWithPrice({
				variables: {
					params: {
						store_id: raceId,
						business_unit: 'races',
						product_sku: product.sku,
						parent_sku: product.parentId,
						sale_area_id: salesAreaId,
					},
					price: list.availableProducts.find(({ id: prodId }) => id === prodId).price,
				},
			});
		};

	const handleRemoveProduct =
		(type: string) =>
		async (productSku: string): Promise<void> => {
			actionRef.current = type;
			const category = type === 'default' ? defaultRaceCategory : optionalRaceCategory;
			await removeVariationsToCategories({
				variables: {
					categories: [category.id],
					variations: [productSku],
				},
			});
			const inventory = inventories.find(({ product_sku }) => product_sku === productSku);
			if (inventory) {
				await removeVariationsToInventory({
					variables: {
						id: inventory.id,
					},
				});
			}
		};

	/**
	 * Add custom price or update it
	 * @param priceId
	 * @param price
	 */
	const handleCustomPriceChange = (priceId: string, price: number): void => {
		void updatePrice({
			variables: {
				id: priceId,
				custom_price: price,
			},
		});
	};

	useEffect(() => {
		if (errorPriceUpdate) {
			console.error(errorPriceUpdate);
			enqueueSnackbar(t('events.race-products.error-price-update'), { variant: 'error' });
		}
	}, [errorPriceUpdate]);

	useEffect(() => {
		if (priceUpdated) {
			init.current = false;
			void refetchAllProducts();
		}
	}, [priceUpdated]);

	return (
		<RaceProductsComp
			onAddDefaultProduct={handleAddProduct('default')}
			onRemoveDefaultProduct={handleRemoveProduct('default')}
			onAddOptionalProduct={handleAddProduct('optional')}
			onRemoveOptionalProduct={handleRemoveProduct('optional')}
			defaultProducts={defaultProducts}
			optionalProducts={optionalProducts}
			haveOptionalProducts={!!optionalProducts.selectedProducts.length}
			onPriceChange={handleCustomPriceChange}
		/>
	);
};
