// React
import React, { FC, ReactElement, useState } from 'react';
// Mui
import { Box, Button, ButtonGroup } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

/**
 * Button confirm component properties
 */
interface ButtonConfirmProps {
	onClick?: () => void;
	onConfirm: () => void;
	children: ReactElement;
	buttonGroupSx?: SxProps<Theme>;
}

/**
 * Button confirm display trash button component with button group confirmation after click
 */
export const ButtonConfirm: FC<ButtonConfirmProps> = ({ onClick, onConfirm, children, buttonGroupSx }) => {
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

	const handleClick = () => {
		onClick?.();
		setConfirmDelete(true);
	};

	return (
		<Box position="relative" minHeight={33}>
			{confirmDelete ? (
				<ButtonGroup size="small" sx={buttonGroupSx}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={() => {
							onConfirm();
							setConfirmDelete(false);
						}}
					>
						<CheckIcon fontSize="small" />
					</Button>
					<Button
						style={{ backgroundColor: '#ffffff' }}
						variant="outlined"
						color="primary"
						size="small"
						onClick={() => {
							setConfirmDelete(false);
						}}
					>
						<CloseIcon fontSize="small" />
					</Button>
				</ButtonGroup>
			) : (
				React.cloneElement(children, {
					onClick: handleClick,
				})
			)}
		</Box>
	);
};
