import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDocumentUploadStyle = makeStyles((theme: Theme) =>
	createStyles({
		fileName: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			width: 200,
			textOverflow: 'ellipsis',
			marginRight: theme.spacing(2),
		},
		documentRow: {
			width: 'max-content',
			padding: theme.spacing(1),
			'&:hover': {
				background: 'rgba(0, 0, 0, 0.1)',
			},
		},
		icon: {
			cursor: 'pointer',
			marginRight: theme.spacing(1),
			'&:hover': {
				color: theme.palette.primary.main,
			},
		},
	})
);
