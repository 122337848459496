import { EventProductEditFieldsFragment } from '../../../../../generated/graphql';
import { ProductEvent } from '@components/seller/event/types/product.type';
import { getMediaUrl } from '@core/services/media';
import { ProductType } from '../../../../../types/product.types';

export const formatApiVariationToProductEvent = (
	{ product, variation, prices }: EventProductEditFieldsFragment,
	trackId: string,
	salesAreaId: string,
	currentLanguage: string
): ProductEvent => {
	const defaultPrice = prices.find((price) => price.sale_area_id === salesAreaId && !price.store_id);
	const inventoryPrice = prices.find((price) => price.sale_area_id === salesAreaId && price.store_id === trackId);
	const [increment, quantity] = variation.options[0].value.split('_');
	const price = inventoryPrice || defaultPrice;

	return {
		productId: product.id,
		price: price?.price || 0,
		id: variation.id,
		parentId: product.sku,
		type: product.categories[0].title[currentLanguage],
		typeId: product.categories[0].id,
		quantity: increment + 'x' + quantity,
		priceId: price?.id || '',
		customPrice: price?.custom_price,
		salesAreaId,
		prices: prices,
		sku: variation.sku,
		img: product.image ? getMediaUrl(product.image) : undefined,
		quantityUnit: product.qty_unit,
		currency: product.currency,
		details: product.description[currentLanguage],
		name: product.title[currentLanguage],
		restricted_product: product.restricted_product,
	};
};

export const filterProducts = (products: ProductEvent[], filterType: string, filterName: string): ProductEvent[] =>
	products
		.filter(({ typeId }) => filterType === ProductType.ALL || typeId === filterType)
		.filter(({ name }) => name.toLocaleLowerCase().trim().includes(filterName.toLowerCase().trim()));
