// react
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
// Utils
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Type
import { RegisterModalPropsType } from './registerModal.type';
// Kore
import { DialogWithIcon } from '@tmds-io/kore';
// Mui
import { Button } from '@mui/material';
import { ArrowRight as ArrowRightIcon, Group as GroupIcon } from '@mui/icons-material';
// Type
import { useSignupMutation } from '../../../generated/graphql';

const NoUserAddModal: FunctionComponent<
	RegisterModalPropsType & { setShowFinalModal: Dispatch<SetStateAction<boolean>> }
> = ({ setShowModal, showModal, setShowFinalModal, formData }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();

	const [signUp] = useSignupMutation({
		onCompleted: () => {
			handleClose();
			setShowFinalModal(true);
		},
		onError: (error) => {
			console.error(error.message);
			if (error.message === 'already registered') {
				enqueueSnackbar(t('register.user-already-exist.error'), {
					variant: 'error',
				});
			} else {
				enqueueSnackbar(t('register.non-added-user.popup.error'), {
					variant: 'error',
				});
			}
		},
	});
	const isUsersAdded = formData.profiles;

	const handleClose = () => setShowModal(false);
	return (
		<DialogWithIcon
			icon={<GroupIcon fontSize="large" color="primary" />}
			title={t('register.added-user.popup.title')}
			text={isUsersAdded ? t('register.added-user.popup.body') : t('register.non-added-user.popup.body')}
			open={showModal}
			onClose={handleClose}
			actionsContent={
				<Button
					color="primary"
					variant="contained"
					startIcon={<ArrowRightIcon fontSize="small" />}
					onClick={() => {
						signUp({ variables: { params: formData } });
					}}
				>
					{t('register.non-added-user.popup.footer-action')}
				</Button>
			}
		/>
	);
};

export default NoUserAddModal;
