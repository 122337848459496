import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Box, Typography, Card, Button, IconButton } from '@mui/material';
import { CreateOutlined as CreateIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { EventBox } from '@components/shared/components/EventBox';
import { ROUTES_URI } from '../../app/router/routes';
import { useTheme } from '@mui/material';
import { Store, useGetSellerEventsQuery } from '../../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import i18next from 'i18next';
import { isPast } from 'date-fns';
import { useRaceDate } from '@core/utils/useRaceDate';

const Events: FC = () => {
	const { getLastRaceDate } = useRaceDate();
	const currentLanguage = i18next.language || 'en';
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const theme = useTheme();
	const { data: eventsData } = useGetSellerEventsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			filter: [
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'parent_id', value: '' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'status', value: 'active' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'business_unit', value: 'races' },
			],
		},
	});
	const eventList = eventsData?.paginatedStores.stores || [];

	return (
		<>
			<Box mb={4}>
				<Typography variant="h1" color="primary">
					{t('events.title')}
				</Typography>
			</Box>

			<Box mb={4}>
				<Typography variant="h2">
					<Box fontWeight="normal" component="span">
						{t('events.incoming')}
					</Box>
				</Typography>
			</Box>

			{eventList
				.filter(({ status, children }) =>
					status === 'active' && children.length ? !isPast(new Date(getLastRaceDate(children as Store[]))) : true
				)
				.map(({ id, title }) => (
					<EventBox
						key={id}
						name={title[currentLanguage]}
						eventLink={generatePath(ROUTES_URI.EVENT_DETAILS_DEFAULT, { id })}
						createIconLink={generatePath(ROUTES_URI.EVENT_EDIT, { id })}
					/>
				))}

			<Box textAlign="right" mt={4} mb={4}>
				<Button
					component={Link}
					to={generatePath(ROUTES_URI.EVENT_ADD)}
					variant="contained"
					color="primary"
					startIcon={<AddIcon fontSize="large" />}
				>
					{t('events.add')}
				</Button>
			</Box>

			<Typography variant="h2" sx={{ mb: 2 }}>
				<Box fontWeight="normal" component="span">
					{t('events.passed')}
				</Box>
			</Typography>

			{eventList
				.filter(({ status, children }) =>
					status === 'active' && children.length ? isPast(new Date(getLastRaceDate(children as Store[]))) : true
				)
				.map((event) => (
					<Card
						square
						key={event.id}
						sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, p: 1, mb: 3 }}
					>
						<Box display="flex" alignItems="center" justifyContent="space-between" alignContent="center">
							<Box display="flex" alignItems="center">
								<IconButton
									component={Link}
									to={generatePath(ROUTES_URI.EVENT_EDIT, { id: event.id })}
									size="small"
								>
									<CreateIcon color="primary" fontSize="medium" />
								</IconButton>
								<Box ml={3}>
									<Typography>
										<Box fontWeight="bold" component="span">
											{event.title[currentLanguage]}
										</Box>
									</Typography>
								</Box>
							</Box>

							<IconButton
								component={Link}
								to={generatePath(ROUTES_URI.EVENT_DETAILS_DEFAULT, { id: event.id })}
								size="small"
							>
								<ChevronRightIcon color="secondary" fontSize="large" />
							</IconButton>
						</Box>
					</Card>
				))}
		</>
	);
};

export default Events;
