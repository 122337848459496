// React
import React, { FC, useState } from 'react';
import { ConditioningRow } from '@components/seller/product-edition/partials/conditioning-section/partials/conditioning-row/conditioning-row';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import {
	ConditioningRowProps,
	ConditioningRowType,
} from '@components/seller/product-edition/partials/conditioning-section/partials/conditioning-row/conditioning-props.type';
import {
	addProduct,
	removeProductVariation,
	updateProduct,
} from '@components/seller/product/services/productVariation.service';
import { ConditioningSectionProps } from '@components/seller/product-edition/styles/product-edition.type';
import { Box, Button, Typography } from '@mui/material';
import { useConditioningSectionStyle } from '@components/seller/product-edition/partials/conditioning-section/styles/conditioning-section.type';

export const ConditioningSection: FC<ConditioningSectionProps> = ({
	product,
	conditioningList: cond,
	saleAreaList = [],
}) => {
	const classes = useConditioningSectionStyle();
	const { t } = useTranslation('acs', { useSuspense: true });
	const [disabledConditioning, setDisabledConditioning] = useState<boolean>(false);
	const [conditioningList, setConditioningList] = useState<ConditioningRowType[]>(cond);

	const addConditioning = (): void => {
		setDisabledConditioning(true);
		setConditioningList((condList) => [...condList, { saleAreaPriceList: saleAreaList } as ConditioningRowType]);
	};

	const updateConditioning = (index: number, newConditioning: ConditioningRowType): void => {
		const newConditioningList = [...conditioningList];
		newConditioningList[index] = newConditioning;
		setConditioningList(newConditioningList);
	};

	const handleDeleteConditioning = async (index: number): Promise<void> => {
		const conditioning = conditioningList[index];
		if (conditioning) {
			const { error } = await removeProductVariation(product.id, conditioning.id);
			if (!error) {
				const newConditioningList = [...conditioningList];
				newConditioningList.splice(index, 1);
				setConditioningList(newConditioningList);
			}
		}
	};

	const handleSubmitConditioning = async (
		conditioning: Omit<ConditioningRowProps, 'onSubmit'>
	): Promise<{ success: boolean; error?: string }> => {
		let data: ConditioningRowType;
		let error;
		if (!conditioning.id) {
			const productAdd = await addProduct(product.id, conditioning);
			data = productAdd.data;
			error = productAdd.error;
		} else {
			const productUpdate = await updateProduct(
				product.id,
				conditioningList[conditioning.index] as ConditioningRowType,
				conditioning
			);
			data = productUpdate.data;
			error = productUpdate.error;
		}

		if (error) {
			return { success: false, error: t('error.cannot-create-product') };
		}
		setDisabledConditioning(false);
		const newCond = {
			id: data?.id || conditioning.id,
			saleAreaPriceList: conditioning.saleAreaPriceList,
		};
		updateConditioning(conditioning.index, { ...conditioning, ...newCond });
		return { success: true };
	};

	return (
		<div>
			<Typography variant="h2" gutterBottom>
				{t('product-edition.title-conditioning')}
			</Typography>
			<table className={classes.conditioningTable}>
				<tbody>
					{conditioningList.map((props, index) => (
						<ConditioningRow
							index={index}
							key={`${index}-${props?.sap ?? ''}`}
							{...props}
							onSubmit={handleSubmitConditioning}
							onDelete={handleDeleteConditioning}
							saleAreaPriceList={props.saleAreaPriceList}
						/>
					))}
				</tbody>
			</table>
			<Box mt={3}>
				<Button
					startIcon={<AddIcon fontSize="large" />}
					variant="contained"
					color="primary"
					onClick={addConditioning}
					disabled={disabledConditioning}
				>
					{t('product-edition.button-add-conditioning')}
				</Button>
			</Box>
		</div>
	);
};
