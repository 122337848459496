import { DataHook } from '../../../../types/dataHook.type';
import {
	useAddRestrictedProductsToContractsMutation,
	useRemoveRestrictedProductsFromContractsMutation,
} from '../../../../generated/graphql';
import { useEffect, useRef, useState } from 'react';

type LocalState = DataHook<boolean, undefined>;
interface Product {
	id: string;
	sku: string;
}
type UpdateContract = (customerId: string, initialProductList: Product[], newProductList: Product[]) => void;

export const useUpdateCustomerProduct = (): [UpdateContract, LocalState] => {
	// Mutation
	const [addRestrictedProducts, { data: addData, error: addError }] = useAddRestrictedProductsToContractsMutation();
	const [removeRestrictedProducts, { data: removeData, error: removeError }] =
		useRemoveRestrictedProductsFromContractsMutation();
	// State
	const [localState, setLocalState] = useState<LocalState>({ data: undefined, loading: false, error: false });
	// Constant
	const waitingCountUpdate = useRef<number>(0);

	const updateContractProducts: UpdateContract = (
		customerId: string,
		initialProductList: Product[],
		productList: Product[]
	) => {
		setLocalState({ data: undefined, loading: true, error: false });
		const productIdsToAdd = productList.filter(({ id }) => !initialProductList.find(({ id: iId }) => id === iId));
		const productIdsToRemove = initialProductList.filter(({ id }) => !productList.find(({ id: iId }) => id === iId));
		waitingCountUpdate.current =
			productIdsToRemove.length && productIdsToAdd.length
				? 2
				: productIdsToRemove.length
				? 1
				: productIdsToAdd.length
				? 1
				: 0;
		if (productIdsToAdd.length) {
			void addRestrictedProducts({
				variables: {
					customer_id: customerId,
					restricted_product_ids: productIdsToAdd.map(({ sku }) => sku),
				},
			});
		}
		if (productIdsToRemove.length) {
			void removeRestrictedProducts({
				variables: {
					customer_id: customerId,
					restricted_product_ids: productIdsToRemove.map(({ sku }) => sku),
				},
			});
		}
	};

	useEffect(() => {
		if (
			(addData && removeData && waitingCountUpdate.current === 2) ||
			((addData || removeData) && waitingCountUpdate.current === 1)
		) {
			setLocalState({ data: true, error: false, loading: false });
		}
	}, [addData, removeData]);

	useEffect(() => {
		if (removeError || addError) {
			setLocalState({ data: undefined, error: true, loading: false });
		}
	}, [addError, removeError]);

	return [updateContractProducts, localState];
};
