import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@tmds-io/kore';

import ProfileForm from './profile-form';
import { Box, Card, CardContent, Typography } from '@mui/material';

const ProfileTemplate: FunctionComponent = () => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	return (
		<Box display="flex" justifyContent="center">
			<Card sx={{ width: 500 }} square>
				<CardContent>
					<Box mt={2} mb={4}>
						<Typography variant="h2" color="primary">
							{t('profile.profile-settings')}
						</Typography>
					</Box>
					<ErrorBoundary FallbackComponent={ErrorFallback}>
						<ProfileForm />
					</ErrorBoundary>
				</CardContent>
			</Card>
		</Box>
	);
};

export default ProfileTemplate;
