// react
import React, { useEffect } from 'react';
// Mui
import { Typography, Box, Paper, Button, IconButton, Skeleton } from '@mui/material';
import { Delete, KeyboardArrowRight } from '@mui/icons-material';
// Utils
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Data fetcher
import { useGroupsListing, GroupListingRow } from '@core/hooks/group/useGroupsListing';
// Components
import { FILTER_COMPARISON, SortableTable, Cell } from '@tmds-io/kore';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
import { useSendThirdPartMessageMutation } from '../../generated/graphql';
import { downloadFile } from '@core/utils/file';
import DownloadIcon from '@mui/icons-material/Download';
import { useErrorNotification } from '@core/hooks/useErrorNotification';

import { useDeleteStoreMutation } from '../../generated/graphql';
import { useDebugContext } from '@core/debug/debugContext';
import { ButtonConfirm } from '@components/ButtonConfirm';
import { getEnvironment } from '../../index';

const getHeadCells = (
	t: (val: string) => string,
	data: GroupListingRow[],
	remove?: (index: number) => void
): Cell<GroupListingRow>[] => {
	const cells: Cell<GroupListingRow>[] = [
		{
			id: 'name',
			numeric: false,
			disablePadding: false,
			label: t('shared.name'),
		},
		{
			id: 'sap',
			numeric: false,
			disablePadding: false,
			label: t('shared.sap'),
		},
		{
			id: 'numberOfUsers',
			numeric: true,
			disablePadding: false,
			label: t('shared.numberOfUsers'),
		},
		{
			id: 'numberOfProducts',
			numeric: true,
			disablePadding: false,
			label: t('shared.numberOfProducts'),
		},
		{
			id: 'entryScore',
			numeric: true,
			disablePadding: false,
			label: t('shared.entryScore'),
			headCellSx: {
				pr: 8,
			},
			endElement: (rowIndex: number) => (
				<IconButton
					sx={{ ml: 1 }}
					component={Link}
					to={generatePath(ROUTES_URI.GROUP_DETAILS, { id: data[rowIndex].id })}
				>
					<KeyboardArrowRight />
				</IconButton>
			),
		},
	];

	return remove
		? [
				...cells,
				{
					id: 'none',
					numeric: true,
					disablePadding: false,
					label: '',
					endElement: (rowIndex: number) => (
						<Box position="relative">
							<ButtonConfirm onConfirm={() => remove(rowIndex)}>
								<IconButton>
									<Delete />
								</IconButton>
							</ButtonConfirm>
						</Box>
					),
				} as Cell<GroupListingRow>,
		  ]
		: cells;
};

/**
 * GroupPage components -
 */
export const GroupsListing = (): JSX.Element => {
	const sendNotificationError = useErrorNotification();
	const navigate = useNavigate();
	const { env } = getEnvironment();

	const { debug } = useDebugContext();
	const [removeStore] = useDeleteStoreMutation({
		onCompleted: () => {
			navigate(0);
		},
	});

	const handleRemove = (index: number): void => {
		removeStore({
			variables: {
				store_id: data[index].id,
			},
		});
	};

	const { data, loading, error } = useGroupsListing({
		stores: {
			filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'business_unit', value: 'resellers' }],
		},
		contracts: {
			filter: [],
		},
	});
	const { t } = useTranslation('acs', { useSuspense: false });
	const headCells = getHeadCells(t, data, debug && !['production'].includes(env) ? handleRemove : undefined);
	const { enqueueSnackbar } = useSnackbar();
	const [exportScore, { data: exportedScore, error: downloadError }] = useSendThirdPartMessageMutation();

	useEffect(() => {
		if (exportedScore) {
			enqueueSnackbar(t('download-file.process'), { variant: 'info' });
			const fileName = exportedScore.sendThirdPartMessage.data.filename.split('.');
			const ext = fileName.pop();
			downloadFile(exportedScore.sendThirdPartMessage.data.file, fileName.join('.'), ext);
		}
	}, [exportedScore]);

	useEffect(() => {
		if (downloadError) {
			sendNotificationError('download-file.error', 'Download fidelity fail');
		}
	}, [downloadError]);

	useEffect(() => {
		if (error) {
			sendNotificationError('group-page.fetch-error', 'Fail fetch group listing data');
		}
	}, [error]);

	const handleDownloadScore = (): void => {
		void exportScore({
			variables: {
				code: 'fidelity',
				data: {},
				timeout: 30,
				need_response: true,
			},
		});
	};

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography color="primary" variant="h1" sx={{ mb: 2 }}>
					{t('group-page.title')}
				</Typography>
				<Button startIcon={<DownloadIcon />} variant="contained" onClick={handleDownloadScore} size="small">
					{t('group-page.export-fidelity')}
				</Button>
			</Box>

			{loading || error ? (
				<Skeleton height={262} variant="rectangular" />
			) : (
				<Paper>
					<SortableTable<GroupListingRow>
						data={data}
						cells={headCells}
						defaultOrder="numberOfUsers"
						defaultOrderBy="desc"
					/>
				</Paper>
			)}
			<Box display="flex" justifyContent="flex-end" pt={1}>
				<Button color="primary" variant="contained" component={Link} to={generatePath(ROUTES_URI.GROUP_CREATE)}>
					{t('group-page.button-add')}
				</Button>
			</Box>
		</Box>
	);
};
