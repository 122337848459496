// react
import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	InputLabel,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useUserContext } from '@tmds-io/kore';
import { useGetAccountOwnerQuery } from '../../generated/graphql';
import { DialogWithIcon } from '@tmds-io/kore';
import BusinessIcon from '@mui/icons-material/Business';
import { checkDeliveryDay, getNoWorkingDay } from '@core/utils/date.util';
import { getShipmentTransportLabel } from '@components/ResellerShippingCard';

export interface DeliveryFormValues {
	preferredDeliveryMethod: string;
	collectionDate: string;
	meansOfConveying: string;
	deliveryPoint: string;
}
export const SELF_DELIVERY_ID = 'YA';
export const TEAFS_DELIVERY_ID = 'YC';

const validationSchema = (t: (value: string) => string) =>
	Yup.object().shape({
		preferredDeliveryMethod: Yup.string().required(t('delivery-selector.required')),
		collectionDate: Yup.date(),
		meansOfConveying: Yup.string().required(t('delivery-selector.required')),
		deliveryPoint: Yup.string().required(t('delivery-selector.required')),
	});

const initialFieldList: {
	name: keyof Omit<DeliveryFormValues, 'collectionDate'>;
	optionList: { label: string; value: string }[];
}[] = [
	{
		name: 'preferredDeliveryMethod',
		optionList: [
			{ label: 'delivery-selector.preferredDeliveryMethod-self', value: SELF_DELIVERY_ID },
			{ label: 'delivery-selector.preferredDeliveryMethod-teafs', value: TEAFS_DELIVERY_ID },
		],
	},
	{
		name: 'meansOfConveying',
		optionList: [
			{ label: getShipmentTransportLabel('Y1'), value: 'Y1' },
			{ label: getShipmentTransportLabel('Y6'), value: 'Y6' },
			{ label: getShipmentTransportLabel('Y7'), value: 'Y7' },
		],
	},
];

interface DeliverySelectorProps {
	validation?: boolean;
	onFormSubmit: (values: DeliveryFormValues) => void;
	onChange: (values: DeliveryFormValues, isValid: boolean) => void;
}

const CustomerTypography = styled(Typography)(({ theme }) => ({
	'& span': {
		color: theme.palette.primary.main,
	},
}));

export const DeliverySelector = ({ onFormSubmit, onChange, validation = null }: DeliverySelectorProps): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { id } = useUserContext();
	const { data } = useGetAccountOwnerQuery({ variables: { id }, fetchPolicy: 'no-cache' });
	const [addressModalIsOpen, setAddressModalIsOpen] = useState<boolean>(false);
	const [fieldList, setFieldList] = useState<
		{
			name: keyof Omit<DeliveryFormValues, 'collectionDate'>;
			optionList: { label: string; value: string }[];
		}[]
	>(initialFieldList);

	const handleOpenAddressInfoModal = (): void => {
		setAddressModalIsOpen(true);
	};
	const handleCloseAddressInfoModal = (): void => {
		setAddressModalIsOpen(false);
	};

	const form = useFormik<DeliveryFormValues>({
		initialValues: {
			preferredDeliveryMethod: '',
			collectionDate: '',
			meansOfConveying: '',
			deliveryPoint: '',
		},
		validationSchema: validationSchema(t),
		onSubmit: (values) => {
			onFormSubmit(values);
		},
		validate: async (values) => {
			if (values?.collectionDate && values.preferredDeliveryMethod === SELF_DELIVERY_ID) {
				const deliveryDateValid = checkDeliveryDay(new Date(), new Date(values.collectionDate));
				if (!deliveryDateValid.isValid) {
					return { collectionDate: t('delivery-selector.date-' + deliveryDateValid.message) };
				}
				const noWorkingDays = await getNoWorkingDay(new Date(values.collectionDate).getFullYear());
				if (
					noWorkingDays &&
					Object.keys(noWorkingDays).includes(format(new Date(values.collectionDate), 'yyyy-MM-dd'))
				) {
					return { collectionDate: t('delivery-selector.date-no-working-day') };
				}
			} else if (values.preferredDeliveryMethod === SELF_DELIVERY_ID) {
				return { collectionDate: t('delivery-selector.required') };
			}
			return {};
		},
	});

	const handleChange = (evt: SelectChangeEvent) => {
		if (evt.target.name === 'preferredDeliveryMethod') {
			onChange(
				{ preferredDeliveryMethod: evt.target.value, deliveryPoint: '', collectionDate: '', meansOfConveying: '' },
				false
			);
			form.resetForm();
		}
		form.handleChange(evt);
	};

	useEffect(() => {
		onChange(form.values, form.isValid);
	}, [form.values, form.isValid]);

	useEffect(() => {
		if (typeof validation === 'boolean') {
			void form.submitForm();
		}
	}, [validation]);

	useEffect(() => {
		if (data) {
			setFieldList([
				...initialFieldList,
				{
					name: 'deliveryPoint',
					optionList:
						data.account.delivery_points
							?.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map(({ id, name, address }) => ({
								label: `${name} - ${address.city}`,
								value: id,
							})) || [],
				},
			]);
		}
	}, [data]);

	return (
		<div>
			<Typography variant="h3">{t('shared.delivery')}</Typography>

			<Box mx={2}>
				{fieldList.map(({ name, optionList }) => (
					<FormControl
						fullWidth
						key={name}
						required
						variant="standard"
						sx={{ my: 2, minWidth: 120 }}
						error={!!(form.touched[name] && form.errors[name])}
					>
						<InputLabel id={'label-' + name} shrink={true}>
							{t('delivery-selector.' + name)}
						</InputLabel>
						<Select
							labelId={'label-' + name}
							name={name}
							value={form.values[name]}
							onChange={handleChange}
							label={t('delivery-selector.' + name)}
							displayEmpty
						>
							{optionList.map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{t(label)}
								</MenuItem>
							))}
						</Select>
						{form.touched[name] && form.errors[name] ? (
							<FormHelperText>{form.errors[name]}</FormHelperText>
						) : null}
					</FormControl>
				))}
				<Box mb={2}>
					<Link component={Button} color="primary" onClick={handleOpenAddressInfoModal}>
						{t('delivery-selector.add-address')}
					</Link>
				</Box>

				{form.values.preferredDeliveryMethod === SELF_DELIVERY_ID ? (
					<TextField
						type="date"
						label={t('delivery-selector.collectionDate')}
						name="collectionDate"
						value={form.values.collectionDate}
						onChange={form.handleChange}
						error={!!(form.touched.collectionDate && form.errors.collectionDate)}
						helperText={
							form.touched.collectionDate && form.errors.collectionDate ? form.errors.collectionDate : undefined
						}
					/>
				) : null}
			</Box>
			{form.values.preferredDeliveryMethod === TEAFS_DELIVERY_ID ? (
				<Box>
					<Typography variant="h3">
						<strong>{t('delivery-selector.note-title')}</strong>
					</Typography>
					<CustomerTypography
						sx={{ mx: 2, mt: 2 }}
						dangerouslySetInnerHTML={{ __html: t('delivery-selector.note-content') }}
					/>
				</Box>
			) : null}

			<DialogWithIcon
				open={addressModalIsOpen}
				title={t('delivery-selector.add-address')}
				icon={<BusinessIcon fontSize="large" color="primary" />}
				onClose={handleCloseAddressInfoModal}
				content={<Typography sx={{ maxWidth: 400 }}>{t('delivery-selector.add-address-info')}</Typography>}
				actionsContent={
					<Button variant="contained" sx={{ mr: 1 }} onClick={handleCloseAddressInfoModal}>
						{t('shared.close')}
					</Button>
				}
			/>
		</div>
	);
};
