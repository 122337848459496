import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useDotStepIconStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: '#eaeaf0',
			display: 'flex',
			height: 22,
			alignItems: 'center',
		},
		active: {
			color: theme.palette.primary.main,
		},
		circle: {
			width: 8,
			height: 8,
			borderRadius: '50%',
			backgroundColor: 'currentColor',
		},
		completed: {
			color: theme.palette.primary.main,
			zIndex: 1,
			fontSize: 18,
		},
	})
);
