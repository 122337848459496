// React
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
// Translation
import { useTranslation } from 'react-i18next';
// Components
import { PictureProductUpload, DocumentUpload, ConditioningSection } from './partials';
import {
	Box,
	Grid,
	Typography,
	Select,
	MenuItem,
	FormHelperText,
	Button,
	FormControl,
	InputLabel,
	FormControlLabel,
	Checkbox,
	Slide,
} from '@mui/material';
import { Dialog } from '@tmds-io/kore';
import { Alert, AlertProps } from '@mui/lab';
import { ArrowRight, Check as CheckIcon } from '@mui/icons-material';
// Form
import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
// Types
import { ProductValues } from '@components/seller/product/types/products.type';
import { ProductEditionProps } from './styles/product-edition.type';
// Routing
import { ROUTES_URI } from '../../app/router/routes';
import { generatePath, Link, useNavigate } from 'react-router-dom';
// Service
import { formatProductValuesToProductInput } from '@components/seller/product/services/product.service';
import { formAddProductSchema } from './schemas/form-add-product.schema';
import { checkFieldsUpdate } from '@components/seller/product-edition/product-edition.core';
// Styles
import { useProductEditionStyle } from './styles/product-edition.style';
import { useButtonStyle } from '@core/styles/button';
// gql
import {
	useUpdateProductMutation,
	useCreateProductMutation,
	ProductFieldsForEditionFragment,
} from '../../../generated/graphql';
import { getMediaUrl } from '@core/services/media';
import { ChangeType } from '@components/seller/product-edition/partials/picture-product-upload/types/picture-product-upload-props.type';
import TranslationTextField from '@components/shared/TranslationTextField';
import { useSnackbar } from 'notistack';
import { useQuery } from '@core/utils/useUrlQuery';

/**
 * Product detail component - Display component details page content for add and edit product
 */
export const ProductEdition: FC<ProductEditionProps> = ({
	product: productFromApi,
	conditioningList,
	productTypeList,
	saleAreaList,
}) => {
	// Styles
	const clsButton = useButtonStyle();
	const classes = useProductEditionStyle();
	// Ref
	const timeout = useRef<ReturnType<typeof setTimeout> | undefined>();
	// translation
	const { t } = useTranslation('acs', { useSuspense: true });
	// states
	const [documentList, setDocumentList] = useState<{ title?: string; file: string }[]>([{ title: '', file: '' }]);
	const [notificationList, setNotification] = useState<AlertProps[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	// Hook gql
	const [createProductMutation, { data: productCreated, error: createError, loading: createLoading }] =
		useCreateProductMutation();
	const [updateProduct, { data: productUpdated, error: updateError, loading: updateLoading }] =
		useUpdateProductMutation();
	// hook router
	const navigate = useNavigate();
	const query = useQuery();
	const { enqueueSnackbar } = useSnackbar();
	// Constant
	const sapProductTypeList = [
		{ label: t('shared.fuel'), value: 'fuel' },
		{ label: t('shared.lubricant'), value: 'lubricant' },
	];

	const loading = createLoading || updateLoading;
	const product =
		(productUpdated?.updateProduct as ProductFieldsForEditionFragment) ||
		(productFromApi as ProductFieldsForEditionFragment) ||
		(productCreated?.createProduct as ProductFieldsForEditionFragment);

	const initialValues = {
		id: product?.id || '',
		sku: product?.sku || uuidv4(),
		image: product?.image || '',
		documents: product?.documents?.map(({ title, file }) => ({ title, file })),
		productName: product?.title.en || '',
		productNameFR: product?.title.fr || '',
		productType: product?.categories?.[0]?.id || '',
		sapProductType: product?.product_type,
		productDescription: product?.description.en || '',
		productDescriptionFR: product?.description.fr || '',
		productSpecifications: product?.data?.specifications?.en || '',
		productSpecificationsFR: product?.data?.specifications?.fr || '',
		productRestricted: product?.restricted_product || false,
	};
	const pictureUrl: string | undefined = product?.image ? getMediaUrl(product.image) : undefined;
	const uploadPictureLabel = t(
		pictureUrl ? t('product-edition.button.update-picture') : t('product-edition.button.upload-picture')
	);
	// store
	const formik = useFormik<ProductValues>({
		initialValues,
		validationSchema: formAddProductSchema,
		onSubmit: async (values) => {
			const isEdition = !!product?.id;

			if (isEdition) {
				await updateProduct({
					variables: {
						id: product.id,
						params: formatProductValuesToProductInput(values),
						paths: [
							'name',
							'title.fr',
							'title.en',
							'categories',
							'product_type',
							'restricted_product',
							'data',
							'description',
							'image',
							'documents',
							'quantity',
						],
					},
				});
			} else {
				await createProductMutation({
					variables: {
						params: formatProductValuesToProductInput(values),
					},
				});
			}
		},
	});

	const productHaveUpdate = checkFieldsUpdate(initialValues, formik.values);

	const addDocument = (title: string, file: string): void => {
		formik.setFieldValue('documents', [...documentList.filter(({ title }) => title), { title, file: `!${file}` }]);
		setDocumentList((docList) => [...docList.filter(({ title }) => title), { title, file }, { title: '', file: '' }]);
	};

	const handleRemoveDocument = (documentName: string) => {
		const newDocumentList = [...documentList];
		const removeIndex = newDocumentList.findIndex(({ title }) => documentName === title);
		if (removeIndex > -1) {
			newDocumentList.splice(removeIndex, 1);
			setDocumentList(newDocumentList);
		}
	};

	const handleChangeImage = ({ success, id }: ChangeType): void => {
		if (success) {
			formik.setFieldValue('image', `!${id}`);
		} else {
			formik.setFieldError('image', 'Cannot upload image');
		}
	};

	const cleanNotification = useCallback(() => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		timeout.current = setTimeout(() => {
			setNotification([]);
		}, 6000);
	}, []);

	const handleCloseModal = (): void => {
		setOpen(false);
	};

	useEffect(() => {
		if (productFromApi && query.get('created') && productFromApi.restricted_product) {
			setOpen(true);
			navigate({ search: '' }, { replace: true });
		}
		if (productFromApi && productFromApi.documents?.length) {
			setDocumentList((docList) => [
				...productFromApi.documents.map(({ title, file }) => ({ title, file })),
				...docList,
			]);
		}
	}, [productFromApi, query.get('created')]);

	useEffect(() => {
		if (productCreated) {
			navigate(`${generatePath(ROUTES_URI.PRODUCTS_EDIT, { id: productCreated.createProduct.id })}?created=true`);
		}
		if (createError) {
			enqueueSnackbar(t('product-edition.create-error'), { variant: 'error' });
		}
	}, [productCreated, createError, cleanNotification]);

	useEffect(() => {
		if (productUpdated) {
			enqueueSnackbar(t('product-edition.update-success'), { variant: 'success' });
		}
		if (updateError) {
			enqueueSnackbar(t('product-edition.update-error'), { variant: 'error' });
		}
	}, [productUpdated, updateError]);

	return (
		<Box pb={4}>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t('product-edition.title')}
			</Typography>
			<Box mb={5}>
				<Typography variant="h2" sx={{ mb: 2 }}>
					{t('product-edition.title-general')}
				</Typography>
				<Grid container spacing={4}>
					<Grid item xs="auto">
						<Box mt={4}>
							<PictureProductUpload
								img={pictureUrl}
								inputLabel={uploadPictureLabel}
								error={!!formik.errors.image}
								onChange={handleChangeImage}
								helperText={formik.errors.image}
							/>
						</Box>
					</Grid>
					<Grid item>
						<Box maxWidth={240} mb={2}>
							<FormControl className={classes.formControl}>
								<TranslationTextField
									name="productName"
									variant="standard"
									values={{ en: formik.values.productName, fr: formik.values.productNameFR }}
									direction="bottom"
									onChange={formik.handleChange}
									error={formik.touched.productName && !!formik.errors.productName}
									helperText={formik.touched.productName && formik.errors.productName}
									label={t('product-edition.input.product-name')}
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel id="productType-label" sx={{ ml: -2 }}>
									{t('product-edition.select.product-type')}
								</InputLabel>
								<Select
									id="productType"
									name="productType"
									labelId="productType-label"
									variant="standard"
									fullWidth
									label={t('product-edition.select.product-type')}
									value={formik.values.productType}
									error={formik.touched.productType && !!formik.errors.productType}
									onChange={formik.handleChange}
								>
									<MenuItem>
										<em>{t('shared.none')}</em>
									</MenuItem>
									{productTypeList.map(({ label, id }) => (
										<MenuItem key={id} value={id}>
											{label}
										</MenuItem>
									))}
								</Select>
								{formik.touched.productType && !!formik.errors.productType && (
									<FormHelperText error={formik.touched.productType && !!formik.errors.productType}>
										{formik.errors.productType}
									</FormHelperText>
								)}
							</FormControl>
							<FormControl className={classes.formControl}>
								<InputLabel id="sap-productType-label" sx={{ ml: -2 }}>
									{t('product-edition.select.sap-product-type')}
								</InputLabel>
								<Select
									id="sapProductType"
									name="sapProductType"
									labelId="sap-productType-label"
									variant="standard"
									fullWidth
									label={t('product-edition.select.sap-product-type')}
									value={formik.values.sapProductType}
									error={formik.touched.sapProductType && !!formik.errors.sapProductType}
									onChange={formik.handleChange}
								>
									<MenuItem>
										<em>{t('shared.none')}</em>
									</MenuItem>
									{sapProductTypeList.map(({ label, value }) => (
										<MenuItem key={value} value={value}>
											{label}
										</MenuItem>
									))}
								</Select>
								{formik.touched.productType && !!formik.errors.productType && (
									<FormHelperText error={formik.touched.productType && !!formik.errors.productType}>
										{formik.errors.productType}
									</FormHelperText>
								)}
							</FormControl>
						</Box>
						<Box>
							<FormControlLabel
								className={classes.formControl}
								control={
									<Checkbox
										name="productRestricted"
										checked={formik.values.productRestricted}
										onChange={formik.handleChange('productRestricted')}
										color="primary"
									/>
								}
								label={t('product-edition.input.restricted-product') as string}
							/>
							{documentList.map((document, index) => (
								<DocumentUpload
									key={document.title + index}
									title={document.title}
									inputLabel={t('product-edition.button.upload-document')}
									inputPlaceholder={t('product-edition.input.document-wording')}
									errorMessage={t('product-edition.error.document-wording')}
									onDelete={handleRemoveDocument}
									onAddDocument={addDocument}
								/>
							))}
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box mb={4}>
				<Typography variant="h2" gutterBottom>
					{t('product-edition.title-details')}
				</Typography>
				<FormControl className={classes.formControl} fullWidth>
					<TranslationTextField
						name="productDescription"
						onChange={formik.handleChange}
						values={{ en: formik.values.productDescription, fr: formik.values.productDescriptionFR }}
						multiline
						fullWidth
						InputProps={{ disableUnderline: true }}
						rows={4}
						direction="bottom"
						label={t('product-edition.input.description')}
						error={!!formik.errors.productDescription}
						helperText={formik.errors.productDescription}
					/>
				</FormControl>

				<FormControl className={classes.formControl} fullWidth>
					<TranslationTextField
						name="productSpecifications"
						values={{ en: formik.values.productSpecifications, fr: formik.values.productSpecificationsFR }}
						onChange={formik.handleChange}
						InputProps={{ disableUnderline: true }}
						multiline
						fullWidth
						rows={4}
						direction="bottom"
						label={t('product-edition.input.specification')}
						error={!!formik.errors.productSpecifications}
						helperText={formik.errors.productSpecifications}
					/>
				</FormControl>
			</Box>

			{product?.id && (
				<ConditioningSection product={product} conditioningList={conditioningList} saleAreaList={saleAreaList} />
			)}
			<Box display="flex" justifyContent="flex-end">
				{!product?.id ? (
					<Button variant="contained" color="primary" onClick={formik.submitForm} disabled={loading}>
						{t('product-edition.btn.save')}
					</Button>
				) : (
					<>
						<Button
							sx={{ mr: 2 }}
							className={clsButton.light}
							variant="outlined"
							component={Link}
							to={generatePath(ROUTES_URI.PRODUCTS)}
							color="primary"
							disabled={loading}
						>
							{t('product-edition.btn.back')}
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={formik.submitForm}
							disabled={!productHaveUpdate || loading}
						>
							{t('shared.save')}
						</Button>
					</>
				)}
			</Box>
			<Box display="flex" justifyContent="center" width="100%">
				<Slide in={!!notificationList.length} direction="up">
					<Box position="fixed" bottom={0}>
						{notificationList.map((props, index) => (
							<Box key={index} mb={2}>
								<Alert {...props} />
							</Box>
						))}
					</Box>
				</Slide>
			</Box>
			<Dialog
				startComponent={<CheckIcon fontSize="large" color="primary" />}
				onClose={handleCloseModal}
				open={open}
				title={t('product-edition.modal.title')}
				text={productFromApi?.restricted_product && t('product-edition.modal.restrictedDescription')}
				actionsContent={
					<Box
						display="flex"
						justifyContent="space-between"
						width="100%"
						mt={productFromApi?.restricted_product ? 0 : 2}
					>
						<Button onClick={handleCloseModal} startIcon={<ArrowRight />}>
							{t('shared.cancel')}
						</Button>
						{productFromApi?.id ? (
							<Button
								component={Link}
								to={generatePath(ROUTES_URI.CUSTOMER_SELECTION, { id: productFromApi?.id })}
								sx={{ mr: 1 }}
								startIcon={<ArrowRight />}
								color="primary"
								variant="contained"
							>
								{t('product-edition.modal.btn.addAccess')}
							</Button>
						) : null}
					</Box>
				}
			/>
		</Box>
	);
};
