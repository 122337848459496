import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { getEventsFieldData } from '@core/utils/events/field-data.util';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Store } from '../../../generated/graphql';
import { Delete } from '@mui/icons-material';

let isAllDisabled = false;

const EventSelectionForm: FunctionComponent<{
	data: Store[];
	formik: FormikProps<{ championships: { id: string; name: string }[] }>;
	eventsIds?: string[];
	width: string;
}> = ({ formik, width, eventsIds = [], data }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const currentLanguage = i18next.language || 'en';

	const [events, setEvents] = useState<Array<Store & { disabled?: boolean }>>([]);

	useEffect(() => {
		if (eventsIds) {
			const championships = [...formik.values.championships.filter(({ id }) => eventsIds.includes(id))];

			formik.setValues({
				...formik.values,
				championships: championships.length !== 0 ? championships : [{ id: '', name: '' }],
			});
			isAllDisabled = false;
		}
	}, []);

	if (data && data?.length !== 0 && events.length === 0) {
		setEvents([...getEventsFieldData(data, eventsIds, currentLanguage)]);
	}

	useEffect(() => {
		if (
			formik.values.championships.length < events.length &&
			formik.values.championships?.[formik.values.championships.length - 1]?.id
		) {
			formik.setValues({
				...formik.values,
				championships: [...formik.values.championships, { id: '', name: '' }],
			});
		}
	}, [formik.values.championships, events]);

	const changeEventStatus = (id: string, eventIdToEnable: string) => {
		isAllDisabled = true;

		const newArray = [
			...events.map((event) => {
				if (event.id === id) {
					event.disabled = true;
				}

				if (event.id === eventIdToEnable) {
					event.disabled = false;
				}

				if (!event.disabled) {
					isAllDisabled = false;
				}

				return event;
			}),
		];

		setEvents(newArray);
	};

	const onChange = (e: SelectChangeEvent, index: number) => {
		changeEventStatus(e.target.value as string, formik.values.championships[index].id);

		const toAddNewField = formik.values.championships[index + 1];

		if ((!isAllDisabled && !formik.values.championships[index].id) || !toAddNewField) {
			onChampionshipChange();
		}

		formik.handleChange(e);
	};

	const onChampionshipChange = (): void => {
		// update dynamic form
		const championships = [...formik.values.championships];
		formik.setValues({ ...formik.values, championships });
	};

	const handleRemoveChampionship = (index: number) => () => {
		const championships = [...formik.values.championships];
		const id = championships.splice(index, 1)[0].id;
		changeEventStatus(id, id);
		formik.setValues({ ...formik.values, championships });
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			{formik.values.championships.map((championship, i) => {
				return (
					<Box key={i + formik.values.championships[i].id} mb={3} width={width}>
						<Box display="flex" alignItems="flex-end">
							<FormControl fullWidth sx={{ mr: 1 }}>
								<InputLabel id={`championships.${i}.id`}>
									{events?.length !== 0
										? t('register.championships.form.championship') + ' ' + (i + 1)
										: t('register.championships.form.no-events')}
								</InputLabel>
								<Select
									labelId={`championships.${i}.id`}
									name={`championships.${i}.id`}
									variant="standard"
									value={formik.values.championships[i].id}
									onChange={(e) => onChange(e, i)}
								>
									{events?.map((event) => (
										<MenuItem key={event.id} disabled={event.disabled} value={event.id}>
											{event.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							{formik.values.championships[i].id && (
								<Box>
									<IconButton size="small" onClick={handleRemoveChampionship(i)}>
										<Delete color="primary" fontSize="small" />
									</IconButton>
								</Box>
							)}
						</Box>
					</Box>
				);
			})}
		</form>
	);
};

export default EventSelectionForm;
