import {
	GetGroupsStoresQuery,
	SendThirdPartMessageMutation,
	useGetGroupsStoresLazyQuery,
	useSendThirdPartMessageMutation,
} from '../../../generated/graphql';
import { DataHook, DataHookLazy } from '../../../types/dataHook.type';
import { useEffect, useState } from 'react';
import { FILTER_COMPARISON } from '@tmds-io/kore';

export interface CustomerDeliveryPoint {
	name: string;
	city: string;
	address?: string;
	country: string;
	shipTo: string;
}

export interface CustomerData {
	businessName: string;
	businessAddress: {
		city: string;
		address?: string;
		country: string;
	};
	deliveryPoints: CustomerDeliveryPoint[];
	payments: {
		accountResponsible: string;
		incoterm: string;
		shippingCondition: string;
		priceGroup: string;
	};
	group: {
		title: {
			[lang: string]: string;
		};
		id: string;
	};
}

const formatApiData = (
	{ data = null }: SendThirdPartMessageMutation['sendThirdPartMessage'],
	groups?: GetGroupsStoresQuery['paginatedStores']['stores']
): CustomerData | undefined => {
	if (!data?.business.name) {
		return;
	}
	return {
		businessName: data?.business?.name || 'N/A',
		businessAddress: {
			city: data?.business_address?.city || 'N/A',
			address: data?.business_address?.address || 'N/A',
			country: data?.business_address?.country || 'N/A',
		},
		deliveryPoints: data.delivery_points.map(
			({
				name,
				city,
				country,
				ship_to,
				address = undefined,
			}: {
				name: string;
				city: string;
				country: string;
				ship_to: string;
				address?: string;
			}) => ({
				name,
				city,
				country,
				address,
				shipTo: ship_to,
			})
		),
		payments: {
			accountResponsible: data?.payments?.account_responsible,
			incoterm: data?.payments?.incoterm,
			shippingCondition: data?.payments?.shipping_condition,
			priceGroup: data?.payments?.price_group,
		},
		group: groups?.length
			? {
					title: groups[0].title,
					id: groups[0].id,
			  }
			: undefined,
	};
};

export const useResellerCustomerSapData = (): DataHookLazy<CustomerData, string> => {
	const [getUserData, { data, error: reqError }] = useSendThirdPartMessageMutation();
	const [getGroup, { data: groupData, error: groupError }] = useGetGroupsStoresLazyQuery();
	const [localState, setLocalState] = useState<DataHook<CustomerData, undefined>>({
		data: undefined,
		loading: false,
		error: false,
	});

	const handleFetch = (sap: string) => {
		setLocalState({ data: undefined, loading: true, error: false });
		void getUserData({
			variables: {
				code: 'sap_masterdata',
				data: {
					edi_ref: sap,
				},
				need_response: true,
				timeout: 30,
			},
		});
	};
	useEffect(() => {
		if (data?.sendThirdPartMessage?.data?.payments?.price_group) {
			getGroup({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.EQUAL,
							key: 'data.sap_price_list',
							value: data.sendThirdPartMessage.data.payments.price_group,
						},
					],
				},
			});
		} else if (data?.sendThirdPartMessage?.data?.payments && !data?.sendThirdPartMessage?.data?.payments) {
			getGroup({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.EQUAL,
							key: 'code',
							value: 'nogroup',
						},
					],
				},
			});
		}
	}, [data?.sendThirdPartMessage]);

	useEffect(() => {
		if (groupError || reqError || data?.sendThirdPartMessage?.failed) {
			setLocalState({ data: undefined, loading: false, error: true });
		}
	}, [groupError, reqError, data?.sendThirdPartMessage]);

	useEffect(() => {
		if (groupData?.paginatedStores) {
			setLocalState({
				data: formatApiData(
					data.sendThirdPartMessage,
					data?.sendThirdPartMessage?.data?.payments?.price_group && groupData?.paginatedStores.stores
				),
				error: false,
				loading: false,
			});
		}
	}, [groupData?.paginatedStores]);

	return [handleFetch, localState];
};
