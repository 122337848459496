import { gql } from '@apollo/client';

export const GET_PRICE = gql`
	query GetPrice($sku: String!, $depot_id: ID, $store_id: ID, $customer_id: ID) {
		price(sku: $sku, depot_id: $depot_id, Store_id: $store_id, customer_id: $customer_id) {
			...PriceFragment
		}
	}
`;

export const GET_PRICES = gql`
	query GetPrices(
		$sku: String
		$store_id: ID
		$customer_id: ID
		$inventory_id: ID
		$business_unit: String
		$sale_area_id: ID
	) {
		prices(
			sku: $sku
			Store_id: $store_id
			inventory_id: $inventory_id
			business_unit: $business_unit
			sale_area_id: $sale_area_id
		) {
			...PriceFragment
		}
	}
`;
