// react
import React from 'react';
import { BankAccountFormValues, IbanCard } from '@components/shared/IbanCard/IbanCard';
import { Alert, Box } from '@mui/material';
import { useGetAccountOwnerQuery, useUpdateMyAccountMutation } from '../../../generated/graphql';
import { useUserContext } from '@tmds-io/kore';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Loader } from '@components/shared/loader';
import { generatePath } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
import { Layout } from '@components/customer/profile';

/**
 * PaymentDetails components -
 */
export const PaymentDetails = (): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { id } = useUserContext();
	const { data, loading } = useGetAccountOwnerQuery({ variables: { id }, fetchPolicy: 'no-cache' });
	const pendingValidation = data?.account.pending_update;

	const { enqueueSnackbar } = useSnackbar();
	const [updateUserAccount] = useUpdateMyAccountMutation({
		onCompleted: () => {
			enqueueSnackbar(t('payment-details.page.update-success'), { variant: 'success' });
		},
		onError: () => {
			enqueueSnackbar(t('payment-details.page.update-fail'), { variant: 'error' });
		},
	});

	const handleSubmit = (values: BankAccountFormValues) => {
		void updateUserAccount({
			variables: {
				id,
				params: {
					account: {
						bank_account: values,
					},
				},
			},
		});
	};

	const bankAccount = {
		iban: pendingValidation?.account?.bank_account?.iban || data?.account?.bank_account?.iban || '',
		rib: pendingValidation?.account?.bank_account?.rib || data?.account?.bank_account?.rib || '',
		swift: pendingValidation?.account?.bank_account?.swift || data?.account?.bank_account?.swift || '',
	};

	return (
		<>
			<Layout title={t('customer.user-profile.bank-details')} backLink={generatePath(ROUTES_URI.INFOS)}>
				<Box width="100%">
					{pendingValidation?.account.bank_account ? (
						<Alert severity="warning">{t('customer.user-profile.pending-validation')}</Alert>
					) : null}
					<Box minWidth={400} m="auto">
						{loading ? <Loader /> : <IbanCard values={bankAccount} onSubmit={handleSubmit} />}
					</Box>
				</Box>
			</Layout>
		</>
	);
};
