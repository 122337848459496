import {
	GetProductsVariationsWithPricesQuery,
	GetRaceCategoryQuery,
	GetRaceInventoriesQuery,
	useGetProductsVariationsWithPricesLazyQuery,
	useGetRaceCategoryLazyQuery,
	useGetRaceInventoriesLazyQuery,
} from '../../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { DataHookLazy } from '../../../types/dataHook.type';

export interface RaceProducts {
	allProducts: GetProductsVariationsWithPricesQuery['productsVariationsWithPrices'];
	defaultRaceCategory: GetRaceCategoryQuery['categories']['categories'][0];
	optionalRaceCategory: GetRaceCategoryQuery['categories']['categories'][0];
	inventories: GetRaceInventoriesQuery['inventories']['inventories'];
}

export interface RaceProductsRefetch {
	refetchInventories: () => void;
	refetchProductOptional: () => void;
	refetchProductDefault: () => void;
	refetchAllProducts: () => void;
}

interface UseRaceProducts {
	request: DataHookLazy<RaceProducts, string>;
	refetchs: RaceProductsRefetch;
}

export const useRaceProducts = (raceId?: string): UseRaceProducts => {
	const [fetchInventories, { data: inventoriesData, refetch: refetchInventories, error: inventoriesError }] =
		useGetRaceInventoriesLazyQuery({
			fetchPolicy: 'network-only',
		});
	const [
		fetchOptionalProducts,
		{ data: selectedProductOptionalIdsData, refetch: refetchProductOptional, error: productOptionalError },
	] = useGetRaceCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});
	const [
		fetchDefaultProducts,
		{ data: selectedProductDefaultIdsData, refetch: refetchProductDefault, error: selectedProductError },
	] = useGetRaceCategoryLazyQuery({
		fetchPolicy: 'network-only',
	});
	const [fetchAllProducts, { data: allProductsData, refetch: refetchAllProducts, error: allProductsError }] =
		useGetProductsVariationsWithPricesLazyQuery({
			fetchPolicy: 'network-only',
		});

	const handleFetch = () => {
		if (raceId) {
			void fetchAllProducts({
				variables: {
					params: {},
				},
			});
			void fetchDefaultProducts({
				variables: {
					filter: [
						{ comparison: FILTER_COMPARISON.EQUAL, value: raceId, key: 'store_id' },
						{ comparison: FILTER_COMPARISON.EQUAL, value: 'default', key: 'name' },
					],
				},
			});
			void fetchOptionalProducts({
				variables: {
					filter: [
						{ comparison: FILTER_COMPARISON.EQUAL, value: raceId, key: 'store_id' },
						{ comparison: FILTER_COMPARISON.EQUAL, value: 'optional', key: 'name' },
					],
				},
			});
			void fetchInventories({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, value: raceId, key: 'store_id' }],
				},
			});
		} else {
			console.error('No race id');
		}
	};

	const data =
		allProductsData && selectedProductDefaultIdsData && selectedProductOptionalIdsData && inventoriesData
			? {
					allProducts: allProductsData.productsVariationsWithPrices,
					defaultRaceCategory: selectedProductDefaultIdsData.categories.categories?.[0],
					optionalRaceCategory: selectedProductOptionalIdsData.categories.categories?.[0],
					inventories: inventoriesData.inventories.inventories,
			  }
			: undefined;

	const error = !!(
		selectedProductError ||
		productOptionalError ||
		allProductsError ||
		inventoriesError ||
		(data && (!data?.defaultRaceCategory || !data?.optionalRaceCategory))
	);

	return {
		request: [
			handleFetch,
			{
				data: error ? undefined : data,
				error,
				loading: !data,
			},
		],
		refetchs: {
			refetchProductDefault,
			refetchAllProducts,
			refetchInventories,
			refetchProductOptional,
		},
	};
};
