// react
import React, { ChangeEvent, FC, useRef, useState } from 'react';
// Types
import { DocumentUploadProps } from './types/document-upload-props.type';
import { DocumentState } from './types/document-upload-enum';
// assets
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Box, FormHelperText } from '@mui/material';
import { FileInput } from '@tmds-io/kore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { uploadMedia } from '@core/services/media';
import { ReadRow } from '@components/seller/product-edition/partials/document-upload/partials';
import { EditionRow } from '@components/seller/product-edition/partials/document-upload/partials/editionRow';

const FILE_SIZE = 2 * 1024 * 1024;

export const DocumentUpload: FC<DocumentUploadProps> = ({
	title,
	inputLabel,
	inputPlaceholder,
	onAddDocument,
	onDelete,
}) => {
	const [documentState, setDocumentState] = useState<DocumentState>(title ? DocumentState.READ : DocumentState.CREATE);
	const token = useRef<string>();

	const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (evt.target.files.length) {
			setDocumentState(DocumentState.EDIT);
			formik.setFieldValue('file', evt.target.files[0]);
			formik.setFieldValue('title', evt.target.files[0].name);
			const result = await uploadMedia(evt.target.files[0]);
			if (result) {
				token.current = result.key;
			}
		}
	};

	const validationSchema = Yup.object().shape({
		file: Yup.mixed()
			.required('A file is required')
			// .test('fileFormat', 'Picture only', (value) => {
			// 	return (
			// 		value && ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'].includes(value.type.toLowerCase())
			// 	);
			// })
			.test('fileSize', 'File Size is too large', (value) => value?.size || 0 <= FILE_SIZE),
		title: Yup.string().required(),
	});

	const formik = useFormik({
		initialValues: {
			file: '',
			title: title || '',
		},
		validationSchema,
		onSubmit: (values) => {
			onAddDocument(values.title, token.current);
			setDocumentState(DocumentState.READ);
		},
	});

	const handleCancel = () => {
		setDocumentState(DocumentState.CREATE);
		formik.resetForm();
	};

	return (
		<div>
			{documentState === DocumentState.CREATE ? (
				<Box mr={2}>
					{
						<FileInput
							color="primary"
							variant="outlined"
							startIcon={<CloudUploadIcon />}
							label={inputLabel}
							name="document"
							onChange={handleFileChange}
						/>
					}
				</Box>
			) : null}
			{documentState === DocumentState.READ ? (
				<ReadRow fileName={formik.values.title} onClickDelete={() => onDelete(formik.values.title)} />
			) : null}
			{documentState === DocumentState.EDIT ? (
				<>
					<EditionRow
						TextFieldProps={{
							placeholder: inputPlaceholder,
							value: formik.values.title,
							onChange: formik.handleChange,
							error: formik.touched.title && !!formik.errors.title,
							helperText: formik.touched.title && formik.errors.title,
						}}
						onCancel={handleCancel}
						onSubmit={formik.submitForm}
					/>
					{formik.touched.file && formik.errors.file ? (
						<FormHelperText error={formik.touched.file && !!formik.errors.file}>
							{formik.errors.file}
						</FormHelperText>
					) : null}
				</>
			) : null}
		</div>
	);
};
