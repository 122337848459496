import {
	CustomerEventFieldsForListFragment,
	OrderFieldsFragment,
	Store,
	StoreWithChildren,
} from '../../../../generated/graphql';
import { EventWithInfo } from '@components/customer/event/types/events-props.type';

/**
 * Format events for display events with checkbox selection in modal
 * @param events - all user's events
 * @param excludedIds - current user's exclude event list
 */
export const formatEventsForModalEdit = (events: Store[], excludedIds: string[]): (Store & { checked: boolean })[] =>
	events.map((event) => ({ ...event, checked: excludedIds.includes(event.id) }));

/**
 * Format events to state for modal events edit for handle checkbox form selection
 */
export const formatEventsToState = (
	events: (CustomerEventFieldsForListFragment & { checked?: boolean })[]
): Record<string, boolean> => events.reduce((obj, current) => ({ ...obj, [current.id]: !!current.checked }), {});

/**
 * Filter event for display only events selected by the user
 * @param events - all events than the user can have
 * @param filter - filter with exclude events id than user don't want to see in the interface
 */
export const filterEvents = (events: EventWithInfo[], filter: string[]): EventWithInfo[] =>
	events.filter(({ id }) => !filter.includes(id));

/**
 * Format events by adding order and race count information
 * @param events - event list
 * @param orderListOrderList
 */
export const formatEventWithInfo = (
	events: StoreWithChildren[],
	orderListOrderList: OrderFieldsFragment[]
): EventWithInfo[] => {
	return events.map((data) => {
		const storeOrder = orderListOrderList.filter(({ store_id }) =>
			(data.children?.map(({ id }) => id) || []).includes(store_id)
		);

		return {
			...data?.store,
			children: data?.children || [],
			noRace: data?.children && !data?.children.length,
			noOrder: !storeOrder.length,
		};
	});
};
