import { RaceDetails } from '@components/seller/event/types/race-details.type';
import { RaceStatus } from '@components/seller/event/enums/race-status.enum';
import { CustomerEventRaceFieldsFragment, StoreRaceFieldsFragment } from '../../../../generated/graphql';
import { isFuture, isPast, formatDistanceToNow } from 'date-fns';
import { useDateUtils } from '@core/utils/date.util';

export const getRaceWithStatus = (
	races: Array<CustomerEventRaceFieldsFragment> | Array<StoreRaceFieldsFragment>
): RaceDetails[] => {
	const { dateOptions } = useDateUtils();
	return races
		.map((race) => {
			const isFutureRace = isFuture(new Date(race.visible_from));
			const isEndedRace = isPast(new Date(race.visible_to));
			return {
				...race,
				status: isEndedRace ? RaceStatus.ENDED : isFutureRace ? RaceStatus.COMING : RaceStatus.GOING,
				raceStart: formatDistanceToNow(new Date(race.visible_from), dateOptions),
				orderStart: formatDistanceToNow(new Date(race.order_from), dateOptions),
			};
		})
		.sort((track, nextTrack) => {
			if (new Date(track.order_from).getTime() < new Date(nextTrack.order_from).getTime()) {
				return -1;
			}
		});
};
