// React
import React, { useEffect } from 'react';
// Hooks
import { Store, StoreWithChildren, useGetCustomerEventsQuery, useGetOrdersLazyQuery } from '../../../generated/graphql';
// Components
import { Events } from './events';
import { useSnackbar } from 'notistack';
import { formatEventWithInfo } from '@components/customer/event/utils';
import { FILTER_COMPARISON, useUserContext } from '@tmds-io/kore';
import { useRaceDate } from '@core/utils/useRaceDate';

/**
 * Event Hoc fetch data from api and pass it to Events display components
 */
const EventHoc = (): JSX.Element => {
	// Hooks
	const {
		data,
		loading,
		error: queryError,
		refetch,
	} = useGetCustomerEventsQuery({ fetchPolicy: 'no-cache', variables: {} });
	const { getFirstRaceDate } = useRaceDate();
	const { id } = useUserContext();
	const [getOrders, { data: orderData }] = useGetOrdersLazyQuery({ fetchPolicy: 'network-only' });

	useEffect(() => {
		if (data) {
			getOrders({
				variables: {
					filter: [
						{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: id },
						{
							comparison: FILTER_COMPARISON.IN,
							key: 'store_id',
							value: data.customerStore.reduce((ids, store) => {
								if (store.children) {
									return [...ids, ...store.children.map(({ id }) => id)];
								}
								return ids;
							}, []),
						},
					],
				},
			});
		}
	}, [data]);

	const { enqueueSnackbar } = useSnackbar();

	if (queryError) {
		enqueueSnackbar('customer.events.fetch-events-error', { variant: 'error' });
	}

	const orderList = orderData?.orders.orders || [];

	const orderedStores = data?.customerStore.sort((champ1, champ2) => {
		return getFirstRaceDate((champ1.children || []) as Store[]) > getFirstRaceDate((champ2.children || []) as Store[])
			? -1
			: 1;
	});

	return (
		orderedStores !== undefined && (
			<Events
				loading={loading}
				events={formatEventWithInfo(orderedStores as StoreWithChildren[], orderList)}
				refetch={refetch}
			/>
		)
	);
};

export default EventHoc;
