// react
import React, { FC, useMemo, useState } from 'react';
// Types
import { ConditioningRowProps } from './conditioning-props.type';
// Component
import { NumberRangeInput } from '@tmds-io/kore';
import { Box, TextField, Button, IconButton, ButtonGroup } from '@mui/material';
import {
	ArrowRight as ArrowRightIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	Check as CheckIcon,
	Close as CloseIcon,
} from '@mui/icons-material';
// Utils
import { useTranslation } from 'react-i18next';
// Form
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getConditioningFormSchema } from './schemas/form-add-conditioning.schema';
// Style
import { useConditioningSectionStyle } from '../../styles/conditioning-section.type';
import { useSnackbar } from 'notistack';

type ConditioningFormValues = Record<string, string | number | string[]> & {
	sap: string;
	multiplier: number;
	quantity: number;
};

/**
 * ConditioningRow component - display all product variation information and user interface for CRUD
 */
export const ConditioningRow: FC<Partial<ConditioningRowProps>> = ({
	id,
	multiplier,
	sap,
	quantity,
	onSubmit,
	saleAreaPriceList,
	onDelete,
	index,
}) => {
	// styles
	const classes = useConditioningSectionStyle();
	const { enqueueSnackbar } = useSnackbar();
	// Utils
	const { t } = useTranslation('acs');
	// States
	const [isEditMode, setEditMode] = useState<boolean>(!id);
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	// Constants
	const priceKeys = saleAreaPriceList?.reduce((priceKeys, saleArea) => {
		return { ...priceKeys, [saleArea.name]: saleArea.value || '' };
	}, {});
	const priceSchema = saleAreaPriceList?.reduce((priceKeys, saleArea) => {
		return { ...priceKeys, [saleArea.name]: Yup.number().required('Required') };
	}, {});
	const conditioningSchema = useMemo(() => getConditioningFormSchema(priceSchema, t), [saleAreaPriceList]);
	// Form
	const formik = useFormik<ConditioningFormValues>({
		initialValues: {
			sap: sap || '',
			multiplier: multiplier || 1,
			quantity: quantity || 0,
			...priceKeys,
		},
		validationSchema: conditioningSchema,
		onSubmit: async (values) => {
			setEditMode(false);
			const result = await onSubmit({
				id,
				sap: values.sap,
				quantity: values.quantity,
				multiplier: values.multiplier,
				index,
				saleAreaPriceList: Object.keys(priceKeys).map((key) => {
					const saleArea = saleAreaPriceList.find((sal) => sal.name === key);
					const priceId = saleArea?.priceId ? { priceId: saleArea?.priceId } : undefined;
					return {
						id: saleArea.id,
						code: saleArea.code,
						name: saleArea.name,
						value: values[key],
						...priceId,
					};
				}),
			} as ConditioningRowProps);
			if (result.success) {
				enqueueSnackbar(t('product-detail.conditioning.add-variation-success'), { variant: 'success' });
			} else {
				enqueueSnackbar(t('product-detail.conditioning.add-variation-fail'), { variant: 'error' });
			}
		},
	});

	return (
		<tr>
			<td>
				<TextField
					type="text"
					name="sap"
					label={t('product-detail.conditioning.input-sap')}
					value={formik.values.sap}
					onChange={formik.handleChange}
					error={formik.touched.sap && !!formik.errors.sap}
					helperText={formik.touched.sap && formik.errors.sap}
					required
					disabled={!!id || !isEditMode}
				/>
			</td>
			<td>
				<NumberRangeInput
					onChange={formik.handleChange('multiplier')}
					label={t('product-detail.conditioning.input-conditioning')}
					value={formik.values.multiplier}
					name="multiplier"
					min={0}
					className={classes.numberRange}
					disabled={!isEditMode}
				/>
			</td>
			<td>
				<Box display="flex" alignItems="center" justifyContent="center">
					<Box pt={3} pr={3} className="grey">
						x
					</Box>
					<TextField
						name="quantity"
						type="number"
						className="input"
						value={formik.values.quantity}
						onChange={formik.handleChange}
						error={formik.touched.quantity && !!formik.errors.quantity}
						helperText={formik.touched.quantity && formik.errors.quantity}
						label="."
						required
						disabled={!isEditMode}
					/>
					<Box component="sup" sx={{ pl: 1 }} className="pl-1 pt-2">
						L
					</Box>
				</Box>
			</td>
			{saleAreaPriceList.map((saleArea) => (
				<td key={saleArea.name}>
					<TextField
						name={saleArea.name}
						className="input"
						type="number"
						label={t(`product-detail.conditioning.input-${saleArea.name}-price`)}
						value={formik.values[saleArea.name]}
						onChange={formik.handleChange}
						error={formik.touched[saleArea.name] && !!formik.errors[saleArea.name]}
						helperText={formik.touched[saleArea.name] && formik.errors[saleArea.name]}
						required
						disabled={!isEditMode}
					/>
				</td>
			))}
			<td>
				{isEditMode ? (
					<Button variant="contained" startIcon={<ArrowRightIcon />} color="primary" onClick={formik.submitForm}>
						{t('product-edition.btn.save')}
					</Button>
				) : (
					<Box display="flex">
						<IconButton size="small" onClick={() => setEditMode(true)}>
							<EditIcon color="primary" fontSize="small" />
						</IconButton>
						{confirmDelete ? (
							<ButtonGroup size="small" classes={{ root: classes.deleteBtnGroup }}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									onClick={() => {
										onDelete(index);
										setConfirmDelete(false);
									}}
								>
									<CheckIcon fontSize="small" />
								</Button>
								<Button
									variant="outlined"
									color="primary"
									size="small"
									onClick={() => {
										setConfirmDelete(false);
									}}
								>
									<CloseIcon fontSize="small" />
								</Button>
							</ButtonGroup>
						) : (
							<IconButton size="small" onClick={() => setConfirmDelete(true)}>
								<DeleteIcon color="primary" fontSize="small" />
							</IconButton>
						)}
					</Box>
				)}
			</td>
		</tr>
	);
};
