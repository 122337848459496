// react
import React, { FC } from 'react';
import { FILTER_COMPARISON, useUserContext } from '@tmds-io/kore';
import { OrdersProvider } from '@components/shared/order/context/orders.provider';
import OrderListing from '@components/shared/order/orders';

const defaultSort = { keys: ['created_at'], sort: -1 };
const defaultLimit = {
	page_number: 1,
	count: 10,
};

export const Orders: FC = () => {
	const { id } = useUserContext();
	return (
		<OrdersProvider
			filters={[{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: id }]}
			limit={defaultLimit}
			sort={defaultSort}
		>
			<OrderListing />
		</OrdersProvider>
	);
};
