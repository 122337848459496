import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { UserKeyValueDetailPropsType } from '../types/user-key-value-detail-props.type';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { Cancel, Edit, ArrowForward } from '@mui/icons-material';

const UserKeyValueDetail: FunctionComponent<UserKeyValueDetailPropsType> = ({
	label,
	detailKey,
	error,
	detailValue,
	editable,
	onChange,
	onCancel,
	editMode: inputEditMode,
	pending,
}) => {
	const initialValue = useRef<string>(detailValue);

	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const [editMode, setEditMode] = useState<boolean>(false);

	useEffect(() => {
		if (typeof inputEditMode !== 'undefined') {
			setEditMode(false);
		}
	}, [inputEditMode]);

	const handleCancel = () => {
		setEditMode(false);
		onCancel?.(detailKey, initialValue.current);
	};
	return (
		<Box display="flex" alignItems="center" mb={2}>
			{editMode ? (
				<>
					<TextField
						fullWidth
						label={label}
						name={detailKey}
						value={detailValue}
						onChange={onChange}
						error={!!error}
						helperText={error}
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<IconButton size="small" onClick={handleCancel}>
									<Cancel />
								</IconButton>
							),
						}}
					/>
				</>
			) : (
				<Box display="flex" alignItems="center">
					<Typography color={editable && error ? 'error' : undefined}>
						<Box pl={1} component="b">
							{label} :{' '}
						</Box>
						<Box component="span" sx={{ textDecoration: pending ? 'line-through' : undefined }}>
							{t(detailValue)}
						</Box>
					</Typography>
					{pending ? (
						<>
							<ArrowForward />
							<Typography sx={{ ml: 1 }}>{pending}</Typography>
						</>
					) : null}
					{editable && (
						<IconButton onClick={() => setEditMode(true)} size="small">
							<Edit />
						</IconButton>
					)}
				</Box>
			)}
		</Box>
	);
};

export default UserKeyValueDetail;
