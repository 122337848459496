// react
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	StoreRaceFieldsFragment,
	useGetStoreChampWithChildrenLazyQuery,
	useGetStoreRacesLazyQuery,
} from '../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';

export const useEventDetailsData = (): [
	{ title: Record<string, string>; races: StoreRaceFieldsFragment[] } | undefined,
	boolean
] => {
	const { id, trackId = null } = useParams();
	const isDefaultPage = !trackId;
	const [currentRaceId, setCurrentRaceId] = useState<string | undefined>();

	const [getStoreChampWithChildren, { data: storeWithChildrenData, loading: storeWithChildrenLoading }] =
		useGetStoreChampWithChildrenLazyQuery({
			fetchPolicy: 'cache-and-network',
		});
	const [getStoreRaces, { data: storeRacesForEditData, loading: storeRacesForEditLoading }] =
		useGetStoreRacesLazyQuery({
			fetchPolicy: 'cache-and-network',
		});

	const loading = storeWithChildrenLoading || storeRacesForEditLoading;

	const storeWithChildren = storeWithChildrenData?.store;
	const storeRacesForEdit = storeRacesForEditData?.paginatedStores.stores;

	// Handle default page, get first race
	useEffect(() => {
		getStoreChampWithChildren({
			variables: {
				id,
			},
		});
		if (!isDefaultPage) {
			setCurrentRaceId(trackId);
		}
	}, [isDefaultPage]);

	// If default page set current race with first store child
	useEffect(() => {
		if (storeWithChildren && storeWithChildren.children.length) {
			setCurrentRaceId(storeWithChildren.children[0].id);
		}
	}, [storeWithChildren]);

	// Fetch data when current race is update
	useEffect(() => {
		if (currentRaceId) {
			getStoreRaces({
				variables: { filter: [{ comparison: FILTER_COMPARISON.EQUAL, value: id, key: 'parent_id' }] },
			});
		}
	}, [currentRaceId]);

	return useMemo(
		() => [
			storeWithChildren && storeRacesForEdit && { title: storeWithChildren.title, races: storeRacesForEdit },
			loading,
		],
		[loading, storeWithChildren, storeRacesForEdit]
	);
};
