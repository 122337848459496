import React, { FC } from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { generatePath, Link, useParams } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
import { useTranslation } from 'react-i18next';
import SellerEventDetails from './details';
import { getRaceWithStatus } from '@components/seller/event/utils/track-format';
import { useEventDetailsData } from '@core/hooks/event-detail-data-wrapper';
import { Loader } from '@components/shared/loader';
import i18next from 'i18next';
import { sortRaces } from '@core/utils/sortRaces';

export const EventDetails: FC = () => {
	const currentLanguage = i18next.language || 'en';

	const { t } = useTranslation('acs', { useSuspense: false });
	const { id } = useParams();
	const [data, loading] = useEventDetailsData();

	if (loading) {
		return <Loader />;
	}

	return data?.races ? (
		<SellerEventDetails
			eventName={data.title[currentLanguage]}
			races={getRaceWithStatus(sortRaces([...data.races]))}
		/>
	) : (
		<Container>
			<Box pt={5} display="flex" justifyContent="center">
				<Typography variant="h5">{t('event-details.no-race')}</Typography>
			</Box>
			<Grid sx={{ pt: 3 }} container spacing={4} justifyContent="center">
				<Grid item>
					<Button
						size="small"
						component={Link}
						to={generatePath(ROUTES_URI.TRACK_ADD, { id })}
						color="primary"
						variant="contained"
					>
						{t('event.add-blanc')}
					</Button>
				</Grid>
				<Grid item>
					<Button
						size="small"
						component={Link}
						to={generatePath(ROUTES_URI.EVENTS)}
						color="primary"
						variant="outlined"
					>
						{t('event-details.back-events')}
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
};
