import { CustomerData } from '@core/hooks/resellerCustomer/useResellerCustomerSapData';
import { useEffect, useRef, useState } from 'react';
import { useCreateCustomerAccountMutation } from '../../../generated/graphql';
import { DataHook } from '../../../types/dataHook.type';
import { useGroupProducts } from '@core/hooks/group/useGroupProducts';
import { Product } from '@core/hooks/group/group.type';

interface DeliveryPoint {
	ediReference: string;
	name: string;
	address: {
		country?: string;
		city?: string;
		street?: string;
	};
}

interface Payments {
	accountResponsible: string;
	incoterm: string;
	shippingCondition: string;
	priceGroup: string;
}

type CreateResellerCustomer = (params: CreationData) => void;

interface CreationData {
	customerData: CustomerData;
	ediRef: string;
	limit: number;
	deliveryPointList: DeliveryPoint[];
	payments: Payments;
	useCustomPrice?: boolean;
}

type UseCreateResellerCustomer = [CreateResellerCustomer, DataHook<{ id: string }, undefined>];

export const useCreateResellerCustomer = (): UseCreateResellerCustomer => {
	const [createAccount, { data: createData, error: createError }] = useCreateCustomerAccountMutation();
	// const [updateContract, { error: updateContractError }] = useUpdateContractMutation();
	const [getGroupProducts, { data: groupProductsData, error: groupProductsError }] = useGroupProducts();

	const groupId = useRef<string | undefined>();
	const creationData = useRef<CreationData>(null);
	const [state, setState] = useState<DataHook<{ id: string }, undefined>>({
		data: undefined,
		error: false,
		loading: false,
	});

	const handleCreateAccount = ({ customerData, ...others }: CreationData) => {
		setState({ loading: true, data: undefined, error: false });
		groupId.current = customerData.group.id;
		creationData.current = {
			customerData,
			...others,
		};
		getGroupProducts({ id: customerData.group.id });
	};

	const doCreateAccount = ({
		customerData,
		ediRef,
		limit,
		deliveryPointList,
		payments,
		useCustomPrice,
		defaultProducts,
	}: CreationData & { defaultProducts: Product[] }) => {
		groupId.current = customerData.group.id;
		void createAccount({
			variables: {
				params: {
					business_unit: 'resellers',
					name: customerData.businessName,
					edi_reference: ediRef,
					address: {
						city: customerData.businessAddress.city,
						country: customerData.businessAddress.country,
						street: customerData.businessAddress?.address,
					},
					contracts: {
						stores: [groupId.current],
						restricted_products: defaultProducts.map(({ sku }) => sku),
						min_quote_orderable_qty: limit,
					},
					delivery_points: deliveryPointList.map(({ ediReference, address, name }) => ({
						edi_reference: ediReference,
						name,
						address,
					})),
					data: {
						price_group: payments.priceGroup || '',
						account_responsible: payments.accountResponsible,
						incoterm: payments.incoterm,
						shipping_condition: payments.shippingCondition,
						use_custom_price: !payments.priceGroup ? true : !!useCustomPrice,
					},
					customer_group_id: customerData.payments.priceGroup,
				},
			},
		});
	};

	useEffect(() => {
		if (groupProductsData) {
			doCreateAccount({ ...creationData.current, defaultProducts: groupProductsData.defaultProducts });
		}
	}, [groupProductsData]);

	useEffect(() => {
		if (createError || groupProductsError) {
			setState({ loading: false, data: undefined, error: true });
		}
	}, [createError, groupProductsError]);

	useEffect(() => {
		if (createData) {
			setState({ data: { id: createData.createCustomerAccount.id }, loading: false, error: false });
		}
	}, [createData]);

	return [handleCreateAccount, state];
};
