import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useResellerCustomerData } from '@core/hooks/resellerCustomer/useResellerCustomerData';
import { useUserContext } from '@tmds-io/kore';
import { Loader } from '@components/shared/loader';
import { ResellerBusinessCard } from '@components/ResellerBusinessCard/ResellerBusinessCard';
import { ResellerShippingCard } from '@components/ResellerShippingCard/ResellerShippingCard';
import { useErrorNotification } from '@core/hooks/useErrorNotification';
import { ResellerDeliveryList } from '@components/ResellerDeliveryList';
import { useGetStoreRacesLazyQuery } from '../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore/build';
import { DataHook } from '../../types/dataHook.type';
import { CustomerData } from '@core/hooks/resellerCustomer/useResellerCustomerSapData';
import { useCurrentLanguage } from '@core/utils/language.utils';

const PAGINATION_COUNT = 4;

export const ResellerProfile = (): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { id } = useUserContext();
	const [getUserDataForEdit, { data: customerData, error: customerError }] = useResellerCustomerData();
	const [getStore, { data: groupData, error: groupError }] = useGetStoreRacesLazyQuery();
	const [{ data, loading, error }, setState] = useState<DataHook<CustomerData, undefined>>({
		data: undefined,
		error: false,
		loading: true,
	});
	const currentLanguage = useCurrentLanguage();
	const sendErrorNotification = useErrorNotification();

	useEffect(() => {
		getUserDataForEdit(id);
	}, []);

	useEffect(() => {
		if (customerData) {
			const filter = [
				customerData.payments?.priceGroup
					? {
							comparison: FILTER_COMPARISON.EQUAL,
							key: 'data.sap_price_list',
							value: customerData.payments.priceGroup,
					  }
					: { comparison: FILTER_COMPARISON.EQUAL, key: 'code', value: 'nogroup' },
			];
			void getStore({
				variables: {
					filter,
				},
			});
		}
	}, [customerData]);

	useEffect(() => {
		if (error || customerError) {
			sendErrorNotification('profile.error', 'Profile error');
		}
	}, [error, customerError]);

	useEffect(() => {
		if (groupError) {
			console.error('Cannot find group for this user');
			setState({ data: customerData, error: false, loading: false });
		}
	}, [groupError]);

	useEffect(() => {
		if (groupData?.paginatedStores) {
			if (groupData.paginatedStores.stores.length) {
				setState({
					data: {
						...customerData,
						payments: {
							...customerData.payments,
							priceGroup: groupData?.paginatedStores.stores[0].title[currentLanguage],
						},
					},
					error: false,
					loading: false,
				});
			} else {
				setState({ data: customerData, error: false, loading: false });
			}
		}
	}, [groupData?.paginatedStores, currentLanguage]);

	return (
		<Box pb={4}>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t('profile.title')}
			</Typography>
			<Box>
				{loading && !data ? (
					<Loader />
				) : (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<ResellerBusinessCard
									name={data.businessName}
									country={data.businessAddress.country}
									city={data.businessAddress.city}
									street={data.businessAddress.address}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<ResellerShippingCard {...data.payments} />
							</Grid>
						</Grid>
						<Typography variant="h2" sx={{ my: 2 }}>
							{t('reseller-customer.add-page.delivery-point')}
						</Typography>
						<Grid container spacing={(data.deliveryPoints?.length || 0) <= 4 ? 3 : 0}>
							<ResellerDeliveryList deliveryPointList={data.deliveryPoints} pageCount={PAGINATION_COUNT} />
						</Grid>
					</>
				)}
			</Box>
		</Box>
	);
};
