// react
import React from 'react';
import { Box, Divider, Grid, Link, LinkProps, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from '@core/utils/language.utils';

const getFooterLinkList = (language: string, t: (val: string) => string): LinkProps[] => {
	return [
		{
			children: t('footer.terms-of-service'),
			href: `static/pdf/terms-of-service-${language.toUpperCase()}.pdf`,
			download: true,
		},
		{
			children: t('footer.personal-data'),
			href: `static/pdf/personal-data-${language.toUpperCase()}.pdf`,
			download: true,
		},
		{
			children: t('footer.terms-conditions-of-sales'),
			href: `static/pdf/CGV-TEAFS-July-2024-${language.toUpperCase()}.pdf`,
			download: true,
		},
	];
};

/**
 * Footer components -
 */
export const Footer = (): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const currentLanguage = useCurrentLanguage();
	const isDownSm = useMediaQuery(useTheme().breakpoints.down('sm'));
	const linkList = getFooterLinkList(currentLanguage, t);
	return (
		<>
			<Box p={2} height={isDownSm ? 120 : 80}>
				<Grid
					container
					component="ul"
					justifyContent="space-between"
					sx={{ p: 0, m: 0 }}
					direction={isDownSm ? 'column' : 'row'}
				>
					{linkList.map((props, index) => (
						<React.Fragment key={props.href}>
							{index ? <Divider orientation="vertical" flexItem /> : null}
							<Grid item component="li" sx={{ listStyle: 'none' }}>
								<Link {...props} underline="hover" color="text.primary" />
							</Grid>
						</React.Fragment>
					))}
					<Divider orientation="vertical" flexItem />
					<Grid item>© Total Energies {new Date().getFullYear()}</Grid>
				</Grid>
			</Box>
		</>
	);
};
