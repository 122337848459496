import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useProductsStyle = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.palette.background.paper,
			top: 65,
			[theme.breakpoints.up('sm')]: {
				top: 118,
			},
		},
		fabWrapper: {
			position: 'fixed',
			bottom: theme.spacing(10),
		},
	})
);

export const useLegendStyle = makeStyles((theme: Theme) =>
	createStyles({
		square: {
			width: 16,
			height: 16,
			marginRight: theme.spacing(1),
			'&.primary': {
				backgroundColor: theme.palette.primary.main,
			},
			'&.grey': {
				backgroundColor: '#666666',
			},
		},
	})
);
