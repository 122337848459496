// react
import React from 'react';
// Mui
import { Box, Typography, Button } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
// Utils
import { useTranslation } from 'react-i18next';
import { useSendThirdPartMessageMutation } from '../../generated/graphql';
import { useQuery } from '@core/utils/useUrlQuery';
import { useSnackbar } from 'notistack';
import CheckIcon from '@mui/icons-material/Check';
/**
 * FailPayment page - Display error message to the customer after payment fail
 */
export const FailPayment = (): JSX.Element => {
	const { t } = useTranslation('acs');
	const { enqueueSnackbar } = useSnackbar();
	const [delayPayment, { data }] = useSendThirdPartMessageMutation({
		onCompleted: () => {
			enqueueSnackbar('fail-payment.pages.delay-success', { variant: 'success' });
		},
		onError: () => {
			enqueueSnackbar('fail-payment.pages.delay-error', { variant: 'error' });
		},
	});
	const urlQuery = useQuery();

	/**
	 * Handle agree button click call validate transaction endpoint
	 */
	const handleAgree = (): void => {
		delayPayment({
			variables: {
				code: 'acs_orders_validation',
				data: {
					payment_id: urlQuery.get('payment_id'),
				},
				timeout: 30,
				need_response: true,
			},
		});
	};

	return (
		<div>
			<Box display="flex" justifyContent="center">
				<WarningAmber sx={{ fontSize: 80 }} />
			</Box>
			<Box textAlign="center" mt={4}>
				<Typography sx={{ mb: 2 }}>{t('fail-payment.page.paragraph1')}</Typography>
				<Typography sx={{ mb: 2 }}>{t('fail-payment.page.paragraph2')}</Typography>
				<Typography sx={{ mb: 2 }}>{t('fail-payment.page.paragraph3')}</Typography>
			</Box>
			<Box display="flex" justifyContent="center">
				{data ? (
					<CheckIcon color="success" fontSize="large" />
				) : (
					<Button color="primary" variant="contained" onClick={handleAgree}>
						{t('fail-payment.page.button-agree')}
					</Button>
				)}
			</Box>
		</div>
	);
};
