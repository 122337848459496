import React, { ChangeEvent, FunctionComponent } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserTablePropsType } from '../types/user-table-props.type';
import {
	Box,
	Button,
	Card,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { ROUTES_URI } from '../../../app/router/routes';
import { USER_TABLE_ROW_PER_PAGE } from '@components/seller/user/users';

const UsersTable: FunctionComponent<UserTablePropsType> = ({
	accounts,
	pagination,
	refetch,
	enableInteraction,
	values,
	onChange,
	additionalCells,
	userDetailsRoute,
	isCustomer,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		refetch({ limit: { page_number: newPage + 1, count: USER_TABLE_ROW_PER_PAGE } });
	};

	const handleRowSelection = (evt: ChangeEvent<HTMLInputElement>): void => {
		onChange?.(evt.target.value);
	};

	return (
		<Card square={true}>
			<Table>
				<TableHead>
					<TableRow>
						{enableInteraction && values ? <TableCell /> : null}
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('shared.sap')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">
								{t(!isCustomer ? 'user-details.team' : 'shared.companyName')}
							</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.first-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.last-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.email')}</Box>
						</TableCell>
						<TableCell align="right" />
						{additionalCells?.map((element, index) => (
							<TableCell key={index} align="right" />
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{accounts.map((account) => (
						<TableRow key={account.id}>
							{enableInteraction && values ? (
								<TableCell>
									<Box>
										<Checkbox
											value={account.id}
											checked={values.includes(account.id)}
											onChange={handleRowSelection}
										/>
									</Box>
								</TableCell>
							) : null}
							<TableCell>{account.edi_reference}</TableCell>
							<TableCell>{account.name}</TableCell>
							<TableCell>{account.firstname}</TableCell>
							<TableCell>{account.lastname}</TableCell>
							<TableCell>{account.email}</TableCell>
							<TableCell align="right">
								<Button
									component={Link}
									to={generatePath(userDetailsRoute || ROUTES_URI.USER_DETAILS, { id: account.id })}
									startIcon={<ArrowRightIcon fontSize="small" />}
									variant="outlined"
									color="primary"
								>
									{t('shared.see-details')}
								</Button>
							</TableCell>
							{additionalCells?.map((element, index) => (
								<TableCell align="right" key={index}>
									{element(index)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
			{pagination?.totalResult && pagination.totalResult > USER_TABLE_ROW_PER_PAGE ? (
				<TablePagination
					rowsPerPage={USER_TABLE_ROW_PER_PAGE}
					component="div"
					count={pagination.totalResult ?? 0}
					rowsPerPageOptions={[]}
					page={pagination.currentPage ? pagination.currentPage - 1 : 0}
					onPageChange={handleChangePage}
				/>
			) : null}
		</Card>
	);
};

export default UsersTable;
