import { GroupProduct } from '@core/hooks/group/group.type';
import {
	CreateInventoryMutation,
	EventProductDetailsFieldsFragment,
	GroupStoreFieldsFragment,
	StoreProductVariationsPriceFieldsFragment,
} from '../../../../generated/graphql';
import { getMediaUrl } from '@core/services/media';
import { Group, GroupInventory } from './useGroupEdition.type';

export const getSelectedProductsForEdition = (
	productList: GroupProduct[],
	inventoriesData: GroupStoreFieldsFragment['inventories']
): GroupProduct[] => productList.filter(({ sku }) => inventoriesData.find(({ product_sku }) => sku === product_sku));

export const formatProductForGroupEdition = (
	data:
		| (StoreProductVariationsPriceFieldsFragment & { prices?: undefined })[]
		| (EventProductDetailsFieldsFragment & { price?: undefined })[],
	currentLanguage: string,
	defaultProductIds: string[] = []
): GroupProduct[] => {
	return data.map(({ variation, product, ...others }) => {
		const [increment, volume] = variation.options[0].value.split('_');

		return {
			id: variation.id,
			name: product.title[currentLanguage],
			type: product.categories[0].title[currentLanguage],
			typeId: product.categories[0].id,
			image: product.image ? getMediaUrl(product.image) : undefined,
			sku: variation.sku,
			parentSku: product.sku,
			volume: `${(+increment * +volume).toFixed(2)} L`,
			isHide: false,
			price: others.price?.price || 0,
			isDefault: !!defaultProductIds?.includes(variation.sku),
		};
	});
};

export const formatStoreForGroupEdition = (data: GroupStoreFieldsFragment, currentLanguage: string): Group => ({
	id: data.id,
	title: data.title[currentLanguage],
	entryScore: data?.data?.entry_score,
	sapPriceList: data?.data?.sap_price_list,
	referringCustomer: data?.data.referring_customer,
});

export const formatInventoryForGroupEdition = (data: CreateInventoryMutation['createInventory']): GroupInventory => ({
	id: data.id,
	productSku: data.product_sku,
	parentSku: data.parent_sku,
});
