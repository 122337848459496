import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceModal from '@components/customer/payment/modals/invoice-modal';
import {
	Box,
	Grid,
	Typography,
	Card,
	CardContent,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Table,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
	Link,
	Button,
	TextField,
	CircularProgress,
} from '@mui/material';
import { Payment as PaymentIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import {
	useGetOrderQuery,
	useGetPaymentMethodByQuoteLazyQuery,
	useSendThirdPartMessageMutation,
} from '../../../generated/graphql';
import { Loader } from '@components/shared/loader';
import { FormSubmit } from '@components/customer/payment/partials/form-sumit';
import { ProductRow } from '@components/customer/payment/partials/product-row';
import { useCurrentLanguage } from '@core/utils/language.utils';
import {
	DeliveryFormValues,
	DeliverySelector,
	SELF_DELIVERY_ID,
	TEAFS_DELIVERY_ID,
} from '@components/DeliverySelector';
import { useUserHelper } from '@core/hooks/useUserHelper';
import { useErrorNotification } from '@core/hooks/useErrorNotification';

export const Payment = (): JSX.Element => {
	const currentLanguage = useCurrentLanguage();
	const { isReseller } = useUserHelper();
	const { id: orderId } = useParams();
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | undefined>();
	const [accept, setAccept] = useState<boolean>(false);
	const cguLink = `/static/pdf/CGV-2021-${currentLanguage.toUpperCase()}.pdf`;
	const [deliveryInfo, setDeliveryInfo] = useState<(Partial<DeliveryFormValues> & { isValid: boolean }) | undefined>();
	const submitButtonRef = useRef<HTMLButtonElement>();
	const sendErrorNotification = useErrorNotification();
	const { t } = useTranslation('acs', { useSuspense: false });
	const [showInvoiceModal, setShowInvoiceModal] = useState(false);
	const [validDelivery, setValidDelivery] = useState<boolean | undefined>();
	const [setShippingCondition, { data: shippingCondition, error: shippingConditionError }] =
		useSendThirdPartMessageMutation();
	const comment1 = useRef<string>('');
	const comment2 = useRef<string>('');
	const [isWaiting, setIsWaiting] = useState<boolean>(false);

	const {
		data: orderData,
		loading,
		error,
	} = useGetOrderQuery({
		variables: {
			id: orderId,
		},
	});

	const order = orderData?.order;

	const [getPaymentMethod, { data: paymentMethodData }] = useGetPaymentMethodByQuoteLazyQuery();

	useEffect(() => {
		if (order) {
			getPaymentMethod({ variables: { quote_id: order.quote_id } });
		}
	}, [order]);

	useEffect(() => {
		if (shippingCondition) {
			if (shippingConditionError || shippingCondition.sendThirdPartMessage.failed) {
				console.error('Payment fail set shipping condition');
				sendErrorNotification('payment.shipping-error', 'set shipping fail');
			} else {
				submitButtonRef?.current?.click();
			}
		}
	}, [shippingCondition, shippingConditionError]);

	const handleShippingCondition = async () => {
		await setShippingCondition({
			variables: {
				code: 'shipping_information',
				timeout: 30,
				need_response: true,
				data: {
					quote_id: order.quote_id,
					carrier_code: deliveryInfo.preferredDeliveryMethod,
					method_code: deliveryInfo.meansOfConveying,
					comments: {
						ZTDL: comment1.current,
						ZTIN: comment2.current,
					},
					desired_shipping_date: deliveryInfo?.collectionDate
						? new Date(deliveryInfo.collectionDate).toISOString()
						: undefined,
					delivery_point_id: deliveryInfo.deliveryPoint,
				},
			},
		});
		setIsWaiting(false);
	};

	const paymentMethod = paymentMethodData?.paymentMethodByQuote.methods;
	const orderLines = paymentMethodData?.paymentMethodByQuote.orders.reduce((orderLines, order) => {
		return [...orderLines, ...order.order_lines];
	}, []);

	const globalOrder = paymentMethodData?.paymentMethodByQuote.orders.reduce(
		(globalOrder, order) => {
			return {
				total: globalOrder.total + order.total,
				tax_amount: globalOrder.tax_amount + order.tax_amount,
				sub_total_incl_tax: globalOrder.sub_total_incl_tax + order.sub_total_incl_tax,
				sub_total: globalOrder.sub_total + order.sub_total,
				total_to_pay: globalOrder.total_to_pay + order.total_to_pay,
			};
		},
		{ total: 0, tax_amount: 0, sub_total_incl_tax: 0, sub_total: 0, total_to_pay: 0 }
	);

	useEffect(() => {
		if (deliveryInfo?.preferredDeliveryMethod === TEAFS_DELIVERY_ID) {
			setSelectedPaymentMethod(paymentMethod.findIndex(({ type }) => type === 'delayed'));
		}
	}, [deliveryInfo?.preferredDeliveryMethod]);

	const handlePaymentMode = (index: number) => {
		setSelectedPaymentMethod(index);
	};

	const handleDeliveryFormSubmit = (values: DeliveryFormValues): void => {
		setDeliveryInfo({ ...values, isValid: true });
	};

	const handleChangeDelivery = (values: DeliveryFormValues, isValid: boolean): void => {
		setDeliveryInfo({ ...values, isValid });
	};

	const handleChangeComment = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (evt.target.name === 'comment1') {
			comment1.current = evt.target.value;
		}
		if (evt.target.name === 'comment2') {
			comment2.current = evt.target.value;
		}
	};

	const handleOrderReseller = () => {
		setIsWaiting(true);
		if (!deliveryInfo.isValid) {
			setValidDelivery((v) => !v);
			if (deliveryInfo.isValid) {
				handleShippingCondition();
			}
		} else {
			handleShippingCondition();
		}
	};

	if (error) {
		return (
			<Box py={4} display="flex" justifyContent="center">
				<Typography>{t('page-not-found')}</Typography>
			</Box>
		);
	}

	if (loading || !paymentMethod) {
		return <Loader />;
	}

	return (
		<>
			<Box mb={3}>
				<Typography variant="h2">{t('payment.title')}</Typography>
			</Box>

			<Grid container spacing={3}>
				<Grid xs={12} md={6} item>
					<Card square>
						<CardContent>
							<Box mb={3}>
								<Typography variant="h6">
									<b>{t('shared.summary')}</b>
								</Typography>
							</Box>

							<Table>
								<TableHead>
									<TableRow>
										<TableCell>
											<small>Qt.</small>
										</TableCell>
										<TableCell>
											<small>{t('shared.product')}</small>
										</TableCell>
										<TableCell>
											<small>Volume</small>
										</TableCell>
										<TableCell>
											<small>{t('shared.price')}</small>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{orderLines.map(
										({ id, quantity_ordered, variations, title, row_total, quantity_increment }) => (
											<ProductRow
												key={id}
												quantity={quantity_ordered.toFixed(2)}
												volume={variations[0].value}
												price={row_total}
												title={title[currentLanguage]}
												qtyIncrement={quantity_increment}
											/>
										)
									)}
									<TableRow>
										<TableCell>Total HT :</TableCell>
										<TableCell />
										<TableCell />
										<TableCell>{globalOrder.sub_total} €</TableCell>
									</TableRow>

									{globalOrder.sub_total_incl_tax !== globalOrder.sub_total ? (
										<TableRow>
											<TableCell>TVA 20% :</TableCell>
											<TableCell />
											<TableCell />
											<TableCell>{globalOrder.tax_amount.toFixed(2)} €</TableCell>
										</TableRow>
									) : null}

									<TableRow>
										<TableCell>Total TTC :</TableCell>
										<TableCell />
										<TableCell />
										<TableCell>{globalOrder.sub_total_incl_tax.toFixed(2)} €</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</CardContent>
					</Card>
				</Grid>

				<Grid xs={12} md={6} item>
					<Card square>
						<CardContent>
							{isReseller ? (
								<>
									<Box mb={2}>
										<DeliverySelector
											validation={validDelivery}
											onChange={handleChangeDelivery}
											onFormSubmit={handleDeliveryFormSubmit}
										/>
									</Box>
									<Box mb={3}>
										<Typography variant="h6">{t('payment.comment-title')}</Typography>
										<Box mx={2}>
											<TextField
												sx={{ mt: 2 }}
												minRows={4}
												name="comment1"
												multiline
												fullWidth
												inputProps={{ maxLength: 132 }}
												onChange={handleChangeComment}
												label={t('payment.comment-placeholder1')}
											/>
											<TextField
												sx={{ mt: 2 }}
												minRows={4}
												multiline
												name="comment2"
												fullWidth
												inputProps={{ maxLength: 132 }}
												onChange={handleChangeComment}
												label={t('payment.comment-placeholder2')}
											/>
										</Box>
									</Box>
								</>
							) : null}
							{!isReseller || (isReseller && deliveryInfo?.preferredDeliveryMethod === SELF_DELIVERY_ID) ? (
								<Box>
									<Box mb={1}>
										<Typography variant="h6">
											<b>{t('payment.title')}</b>
										</Typography>
									</Box>

									<Box pl={3} pr={3} mb={3}>
										<RadioGroup aria-label="paymentMethod" name="paymentMethod">
											{paymentMethod
												?.map(({ type }, index) => (
													<FormControlLabel
														key={index}
														value={index}
														control={<Radio color="primary" />}
														onClick={() => handlePaymentMode(index)}
														checked={index === selectedPaymentMethod}
														label={
															<Box display="flex" alignItems="center">
																<PaymentIcon sx={{ mr: 1 }} />
																<Box>
																	<Typography>{t('payment.' + type)}</Typography>
																	{type === 'delayed' && index === selectedPaymentMethod ? (
																		<Typography variant="caption">
																			{t('payment.delayed-caption')}
																		</Typography>
																	) : null}
																</Box>
															</Box>
														}
													/>
												))
												.sort((a, b) => (a.type > b.type ? -1 : 1))}
										</RadioGroup>
									</Box>

									<FormControlLabel
										sx={{ mb: 3 }}
										control={
											<Checkbox
												onChange={() => setAccept((a) => !a)}
												checked={accept}
												name="termsAndCond"
												color="primary"
											/>
										}
										label={
											<Typography>
												{t('payment.terms-and-cons')}{' '}
												<Link href={cguLink} download>
													{t('payment.terms-and-cons-link')}
												</Link>
											</Typography>
										}
									/>

									<Box textAlign="right">
										{isReseller ? (
											<>
												<Button
													variant="contained"
													color="primary"
													onClick={handleOrderReseller}
													disabled={!accept || isWaiting}
													sx={{ display: isWaiting ? 'none' : undefined }}
												>
													{t('shared.toOrder')}
												</Button>
												<Button
													variant="contained"
													color="primary"
													startIcon={<CircularProgress size={16} />}
													disabled={!accept || isWaiting}
													sx={{ display: isWaiting ? undefined : 'none' }}
												>
													{t('shared.toOrder')}
												</Button>
											</>
										) : null}
									</Box>
								</Box>
							) : null}
							{isReseller && deliveryInfo?.preferredDeliveryMethod === TEAFS_DELIVERY_ID ? (
								<Box>
									<FormControlLabel
										sx={{ mb: 3 }}
										control={
											<Checkbox
												onChange={() => setAccept((a) => !a)}
												checked={accept}
												name="termsAndCond"
												color="primary"
											/>
										}
										label={
											<Typography>
												{t('payment.terms-and-cons')}{' '}
												<Link href={cguLink} download>
													{t('payment.terms-and-cons-link')}
												</Link>
											</Typography>
										}
									/>
									<Box display="flex" justifyContent="flex-end">
										<Button
											variant="contained"
											color="primary"
											onClick={handleOrderReseller}
											disabled={!accept}
										>
											{t('shared.sendMyOrder')}
										</Button>
									</Box>
								</Box>
							) : null}

							<Box visibility={isReseller ? 'hidden' : undefined} textAlign="right">
								<FormSubmit
									ref={submitButtonRef}
									paymentId={paymentMethodData?.paymentMethodByQuote.payment_id}
									paymentType={paymentMethod?.[selectedPaymentMethod]?.type}
									buttonText={t('shared.pay')}
									paymentButton={
										typeof selectedPaymentMethod === 'number' && paymentMethod?.[selectedPaymentMethod]?.data
									}
									async={paymentMethod?.[selectedPaymentMethod]?.async}
									disabled={!accept || typeof selectedPaymentMethod !== 'number'}
								/>
							</Box>
						</CardContent>
					</Card>
				</Grid>
			</Grid>

			<InvoiceModal open={showInvoiceModal} onClose={() => setShowInvoiceModal(false)} />
		</>
	);
};
