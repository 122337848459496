// react
import { useEffect, useState } from 'react';
import { UseGroupEditionBase, UseGroupEditionProps } from '@core/hooks/group/useGroupEdition/useGroupEdition.type';
import {
	EventProductDetailsFieldsFragment,
	useGetGroupProductsVariationsLazyQuery,
	useGetGroupStoreLazyQuery,
} from '../../../../generated/graphql';

import { useCurrentLanguage } from '@core/utils/language.utils';
import {
	formatInventoryForGroupEdition,
	formatProductForGroupEdition,
	getSelectedProductsForEdition,
} from './groupEdition.core';

export const useGroupEdition = (props: UseGroupEditionProps): UseGroupEditionBase => {
	const currentLanguage = useCurrentLanguage();
	const [fetchGroup, { data: groupData, error: groupError }] = useGetGroupStoreLazyQuery();
	const [fetchAvailableProduct, { data: availableProductsData, error: availableProductsError }] =
		useGetGroupProductsVariationsLazyQuery();
	const [localState, setLocalState] = useState<UseGroupEditionBase>({
		data: undefined,
		loading: !props?.lazy,
		error: false,
	});

	const handleFetch = (props: UseGroupEditionProps): void => {
		if (props.id) {
			void fetchAvailableProduct({ variables: { params: {} } });
			void fetchGroup({
				variables: {
					id: props.id,
				},
			});
		}
	};

	useEffect(() => {
		if (!props.lazy && props.id) {
			void fetchGroup({
				variables: {
					id: props.id,
				},
			});
		}
		if (!props.lazy) {
			void fetchAvailableProduct({
				variables: { params: {} },
			});
		}
	}, [props.id, props.lazy]);

	useEffect(() => {
		if (availableProductsData) {
			if (groupData) {
				const availableProducts = formatProductForGroupEdition(
					availableProductsData.productsVariationsWithPrices as EventProductDetailsFieldsFragment[],
					currentLanguage,
					groupData.store.data.default_products_sku
				);

				setLocalState({
					data: {
						allProducts: availableProducts,
						groupSelectProducts: groupData?.store.inventories
							? getSelectedProductsForEdition(availableProducts, groupData.store.inventories)
							: [],
						group: {
							id: groupData.store.id,
							sapPriceList: groupData?.store.data?.sap_price_list,
							title: groupData.store.title,
							entryScore: groupData.store.data?.entry_score,
							referringCustomer: groupData.store.data?.referring_customer,
						},
						inventories: groupData?.store?.inventories
							? groupData?.store?.inventories?.map(formatInventoryForGroupEdition)
							: [],
					},
					loading: false,
					error: false,
					fetch: handleFetch,
				});
			} else if (!props.id) {
				const availableProducts = formatProductForGroupEdition(
					availableProductsData.productsVariationsWithPrices as EventProductDetailsFieldsFragment[],
					currentLanguage,
					[]
				);
				setLocalState({
					data: {
						allProducts: availableProducts,
						groupSelectProducts: [],
						group: undefined,
						inventories: [],
					},
					loading: false,
					error: false,
					fetch: handleFetch,
				});
			}
		}
	}, [groupData, availableProductsData, props.id]);

	useEffect(() => {
		if (groupError || availableProductsError) {
			setLocalState({
				data: undefined,
				loading: false,
				error: true,
				fetch: handleFetch,
			});
		}
	}, [groupError, availableProductsError]);

	return localState;
};
