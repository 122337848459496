// React
import React from 'react';
// Mui
import { Alert, Box, Button, Card, Grid, Typography } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
// Routing
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
// Components
import UserKeyValueDetail from '@components/seller/user/partials/users-details-colums';
import { Layout } from '@components/customer/profile';
import { AsyncContent } from '@components/shared/AsyncContent';
// Gql
import { Account, useGetAccountOwnerQuery } from '../../../../generated/graphql';
// Hooks
import { useUserContext } from '@tmds-io/kore';
import { useTranslation } from 'react-i18next';
import deepmerge from 'deepmerge';

/**
 * User company and main contact details page
 */
export const CompanyAndMainContact = (): JSX.Element => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { id } = useUserContext();
	const {
		data: accountData,
		loading,
		error,
	} = useGetAccountOwnerQuery({
		variables: { id },
		fetchPolicy: 'no-cache',
	});

	const pendingValidation = accountData?.account?.pending_update?.account;
	const account = pendingValidation
		? (deepmerge(accountData?.account, pendingValidation) as Account)
		: (accountData?.account as Account);

	return (
		<Layout title={t('customer.user-profile.business')} backLink={generatePath(ROUTES_URI.INFOS)}>
			<Box>
				{pendingValidation ? (
					<Alert severity="warning">{t('customer.user-profile.pending-validation')}</Alert>
				) : null}
				<Card square sx={{ minHeight: 330, height: '100%', width: '100%' }}>
					<AsyncContent<Account> loading={loading} error={error} data={account}>
						{(data) => (
							<Box p={3}>
								<Grid container spacing={2}>
									<Grid md={6} xs={12} item>
										<Typography variant="h6" color="primary">
											<b>{t('register.business.form.title')}</b>
										</Typography>
										<Box mt={2} pl={1}>
											<UserKeyValueDetail
												label={t('user-details.social-reason')}
												detailKey={'user-details.social-reason'}
												detailValue={data.name}
											/>
											<UserKeyValueDetail
												label={t('user-details.company-number')}
												detailKey={'user-details.company-number'}
												detailValue={data.companyNumber}
											/>
											<UserKeyValueDetail
												label={t('user-details.address')}
												detailKey={'user-details.address'}
												detailValue={data.address?.street}
											/>
											<UserKeyValueDetail
												label={t('user-details.phone')}
												detailKey={'user-details.phone'}
												detailValue={data.phone}
											/>
											<UserKeyValueDetail
												label={t('user-details.vat-number')}
												detailKey={'user-details.vat-number'}
												detailValue={data.vat}
											/>
										</Box>
									</Grid>
									<Grid md={6} xs={12} item>
										<Typography variant="h6" color="primary">
											<b>{t('register.business.form.contact-title')}</b>
										</Typography>

										<Box mt={2} pl={1}>
											<UserKeyValueDetail
												label={t('user-details.first-name')}
												detailKey={'user-details.first-name'}
												detailValue={data.billing_contact?.firstname}
											/>
											<UserKeyValueDetail
												label={t('user-details.last-name')}
												detailKey={'user-details.last-name'}
												detailValue={data.billing_contact?.lastname}
											/>
											<UserKeyValueDetail
												label={t('user-details.email')}
												detailKey={'user-details.email'}
												detailValue={data.billing_contact?.mail}
											/>
										</Box>
									</Grid>
								</Grid>

								<Box textAlign="right">
									<Button
										variant="outlined"
										component={Link}
										startIcon={<EditIcon />}
										color="primary"
										to={generatePath(ROUTES_URI.PROFILE_EDIT)}
									>
										{t('shared.edit')}
									</Button>
								</Box>
							</Box>
						)}
					</AsyncContent>
				</Card>
			</Box>
		</Layout>
	);
};
