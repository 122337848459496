import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useConditioningSectionStyle = makeStyles((theme: Theme) =>
	createStyles({
		conditioningTable: {
			'& tr': {
				'& td:not(:last-of-type)': {
					paddingRight: theme.spacing(2),
				},
				'& td:not(:first-of-type)': {
					paddingLeft: theme.spacing(2),
				},
			},
		},
		numberRange: {
			fontSize: 10,
		},
		deleteBtnGroup: {
			borderRadius: 15,
		},
	})
);
