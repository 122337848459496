// react
import React, { useEffect } from 'react';
// Data
import { useGroupDetails, User } from '@core/hooks/group/useGroupDetails';
// Router
import { generatePath, Link, useParams } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
// Components
import { Loader } from '@components/shared/loader';
import { Cell, SortableTable } from '@tmds-io/kore';
// Hooks
import { Typography, Paper, Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Types
import { Product } from '@core/hooks/group/group.type';
import { ArrowRight as ArrowRightIcon, ArrowRight } from '@mui/icons-material';

const getHeadCells = (t: (val: string) => string): Cell<Product>[] => [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: t('shared.name'),
	},
	{
		id: 'conditioning',
		numeric: false,
		disablePadding: false,
		label: t('shared.conditioning'),
	},
	{
		id: 'type',
		numeric: true,
		disablePadding: false,
		label: t('shared.type'),
	},
	{
		id: 'sku',
		numeric: true,
		disablePadding: false,
		label: t('shared.sap'),
	},
	{
		id: 'pricePerLiter',
		numeric: true,
		disablePadding: false,
		label: t('shared.pricePerLiter'),
	},
];
const getUserHeadCells = (t: (val: string) => string, users: User[]): Cell<User>[] => [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: t('shared.companyName'),
	},
	{
		id: 'firstName',
		numeric: false,
		disablePadding: false,
		label: t('shared.firstname'),
	},
	{
		id: 'lastName',
		numeric: true,
		disablePadding: false,
		label: t('shared.lastname'),
	},
	{
		id: 'email',
		numeric: true,
		disablePadding: false,
		label: t('shared.email'),
	},
	{
		id: 'score',
		numeric: true,
		disablePadding: false,
		label: t('shared.score'),
	},
	{
		id: 'none',
		numeric: true,
		disablePadding: false,
		label: '',
		endElement: (rowIndex) => (
			<Grid container spacing={2} justifyContent="flex-end">
				<Grid item>
					<Button
						component={Link}
						to={generatePath(ROUTES_URI.CUSTOMER_EDIT, { id: users[rowIndex].id })}
						startIcon={<ArrowRightIcon fontSize="small" />}
						variant="outlined"
						color="primary"
					>
						{t('shared.see-details')}
					</Button>
				</Grid>
				<Grid item>
					<Button
						component={Link}
						to={generatePath(ROUTES_URI.USER_PRODUCTS, { id: users[rowIndex].id })}
						startIcon={<ArrowRight fontSize="small" />}
						variant="outlined"
						color="primary"
					>
						{t('customer-selection.user-products')}
					</Button>
				</Grid>
			</Grid>
		),
	},
];

/**
 * GroupDetails components -
 */
export const GroupDetails = (): JSX.Element => {
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('acs', { useSuspense: false });
	const [getGroupDetails, { data, error, loading }] = useGroupDetails();
	const cells = getHeadCells(t);
	const userCells = getUserHeadCells(t, data?.users || []);

	useEffect(() => {
		getGroupDetails({ id });
	}, []);

	useEffect(() => {
		if (error) {
			enqueueSnackbar(t('group-details.page.fetch-error'), { variant: 'error' });
		}
	}, [error]);

	if (loading || error) {
		return <Loader />;
	}

	return (
		<div>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t('group-details.page.title')} : {data.group.title}
			</Typography>
			<Box pb={4}>
				<Typography variant="h2" sx={{ mb: 2 }}>
					{t('group-details.page.default-products')}
				</Typography>
				<Paper sx={{ mb: 4 }}>
					<SortableTable<Product> cells={cells} data={data.defaultProducts} />
				</Paper>
				<Box mt={2}>
					<Typography variant="h2" sx={{ mb: 2 }}>
						{t('group-details.page.other-products')}
					</Typography>
					<Paper sx={{ mb: 4 }}>
						<SortableTable<Product> cells={cells} data={data.otherProducts} />
					</Paper>
				</Box>
				<Box display="flex" justifyContent="flex-end">
					<Button
						component={Link}
						variant="contained"
						color="primary"
						to={generatePath(ROUTES_URI.GROUP_EDIT, { id })}
					>
						{t('group-details.page.btn-update-group')}
					</Button>
				</Box>
			</Box>

			<Typography variant="h2" sx={{ mb: 2 }}>
				{t('group-details.page.users')}
			</Typography>
			<Paper sx={{ mb: 4 }}>
				<SortableTable<User> cells={userCells} data={data.users} />
			</Paper>
		</div>
	);
};
