import React, { FunctionComponent, useState } from 'react';
import { HeaderPropsType } from './types/header-props.type';
import { useTranslation } from 'react-i18next';
import { Header, CartIcon, HeaderProps, useUserContext } from '@tmds-io/kore';
import { Box, Grid } from '@mui/material';
import { getLng, LanguageEnum, languagesList, updateLanguage } from '../../../../i18n';
import { useCartContext } from '@core/services/cart/cart.context';
import { generatePath } from 'react-router-dom';
import { ROUTES_URI } from '../../../app/router/routes';

const CoreHeader: FunctionComponent<HeaderPropsType> = ({ items = [], hideMenu = false, isSeller }) => {
	const [currentLanguage, setCurrentLanguage] = useState<LanguageEnum>(getLng());
	const { t } = useTranslation('acs', { useSuspense: true });
	const { firstname, lastname } = useUserContext();
	const menuItems = items
		.filter((item) => item.name)
		.map((item) => ({ ...item, name: t(item.name), path: generatePath(item.path) }));
	const { products } = useCartContext();
	const headerCols = !isSeller
		? [
				{
					position: 2 as const,
					element: (
						<Grid item xs="auto">
							<Box display="flex" alignItems="center" height={'100%'} sx={{ color: 'primary' }}>
								<CartIcon
									to={generatePath(ROUTES_URI.CART)}
									badgeValue={products.length}
									currency="€"
									hideBalance
								/>
							</Box>
						</Grid>
					),
				},
		  ]
		: undefined;

	const handleChangeLanguage = (lng: LanguageEnum): void => {
		setCurrentLanguage(lng);
		updateLanguage(lng);
	};

	const headerProps: HeaderProps<LanguageEnum> = {
		firstName: firstname,
		lastName: lastname,
		appName: 'Racing-shop',
		menus: hideMenu ? [] : menuItems,
		headerCols: headerCols,
		languages: languagesList.map((lang) => ({ label: lang.toUpperCase(), value: lang as LanguageEnum })),
		currentLanguage: currentLanguage,
		onChangeLanguage: handleChangeLanguage,
	};

	return <Header {...headerProps} />;
};

export default CoreHeader;
