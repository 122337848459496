import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';

type DebugContextType = { debug: boolean; setDebug: (value: boolean) => void; enableListener: () => void };

const DebugContext = createContext<DebugContextType>({
	debug: false,
	setDebug: (value: boolean) => console.log(value),
	enableListener: () => console.log('none'),
});
const debugKey = '_deb';
const keys = [
	'ArrowUp',
	'ArrowUp',
	'ArrowDown',
	'ArrowDown',
	'ArrowLeft',
	'ArrowRight',
	'ArrowLeft',
	'ArrowRight',
	'b',
	'a',
];

export const DebugProvider: FC = ({ children }) => {
	const [debug, setDebug] = useState<boolean>(!!window.localStorage.getItem(debugKey));
	const [isListening, setIsListening] = useState<boolean>(false);
	const enableListener = () => {
		setIsListening((l) => !l);
	};
	const code = useRef<number>(0);

	const handleKeyPress = (evt: KeyboardEvent) => {
		if (evt.key === keys[code.current++]) {
			if (code.current === keys.length) {
				code.current = 0;
				setDebug((d) => !d);
				return false;
			}
		} else {
			code.current = 0;
		}
	};

	useEffect(() => {
		if (isListening) {
			window.document.addEventListener('keyup', handleKeyPress);
		} else {
			window.document.removeEventListener('keyup', handleKeyPress);
		}
		return () => {
			window.document.removeEventListener('keyup', handleKeyPress);
		};
	}, [isListening]);

	useEffect(() => {
		if (debug) {
			window.localStorage.setItem(debugKey, '1');
		} else {
			window.localStorage.removeItem(debugKey);
		}
	}, [debug]);

	return <DebugContext.Provider value={{ debug, setDebug, enableListener }}>{children}</DebugContext.Provider>;
};

export const useDebugContext = (): DebugContextType => useContext(DebugContext);
