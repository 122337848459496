import { gql } from '@apollo/client';

export const UPDATE_PRICES = gql`
	mutation updatePrices($price: PriceInput!) {
		updatePrices(price: $price)
	}
`;

export const ADD_PRICES = gql`
	mutation addPrices($data: [PriceInput]!) {
		addPrices(data: $data) {
			id
			price
			custom_price
			product_sku
			inventory_id
			sale_area_id
		}
	}
`;
