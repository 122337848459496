import { UPDATE_PRICES } from '../../../../gql/price/mutation.gql';
import { GET_PRICES } from '../../../../gql/price/query.gql';
import { Price, PriceInput, GetPricesQueryVariables, GetPricesQuery } from '../../../../generated/graphql';
import { client } from '@tmds-io/kore';
import { Response } from '@core/utils/apollo/request';

export const updateProductPrice = async (id: string, price: PriceInput): Promise<Response<Price[]>> => {
	const { data, errors } = await client.mutate({
		mutation: UPDATE_PRICES,
		variables: { id, price },
	});
	if (errors) {
		console.error('product.request.fail.update-price');
		return { error: data.addPrices };
	}

	return { error: false, data: data.updatePrice };
};

export const getProductVariationPrices = async (sku: string): Promise<Price[]> => {
	const { data } = await client.query<GetPricesQuery, GetPricesQueryVariables>({
		query: GET_PRICES,
		variables: { sku },
	});
	return data.prices;
};
