// react
import React, { FC, useEffect, useRef, useState } from 'react';
import { Typography, Box, TextField, Button, Paper, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserContext, SortableTable, DialogWithIcon, Dialog } from '@tmds-io/kore';
import {
	useDeleteMemberMutation,
	useGetProfilesQuery,
	useGetRolesQuery,
	useInviteMemberMutation,
	useUpdateProfileMemberMutation,
} from '../../generated/graphql';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Loader } from '@components/shared/loader';
import { formatMembers, getSchema, getHeadCells, FormValues, Member } from '@components/Members';
import { WarningAmberOutlined } from '@mui/icons-material';
import { RoleSelect } from '@components/Members/RoleSelect';
import { MemberEditRoleModalContent } from '@components/Members/MemberEditRoleModalContent';
import { TEAFS_ADMIN, TEAFS_USER } from '@core/hooks/useUserHelper';

/** Members page properties */
interface MembersProps {
	employeeRole?: string;
	adminRole?: string;
}

/**
 * Members components -
 */
export const Members: FC<MembersProps> = ({ employeeRole = TEAFS_USER, adminRole = TEAFS_ADMIN }: MembersProps) => {
	const [warningModalIsOpen, setWarningModalIsOpen] = useState<boolean>(false);
	const [memberToEdit, setMemberToEdit] = useState<Member | undefined>();
	const selfDeletion = useRef<boolean>(false);
	const { t } = useTranslation('acs', { useSuspense: false });
	// Hooks
	const { id, logout, profileId } = useUserContext();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	// Api hooks
	const [invite, { data, error, loading }] = useInviteMemberMutation();
	const [updateProfile] = useUpdateProfileMemberMutation({
		onCompleted: (data) => {
			if (data.updateProfileMember) {
				setMemberToEdit(undefined);
				enqueueSnackbar(t('members.page.error-update-success'), { variant: 'success' });
				refetchProfiles();
			}
		},
		onError: () => {
			enqueueSnackbar(t('members.page.error-update-fail'), { variant: 'success' });
		},
	});
	const { data: profilesData, error: profilesError, refetch: refetchProfiles } = useGetProfilesQuery();
	const { data: rolesData, error: rolesError } = useGetRolesQuery();

	const [deleteMember] = useDeleteMemberMutation({
		onCompleted: (data) => {
			if (data.deleteMember) {
				void refetchProfiles();

				if (selfDeletion.current) {
					logout();
				}
			}
		},
		onError: () => {
			enqueueSnackbar(t('members.page.error-delete-fail'));
		},
	});
	// Constants
	const employeeRoleId = useRef<string | undefined>();
	const adminRoleId = useRef<string | undefined>();
	const roleSelectOptions = useRef<{ label: string; value: string }[]>([]);

	// Set employee and admin role id after api call
	useEffect(() => {
		if (rolesData) {
			employeeRoleId.current = rolesData.roles.find(({ code }) => code === employeeRole)?.id;
			adminRoleId.current = rolesData.roles.find(({ code }) => code === adminRole)?.id;
			roleSelectOptions.current = [
				{
					label: t('members.page.' + adminRole),
					value: adminRoleId.current,
				},
				{
					label: t('members.page.' + employeeRole),
					value: employeeRoleId.current,
				},
			];
		}
	}, [rolesData]);

	/**
	 * Handle member deletion - Call api for remove member or display notification error
	 * @param index
	 */
	const handleMemberDelete = (index: string): void => {
		const memberId = profilesData.profiles[+index].membership.find(({ roles }) =>
			roles.find(({ code }) => code === employeeRole || code === adminRole)
		)?.id;
		if (memberId) {
			selfDeletion.current = profilesData.profiles[+index].id === profileId;
			if (selfDeletion.current) {
				const time = setTimeout(() => {
					void deleteMember({
						variables: {
							id: memberId,
						},
					});
				}, 5000);
				enqueueSnackbar(
					<Box display="flex" justifyContent="space-between" alignItems="center">
						{t('members.page.self-deletion')}
						<Button
							color="inherit"
							onClick={() => {
								closeSnackbar();
								window.clearTimeout(time);
							}}
						>
							{t('shared.cancel')}
						</Button>
					</Box>,
					{ variant: 'warning' }
				);
			} else {
				void deleteMember({
					variables: {
						id: memberId,
					},
				});
			}
		} else {
			enqueueSnackbar(t('members.page.error-user-not-found'), { variant: 'error' });
		}
	};

	/**
	 * Handle form submission
	 * @param values
	 */
	const handleSubmit = (values: FormValues) => {
		invite({
			variables: {
				params: {
					account_id: id,
					roles: [values.role],
					profile_data: {
						first_name: values.firstName,
						last_name: values.lastName,
						mail: values.email,
					},
					sale_groups: [],
				},
			},
		});
	};

	// Form initialization
	const form = useFormik<FormValues>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			role: '',
		},
		validationSchema: getSchema(t),
		onSubmit: handleSubmit,
	});

	// Handle form submit error, display error notification
	useEffect(() => {
		if (error) {
			enqueueSnackbar(t('members.page.send-error'), { variant: 'error' });
		}
	}, [error]);

	// Handle date fetch error, display error notification
	useEffect(() => {
		if (profilesError || rolesError) {
			enqueueSnackbar(t('error.cannot-fetch-data'), { variant: 'error' });
		}
	}, [profilesError, rolesError]);

	// Handle form submission success, display success notification, refetch members list and reset form
	useEffect(() => {
		if (data) {
			enqueueSnackbar(t('members.page.send-success'), { variant: 'success' });
			void refetchProfiles();
			form.resetForm();
		}
	}, [data]);

	const handleCloseWarningModal = (): void => {
		setWarningModalIsOpen(false);
	};
	const handleOpenWarningModal = (): void => {
		setWarningModalIsOpen(true);
	};

	const handleCloseEditRoleModal = (): void => {
		setMemberToEdit(undefined);
	};

	const handleEditRole = (member: Member): void => {
		setMemberToEdit(member);
	};

	const handleUpdateMemberRole = (newRole: string): void => {
		updateProfile({
			variables: {
				params: {
					profile: {
						id: memberToEdit.id,
						data: {},
					},
					profile_path: [],
					member: {
						id: memberToEdit.memberId,
						roles: [newRole],
					},
					member_path: ['roles'],
				},
			},
		});
	};

	return (
		<div>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t('members.page.title')}
			</Typography>

			<Grid container spacing={3}>
				<Grid item xs={12} md={8}>
					{profilesData ? (
						<Paper>
							<SortableTable<Member>
								cells={getHeadCells(t, handleMemberDelete, handleOpenWarningModal, handleEditRole, profileId)}
								data={formatMembers(profilesData, t)}
							/>
						</Paper>
					) : (
						<Loader />
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					<Paper sx={{ p: 4 }}>
						<Typography variant="h2" sx={{ mb: 2 }}>
							{t('members.page.subtitle')}
						</Typography>
						<TextField
							fullWidth
							sx={{ display: 'block', mb: 3 }}
							label={t('user-details.first-name')}
							value={form.values.firstName}
							name="firstName"
							onChange={form.handleChange}
							error={form.touched.firstName && !!form.errors.firstName}
							helperText={form.touched.firstName && form.errors.firstName}
						/>
						<TextField
							fullWidth
							sx={{ display: 'block', mb: 3 }}
							label={t('user-details.last-name')}
							value={form.values.lastName}
							name="lastName"
							onChange={form.handleChange}
							error={form.touched.lastName && !!form.errors.lastName}
							helperText={form.touched.lastName && form.errors.lastName}
						/>
						<TextField
							fullWidth
							sx={{ display: 'block', mb: 3 }}
							label={t('user-details.email')}
							value={form.values.email}
							name="email"
							type="email"
							onChange={form.handleChange}
							error={form.touched.email && !!form.errors.email}
							helperText={form.touched.email && form.errors.email}
						/>

						<RoleSelect
							touched={form.touched.role}
							value={form.values.role}
							onChange={form.handleChange}
							options={roleSelectOptions.current}
						/>

						<Box mt={2} display="flex" justifyContent="flex-end">
							<Button color="primary" variant="contained" onClick={() => form.handleSubmit()} disabled={loading}>
								{t('members.page.send-invitation')}
							</Button>
						</Box>
					</Paper>
				</Grid>
			</Grid>
			<DialogWithIcon
				icon={<WarningAmberOutlined fontSize="large" color="primary" />}
				title={t('members.page.modal-title-self-deletion-confirm')}
				text={t('members.page.modal-text-self-deletion-confirm')}
				open={warningModalIsOpen}
				onClose={handleCloseWarningModal}
			/>
			{memberToEdit && (
				<Dialog
					open={!!memberToEdit}
					content={
						<MemberEditRoleModalContent
							firstName={memberToEdit.firstName}
							lastName={memberToEdit.lastName}
							value={memberToEdit.roleCode === adminRole ? adminRoleId.current : employeeRoleId.current}
							onSubmit={handleUpdateMemberRole}
							options={roleSelectOptions.current}
						/>
					}
					title={t('members.page.modal-edit-role.title')}
					onClose={handleCloseEditRoleModal}
				/>
			)}
		</div>
	);
};
