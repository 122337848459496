// react
import React, { ChangeEvent, FC, useState } from 'react';
// Types
import { CustomerProductCardProps } from './customerProductCard.type';
// Styles
import { useTypographyStyle } from '@core/styles/typography';
// Components
import { Box, Button, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { AddShoppingCart, BrokenImage } from '@mui/icons-material';
import { NumberRangeInput } from '@tmds-io/kore';
import { useTranslation } from 'react-i18next';
import { getMediaUrl } from '@core/services/media';

export const CustomerProductCard: FC<CustomerProductCardProps> = ({
	id,
	title,
	type,
	volume,
	image,
	onClick,
	volumeUnit,
	currency,
	price,
	onSubmit,
	disabled,
}) => {
	// Styles
	const clsTypography = useTypographyStyle();
	const [quantity, setQuantity] = useState<number>(1);
	const { t } = useTranslation('acs', { useSuspense: false });
	const handleChange = (evtOrValue: ChangeEvent<HTMLInputElement> | string) => {
		if (typeof evtOrValue === 'string') {
			setQuantity(+evtOrValue);
		} else {
			setQuantity(+evtOrValue.target.value);
		}
	};
	const priceByUnit = `${price.toFixed(2)}${currency}/${volumeUnit}`;

	const handleSubmit = () => {
		onSubmit(id, quantity);
	};

	return (
		<Card>
			<CardContent>
				<Grid container>
					<Grid component={CardActionArea} item container onClick={onClick}>
						<Grid item container xs>
							<Grid item>
								<Box display="flex" width={64} height={64} justifyContent="center" alignItems="center" mr={2}>
									{image ? (
										<img src={getMediaUrl(image)} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
									) : (
										<BrokenImage fontSize="large" />
									)}
								</Box>
							</Grid>
							<Grid item>
								<Typography variant="caption">{type}</Typography>
								<Typography className={clsTypography.uppercase} variant="body1">
									{title}
								</Typography>
							</Grid>
						</Grid>
						<Grid item>
							<Typography>
								{volume.increment}x{volume.quantity}
								{volumeUnit}
							</Typography>
							<Typography>
								<em>{priceByUnit}</em>
							</Typography>
						</Grid>
					</Grid>
					<Grid item container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Box mt={-2}>
								<NumberRangeInput
									name="quantity"
									onChange={handleChange}
									value={quantity}
									min={0}
									disabled={disabled}
								/>
							</Box>
						</Grid>

						<Grid item>
							<Typography>
								<b>
									{(volume.increment * volume.quantity * quantity).toFixed(2)}
									{volumeUnit}: {(price * volume.increment * volume.quantity * quantity).toFixed(2)}
									{currency}
								</b>{' '}
								HT
							</Typography>
						</Grid>
						<Grid item>
							<Button startIcon={<AddShoppingCart />} color="primary" onClick={handleSubmit} disabled={disabled}>
								{t('shared.add')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};
