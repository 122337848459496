// react
import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDocumentUploadStyle } from '@components/seller/product-edition/partials/document-upload/styles/document-upload.style';
import { ReadRowProps } from '@components/seller/product-edition/partials/document-upload/types/document-upload-props.type';

export const ReadRow: FC<ReadRowProps> = ({ onClickDelete, fileName }) => {
	const classes = useDocumentUploadStyle();
	return (
		<Box display="flex" alignItems="center" className={classes.documentRow}>
			<Box display="flex" alignItems="center">
				<DescriptionIcon sx={{ mr: 2 }} fontSize="small" />
				<Box component="span" sx={{ mr: 2 }} className={classes.fileName}>
					{fileName}
				</Box>
				<IconButton sx={{ mr: 2 }} size="small" onClick={onClickDelete}>
					<DeleteOutlineIcon className="icon" fontSize="small" />
				</IconButton>
			</Box>
		</Box>
	);
};
