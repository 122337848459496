import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ArrowRight as ArrowRightIcon,
	Check,
	CloudDownload as DownloadIcon,
	Print as PrintIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { DialogWithIcon } from '@tmds-io/kore';
import { ModalPropsType } from '@components/shared/modal/types/modal-props.type';

const InvoiceModal: FunctionComponent<ModalPropsType> = ({ onClose, open }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	return (
		<DialogWithIcon
			title={t('payment.order-taken')}
			icon={<Check fontSize="large" />}
			open={open}
			onClose={() => onClose()}
			actionsContent={
				<Button
					color="primary"
					variant="contained"
					startIcon={<ArrowRightIcon fontSize="small" />}
					type="button"
					onClick={onClose}
				>
					{t('payment.return')}
				</Button>
			}
			content={
				<>
					<Box mb={2}>
						<Button
							fullWidth
							variant="outlined"
							color="primary"
							type="button"
							startIcon={<DownloadIcon fontSize="small" />}
							onClick={onClose}
						>
							{t('shared.download')}
						</Button>
					</Box>
					<Typography sx={{ display: 'flex', textAlign: 'center' }}>
						<Box mr={2}>
							<PrintIcon />
						</Box>{' '}
						{t('payment.order-invoice')}
					</Typography>
				</>
			}
		/>
	);
};

export default InvoiceModal;
