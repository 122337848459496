import {
	useGetGroupStoreLazyQuery,
	useGetStoreProductVariationsWithPricesLazyQuery,
} from '../../../../generated/graphql';
import { UseGroupDetailsProps } from '@core/hooks/group/useGroupDetails';
import { useCallback, useEffect, useState } from 'react';
import {
	formatProductForGroupDetails,
	getDefaultProductsForGroupDetails,
	getOtherProductsForGroupDetails,
} from '@core/hooks/group/useGroupDetails/useGroupDetails.core';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { DataHook } from '../../../../types/dataHook.type';
import { Product } from '@core/hooks/group/group.type';

interface GroupProducts {
	group: {
		id: string;
		title: string;
	};
	defaultProducts: Product[];
	otherProducts: Product[];
}

type UseGroupProductState = DataHook<GroupProducts, undefined>;

export const useGroupProducts = (): [(props: { id: string }) => void, UseGroupProductState] => {
	const [state, setState] = useState<UseGroupProductState>({ data: undefined, loading: false, error: false });
	const currentLanguage = useCurrentLanguage();
	const [fetchGroup, { data: groupData, error: groupError }] = useGetGroupStoreLazyQuery({ fetchPolicy: 'no-cache' });
	const [fetchAvailableProduct, { data: availableProductsData, error: availableProductsError }] =
		useGetStoreProductVariationsWithPricesLazyQuery({ fetchPolicy: 'no-cache' });

	const handleFetch = useCallback(
		async ({ id }: UseGroupDetailsProps) => {
			const result = await fetchGroup({
				variables: {
					id,
				},
			});
			const variables = result?.data?.store?.data?.referring_customer
				? { store_id: id, customer_id: result.data.store.data.referring_customer }
				: { store_id: id };
			void fetchAvailableProduct({
				variables,
			});
		},
		[fetchGroup, fetchAvailableProduct]
	);

	useEffect(() => {
		if (availableProductsError || groupError) {
			setState({ data: undefined, loading: false, error: true });
		} else if (availableProductsData && groupData) {
			const availableProducts = formatProductForGroupDetails(
				availableProductsData.storeProductVariationsWithPrices,
				currentLanguage
			);
			setState({
				data: {
					group: {
						id: groupData.store.id,
						title: groupData.store.title[currentLanguage],
					},
					defaultProducts: getDefaultProductsForGroupDetails(
						availableProducts,
						groupData?.store.inventories || [],
						groupData.store.data.default_products_sku
					),
					otherProducts: getOtherProductsForGroupDetails(
						availableProducts,
						groupData?.store.inventories || [],
						groupData.store.data.default_products_sku
					),
				},
				loading: false,
				error: false,
			});
		}
	}, [groupData, groupError, availableProductsData, availableProductsError]);

	return [handleFetch, state];
};
