// react
import React from 'react';
// Components
import { Layout } from '@components/customer/profile';
import { BusinessForm } from '@components/shared/BusinessForm';
import { useUserContext } from '@tmds-io/kore';
import { Alert, Box, Card, CardContent } from '@mui/material';
import { AsyncContent } from '@components/shared/AsyncContent';
// Hooks
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Gql
import {
	Account,
	AccountInput,
	useGetAccountOwnerQuery,
	useUpdateMyAccountMutation,
} from '../../../../generated/graphql';
// Routing
import { generatePath } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
import deepmerge from 'deepmerge';

/**
 * CompanyAndMainContactEdit components, provide interface for customer profile and account update
 */
export const CompanyAndMainContactEdit = (): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();
	const { id } = useUserContext();

	const {
		data: accountData,
		loading,
		error,
	} = useGetAccountOwnerQuery({
		variables: { id },
		fetchPolicy: 'no-cache',
	});
	const pendingValidation = accountData?.account?.pending_update?.account;

	const [updateUserAccount] = useUpdateMyAccountMutation({
		onCompleted: () =>
			enqueueSnackbar(t('user-details.success-update'), {
				variant: 'success',
			}),
		onError: () => {
			enqueueSnackbar(t('user-details.error-update'), {
				variant: 'error',
			});
		},
	});

	const handleSubmit = (values: AccountInput) => {
		const account = { ...values };
		delete account.business_unit;
		void updateUserAccount({
			variables: {
				id,
				params: {
					account,
				},
			},
		});
	};

	const account = pendingValidation
		? (deepmerge(accountData?.account, pendingValidation) as Account)
		: (accountData?.account as Account);

	return (
		<Layout
			title={t('customer.user-profile.edit-business-modal-title')}
			backLink={generatePath(ROUTES_URI.PROFILE_DETAILS)}
		>
			<Box>
				{pendingValidation ? (
					<Alert severity="warning">{t('customer.user-profile.pending-validation')}</Alert>
				) : null}
				<Card square sx={{ minHeight: 995, width: '100%' }}>
					<CardContent>
						<AsyncContent<Account> loading={loading} error={error} data={account}>
							{(data) => <BusinessForm onSubmit={handleSubmit} account={data} />}
						</AsyncContent>
					</CardContent>
				</Card>
			</Box>
		</Layout>
	);
};
