import React, { FunctionComponent, useRef } from 'react';
import { PrivateRouter, PublicRouter } from './router/router';
import { ROUTES_URI } from './router/routes';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { Box, GlobalStyles, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { UserProvider } from '@tmds-io/kore';
import AuthGuard from '@components/shared/common/guard/auth-guard';
import { BrowserRouter } from 'react-router-dom';
import { useDebugContext } from '@core/debug/debugContext';

/**
 * Main application component - Display current page content and check user role and redirect to login page
 */
export const App: FunctionComponent = () => {
	const alertRef = useRef<SnackbarProvider>();
	const { debug } = useDebugContext();
	const onClone = (key?: SnackbarKey) => () => {
		alertRef?.current?.closeSnackbar(key);
	};

	return (
		<SnackbarProvider
			ref={alertRef}
			action={(key) => (
				<IconButton aria-label="close" color="inherit" size="small" onClick={onClone(key)}>
					<CloseIcon fontSize="inherit" />
				</IconButton>
			)}
			maxSnack={4}
		>
			<GlobalStyles
				styles={{
					html: { height: '100%' },
					body: { height: '100%' },
					'#root': { height: '100%' },
				}}
			/>
			<UserProvider>
				{window.location.href.includes(ROUTES_URI.REGISTER) ? (
					<PublicRouter />
				) : (
					<AuthGuard>
						<BrowserRouter>
							<PrivateRouter />
						</BrowserRouter>
					</AuthGuard>
				)}
			</UserProvider>
			{debug ? (
				<Box
					position="fixed"
					top={0}
					width="100%"
					height={15}
					sx={{ bgcolor: 'primary.main', color: '#ffffff' }}
					zIndex={10000}
				>
					<Typography color="inherit" textAlign="center" sx={{ width: '100%', fontSize: 10 }}>
						Debug mode
					</Typography>
					<button
						onClick={() => {
							throw new Error('Test error');
						}}
					>
						Break the world
					</button>
				</Box>
			) : null}
		</SnackbarProvider>
	);
};
