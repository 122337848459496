import { PaginationFieldsFragment } from '../../generated/graphql';
import { ResponsePagination } from '@core/types/response-pagination';

export const formatResponsePagination = ({
	current_page,
	last_page,
	total_pages,
	total_result,
	result_count,
}: PaginationFieldsFragment): ResponsePagination => ({
	lastPage: last_page,
	currentPage: current_page,
	resultCount: result_count,
	totalPages: total_pages,
	totalResult: total_result,
});
