// react
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
// Types
import { RaceImagePropsType } from '../types/race-form-props.type';
// Styles
import { useEventHeaderDetailsStyle } from '@components/shared/event/styles/event-details.style';
// Components
import { Box, Button, FormControl, FormGroup, FormHelperText } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { FileInput } from '@tmds-io/kore';
import { Cancel, CloudUpload as UploadIcon } from '@mui/icons-material';
// Utils
import { useTranslation } from 'react-i18next';

export const RaceImageEdit: FC<RaceImagePropsType> = ({ onCancel, onChange, preview, formikState }) => {
	// Styles
	const classes = useEventHeaderDetailsStyle();
	//states
	const [editImage, setEditImage] = useState<boolean>(false);
	const [localPreview, setLocalPreview] = useState<string | undefined>();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const handleEditImage = (value: boolean) => (): void => {
		setEditImage(value);
	};

	const handleLocalPreview = (file: File): void => {
		setLocalPreview(URL.createObjectURL(file));
	};

	const cancelFileUpdate = () => {
		setLocalPreview(undefined);
		setEditImage(false);
		onCancel();
	};

	useEffect(() => {
		if (editImage || localPreview) {
			setEditImage(false);
			setLocalPreview(undefined);
		}
	}, [preview]);

	return (
		<>
			<Box className={classes.eventUpload} display="flex" alignItems="center" justifyContent="center">
				{!localPreview && preview && !editImage ? (
					<Box overflow="hidden" height="100%" width="100%" position="relative">
						<img className={classes.imgPreview} src={preview} alt="" />
						<Box position="absolute" bottom={24} right={24}>
							<Button
								startIcon={<EditIcon />}
								color="primary"
								variant="outlined"
								onClick={handleEditImage(true)}
							>
								{t('shared.edit')}
							</Button>
						</Box>
					</Box>
				) : !localPreview ? (
					<>
						<FormGroup>
							<FormControl error={formikState.touched.file && !!formikState.errors.file}>
								<FileInput
									name="file"
									color="primary"
									size="large"
									startIcon={<UploadIcon />}
									label={t('track-details.upload-file')}
									onChange={(event) => {
										handleLocalPreview((event.currentTarget as HTMLInputElement).files[0]);
										onChange(event as ChangeEvent<HTMLInputElement>);
									}}
								/>
								{formikState.touched.file && formikState.errors.file && (
									<FormHelperText sx={{ display: 'flex', justifyContent: 'center' }}>
										<b>{formikState.errors.file}</b>
									</FormHelperText>
								)}
							</FormControl>
						</FormGroup>
						{preview && editImage ? (
							<Box position="absolute" bottom={24} right={24}>
								<Button
									startIcon={<Cancel />}
									color="primary"
									variant="outlined"
									onClick={handleEditImage(false)}
								>
									{t('shared.cancel')}
								</Button>
							</Box>
						) : null}
					</>
				) : (
					<Box overflow="hidden" height="100%" width="100%" position="relative">
						<img className={classes.imgPreview} src={localPreview} alt="" />
						<Box position="absolute" bottom={24} right={24}>
							<Button startIcon={<EditIcon />} color="primary" variant="outlined" onClick={cancelFileUpdate}>
								{t('shared.cancel')}
							</Button>
						</Box>
					</Box>
				)}
			</Box>

			<Box overflow="hidden" height={300} width="100%" position="relative">
				<img className={classes.imgPreview} src="/static/img/event-placeholder.jpg" alt="" />
			</Box>
		</>
	);
};
