// react
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { ProductTypeTabFilter } from '@components/shared/product-type-tab-filter';
import { Box, IconButton, TextField } from '@mui/material';
import { ProductType } from '../../../types/product.types';
import { FilterAppBar } from '@components/ProductFilter/FilterAppBar';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useResellerCustomerProductList } from '@core/hooks/resellerCustomerProductList/useResellerCustomerProductList';
import { Loader } from '@components/shared/loader';
import { CustomerProductList } from '@components/customer/CustomerProductList';
import { OrderNotification } from '@components/OrderNotification';
import { Product } from '@core/hooks/resellerCustomerProductList/useResellerCustomerProductList.type';
import { useCurrentLanguage } from '@core/utils/language.utils';

/**
 * ProductList components -
 */
export const ProductListForResellerCustomer = (): JSX.Element => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const [filter, setFilter] = useState<string>(ProductType.ALL);
	const { data, loading } = useResellerCustomerProductList();
	const [products, setProducts] = useState<Product[]>([]);
	const search = useRef<string>('');
	const currentLanguage = useCurrentLanguage();

	const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
		search.current = evt.target.value;
		if (search.current === '') {
			filterData();
		}
	};

	const handleSearchSubmit = (
		evt: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (
			evt.type === 'click' ||
			(evt.type === 'keyup' && (evt as React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>).code === 'Enter')
		) {
			filterData();
		}
	};

	const filterData = useCallback(() => {
		if (filter === ProductType.ALL) {
			setProducts(
				data.products.filter(({ product }) =>
					search ? product.title[currentLanguage]?.toLowerCase().includes(search.current?.toLowerCase()) : true
				)
			);
		} else {
			setProducts(
				data.products.filter(({ product }) => {
					return (
						product.categories?.[0]?.id === (filter as string) &&
						(search
							? product.title[currentLanguage]?.toLowerCase().includes(search.current?.toLowerCase().trim())
							: true)
					);
				})
			);
		}
	}, [filter, data?.products]);

	useEffect(() => {
		if (filter && data?.products.length) {
			filterData();
		}
	}, [filter, data?.products]);

	return (
		<Box position="relative" pt={!loading && data?.lastOrder ? 14 : 8}>
			<FilterAppBar position="fixed" elevation={0}>
				<ProductTypeTabFilter onSelect={setFilter} />
			</FilterAppBar>
			{!loading && data?.lastOrder && (
				<Box position="absolute" top={40} width="100%">
					<OrderNotification
						date={data.lastOrder.date}
						productList={data.lastOrder.products || []}
						onAdd={data.addLastOrder}
					/>
				</Box>
			)}

			<TextField
				fullWidth
				color="secondary"
				placeholder={t('products.search-input.placeholder')}
				onChange={handleSearchChange}
				InputProps={{
					onKeyUp: handleSearchSubmit,
					endAdornment: (
						<IconButton size="small" onClick={handleSearchSubmit} disabled={loading}>
							<SearchIcon color="primary" />
						</IconButton>
					),
				}}
			/>
			<Box pt={2}>
				{loading ? (
					<Loader />
				) : (
					<CustomerProductList storeId={data?.storeId} raceName={{ fr: '', en: '' }} products={products} />
				)}
			</Box>
		</Box>
	);
};
