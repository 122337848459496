import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ProductEdition as ProductDetailComponent } from '@components/seller/product-edition/product-edition';
import { ConditioningRowType } from '@components/seller/product-edition/partials/conditioning-section/partials/conditioning-row/conditioning-props.type';
import { formatProductVariationsToConditioning } from '@components/seller/product-edition/partials/conditioning-section/conditioning-section.service';
import { ROUTES_URI } from '../../app/router/routes';
import { useTranslation } from 'react-i18next';
import {
	ProductFieldsForEditionFragment,
	useGetProductForEditionLazyQuery,
	useGetProductsVariationsWithPricesLazyQuery,
	useGetSalesAreaQuery,
} from '../../../generated/graphql';
import { Box, Typography } from '@mui/material';
import { Alert } from '@mui/lab';
import { Loader } from '@components/shared/loader';
import { useProductType } from '@core/hooks/useProductType';

export const ProductEdition: FC = () => {
	const { t } = useTranslation('acs', { useSuspense: true });

	const params = useParams();
	const isProductEdition = !!params?.id;
	const location = useLocation();

	const { data: productType } = useProductType();
	const [getProductForEdition, { data: productDetails, loading, error, called }] = useGetProductForEditionLazyQuery({
		fetchPolicy: 'no-cache',
	});
	const [getProductVariationsWithPrices, { data: productVariationsWithPricesData }] =
		useGetProductsVariationsWithPricesLazyQuery({ fetchPolicy: 'no-cache' });

	const [product, setProduct] = useState<ProductFieldsForEditionFragment>();
	const { data: salesAreaList } = useGetSalesAreaQuery({
		variables: {
			sort: { keys: ['code'], sort: 1 },
		},
	});

	const [conditioningList, setConditioningList] = useState<ConditioningRowType[] | undefined>();

	const getProductVariations = (product: ProductFieldsForEditionFragment) => {
		void getProductVariationsWithPrices({
			variables: {
				params: {
					product_sku: product.sku,
				},
			},
		});
	};

	useEffect(() => {
		if (productVariationsWithPricesData) {
			setProduct(productDetails.product);
			setConditioningList(
				formatProductVariationsToConditioning(
					salesAreaList.salesArea.sales_area,
					productVariationsWithPricesData.productsVariationsWithPrices
				) || []
			);
		}
	}, [productVariationsWithPricesData]);

	useEffect(() => {
		if (productDetails) {
			getProductVariations(productDetails.product);
		}
	}, [productDetails]);

	useEffect(() => {
		if (isProductEdition && salesAreaList?.salesArea.sales_area.length) {
			getProductForEdition({ variables: { id: params.id } });
		}
	}, [params, salesAreaList]);

	useEffect(() => {
		if (location.pathname === ROUTES_URI.PRODUCTS_CREATE) {
			setConditioningList([]);
		}
	}, [location]);

	if (error) {
		return (
			<Box p={5} textAlign="center" width="100%">
				<Alert severity="error">{t('product-edition.fetch-error')}</Alert>
			</Box>
		);
	}

	if (isProductEdition && called && !productDetails && !loading) {
		return (
			<Box p={5} textAlign="center" width="100%">
				<Typography variant="h3">{t('product-edition.product-not-found')}</Typography>
			</Box>
		);
	}

	return loading || !productType || (!product && isProductEdition) || !conditioningList ? (
		<Loader />
	) : (
		<ProductDetailComponent
			product={product}
			conditioningList={conditioningList}
			productTypeList={productType}
			saleAreaList={salesAreaList?.salesArea?.sales_area || []}
		/>
	);
};

export * from './product-edition';
