import { TableCell, TableRow } from '@mui/material';
import React, { FC } from 'react';
import { ProductRowProps } from '@components/customer/payment/types/product-row.type';

export const ProductRow: FC<ProductRowProps> = ({ quantity, volume, title, price, qtyIncrement }) => {
	const [increment, vol] = volume.split('_');

	return (
		<TableRow>
			<TableCell>
				{(quantity / qtyIncrement).toFixed()}/{increment}x{vol} L
			</TableCell>
			<TableCell>{title}</TableCell>
			<TableCell>{quantity} L</TableCell>
			<TableCell>{price} €</TableCell>
		</TableRow>
	);
};
