import { useCurrentLanguage } from '@core/utils/language.utils';
import { LanguageEnum } from '../../i18n';
import { isMobile, isSmartTV, isTablet } from 'react-device-detect';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTealium } from '@tmds-io/kore';

export const useTealiumPageView = (): null => {
	const location = useLocation();
	const { trackView } = useTealium();
	useEffect(() => {
		const currentPath = location.pathname + location.search;
		const urlSegmentList = location.pathname.split('/').filter((data) => data);
		trackView({
			page_name: urlSegmentList.join(' '),
			page_section: urlSegmentList[0],
			URL: currentPath,
			env_type: window._env_.ENV,
			language: useCurrentLanguage() === LanguageEnum.EN ? '2' : '1',
			device_type: isMobile ? 'mobile' : isTablet ? 'tablet' : isSmartTV ? 'smartTv' : 'desktop',
		});
	}, [location]);
	return null;
};
