import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventForm from '@components/seller/event/partials/event-form';
import { generatePath, Link, useParams } from 'react-router-dom';
import EventDeleteConfirmationModal from '@components/seller/event/modals/event-delete-confirmation-modal';
import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import { ChevronRight as ChevronRightIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import i18next from 'i18next';
import { useEventEdit } from '@components/seller/event-edit/index';
import { Loader } from '@components/shared/loader';
import { useSnackbar } from 'notistack';
import { ROUTES_URI } from '../../app/router/routes';
import { sortRaces } from '@core/utils/sortRaces';

const EventEdit: FC = () => {
	const currentLanguage = i18next.language || 'en';
	const { id } = useParams();
	const { data, loading, updateEvent, error, refetchRaces } = useEventEdit();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { enqueueSnackbar } = useSnackbar();
	const theme = useTheme();

	const [showModal, setShowModal] = useState(false);

	const updatedEvent = data?.updatedEvent;
	const event = updatedEvent || data?.event;
	const tracks = data?.races ? sortRaces([...data.races]) : [];

	const [currentDeleteTrackId, setCurrentDeleteTrackId] = useState('');

	const handleCloseDeleteRaceModal = (refetch: boolean): void => {
		if (refetch) {
			refetchRaces();
		}
		setShowModal(false);
	};

	useEffect(() => {
		if (updatedEvent) {
			enqueueSnackbar(t('event-edit.save-success'), { variant: 'success' });
		}
	}, [updatedEvent]);

	useEffect(() => {
		if (error) {
			enqueueSnackbar(t('event-edit.fetch-error'), { variant: 'error' });
		}
	}, [error]);

	return (
		<>
			<Box mb={4}>
				<Typography variant="h1" color="primary">
					{t('event.edit-title')}
				</Typography>
			</Box>
			{loading ? <Loader /> : null}

			{event ? (
				<EventForm
					values={{
						eventName: event?.title.en ?? '',
						eventNameFR: event?.title.fr ?? '',
						mandatoryRib: event?.data?.mandatory_rib ?? false,
						hidden: event?.hidden ?? false,
					}}
					type="edit"
					onSubmit={updateEvent}
				>
					<Box mb={4}>
						{tracks.map((track) => (
							<Card square key={track.id} sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, p: 1 }}>
								<Box display="flex" alignItems="center" justifyContent="space-between" alignContent="center">
									<Box display="flex" alignItems="center">
										<IconButton
											size="small"
											onClick={() => {
												setCurrentDeleteTrackId(track?.id);
												setShowModal(true);
											}}
										>
											<DeleteOutlineIcon color="primary" />
										</IconButton>
										<Box ml={3}>
											<Typography>
												<Box fontWeight="bold" component="span">
													{track?.title[currentLanguage]}
												</Box>
											</Typography>
										</Box>
									</Box>

									<IconButton
										component={Link}
										to={generatePath(ROUTES_URI.TRACK_EDIT, { id, trackId: track.id })}
										size="small"
									>
										<ChevronRightIcon color="secondary" fontSize="large" />
									</IconButton>
								</Box>
							</Card>
						))}
					</Box>
				</EventForm>
			) : null}

			<EventDeleteConfirmationModal
				onClose={handleCloseDeleteRaceModal}
				open={showModal}
				eventName={event?.title[currentLanguage]}
				trackId={currentDeleteTrackId}
			/>
		</>
	);
};

export default EventEdit;
