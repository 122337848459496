import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { Theme, StepConnector } from '@mui/material';

export const DotConnector = withStyles((theme: Theme) =>
	createStyles({
		alternativeLabel: {
			top: 10,
			left: 'calc(-50% + 16px)',
			right: 'calc(50% + 16px)',
		},
		active: {
			'& $line': {
				borderColor: theme.palette.primary.main,
			},
		},
		completed: {
			'& $line': {
				borderColor: theme.palette.primary.main,
			},
		},
		line: {
			borderColor: '#eaeaf0',
			borderTopWidth: 3,
			borderRadius: 1,
		},
	})
)(StepConnector);

export const useRegisterStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		registrationCard: {
			width: '500px',
			margin: 'auto',
			marginTop: '3rem',
		},
		trashIcon: {
			color: theme.palette.primary.main,
			cursor: 'pointer',
		},
	})
);
