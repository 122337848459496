// React
import { useCallback, useEffect, useState } from 'react';
import {
	UseGroupDetailsProps,
	UseGroupDetails,
	GroupDetails,
} from '@core/hooks/group/useGroupDetails/useGroupDetails.type';
import { useGetGroupUsersLazyQuery } from '../../../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { formatUser } from '@core/hooks/group/useGroupDetails/useGroupDetails.core';
import { useGroupProducts } from '@core/hooks/group/useGroupProducts';
import { DataHook } from '../../../../types/dataHook.type';

export const useGroupDetails = (): UseGroupDetails => {
	const [getGroupWithProducts, { data: groupWithProductsData, error: groupWithProductsError }] = useGroupProducts();
	const [fetchUsers, { data: usersData, error: usersError }] = useGetGroupUsersLazyQuery({ fetchPolicy: 'no-cache' });

	const handleFetch = useCallback(
		({ id }: UseGroupDetailsProps): void => {
			void getGroupWithProducts({ id });
			void fetchUsers({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'status', value: 'validated' }],
				},
			});
		},
		[getGroupWithProducts, fetchUsers]
	);
	const [state, setState] = useState<DataHook<GroupDetails, undefined>>({
		data: undefined,
		loading: true,
		error: false,
	});

	useEffect(() => {
		if (usersError || groupWithProductsError) {
			setState({ data: undefined, error: true, loading: false });
		} else if (usersData && groupWithProductsData) {
			setState({
				data: {
					group: groupWithProductsData.group,
					defaultProducts: groupWithProductsData.defaultProducts,
					otherProducts: groupWithProductsData.otherProducts,
					users: formatUser(usersData, groupWithProductsData.group.id),
				},
				loading: false,
				error: false,
			});
		}
	}, [usersData, usersError, groupWithProductsData]);

	return [handleFetch, state];
};
