// React
import React, { FC, useEffect, useMemo, useState } from 'react';
// Form
import { FormikProvider, useFormik } from 'formik';
import { businessFormSchema } from './businessForm.schema';
// Types
import { BusinessFormProps, BusinessFormValues } from '@components/shared/BusinessForm/businessForm.type';
// Utils
import { useTranslation } from 'react-i18next';
// Mui
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
	Box,
	Typography,
	Grid,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
// core
import { getCountryList } from '@components/seller/event/partials/country-list';
import { ContactDetailsFieldsTemplate } from '@components/shared/ContactDetailsFieldsTemplate';
import { IntraCommunityVatEditor } from '@tmds-io/kore';

export const BusinessForm: FC<BusinessFormProps> = ({ account, onSubmit }) => {
	const { t } = useTranslation('acs');
	const [showVAT, setShowVAT] = useState<boolean>(false);

	const countryList = useMemo(
		() => [
			<MenuItem key={0} value="">
				<em>{t('shared.none')}</em>
			</MenuItem>,
			...getCountryList(),
		],
		[]
	);

	const formik = useFormik<BusinessFormValues>({
		initialValues: {
			socialReason: account?.name || '',
			country: account?.address?.country || '',
			address: account?.address?.street || '',
			city: account?.address?.city || '',
			zipCode: account?.address?.zip || '',
			phone: account?.phone || '',
			vatNumber: account?.vat || '',
			companyNumber: account?.companyNumber || '',
			firstName: account?.billing_contact?.firstname || '',
			lastName: account?.billing_contact?.lastname || '',
			email: account?.billing_contact?.mail || '',
			additionalVat: account?.additional_vat || [''],
			haveAdditionalVat: !!account?.additional_vat?.length,
		},
		validationSchema: businessFormSchema,
		onSubmit: (values) => {
			onSubmit({
				name: values.socialReason,
				address: {
					street: values.address,
					zip: values.zipCode,
					city: values.city,
					country: values.country,
				},
				phone: values.phone,
				vat: values.vatNumber,
				business_unit: 'races',
				additional_vat: values.additionalVat.filter((vat) => vat),
				companyNumber: values.companyNumber,
				billing_contact: {
					firstname: values.firstName,
					lastname: values.lastName,
					mail: values.email,
				},
			});
		},
	});

	const handleChangeAdditionalVat = (data: { isValid: boolean; values: string[] }): void => {
		if (data.isValid) {
			formik.setFieldValue('additionalVat', data.values);
		} else {
			formik.setFieldValue('additionalVat', []);
			formik.setFieldError('additionalVat', 'Vat error');
		}
	};

	useEffect(() => {
		if (formik.values.country === 'fr' && !showVAT) {
			setShowVAT(true);
		} else if (showVAT) {
			setShowVAT(false);
		}
	}, [formik.values.country]);
	return (
		<FormikProvider value={formik}>
			<Box mb={3}>
				<Typography variant="h3" align={'center'} color={'primary'}>
					{t('register.business.form.title')}
				</Typography>
			</Box>

			<Typography sx={{ mb: 3 }} paragraph color={'primary'}>
				{t('register.business.form.details')}
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<TextField
					fullWidth
					sx={{ mb: 3 }}
					error={formik.touched.socialReason && !!formik.errors.socialReason}
					name="socialReason"
					label={t('register.business.form.social-reason')}
					value={formik.values.socialReason}
					helperText={formik.touched.socialReason && formik.errors.socialReason}
					onChange={formik.handleChange}
					required
				/>

				<FormControl error={formik.touched.country && !!formik.errors.country} fullWidth sx={{ mb: 3 }}>
					<InputLabel id="country" required>
						{t('user-details.country')}
					</InputLabel>
					<Select
						labelId="country"
						name="country"
						variant="standard"
						value={formik.values.country}
						onChange={formik.handleChange}
						error={formik.touched.country && !!formik.errors?.country}
						required
					>
						{countryList}
					</Select>
					{formik.touched.country && formik.errors.country ? (
						<FormHelperText>{formik.errors.country}</FormHelperText>
					) : null}
				</FormControl>

				<TextField
					fullWidth
					sx={{ mb: 3 }}
					error={formik.touched.address && !!formik.errors.address}
					name="address"
					label={t('user-details.address')}
					value={formik.values.address}
					helperText={formik.touched.address && formik.errors.address}
					onChange={formik.handleChange}
					required
				/>

				<Grid sx={{ mb: 3 }} container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							error={formik.touched.zipCode && !!formik.errors.zipCode}
							name="zipCode"
							label={t('user-details.zip-code')}
							value={formik.values.zipCode}
							helperText={formik.touched.zipCode && formik.errors.zipCode}
							onChange={formik.handleChange}
							required
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField
							fullWidth
							error={formik.touched.city && !!formik.errors.city}
							name="city"
							label={t('user-details.city')}
							value={formik.values.city}
							helperText={formik.touched.city && formik.errors.city}
							onChange={formik.handleChange}
							required
						/>
					</Grid>
				</Grid>

				<TextField
					sx={{ mb: 3 }}
					fullWidth
					error={formik.touched.phone && !!formik.errors.phone}
					name="phone"
					label={t('user-details.phone')}
					value={formik.values.phone}
					helperText={formik.touched.phone && formik.errors.phone}
					onChange={formik.handleChange}
					required
				/>

				{showVAT && (
					<TextField
						fullWidth
						sx={{ mb: 3 }}
						error={formik.touched.vatNumber && !!formik.errors.vatNumber}
						name="vatNumber"
						label={t('user-details.vat-number')}
						value={formik.values.vatNumber}
						required
						helperText={formik.touched.vatNumber && formik.errors.vatNumber}
						onChange={formik.handleChange}
					/>
				)}

				<TextField
					fullWidth
					sx={{ mb: 3 }}
					error={formik.touched.companyNumber && !!formik.errors.companyNumber}
					name="companyNumber"
					label={t('user-details.company-number')}
					value={formik.values.companyNumber}
					helperText={formik.touched.companyNumber && formik.errors.companyNumber}
					onChange={formik.handleChange}
					required
				/>

				<Box>
					<FormControlLabel
						name="haveAdditionalVat"
						onChange={formik.handleChange}
						value={formik.values.haveAdditionalVat}
						control={<Checkbox />}
						label={t<string>('register.business-form.i-have-intra-com-vat')}
					/>
					{formik.values.haveAdditionalVat && (
						<IntraCommunityVatEditor
							valueList={formik.initialValues.additionalVat}
							label={t('user-details.intra-community-vat')}
							onChange={handleChangeAdditionalVat}
							buttonText={t('register.business.form.add-one-more-vat')}
							invalidMessage={t('kore.intra-com-vat-invalid')}
						/>
					)}
				</Box>

				<Typography sx={{ my: 3 }} paragraph color={'primary'}>
					{t('register.business.form.contact-title')}
				</Typography>

				<ContactDetailsFieldsTemplate
					firstName="firstName"
					lastName="lastName"
					email="email"
					touched={formik.touched}
					valueFirstName={formik.values.firstName}
					valueLastName={formik.values.lastName}
					valueEmail={formik.values.email}
					errors={formik.errors}
					formik={formik}
				/>

				<Box textAlign="right">
					<Button
						color="primary"
						type="submit"
						variant="contained"
						startIcon={<ArrowRightIcon fontSize="small" />}
					>
						{t('shared.validate')}
					</Button>
				</Box>
			</form>
		</FormikProvider>
	);
};
