import * as Yup from 'yup';
import { countries } from '@components/seller/event/partials/constants/countries';
import { RaceFormValidationSchema } from '@components/seller/event/types/race-form-props.type';
import { ObjectSchema } from 'yup';
import { SellerTrackEditFieldsFragment } from '../../../../generated/graphql';

const FILE_SIZE = 2 * 1024 * 1024;

export const getValidationSchema = (
	saleAreaList: { id: string }[],
	track?: SellerTrackEditFieldsFragment
): ObjectSchema<RaceFormValidationSchema> => {
	const file = !track?.image
		? {
				file: Yup.mixed()
					.required('A file is required')
					.test('fileFormat', 'Picture only', (value) => {
						return (
							value &&
							['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'].includes(value.type.toLowerCase())
						);
					})
					.test('fileSize', 'File Size is too large', (value) => value?.size || 0 <= FILE_SIZE),
		  }
		: undefined;

	return Yup.object().shape({
		raceName: Yup.string().required(),
		shipTo: Yup.string().required(),
		storageLocation: Yup.string().required(),
		orderFrom: Yup.date().required().max(Yup.ref('orderTo')),
		orderTo: Yup.date().required().max(Yup.ref('visibleFrom')),
		visibleFrom: Yup.date().required().max(Yup.ref('visibleTo')),
		visibleTo: Yup.date().required(),
		city: Yup.string().required(),
		firstReminder: Yup.number().required().moreThan(0),
		secondReminder: Yup.number().required().moreThan(0),
		salesArea: Yup.string()
			.required()
			.oneOf(countries.map(({ code }) => code.toLowerCase())),
		salesAreaId: Yup.string()
			.required()
			.oneOf(saleAreaList.map(({ id }) => id)),
		...file,
	});
};
