// react
import React, { FC, useEffect, useState } from 'react';
// Components
import { Button, CircularProgress } from '@mui/material';
import { CreateOutlined as CreateIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import StoreCloneModal from '@components/StoreCloneModal/store-clone-modal';
import { GetSellerEventsQuery, useGetSellerEventsLazyQuery } from '../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore/build';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';

/**
 * AddRace components -
 */
export const AddRace: FC = () => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { id } = useParams();
	const navigate = useNavigate();
	const [{ open, loading, storeList }, setState] = useState<{
		loading: boolean;
		open: boolean;
		storeList: GetSellerEventsQuery['paginatedStores']['stores'];
	}>({ loading: false, open: false, storeList: [] });
	const [getStores, { data: storeListData, called: storeListCalled }] = useGetSellerEventsLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const handleCloseModal = (): void => {
		setState((s) => ({ ...s, open: false }));
	};

	const handleOpenModal = (): void => {
		if (storeList.length === 0 && !storeListCalled) {
			setState((s) => ({ ...s, loading: true, open: false }));
			handleFetchStores();
		} else {
			handleFetchStores();
			setState((s) => ({ ...s, open: true }));
		}
	};

	const handleFetchStores = (): void => {
		void getStores({
			variables: {
				filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'parent_id', value: id }],
			},
		});
	};

	useEffect(() => {
		if (storeListData?.paginatedStores) {
			if (storeListData.paginatedStores?.stores.length) {
				setState({ loading: false, storeList: storeListData.paginatedStores.stores, open: true });
			} else {
				navigate(generatePath(ROUTES_URI.TRACK_ADD, { id }));
			}
		}
	}, [storeListData]);

	return (
		<>
			<Button
				disabled={loading}
				color="primary"
				variant="outlined"
				type="submit"
				startIcon={loading ? <CircularProgress size={16} /> : <CreateIcon fontSize="medium" />}
				onClick={handleOpenModal}
			>
				{t('event.add-blanc')}
			</Button>
			<StoreCloneModal
				onClose={handleCloseModal}
				open={open}
				storeList={storeList.map(({ id, status, title, children }) => ({ id, status, title, children }))}
			/>
		</>
	);
};
