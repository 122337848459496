// react
import React, { FC, useState } from 'react';
// Types
import { TransferListPropsType } from '../../types/transfer-list-props.type';
import { ProductType } from '../../../../../types/product.types';
import { Box, Grid } from '@mui/material';
import RaceProductsPage from '@components/seller/event/partials/race-products-page';
// Core
import { filterProducts } from './transfer-list.core';

export const TransferList: FC<TransferListPropsType> = ({
	sx,
	products,
	onAddProduct,
	onRemoveProduct,
	onPriceChange,
	rightColumnTitle,
}) => {
	// States
	const [availableProductTypeFilter, setAvailableProductTypeFilter] = useState<string>(ProductType.ALL);
	const [selectedProductTypeFilter, setSelectedProductTypeFilter] = useState<string>(ProductType.ALL);
	const [availableProductNameFilter, setAvailableProductNameFilter] = useState<string>('');
	const [selectedProductNameFilter, setSelectedProductNameFilter] = useState<string>('');

	/**
	 * Filter available product list by name
	 * @param query
	 */
	const handleAvailableProductSearch = (query: string): void => {
		setAvailableProductNameFilter(query);
	};

	/**
	 * Filter available product list by name
	 * @param query
	 */
	const handleSelectedProductSearch = (query: string): void => {
		setSelectedProductNameFilter(query);
	};

	/**
	 * Update list filter value
	 * @param type
	 */
	const handleFilter = (type: string) => (value: string) => {
		setAvailableProductNameFilter('');
		setSelectedProductNameFilter('');
		if (type === 'default') {
			setAvailableProductTypeFilter(value);
		} else {
			setSelectedProductTypeFilter(value);
		}
	};

	return (
		<Box sx={sx}>
			<Grid container spacing={3}>
				<Grid item lg={6}>
					<RaceProductsPage
						products={filterProducts(
							products.availableProducts,
							availableProductTypeFilter,
							availableProductNameFilter
						)}
						isCatalog
						onFilterChange={handleFilter('default')}
						title="track-details.catalog-products"
						onProductAdd={onAddProduct}
						onSearchSubmit={handleAvailableProductSearch}
					/>
				</Grid>

				<Grid item lg={6}>
					<RaceProductsPage
						products={filterProducts(
							products.selectedProducts,
							selectedProductTypeFilter,
							selectedProductNameFilter
						)}
						onFilterChange={handleFilter('optional')}
						title={rightColumnTitle || 'track-details.mandatory-products'}
						onProductRemove={onRemoveProduct}
						onSearchSubmit={handleSelectedProductSearch}
						onPriceChange={onPriceChange}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
