import { SaleArea } from '../../generated/graphql';

export const sortSaleArea = (a: Partial<SaleArea>, b: Partial<SaleArea>): number => {
	if (a.code === 'france' || b.code === 'france') {
		return 1;
	}
	if (a.code === 'overseas' || b.code === 'overseas') {
		return -1;
	}

	return 0;
};
