// react
import React, { FC, useState } from 'react';
import { Grid, Paper, TablePagination } from '@mui/material';
import { CustomerDeliveryPoint } from '@core/hooks/resellerCustomer/useResellerCustomerSapData';
import { SortableTable, Cell } from '@tmds-io/kore';
import { useTranslation } from 'react-i18next';
import { ResellerDeliveryCard } from '@components/ResellerDeliveryCard';

// Components properties
interface ResellerDeliveryListProps {
	deliveryPointList: CustomerDeliveryPoint[];
	pageCount?: number;
}

const getHeadCells = (t: (val: string) => string): Cell<CustomerDeliveryPoint>[] => [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: t('shared.name'),
	},
	{
		id: 'address',
		numeric: false,
		disablePadding: false,
		label: t('user-details.address'),
	},
	{
		id: 'city',
		numeric: false,
		disablePadding: false,
		label: t('shared.city'),
	},
	{
		id: 'country',
		numeric: false,
		disablePadding: false,
		label: t('shared.country'),
	},
	{
		id: 'shipTo',
		numeric: true,
		disablePadding: false,
		label: t('shared.ship-to'),
	},
];

const PAGE_COUNT = 4;

/**
 * ResellerDeliveryList components -
 */
export const ResellerDeliveryList: FC<ResellerDeliveryListProps> = ({ deliveryPointList, pageCount = PAGE_COUNT }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const [deliveryPointPage, setDeliveryPointPage] = useState<number>(0);

	const handleDeliveryPointPageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
		setDeliveryPointPage(page);
	};

	if (deliveryPointList.length <= pageCount) {
		return (
			<>
				{deliveryPointList.map((props, key) => (
					<Grid key={key} item xs={12} sm={6}>
						<ResellerDeliveryCard {...props} />
					</Grid>
				))}
			</>
		);
	}

	return (
		<Paper sx={{ width: '100%' }}>
			<SortableTable<CustomerDeliveryPoint>
				cells={getHeadCells(t)}
				data={[...deliveryPointList].splice(Math.floor(deliveryPointPage * pageCount), pageCount)}
			/>
			<TablePagination
				component="div"
				count={deliveryPointList.length}
				page={deliveryPointPage}
				onPageChange={handleDeliveryPointPageChange}
				rowsPerPage={pageCount}
				rowsPerPageOptions={[]}
			/>
		</Paper>
	);
};
