// react
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
import { useSnackbar } from 'notistack';

export const CallbackPayment = (): JSX.Element => {
	const { pathname } = useLocation();
	const { t } = useTranslation('acs', { useSuspense: true });
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const success = pathname === ROUTES_URI.CALLBACK_PAYMENT_SUCCESS;
	const message = success ? 'payment.callback.success' : 'payment.callback.failure';
	useEffect(() => {
		enqueueSnackbar(t(message), {
			variant: success ? 'success' : 'error',
			anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
		});
		navigate(ROUTES_URI.ORDERS);
	}, []);

	return null;
};
