import React, { FC, useEffect, useRef } from 'react';
import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
import OrdersFilter from '@components/shared/order/partials/orders-filter';
import { OrdersProps } from '@components/shared/order/types/orders.type';
import { format } from 'date-fns';
import { useDateUtils } from '@core/utils/date.util';
import { Filter, useSendThirdPartMessageMutation } from '../../../generated/graphql';
import { useOrdersContext } from '@components/shared/order/context/orders.context';
import { Loader } from '@components/shared/loader';
import { useUserHelper } from '@core/hooks/useUserHelper';
import { FILTER_COMPARISON } from '@tmds-io/kore/build';
import { downloadFile } from '@core/utils/file';
import { useSnackbar } from 'notistack';
import { useErrorNotification } from '@core/hooks/useErrorNotification';

const Orders: FC<OrdersProps> = ({ isSeller }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { dateFormat } = useDateUtils();
	const { orderList, pagination, get, rowPerPage } = useOrdersContext();
	const { enqueueSnackbar } = useSnackbar();
	const filters = useRef<Filter[]>([]);
	const count = useRef<number>(rowPerPage || 10);
	const { isReseller } = useUserHelper();
	const sendNotificationError = useErrorNotification();
	const [exportData, { data: exportedData, error: exportError }] = useSendThirdPartMessageMutation();

	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, pageNumber: number): void => {
		get({ limit: { page_number: pageNumber + 1, count: rowPerPage }, filters: filters.current });
	};

	const handleRowsPerPageChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		count.current = +evt.target.value;
		get({ limit: { page_number: 1, count: +evt.target.value }, filters: filters.current });
	};

	const handleFilter = (newFilters: Filter[]) => {
		filters.current = newFilters;
		get({ limit: { page_number: 1, count: count.current }, filters: newFilters });
	};

	const handleExportOrder = () => {
		const from = filters.current.find(
			({ key, comparison }) => key === 'created_at.timestamp' && FILTER_COMPARISON.GREATER_EQUAL === comparison
		);
		const to = filters.current.find(
			({ key, comparison }) => key === 'created_at.timestamp' && FILTER_COMPARISON.LESS_EQUAL === comparison
		);
		const bu = filters.current.find(({ key }) => key === 'business_unit')?.value;
		exportData({
			variables: {
				code: 'order_extract',
				data: {
					from: from ? new Date(from.value).toISOString() : undefined,
					to: to ? new Date(to.value).toISOString() : undefined,
					business_unit: bu,
				},
				need_response: true,
				timeout: 30,
			},
		});
	};

	useEffect(() => {
		if (exportError || exportedData?.sendThirdPartMessage.failed) {
			console.error(exportError);
			sendNotificationError('orders.export-fail', 'Cannot export orders');
		}
		if (exportedData?.sendThirdPartMessage && !exportedData.sendThirdPartMessage.failed) {
			enqueueSnackbar(t('order-products.dl-success'), { variant: 'info' });
		}
	}, [exportError, exportedData?.sendThirdPartMessage]);

	useEffect(() => {
		if (exportedData?.sendThirdPartMessage) {
			downloadFile(
				exportedData.sendThirdPartMessage.data.file,
				exportedData.sendThirdPartMessage.data.filename,
				'xlsx'
			);
		}
	}, [exportedData]);

	return (
		<>
			<Typography variant="h1" sx={{ mb: 4 }} color="primary">
				{t('orders.title')}
			</Typography>

			{isSeller ? (
				<Box p={2} display="flex" justifyContent="flex-end">
					<Button variant="contained" onClick={handleExportOrder}>
						{t('orders.export')}
					</Button>
				</Box>
			) : null}

			<OrdersFilter onSubmit={handleFilter} />
			<Paper>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow sx={{ backgroundColor: 'lightgray' }}>
								<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.reference')}</TableCell>
								{isSeller ? (
									<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.customer')}</TableCell>
								) : null}
								<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.date')}</TableCell>
								{!isReseller ? (
									<>
										<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.event')}</TableCell>
										<TableCell sx={{ textTransform: 'uppercase' }}>
											{t('order.race')}
											{isSeller ? ' / ' + t('shared.group') : null}
										</TableCell>
									</>
								) : null}

								<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.volume')}</TableCell>
								<TableCell sx={{ textTransform: 'uppercase' }}>{t('orders.amount')}</TableCell>
								<TableCell sx={{ textTransform: 'uppercase' }}>{t('order.status')}</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{orderList ? (
								orderList?.map((order, index) => (
									<TableRow key={'order-' + index}>
										<TableCell>{order.reference}</TableCell>
										{isSeller ? (
											<TableCell>
												{order.companyName} - {order.companyRef}
											</TableCell>
										) : null}

										<TableCell>{format(new Date(order.creationDate), dateFormat)}</TableCell>
										{!isReseller ? (
											<>
												<TableCell>{order.event}</TableCell>
												<TableCell>{order.race}</TableCell>
											</>
										) : null}
										<TableCell>{order.volume.toFixed(2)}</TableCell>
										<TableCell>{order.total.toFixed(2)}€</TableCell>
										<TableCell>{t('order.' + order.status)}</TableCell>
										<TableCell>
											<Button
												component={Link}
												to={generatePath(ROUTES_URI.ORDERS_DETAILS, { id: order.id })}
												color="primary"
												variant="outlined"
												startIcon={<ArrowRightIcon fontSize="small" />}
											>
												{t('shared.see-details')}
											</Button>
										</TableCell>
									</TableRow>
								))
							) : (
								<Loader />
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					rowsPerPage={rowPerPage}
					count={pagination.total_result}
					page={pagination.current_page ? pagination.current_page - 1 : 0}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleRowsPerPageChange}
				/>
			</Paper>
		</>
	);
};

export default Orders;
