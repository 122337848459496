// react
import React, { ChangeEvent, FC, useState } from 'react';
// types
import { PictureProductUploadProps } from './types/picture-product-upload-props.type';
// Components
import { CloudUpload as CloudUploadIcon, PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
// styles
import { usePictureProductUploadStyle } from './styles/picture-product-upload.style';
import { FileInput } from '@tmds-io/kore';
import { uploadMedia } from '@core/services/media';

export const PictureProductUpload: FC<PictureProductUploadProps> = ({
	img,
	inputLabel,
	className,
	error,
	helperText,
	onChange,
}) => {
	const classes = usePictureProductUploadStyle();
	const [preview, setPreview] = useState<string | undefined>();

	const handleInputChange = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (evt.target.files.length) {
			setPreview(URL.createObjectURL(evt.target.files[0]));
			const result = await uploadMedia(evt.target.files[0]);
			if (result) {
				onChange({ success: true, id: result.key });
			} else {
				onChange({ success: false });
			}
		}
	};

	return (
		<Grid container justifyContent="center" alignItems="center" direction="column" className={className}>
			<Grid item container className={classes.pictureWrapper} alignItems="center">
				{img && !preview && <img src={img} alt="" />}
				{(preview && (
					<>
						<img src={preview} alt="" />
						<CloudUploadIcon className={classes.uploadIcon} fontSize="large" />
					</>
				)) || <PhotoCameraIcon className={classes.picture} />}
			</Grid>
			<Grid container item justifyContent="center">
				<Box pt={2}>
					<FileInput
						startIcon={<CloudUploadIcon />}
						variant="outlined"
						color="primary"
						name="image"
						label={inputLabel}
						onChange={handleInputChange}
						error={error}
						helperText={helperText}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};
