import * as Yup from 'yup';

export const formAddProductSchema = Yup.object().shape({
	image: Yup.string(),
	productName: Yup.string().required('Required'),
	productType: Yup.string().required('Required'),
	sapProductType: Yup.string().required('Required'),
	productDescription: Yup.string(),
	productSpecifications: Yup.string(),
	productRestricted: Yup.boolean(),
});
