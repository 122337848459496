import * as Yup from 'yup';
import { TFunction } from 'i18next';

// eslint-disable-next-line @typescript-eslint/ban-types
export const getConditioningFormSchema = (priceSchema: Record<string, {}>, t: TFunction): Yup.ObjectSchema<{}> => {
	return Yup.object().shape({
		sap: Yup.string().required('Required'),
		multiplier: Yup.number().min(1, t('form.field-too-short')).required(t('form.required')),
		quantity: Yup.number().moreThan(0, t('form.field-too-short')).required(t('form.required')),
		...priceSchema,
	});
};
