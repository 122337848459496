import {
	GetGroupUsersQuery,
	GetStoreProductVariationsWithPricesQuery,
	GroupInventoryFieldsFragment,
} from '../../../../generated/graphql';
import { Product } from '../group.type';
import { useCurrency } from '@core/utils/currency';
import { User } from '@core/hooks/group/useGroupDetails/useGroupDetails.type';

export const formatProductForGroupDetails = (
	data: GetStoreProductVariationsWithPricesQuery['storeProductVariationsWithPrices'],
	currentLanguage: string
): Product[] => {
	const { letterToSign } = useCurrency();
	return data.map(({ product, price, variation }) => {
		const [increment, volume] = variation.options[0].value.split('_');
		const finalPrice = price?.price || 0;
		return {
			id: variation.id,
			sku: variation.sku,
			parentSku: product.sku,
			name: product.title[currentLanguage],
			conditioning: `${increment}x${volume}`,
			type: product.categories[0].title[currentLanguage],
			typeId: product.categories[0].id,
			pricePerLiter: `${finalPrice} ${letterToSign(product.currency)}/L`,
		};
	});
};

export const getDefaultProductsForGroupDetails = (
	productList: Product[],
	inventoriesData: GroupInventoryFieldsFragment[],
	defaultProductsSku: string[] = []
): Product[] =>
	productList.filter(
		({ sku }) => defaultProductsSku.includes(sku) && inventoriesData.find(({ product_sku }) => sku === product_sku)
	);

export const getOtherProductsForGroupDetails = (
	productList: Product[],
	inventoriesData: GroupInventoryFieldsFragment[],
	defaultProductsSku: string[] = []
): Product[] =>
	productList.filter(
		({ sku }) => !defaultProductsSku.includes(sku) || !inventoriesData.find(({ product_sku }) => sku === product_sku)
	);

export const formatUser = (users: GetGroupUsersQuery, id: string): User[] =>
	users.customerAccounts.accounts
		.filter(({ contracts }) => contracts?.[0].stores?.includes(id))
		.map(({ id, name, owner, data }) => ({
			id,
			email: owner?.profile_data?.mail || 'N/A',
			name: name,
			score: typeof data?.fidelity_points !== 'undefined' ? data.fidelity_points : 'N/A',
			lastName: owner?.profile_data?.last_name || 'N/A',
			firstName: owner?.profile_data?.first_name || 'N/A',
		}));
