import React, { useState } from 'react';
import { TransferList, TransferListProps } from '@tmds-io/kore';
import { ProductTabs } from './partials';
import { GroupProduct } from '@core/hooks/group/group.type';
import { ProductType } from '../../types/product.types';

export interface TransferProductColumns<T> {
	left: T[];
	right: T[];
}

interface TransferProductProps<T, K> {
	itemList: T[];
	selectedItem: string[];
	itemTypeList: K[];
	onChange: (columns: TransferProductColumns<T>) => void;
	customList: TransferListProps<T>['customList'];
}

interface FilterState {
	left: string | undefined;
	right: string | undefined;
}

interface ProductDefaultProps {
	id: string;
	typeId: string;
}

interface ProductTypeDefaultProps {
	id?: string;
	name: ProductType;
}

const filterProductType = <T extends ProductDefaultProps>(
	filter: FilterState,
	products: T[],
	column: 'left' | 'right'
) =>
	products.map((product) => ({
		...product,
		isHide: filter?.[column] && product.typeId !== filter?.[column],
	}));

export const TransferProduct = <T extends ProductDefaultProps, K extends ProductTypeDefaultProps>({
	itemList,
	selectedItem,
	itemTypeList,
	onChange,
	customList,
}: TransferProductProps<T, K>): React.ReactElement => {
	const [filter, setFilter] = useState<FilterState>({
		left: undefined,
		right: undefined,
	});
	const [{ left: leftProducts, right: rightProducts }, setProducts] = useState<TransferProductColumns<T>>({
		left: itemList.filter(({ id }) => !selectedItem.includes(id)),
		right: itemList.filter(({ id }) => selectedItem.includes(id)),
	});

	const handleUpdateFilter = (column: 'left' | 'right', productTypeId: string) => {
		setFilter((f) => ({ ...f, [column]: productTypeId }));
	};

	const listHeader: TransferListProps<GroupProduct>['listHeader'] = ({ column }) => {
		const [value, setValue] = useState<number>(0);
		const handleChange = (val: string) => {
			setValue(itemTypeList.findIndex(({ id }) => id === val));
			handleUpdateFilter(column, val);
		};

		return <ProductTabs value={value} onChange={handleChange} productTypeList={itemTypeList} />;
	};

	const handleChange = (data: TransferProductColumns<T>): void => {
		setProducts(data);
		onChange(data);
	};

	return (
		<TransferList
			customList={customList}
			leftData={filterProductType<T>(filter, leftProducts, 'left')}
			rightData={filterProductType<T>(filter, rightProducts, 'right')}
			onUpdate={handleChange}
			listHeader={listHeader}
		/>
	);
};
