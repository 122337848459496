import { gql } from '@apollo/client';

export const ADD_PRODUCT_VARIATION = gql`
	mutation AddProductVariation($product_id: ID!, $data: ProductVariationInput!) {
		addProductVariation(product_id: $product_id, data: $data) {
			id
			sku
			data
			variations {
				id
			}
		}
	}
`;

export const UPDATE_PRODUCT_VARIATION = gql`
	mutation UpdateProductVariation($product_id: ID!, $variation_id: ID!, $data: ProductVariationInput!) {
		updateProductVariation(product_id: $product_id, variation_id: $variation_id, data: $data) {
			id
			sku
			data
			variations {
				id
			}
		}
	}
`;
