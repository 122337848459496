import { fr } from 'date-fns/locale';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { differenceInCalendarDays } from 'date-fns';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export const getDiffBetweenDates = (startDate: Date, endDate: Date): number => {
	const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
	const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

	return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const useDateUtils = (): { dateOptions: { locale: Locale }; dateFormat: string } => {
	const currentLanguage = useCurrentLanguage();
	const dateOptions = {
		locale: currentLanguage === 'fr' ? fr : undefined,
	};
	const dateFormat = currentLanguage === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
	return { dateOptions, dateFormat };
};

export const checkDeliveryDay = (
	currentDate: Date,
	selectedDate: Date
): { isValid: boolean; diff: number; message?: string } => {
	const currentDay = currentDate.getDay();
	const selectedDateDay = selectedDate.getDay();
	const selectedDateDiff = differenceInCalendarDays(selectedDate, currentDate);

	if ([6, 0].includes(selectedDateDay)) {
		return { isValid: false, message: 'not-week-end', diff: selectedDateDiff };
	}
	if (selectedDateDiff === 3 && currentDay === 5) {
		return { isValid: false, message: 'not-monday-for-friday', diff: selectedDateDiff };
	}

	if (selectedDateDiff < 2) {
		return { isValid: false, message: 'not-less-two-days', diff: selectedDateDiff };
	}

	if (selectedDateDiff > 365) {
		return { isValid: false, message: 'more-than-one-year', diff: selectedDateDiff };
	}

	return { isValid: true, diff: selectedDateDiff };
};

let noWorkingDays: { [p: number]: Record<string, string> } = {};

const getDefaultHolidays = (year: number) => {
	return {
		[year + '-01-01']: '1er janvier',
		[year + '-05-01']: '1er mai',
		[year + '-05-08']: '8 mai',
		[year + '-07-14']: '14 juillet',
		[year + '-08-15']: 'Assomption',
		[year + '-11-01']: 'Toussaint',
		[year + '-11-11']: '11 novembre',
		[year + '-12-25']: 'Jour de Noël',
	};
};

export const getNoWorkingDay = async (year: number): Promise<Record<string, string>> => {
	try {
		if (noWorkingDays[year]) {
			return noWorkingDays[year];
		}
		if (year > new Date().getFullYear() + 3) {
			return;
		}
		const result = await fetch('https://calendrier.api.gouv.fr/jours-feries/metropole/' + year + '.json');
		if (result.ok) {
			const data = await result.json();
			noWorkingDays = { ...noWorkingDays, [year]: data };
			return data;
		}
		console.error('Cannot get no working days');
		return getDefaultHolidays(year);
	} catch (error) {
		console.error(error);
		return getDefaultHolidays(year);
	}
};
