import React, { FunctionComponent, useMemo, useRef } from 'react';
import { Box, Button, Card, Container, Grid, IconButton, Menu, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
	Check,
	ChevronLeft as LeftIcon,
	ChevronLeft as ChevronLeftIcon,
	ChevronRight as RightIcon,
	Close,
	DateRange as DateRangeIcon,
	Event as DateIcon,
	FlagOutlined as FlagIcon,
	Timelapse as TimeIcon,
	Timer as TimerIcon,
} from '@mui/icons-material';
import { format, formatDistanceToNowStrict, differenceInCalendarDays } from 'date-fns';

import { RaceStatus } from '@components/seller/event/enums/race-status.enum';
import { RaceDetails } from '@components/seller/event/types/race-details.type';
import { useEventHeaderDetailsStyle } from '@components/shared/event/styles/event-details.style';
import * as Flags from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { EventDetailsHeaderPropsType } from './types/event-details-header-props.type';
import { getMediaUrl } from '@core/services/media';
import { useDateUtils } from '@core/utils/date.util';

const EventDetailsHeader: FunctionComponent<EventDetailsHeaderPropsType> = ({
	tracks,
	setTrackIndex,
	trackIndex,
	prev,
	next,
	customerData,
	eventName,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const menuRef = useRef<HTMLDivElement>(null);
	const { dateOptions, dateFormat } = useDateUtils();
	const classes = useEventHeaderDetailsStyle();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const navigate = useNavigate();

	const code =
		tracks.length !== 0 && tracks[trackIndex]?.sale_area_info
			? tracks[trackIndex]?.sale_area_info.toUpperCase()
			: 'FR';
	const Flag = Flags[code as keyof typeof Flags];

	const getRaceDateParagraph = (track: RaceDetails): string => {
		if (track.status === RaceStatus.COMING) {
			return `${t('shared.from')} ${format(new Date(track?.visible_from), dateFormat)} ${t('shared.to')} ${format(
				new Date(track?.visible_to),
				dateFormat
			)}`;
		}

		return `${t('shared.until')} ${format(new Date(track?.visible_to), dateFormat)}`;
	};

	const lastOrderInDays = useMemo(
		() => differenceInCalendarDays(new Date(tracks[trackIndex].order_to), new Date()),
		[tracks[trackIndex].order_to]
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (): void => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box className={classes.eventNameLabel}>
				<Container>
					<Box display="flex" alignItems="center">
						<IconButton onClick={() => navigate(-1)} size="small">
							<ChevronLeftIcon color="secondary" fontSize="large" />
						</IconButton>
						<Typography>
							<Box fontWeight="bold" component="span" ml={1} fontSize="18px">
								{eventName}
							</Box>
						</Typography>
					</Box>
				</Container>
			</Box>
			<Box overflow="hidden" height="300px" width="100%" position="relative">
				<img
					style={{ opacity: 0.33 }}
					className={classes.imgPreview}
					src="/static/img/event-placeholder.jpg"
					alt=""
				/>

				<img
					className={classes.imgPreview}
					src={tracks[trackIndex]?.image && getMediaUrl(tracks[trackIndex].image)}
					alt=""
				/>
			</Box>

			<Card square>
				<Grid container sx={{ pt: 2, pb: 2 }}>
					{prev ? (
						<Grid item>
							<Box ml={2} height="100%" display="flex" alignItems="center">
								<Button
									color="primary"
									startIcon={<LeftIcon fontSize="large" />}
									onClick={() => setTrackIndex((index: number) => index - 1)}
									size="large"
								>
									<Typography color="primary" variant="h6">
										<Box fontWeight="bold" component="span">
											{t('shared.previous')}
										</Box>
									</Typography>
								</Button>
							</Box>
						</Grid>
					) : null}
					<Grid item xs>
						<Container>
							<Box mb={3} display="flex">
								{Flag && (
									<Box className={classes.flag}>
										<Flag />
									</Box>
								)}
								<Typography>
									<b>{tracks[trackIndex]?.name} </b> - {getRaceDateParagraph(tracks[trackIndex])}
								</Typography>
							</Box>

							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Box display="flex" alignItems="center" mb={2}>
									{tracks[trackIndex].status === RaceStatus.COMING ? (
										<>
											<Box mr={3}>
												<Box display="flex" alignItems="center">
													<TimerIcon sx={{ mr: 1 }} />
													<Typography>
														{lastOrderInDays
															? lastOrderInDays > 0
																? `${t('event-details.last-order-in')}: ${lastOrderInDays} ${t(
																		'shared.days'
																  )}`
																: t('event-details.order-closed')
															: t('event-details.order-today')}
													</Typography>
												</Box>
											</Box>

											<Box mr={3}>
												<Box display="flex" alignItems="center">
													<DateIcon sx={{ mr: 1 }} />

													<Typography>
														{t('event-details.date-limit')}:{' '}
														{format(new Date(tracks[trackIndex]?.order_to), dateFormat)}
													</Typography>
												</Box>
											</Box>

											<Box display="flex" alignItems="center">
												<FlagIcon sx={{ mr: 1 }} />

												<Typography>
													{t('event-details.start-in')}:{' '}
													{formatDistanceToNowStrict(
														new Date(tracks[trackIndex]?.visible_from),
														dateOptions
													)}
												</Typography>
											</Box>
										</>
									) : (
										<>
											{/*<Box mr={3}>*/}
											{/*	<Box display="flex" alignItems="center">*/}
											{/*		<CartIcon sx={{ mr: 1 }} />*/}

											{/*		<Typography>{t('track-details.fuel-ordered')}: 2000L</Typography>*/}
											{/*	</Box>*/}
											{/*</Box>*/}

											<Box display="flex" alignItems="center">
												<FlagIcon sx={{ mr: 1 }} />
												{tracks[trackIndex].status === RaceStatus.ENDED ? (
													<Typography>{t('track-details.race-ended')}</Typography>
												) : (
													<Typography color="primary">{t('track-details.race-in-progress')}</Typography>
												)}
											</Box>
										</>
									)}
								</Box>
							</Box>

							<Box
								display="flex"
								justifyContent={customerData ? 'space-between' : 'flex-end'}
								alignItems="center"
							>
								{/*{sellerData && (*/}
								{/*	<Box display="flex" alignItems="center">*/}
								{/*		<CartIcon sx={{ mr: 1 }} />*/}

								{/*		<Typography>*/}
								{/*			{t('event-details.order-percentage')}: {sellerData.percentageOrder}*/}
								{/*		</Typography>*/}
								{/*	</Box>*/}
								{/*)}*/}

								{customerData && (
									<Box display="flex" alignItems="center">
										{customerData.ordered ? (
											<Check sx={{ mr: 1 }} className={classes.successColor} />
										) : (
											<Close sx={{ mr: 1 }} className={classes.errorColor} />
										)}
										<Typography className={customerData.ordered ? classes.successColor : classes.errorColor}>
											{customerData.ordered ? t('event-details.already-order') : t('event-details.no-order')}
										</Typography>
									</Box>
								)}

								<Button
									size="large"
									variant="outlined"
									color="primary"
									onClick={handleClick}
									startIcon={<DateRangeIcon />}
								>
									{t('event-details.list-of-events')}
								</Button>

								<Menu
									id="races-menu"
									ref={menuRef}
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
								>
									<Box width={300} pl={2} pr={2}>
										<table>
											<tbody>
												{tracks?.map((track, index) => {
													if (track.status === RaceStatus.COMING) {
														return (
															<tr key={track.id + '' + index}>
																<Box component="td" height={50}>
																	<Typography>{track.name}</Typography>
																</Box>
																<td>
																	<Box display="flex" alignItems="center">
																		<TimerIcon sx={{ mr: 1 }} />

																		<Typography>{track.raceStart}</Typography>
																	</Box>
																</td>
																<td>
																	<Box display="flex" alignItems="center">
																		<FlagIcon sx={{ mr: 1 }} />

																		<Typography>{track.ordersStart}</Typography>
																	</Box>
																</td>
															</tr>
														);
													}

													return (
														<tr key={track.id + '' + index}>
															<Typography
																sx={{
																	color: track.status === RaceStatus.ENDED && 'lightGray',
																	height: 50,
																}}
																component="td"
															>
																{track.name}
															</Typography>
															<td>
																<Box display="flex" alignItems="center">
																	<TimeIcon sx={{ mr: 1 }} />

																	<Typography
																		sx={{
																			color: track.status === RaceStatus.ENDED && 'lightGray',
																		}}
																	>
																		{t('shared.' + track.status)}
																	</Typography>
																</Box>
															</td>
															<td />
														</tr>
													);
												})}
											</tbody>
										</table>
									</Box>
								</Menu>
							</Box>
						</Container>
					</Grid>
					{next && (
						<Grid item>
							<Box mr={2} height="100%" display="flex" alignItems="center">
								<Button
									color="primary"
									endIcon={<RightIcon fontSize="large" />}
									onClick={() => setTrackIndex((index) => index + 1)}
									size="large"
								>
									<Typography color="primary" variant="h6">
										<Box fontWeight="bold" component="span">
											{t('shared.next')}
										</Box>
									</Typography>
								</Button>
							</Box>
						</Grid>
					)}
				</Grid>
			</Card>
		</>
	);
};

export default EventDetailsHeader;
