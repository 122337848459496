import React, { FunctionComponent } from 'react';
import { formatDate } from '@tmds-io/kore';
import { generatePath, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserTablePropsType } from '../types/user-table-props.type';
import {
	Box,
	Button,
	Card,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { ROUTES_URI } from '../../../app/router/routes';
import { USER_TABLE_ROW_PER_PAGE } from '@components/seller/user/users';

const PendingUsersTable: FunctionComponent<UserTablePropsType> = ({ accounts, pagination, refetch }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		refetch({ limit: { page_number: newPage + 1, count: USER_TABLE_ROW_PER_PAGE } });
	};

	return (
		<Card square={true}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('shared.sap')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.team') + ' / ' + t('shared.companyName')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.first-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.last-name')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('user-details.email')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('shared.type')}</Box>
						</TableCell>
						<TableCell>
							<Box fontWeight="fontWeightBold">{t('shared.date-of-request')}</Box>
						</TableCell>
						<TableCell align="right" />
					</TableRow>
				</TableHead>
				<TableBody>
					{accounts?.map((row) => (
						<TableRow key={row.id}>
							<TableCell>{row.edi_reference}</TableCell>
							<TableCell>{row.name}</TableCell>
							<TableCell>{row.firstname}</TableCell>
							<TableCell>{row.lastname}</TableCell>
							<TableCell>{row.email}</TableCell>
							<TableCell>{t('shared.bu-' + row.business_unit)}</TableCell>
							<TableCell>{formatDate(row.createdAt)}</TableCell>
							<TableCell align="right">
								<Button
									component={Link}
									to={generatePath(
										row.business_unit === 'resellers' ? ROUTES_URI.CUSTOMER_EDIT : ROUTES_URI.USER_DETAILS,
										{ id: row.id }
									)}
									startIcon={<ArrowRightIcon fontSize="small" />}
									variant="outlined"
									color="primary"
								>
									{t('shared.see-details')}
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{pagination?.totalResult && pagination.totalResult > USER_TABLE_ROW_PER_PAGE ? (
				<TablePagination
					rowsPerPage={USER_TABLE_ROW_PER_PAGE}
					component="div"
					count={pagination.totalResult ?? 0}
					rowsPerPageOptions={[]}
					page={pagination.currentPage - 1}
					onPageChange={handleChangePage}
				/>
			) : !pagination?.totalResult ? (
				<Typography textAlign="center" sx={{ p: 2 }} color="text.disabled">
					{t('users.no-pending-user')}
				</Typography>
			) : null}
		</Card>
	);
};

export default PendingUsersTable;
