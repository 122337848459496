// react
import React, { FC } from 'react';
// Components
import { Box, CircularProgress } from '@mui/material';

export const Loader: FC = () => (
	<Box sx={{ p: 5, display: 'flex', justifyContent: 'center' }}>
		<CircularProgress />
	</Box>
);
