// React
import React, { useEffect, useRef } from 'react';
// Router
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
// Components
import { Box, Button, styled, TextField, TextFieldProps, Typography } from '@mui/material';
import { TransferProduct, TransferProductColumns } from '@components/TransferProduct';
import { ProductRow } from '@components/TransferProduct/partials';
import { Loader } from '@components/shared/loader';
import TranslationTextField from '@components/shared/TranslationTextField';
// Helpers
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// Types
import { TransferListProps } from '@tmds-io/kore';
import { ProductType } from '../../types/product.types';
import { GroupData } from '@core/hooks/group/useGroupEdition/useGroupEdition.type';
import { GroupProduct } from '@core/hooks/group/group.type';
// Hooks queries mutation
import { useGroupEdition } from '@core/hooks/group/useGroupEdition';
import { useGroupEditionCreateGroup } from '@core/hooks/group/useGroupEdition/useGroupEditionCreateGroup';
import { useGroupEditionUpdateGroup } from '@core/hooks/group/useGroupEdition/useGroupEditionUpdateGroup';
import { useProductType } from '@core/hooks/useProductType';

type GroupFormValues = Omit<GroupData['group'], 'title' | 'id'> & {
	defaultProductIds: string[];
	title: string;
	titleFR: string;
};

const CustomTextField = styled((props: TextFieldProps) => <TextField variant="standard" {...props} />)(() => ({
	display: 'block',
	marginBottom: 0,
}));

const validationSchema = Yup.object().shape({
	sapPriceList: Yup.string(),
	title: Yup.string().required(),
	titleFR: Yup.string(),
	entryScore: Yup.number(),
	referringCustomer: Yup.string(),
});

export const GroupEdition = (): JSX.Element => {
	const params = useParams();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { data: groupInitData, loading: initLoading, error: initError } = useGroupEdition({ id: params.id });
	const [createGroup, { data: groupCreatedData, loading: groupCreatedLoading, error: groupCreatedError }] =
		useGroupEditionCreateGroup();
	const [updateGroup, { data: groupUpdatedData, error: groupUpdatedError }] = useGroupEditionUpdateGroup();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const loading = initLoading || groupCreatedLoading;
	const { data: productTypes = [] } = useProductType();
	const defaultProductIdsList = useRef<string[]>([]);

	const customList: TransferListProps<GroupProduct>['customList'] = ({ data, column, onClick }) => {
		const handleClick = (id: string) => (sku: string) => {
			if (column === 'right' && defaultProductIdsList.current.includes(sku)) {
				defaultProductIdsList.current = defaultProductIdsList.current.filter((localSku) => localSku !== sku);
			}
			onClick(id);
		};
		const handleToggleDefault = (sku: string, isDefault: boolean) => {
			const productFound = !!defaultProductIdsList.current.includes(sku);
			if (!productFound && isDefault) {
				defaultProductIdsList.current = [...defaultProductIdsList.current, sku];
			} else if (productFound && !isDefault) {
				defaultProductIdsList.current = defaultProductIdsList.current.filter((id) => id !== sku);
			}
		};

		return (
			<>
				{data
					.filter(({ isHide }) => !isHide)
					.map((props) => (
						<ProductRow
							key={props.id}
							onClick={handleClick(props.id)}
							onToggleDefault={handleToggleDefault}
							{...props}
							column={column}
							enableDefault
						/>
					))}
			</>
		);
	};

	const groupForm = useFormik<GroupFormValues>({
		initialValues: {
			sapPriceList: '',
			titleFR: '',
			title: '',
			entryScore: 0,
			defaultProductIds: [],
			referringCustomer: '',
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			if (params.id) {
				void updateGroup(
					{
						title: {
							fr: values.titleFR,
							en: values.title,
						},
						entryScore: values.entryScore,
						sapPriceList: values.sapPriceList,
						id: params.id,
						referringCustomer: values.referringCustomer,
					},
					groupInitData.inventories,
					groupInitData.allProducts.filter(({ id }) => values.defaultProductIds.includes(id)),
					defaultProductIdsList.current
				);
			} else {
				void createGroup(
					{
						title: { en: values.title, fr: values.titleFR },
						entryScore: values.entryScore,
						sapPriceList: values.sapPriceList,
						referringCustomer: values.referringCustomer,
					},
					groupInitData?.allProducts.filter(({ id }) => values.defaultProductIds.includes(id)),
					defaultProductIdsList.current
				);
			}
		},
	});

	useEffect(() => {
		if (groupInitData?.allProducts) {
			defaultProductIdsList.current = groupInitData?.allProducts
				.filter(({ isDefault }) => isDefault)
				.map(({ sku }) => sku);
			groupForm.setFieldValue('defaultProductIds', defaultProductIdsList.current);
		}
	}, [groupInitData?.allProducts]);

	const handleChangeDefaultProductList = (columns: TransferProductColumns<GroupProduct>): void => {
		groupForm.setFieldValue(
			'defaultProductIds',
			columns.right.map(({ id }) => id)
		);
	};

	useEffect(() => {
		if (groupInitData?.group) {
			const newValues = {
				sapPriceList: groupInitData.group.sapPriceList,
				titleFR: groupInitData.group.title.fr,
				title: groupInitData.group.title.en,
				entryScore: groupInitData.group.entryScore,
				defaultProductIds: groupInitData.groupSelectProducts.map(({ id }) => id),
				referringCustomer: groupInitData.group.referringCustomer,
			};
			groupForm.setValues(newValues);
		}
	}, [groupInitData]);

	useEffect(() => {
		if (groupCreatedData) {
			enqueueSnackbar(t('group-edition.page.group-created'), { variant: 'success' });
			navigate(generatePath(ROUTES_URI.GROUP_EDIT, { id: groupCreatedData.id }));
		}
		if (groupUpdatedData) {
			enqueueSnackbar(t('group-edition.page.group-updated'), { variant: 'success' });
		}
	}, [groupCreatedData, groupUpdatedData]);

	useEffect(() => {
		if (initError) {
			enqueueSnackbar(t('group-edition.page.error-fail-init'), { variant: 'error' });
		}
		if (groupCreatedError) {
			enqueueSnackbar(t('group-edition.page.error-fail-create'), { variant: 'error' });
		}
		if (groupUpdatedError) {
			enqueueSnackbar(t('group-edition.page.error-fail-update'), { variant: 'error' });
		}
	}, [initError, groupCreatedError, groupUpdatedError]);

	if (loading) {
		return <Loader />;
	}

	return (
		<Box pb={4}>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t(params.id ? 'group-edition.page.edit-title' : 'group-edition.page.add-title')}
			</Typography>
			<Box mb={3}>
				<TranslationTextField
					variant="standard"
					name="title"
					label={t('shared.name')}
					values={{ en: groupForm.values.title, fr: groupForm.values.titleFR }}
					onChange={groupForm.handleChange}
					error={groupForm.touched.title && !!groupForm.errors.title}
					helperText={groupForm.touched.title && groupForm.errors.title}
					direction="bottom"
					required
				/>
				<CustomTextField
					sx={{ mt: 1, mb: 2 }}
					name="sapPriceList"
					label={t('group-edition.page.form.field-sap-list-price')}
					value={groupForm.values.sapPriceList}
					onChange={groupForm.handleChange}
					error={groupForm.touched.sapPriceList && !!groupForm.errors.sapPriceList}
					helperText={groupForm.touched.sapPriceList && groupForm.errors.sapPriceList}
				/>
				<CustomTextField
					sx={{ mt: 1, mb: 2 }}
					type="number"
					name="entryScore"
					label={t('group-edition.page.form.field-entryScore')}
					onChange={groupForm.handleChange}
					value={groupForm.values.entryScore}
				/>
				<CustomTextField
					type="text"
					name="referringCustomer"
					label={t('group-edition.page.form.field-referring-customer')}
					onChange={groupForm.handleChange}
					error={groupForm.touched.referringCustomer && !!groupForm.errors.referringCustomer}
					helperText={groupForm.touched.referringCustomer && groupForm.errors.referringCustomer}
					value={groupForm.values.referringCustomer}
				/>
			</Box>

			<Box>
				<Typography sx={{ mb: 3 }} variant="h2">
					{t('group-edition.page.products-selection')}
				</Typography>

				<TransferProduct
					itemList={groupInitData?.allProducts}
					selectedItem={groupInitData.groupSelectProducts.map(({ id }) => id)}
					itemTypeList={[{ name: ProductType.ALL }, ...productTypes]}
					customList={customList}
					onChange={handleChangeDefaultProductList}
				/>
			</Box>
			<Box display="flex" p={4} justifyContent="flex-end">
				<Button variant="contained" color="primary" onClick={() => groupForm.handleSubmit()}>
					{t('shared.save')}
				</Button>
			</Box>
		</Box>
	);
};
