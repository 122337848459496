interface UseCurrency {
	letterToSign: (letter: string) => string;
	signToLetter: (sign: string) => string;
}

export const useCurrency = (): UseCurrency => ({
	letterToSign: (letter: string): string => {
		switch (letter) {
			case 'EUR':
				return '€';
			case 'USD':
				return '$';
			default:
				return letter;
		}
	},
	signToLetter: (sign: string): string => {
		switch (sign) {
			case '€':
				return 'EUR';
			case '$':
				return 'USD';
			default:
				return sign;
		}
	},
});
