// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkFieldsUpdate = (initialValue: Record<string, any>, value: Record<string, any>): boolean => {
	const keys = Object.keys(value);
	const result = keys.reduce((haveUpdate, key) => {
		if (!haveUpdate) {
			return initialValue[key] !== value[key];
		}
		return haveUpdate;
	}, false);

	return result as boolean;
};
