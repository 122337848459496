import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, List, ListItemText, Typography } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { ProductRowProps } from './ProductRow.type';

export const ProductRow: FC<ProductRowProps> = ({
	name,
	type,
	image,
	sku,
	volume,
	column,
	onClick,
	isDefault: initialIsDefault,
	enableDefault,
	onToggleDefault,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const handleClick = (): void => onClick(sku);

	const handleCheck = (evt: ChangeEvent<HTMLInputElement>): void => onToggleDefault(sku, evt.target.checked);

	return (
		<Box width={'100%'} borderBottom={'1px solid #C3C3C3'} px={2}>
			<Grid container sx={{ width: '100%' }} alignItems="center" flexDirection="row" justifyContent="space-between">
				<Grid item>
					<Grid container spacing={2} alignItems="center">
						{column === 'right' ? (
							<Grid item>
								<IconButton color="primary" onClick={handleClick}>
									<ArrowBack />
								</IconButton>
							</Grid>
						) : null}
						<Grid item>
							<Box maxWidth={64} maxHeight={64} display="flex" alignItems="center" justifyContent="center">
								<img src={image} width="100%" height="auto" alt={name} />
							</Box>
						</Grid>
						<Grid item>
							<List>
								<ListItemText>{type}</ListItemText>
								<ListItemText>
									<Typography fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
										{name}
									</Typography>
								</ListItemText>
								<ListItemText>
									{t('group-edition.page.ref-sap')} {sku}
								</ListItemText>
							</List>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container alignItems="center" spacing={2}>
						<Grid item>{volume}</Grid>
						{column === 'left' ? (
							<Grid item>
								<IconButton color="primary" onClick={handleClick}>
									<ArrowForward />
								</IconButton>
							</Grid>
						) : null}
					</Grid>
				</Grid>
				{column === 'right' && enableDefault ? (
					<Grid container item sm={12} justifyContent="flex-end">
						<FormControlLabel
							sx={{ m: 0 }}
							control={<Checkbox defaultChecked={initialIsDefault} onChange={handleCheck} />}
							label={t('shared.default-product') as string}
						/>
					</Grid>
				) : null}
			</Grid>
		</Box>
	);
};
