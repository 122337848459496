import { GetCustomersQuery, GetCustomersWithContractProductsQuery } from '../../generated/graphql';
import { UserTableAccountRow } from '@components/seller/user/types/user-table-props.type';

export const formatCustomerAccounts = (
	accounts: GetCustomersQuery['customerAccounts']['accounts']
): UserTableAccountRow[] => {
	return accounts.map(
		({ id, name, owner, type, created_at, edi_reference, business_unit, status, contracts, data }) => ({
			id,
			name,
			firstname: owner?.profile_data?.first_name || 'N/A',
			lastname: owner?.profile_data?.last_name || 'N/A',
			email: owner?.profile_data?.mail || 'N/A',
			createdAt: created_at,
			contractId: contracts?.[0].id,
			limit: contracts?.[0]?.min_quote_orderable_qty || 0,
			type,
			data,
			status,
			edi_reference,
			business_unit,
		})
	);
};

export const formatCustomerAccountsWithContractProducts = (
	accounts: GetCustomersWithContractProductsQuery['customerAccounts']['accounts']
): (UserTableAccountRow & { productList: string[]; contractId: string | undefined; stores: string[] })[] => {
	return accounts.map(
		({ id, name, owner, type, created_at, contracts, edi_reference, business_unit, status, data }) => ({
			id,
			name,
			firstname: owner?.profile_data.first_name || 'N/A',
			lastname: owner?.profile_data.last_name || 'N/A',
			email: owner?.profile_data.mail || 'N/A',
			createdAt: created_at,
			type,
			limit: contracts?.[0]?.min_quote_orderable_qty || 0,
			productList: contracts?.[0]?.restricted_products || [],
			contractId: contracts?.[0]?.id,
			stores: contracts?.[0].stores,
			edi_reference,
			data,
			business_unit,
			status,
		})
	);
};
