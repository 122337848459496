import { Store } from '../../../generated/graphql';

export const getEventsFieldData = (
	events: Array<Store>,
	disabledEventsIds: string[],
	language: string
): Array<Store & { disabled?: boolean }> => {
	const newEvents: Array<Store & { disabled?: boolean }> = [];

	events.forEach((event) => {
		newEvents.push({
			...event,
			name: event.title[language],
			id: event.id,
			disabled: disabledEventsIds.includes(event.id),
			business_unit: 'races',
		});
	});

	return newEvents;
};
