// react
import React, { FC, Fragment, useEffect, useState } from 'react';
// Types
import { ModalEditEventsPropsType } from './types/modal-edit-events-props.type';
// Components
import { Dialog } from '@tmds-io/kore';
import { Box, Button, Checkbox, Divider, Typography, Skeleton, Alert } from '@mui/material';
import { ArrowRight } from '@mui/icons-material';
// Utils
import { useTranslation } from 'react-i18next';
import { formatEventsToState } from '@components/customer/event/utils';
import i18next from 'i18next';

export const ModalEditEvents: FC<ModalEditEventsPropsType> = ({ events = [], error, loading, open, onClose }) => {
	// Hooks
	const { t } = useTranslation('acs', { useSuspense: false });
	// State
	const [state, setState] = useState<Record<string, boolean>>(formatEventsToState(events));
	const currentLanguage = i18next.language || 'en';

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleSubmit = () => {
		const checkedEvents = Object.keys(state).filter((key) => state[key]);

		onClose(checkedEvents);
	};

	useEffect(() => {
		if (events) {
			setState(formatEventsToState(events));
		}
	}, [events]);

	return (
		<Dialog
			keepMounted={false}
			fullWidth
			maxWidth="sm"
			open={open}
			onClose={() => onClose()}
			title={t('customer.events-modal.title')}
			content={
				<Box>
					<Typography variant="h6">{t('events.title')}</Typography>
					<Box mt={2}>
						{error && <Alert severity="error">{t('customer.events.fetch-events-error')}</Alert>}
						{loading
							? new Array(3)
									.fill(true)
									.map((_, index) => <Skeleton key={index} sx={{ mb: 1 }} variant="rectangular" height={54} />)
							: events.map(({ id, title }) => (
									<Fragment key={id}>
										<Box display="flex" justifyContent="space-between" alignItems="center" py={1}>
											<Typography variant="h6">
												<b>{title[currentLanguage]}</b>
											</Typography>

											<Checkbox checked={state[id]} onChange={handleChange} name={id} />
										</Box>
										<Divider />
									</Fragment>
							  ))}
					</Box>
				</Box>
			}
			actionsContent={
				<Box width="100%" px={2} pt={2} display="flex" justifyContent="space-between">
					<Button startIcon={<ArrowRight />} variant={'outlined'} color="primary" onClick={() => onClose()}>
						{t('shared.cancel')}
					</Button>
					<Button startIcon={<ArrowRight />} variant={'contained'} color="primary" onClick={handleSubmit}>
						{t('shared.validate')}
					</Button>
				</Box>
			}
		/>
	);
};
