import { GetProductTypeQuery, ProductTypeFieldsFragment } from '../../../generated/graphql';
import React, { ReactNode } from 'react';
import { ProductType } from '../../../types/product.types';
import {
	Apps as AppsIcon,
	LocalGasStation as LocalGasStationIcon,
	MoreHoriz as MoreHorizIcon,
	Opacity as OpacityIcon,
} from '@mui/icons-material';

export const formatActionList = (
	setFilter: (id: string) => void,
	t: (name: string) => string,
	productTypeList: ProductTypeFieldsFragment[]
): { icon: ReactNode; name: string; action: () => void }[] => {
	return [
		{
			action: () => {
				setFilter(ProductType.ALL);
			},
			name: t('products.filter.all'),
			icon: <AppsIcon fontSize="large" />,
		},
		...productTypeList.map(({ id, name }) => ({
			action: () => {
				setFilter(id);
			},
			name: t(`products.filter.${name}`),
			icon:
				name === ProductType.OTHER ? (
					<MoreHorizIcon fontSize="large" />
				) : name === ProductType.LUBRICANT ? (
					<OpacityIcon fontSize="large" />
				) : (
					<LocalGasStationIcon fontSize="large" />
				),
		})),
	];
};

export const formatCategoryToProductType = (data?: GetProductTypeQuery): ProductTypeFieldsFragment[] => {
	const types = data?.categories.categories;
	if (types?.length) {
		return types[0].childs.slice().sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
	}
	return [];
};
