// react
import React, { FC } from 'react';
// Types
import { EventBoxPropsType } from './types/eventBox-props.type';
// Component
import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ChevronRight as ChevronRightIcon, CreateOutlined as CreateIcon } from '@mui/icons-material';

export const EventBox: FC<EventBoxPropsType> = ({ name, eventLink, createIconLink, info }) => {
	const theme = useTheme();
	return (
		<Card square sx={{ borderBottom: `2px solid ${theme.palette.primary.main}`, mb: 3, p: 2 }}>
			<Box display="flex" alignItems="center" justifyContent="space-between" alignContent="center">
				<Box>
					<Box display="flex" alignItems="center">
						{createIconLink && (
							<IconButton sx={{ mr: 3 }} component={Link} to={createIconLink} size="small">
								<CreateIcon color="primary" fontSize="medium" />
							</IconButton>
						)}
						<Typography>
							<Box fontWeight="bold" component="span">
								{name}
							</Box>
						</Typography>
					</Box>
					{info && <Box mt={1}>{info}</Box>}
				</Box>
				<IconButton component={Link} to={eventLink} size="small">
					<ChevronRightIcon color="secondary" fontSize="large" />
				</IconButton>
			</Box>
		</Card>
	);
};
