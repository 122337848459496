import {
	GroupStoreFieldsFragment,
	useCreateGroupStoreMutation,
	useGetGroupsStoresLazyQuery,
} from '../../../generated/graphql';
import { useState } from 'react';
import { DataHook } from '../../../types/dataHook.type';
import { FILTER_COMPARISON } from '@tmds-io/kore/build';
import { RESELLERS_BU } from '@core/hooks/useUserHelper';

export const useGetNoGroup = (): [() => void, DataHook<GroupStoreFieldsFragment, undefined>] => {
	const [getStore] = useGetGroupsStoresLazyQuery();
	const [createStore] = useCreateGroupStoreMutation();
	const [state, setState] = useState<DataHook<GroupStoreFieldsFragment, undefined>>({
		data: undefined,
		loading: false,
		error: false,
	});

	const handleGetStore = async () => {
		try {
			const storeData = await getStore({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'code', value: 'nogroup' }],
				},
			});

			if (!storeData.data.paginatedStores.stores.length) {
				const newStore = await createStore({
					variables: {
						data: {
							business_unit: RESELLERS_BU,
							code: 'nogroup',
							name: 'No group',
							title: {
								fr: 'Sp. groupe',
								en: 'Sp. group',
							},
							hidden: false,
						},
					},
				});
				setState({ data: newStore.data.createStore, loading: false, error: false });
			} else {
				setState({ data: storeData.data.paginatedStores.stores[0], loading: false, error: false });
			}
		} catch (error) {
			console.error(error);
			setState({ data: undefined, loading: false, error: true });
		}
	};

	return [handleGetStore, state];
};
