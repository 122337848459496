// react
import React from 'react';
import { Box, MenuItem } from '@mui/material';
import { countries } from '@components/seller/event/partials/constants/countries';
import i18next from 'i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as Flags from 'country-flag-icons/string/3x2';
import { hasFlag } from 'country-flag-icons';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

export const getCountryList = (): React.ReactNode[] => {
	const currentLanguage = i18next.language || 'En';
	const countryLanguage = currentLanguage === 'en' ? 'nameEn' : 'nameFr';

	return countries
		.sort((countryA, countryB) => {
			return countryA[countryLanguage].localeCompare(countryB[countryLanguage]);
		})
		.map(({ code, nameEn, nameFr }) => {
			return (
				<MenuItem key={code} value={code.toLowerCase()}>
					<Box display="flex" alignItems="center">
						<Box
							mr={2}
							width={32}
							fontSize={28}
							dangerouslySetInnerHTML={{
								__html: hasFlag(code) ? Flags[code as keyof typeof Flags] : getUnicodeFlagIcon(code),
							}}
						/>
						{currentLanguage === 'fr' ? nameFr : nameEn}
					</Box>
				</MenuItem>
			);
		});
};
