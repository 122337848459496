// react
import React, { FC, useEffect, useRef } from 'react';
import PendingUsersTable from './partials/pending-users-table';
import UsersTable from './partials/users-table';
import { Add, Search as SearchIcon } from '@mui/icons-material';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { useTranslation } from 'react-i18next';
import { Box, Button, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useGetAccounts, useGetAccountsLazy } from '@core/hooks/useGetAccounts';
import { Filter, Limit } from '../../../generated/graphql';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
import { UserTableAccountRow } from '@components/seller/user/types/user-table-props.type';
import { ResponsePagination } from '@core/types/response-pagination';
import { useUserHelper } from '@core/hooks/useUserHelper';

const accountsValidatedFilter: Filter[] = [
	{ key: 'status', value: 'validated', comparison: FILTER_COMPARISON.EQUAL },
	{ key: 'state', value: 'active', comparison: FILTER_COMPARISON.EQUAL },
];
const competitorFilters = [
	...accountsValidatedFilter,
	{ comparison: FILTER_COMPARISON.EQUAL, key: 'business_unit', value: 'races' },
];
const customerFilters = [
	...accountsValidatedFilter,
	{ comparison: FILTER_COMPARISON.EQUAL, key: 'business_unit', value: 'resellers' },
];

export const USER_TABLE_ROW_PER_PAGE = 10;

const Users: FC = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { isSeller, isAdmin } = useUserHelper();
	const isSellerAdmin = isSeller && isAdmin;
	const isCustomerAdmin = !isSeller && isAdmin;
	const pendingFilter = useRef<{ limit?: Limit; filter?: Filter[] }>();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const [
		getCustomersPending,
		{ data: accountsPendingData, refetch: refetchPending, loading: loadingPending, error: errorPending },
	] = useGetAccountsLazy();

	const { data: accountsValidated, error: errorValidated } = useGetAccounts(accountsValidatedFilter);

	const [getCompetitorsValidated, { data: competitorsData, loading: competitorsLoading }] = useGetAccountsLazy();
	const [getCustomersValidated, { data: customersData, loading: customersLoading }] = useGetAccountsLazy();

	useEffect(() => {
		getCompetitorsValidated({
			variables: {
				filter: competitorFilters,
				limit: { page_number: 1, count: USER_TABLE_ROW_PER_PAGE },
			},
		});
		getCustomersValidated({
			variables: {
				filter: customerFilters,
				limit: { page_number: 1, count: USER_TABLE_ROW_PER_PAGE },
			},
		});
	}, []);

	useEffect(() => {
		if (accountsValidated?.accounts.length && !accountsPendingData) {
			const ids = accountsValidated.accounts.map(({ id }) => id);
			pendingFilter.current = {
				filter: [{ key: '_key', value: ids, comparison: FILTER_COMPARISON.NOT_IN }],
				limit: { page_number: 1, count: USER_TABLE_ROW_PER_PAGE },
			};
			getCustomersPending({ variables: pendingFilter.current });
		}
	}, [accountsValidated]);

	useEffect(() => {
		if (errorPending || errorValidated) {
			console.error(errorPending || errorValidated);
			enqueueSnackbar(t('user-details.error-fetch'), { variant: 'error' });
		}
	}, [errorPending, errorValidated]);

	return (
		<>
			<Box mb={4}>
				<Typography variant="h2" color="primary">
					{t('users.pending-title')}
				</Typography>
			</Box>
			{loadingPending ? (
				<Skeleton variant="rectangular" />
			) : (
				<PendingUsersTable
					accounts={accountsPendingData.accounts}
					pagination={accountsPendingData.pagination}
					refetch={refetchPending}
				/>
			)}
			<UsersSection
				title={t('users.validate-title')}
				loading={competitorsLoading}
				users={competitorsData?.accounts}
				pagination={competitorsData?.pagination}
				refetch={getCompetitorsValidated}
				filters={competitorFilters}
			/>

			<UsersSection
				title={t('users.validate-title-customers')}
				loading={customersLoading}
				users={customersData?.accounts}
				pagination={customersData?.pagination}
				refetch={getCustomersValidated}
				filters={customerFilters}
				userDetailsRoute={ROUTES_URI.CUSTOMER_EDIT}
				isCustomer
			/>
			{isSellerAdmin ? (
				<Box display="flex" justifyContent="flex-end" p={2}>
					<Button component={Link} to={ROUTES_URI.CUSTOMER_ADD} startIcon={<Add />} variant="contained">
						{t('users.add-customer')}
					</Button>
				</Box>
			) : null}
			{isCustomerAdmin ? (
				<Box mt={3} display="flex" justifyContent="flex-end">
					<Button component={Link} to={generatePath(ROUTES_URI.MEMBERS)} color="primary" variant="contained">
						{t('users.btn-add')}
					</Button>
				</Box>
			) : null}
		</>
	);
};

interface UserSectionProps {
	title: string;
	loading: boolean;
	users?: UserTableAccountRow[];
	pagination?: ResponsePagination;
	refetch: (variables?: unknown) => unknown;
	filters: Filter[];
	userDetailsRoute?: string;
	isCustomer?: boolean;
}

const UsersSection: FC<UserSectionProps> = ({
	loading,
	users,
	pagination,
	refetch,
	title,
	filters,
	userDetailsRoute,
	isCustomer,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const localFilters = useRef<Filter[]>(filters);
	const onSearch = ({
		target: { value },
		code,
	}: React.FocusEvent<HTMLInputElement> & React.KeyboardEvent<HTMLInputElement>): void => {
		if (!code || code === 'Enter') {
			localFilters.current = [...filters, { key: 'name', value: `%${value}%`, comparison: FILTER_COMPARISON.LIKE }];
			refetch({
				variables: {
					filter: localFilters.current,
					limit: { page_number: 1, count: USER_TABLE_ROW_PER_PAGE },
				},
			});
		}
	};

	const refetchWithFilters = (variables: { limit?: Limit }): void => {
		refetch({
			variables: {
				filter: localFilters.current,
				limit: variables?.limit,
			},
		});
	};

	return (
		<div>
			<Box mb={4} mt={4} display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h2" color="primary">
					{title}
				</Typography>

				<Box bgcolor="white">
					<TextField
						name="search"
						placeholder={t('shared.search')}
						onBlur={onSearch}
						size="small"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchIcon />
								</InputAdornment>
							),
							color: 'secondary',
						}}
						onKeyPress={onSearch}
						variant="outlined"
					/>
				</Box>
			</Box>

			{loading ? (
				<Skeleton variant="rectangular" />
			) : (
				<UsersTable
					accounts={users}
					pagination={pagination}
					refetch={refetchWithFilters}
					userDetailsRoute={userDetailsRoute}
					isCustomer={isCustomer}
				/>
			)}
		</div>
	);
};

export default Users;
