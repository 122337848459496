import React, { FC } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useTranslation } from 'react-i18next';

export interface RoleSelectProps {
	touched: boolean;
	error?: string;
	value: string;
	onChange: SelectInputProps['onChange'];
	options: { label: string; value: string }[];
}

export const RoleSelect: FC<RoleSelectProps> = ({ value, error, touched, onChange, options }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	return (
		<FormControl error={touched && !!error} fullWidth>
			<InputLabel sx={{ ml: -2 }}>{t('shared.role')}</InputLabel>
			<Select name="role" variant="standard" label={t('shared.role')} value={value} onChange={onChange}>
				<MenuItem>{t('shared.none')}</MenuItem>
				{options.map(({ label, value }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
			{touched && error ? <FormHelperText sx={{ ml: 0 }}>{error}</FormHelperText> : null}
		</FormControl>
	);
};
