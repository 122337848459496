// react
import React from 'react';
import CustomerOrderDetails from '@components/shared/order/order-details';
import { useGetOrderQuery } from '../../../generated/graphql';
import { useParams } from 'react-router-dom';
import { Loader } from '@components/shared/loader';
import { formatOrder } from '../../shared/order/order.core';
import { COMPETITORS_BU } from '@core/hooks/useUserHelper';

export const OrderDetails = (): JSX.Element => {
	const { id } = useParams();

	const { data: orderData, loading, refetch } = useGetOrderQuery({ variables: { id }, fetchPolicy: 'network-only' });

	const order = orderData?.order;
	if (loading) {
		return <Loader />;
	}

	const handleRefreshData = () => {
		refetch({ id });
	};

	return (
		<CustomerOrderDetails
			orderId={order.id}
			isRaces={order.business_unit === COMPETITORS_BU}
			orderStatus={order.status}
			orderReference={order.reference}
			orderData={order.data}
			orderSap={order?.edi_reference}
			orderDate={order.created_at}
			orderPrice={order.sub_total}
			orderProductTable={formatOrder(order)}
			onUpdate={handleRefreshData}
		/>
	);
};
