// react
import React, { FC } from 'react';
// Types
import { EventDetailsPropsType } from '../types/event-details-props.type';
// Styles
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTypographyStyle } from '@core/styles/typography';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '../../../app/router/routes';
import EventDetailsHeader from '@components/shared/event/event-details-header';
import { CustomerProductList } from '@components/customer/CustomerProductList/CustomerProductList';
import { FILTER_COMPARISON, useUserContext } from '@tmds-io/kore';
import { useGetOrdersQuery } from '../../../../generated/graphql';
import { isPast } from 'date-fns';

export const EventDetails: FC<EventDetailsPropsType> = ({
	eventName,
	tracks,
	optionalProducts,
	defaultProducts,
	currentTrack,
	setCurrentTrack,
}) => {
	// Styles
	const clsTypo = useTypographyStyle();

	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { id } = useUserContext();
	const { data: orderData } = useGetOrdersQuery({
		variables: {
			filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: id }],
		},
	});
	const track = tracks[currentTrack];
	const raceEnded = isPast(new Date(track.visible_to));

	const customerOrder = !!orderData?.orders.orders.find(({ order_lines }) =>
		order_lines.find(({ store_id }) => store_id === track.id)
	);

	if (!tracks.length) {
		return (
			<Box display="flex" justifyContent="center" p={4} flexDirection="column">
				<Box mb={4} display="flex" justifyContent="center">
					<Typography>No content</Typography>
				</Box>
				<Box display="flex" justifyContent="center">
					<Button variant="outlined" color="primary" component={Link} to={generatePath(ROUTES_URI.PRODUCTS)}>
						Back to event
					</Button>
				</Box>
			</Box>
		);
	}

	const prev = currentTrack !== 0;
	const next = currentTrack < tracks.length - 1;

	return (
		<Box pb={3}>
			<EventDetailsHeader
				eventName={eventName}
				tracks={tracks}
				setTrackIndex={setCurrentTrack}
				trackIndex={currentTrack}
				prev={prev}
				next={next}
				customerData={{
					ordered: customerOrder,
				}}
			/>
			{!raceEnded ? (
				<>
					<Box mt={3}>
						<Box mb={3}>
							<Typography variant="h1" color="primary" className={clsTypo.textTransFormInitial}>
								{t('event-details.total-products')}
							</Typography>
						</Box>
						{defaultProducts ? (
							<CustomerProductList products={defaultProducts} raceName={track.title} storeId={track.id} />
						) : (
							<Typography>{t('event-details.customer.no-product-found')}</Typography>
						)}
					</Box>
					{optionalProducts?.length ? (
						<Box mt={10}>
							<Box mb={3}>
								<Typography variant="h1" className={clsTypo.textTransFormInitial}>
									{t('event-details.total-products-optional')}
								</Typography>
							</Box>
							<CustomerProductList products={optionalProducts} raceName={track.title} storeId={track.id} />
						</Box>
					) : null}
				</>
			) : null}
		</Box>
	);
};
