import { DataHook, DataHookBase, DataHookLazy } from '../../../types/dataHook.type';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { Filter, Limit, PaginationFieldsFragment, Sort, useGetOrdersLazyQuery } from '../../../generated/graphql';
import { formatOrders } from './useOrders.core';
import { Order } from '@components/shared/order/types/orders.type';
import { useEffect, useMemo } from 'react';

type UseOrdersBaseProps = UseOrdersProps & { lazy?: boolean };

interface UseOrdersProps {
	filters?: Filter[];
	sort?: Sort;
	limit?: Limit;
}

interface UseOrders {
	orders: Order[];
	pagination: PaginationFieldsFragment;
}

const useBaseOrders = ({
	filters = null,
	sort = null,
	limit = null,
	lazy = null,
}: UseOrdersBaseProps | undefined): DataHookBase<UseOrders, UseOrdersProps> => {
	const currentLanguage = useCurrentLanguage();
	const [getOrders, { data, error, loading }] = useGetOrdersLazyQuery({ fetchPolicy: 'cache-and-network' });
	const currentLoading = !data ? true : loading;

	const fetch = (props: UseOrdersProps): void => {
		void getOrders({
			variables: {
				filter: props.filters,
				sort: props.sort || { keys: ['created_at'], sort: -1 },
				limit: props.limit,
			},
		});
	};

	useEffect(() => {
		if (!lazy) {
			fetch({ filters, limit, sort });
		}
	}, [lazy]);

	return {
		data: data
			? { orders: formatOrders(data.orders.orders, currentLanguage), pagination: data.orders.pagination }
			: undefined,
		error: !!error,
		loading: currentLoading,
		fetch,
	};
};

export const useOrders = (props: UseOrdersProps): DataHook<UseOrders, UseOrdersProps> => {
	const { data, loading, fetch, error } = useBaseOrders({ ...props, lazy: true });
	return useMemo(() => ({ data, loading, error, refetch: fetch }), [data, error, loading]);
};

export const useLazyOrders = (props?: UseOrdersProps): DataHookLazy<UseOrders, UseOrdersProps> => {
	const { data, loading, fetch, error } = useBaseOrders({ ...props, lazy: true });
	return useMemo(() => [fetch, { data, loading, error }], [data, error, loading]);
};
