// React
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
// Utils
import { useTranslation } from 'react-i18next';
// SharedComponents
import { ContactDetailsFieldsTemplate } from '../../../shared/ContactDetailsFieldsTemplate';
// Form
import { useFormik } from 'formik';
import { addUsersFormSchema } from './add-users-form.schema';
// Type
import { RegisterModalPropsType } from '../registerModal.type';
// Kore
import { DialogWithIcon } from '@tmds-io/kore';
// Styles
import { useRegisterStyles } from '@components/Register/shared/styles/registration.style';
// Mui
import {
	ArrowRight as ArrowRightIcon,
	Group as GroupIcon,
	Delete as DeleteIcon,
	Add as AddIcon,
} from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

const AddUsersModal: FunctionComponent<
	RegisterModalPropsType & { setShowNoUserAddModal?: Dispatch<SetStateAction<boolean>> }
> = ({ setShowModal, showModal, setShowNoUserAddModal, formData }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const classes = useRegisterStyles();

	const handleClose = () => {
		setShowModal(false);
	};

	const formik = useFormik({
		initialValues: {
			users: [{ firstName: '', lastName: '', email: '' }],
		},
		validationSchema: addUsersFormSchema,
		onSubmit: (values) => {
			formData.profiles = values.users.map((user) => {
				return {
					profile_data: {
						first_name: user.firstName,
						last_name: user.lastName,
						mail: user.email,
					},
					optin: null,
				};
			});
			setShowModal(false);
			setShowNoUserAddModal(true);
		},
	});

	const onChangeUsers = (type: string): void => {
		// update dynamic form
		const users = [...formik.values.users];
		if (type === 'add') {
			users.push({ firstName: '', lastName: '', email: '' });
		} else if (type === 'delete') {
			users.splice(users.length - 1, 1);
		}

		formik.setValues({ ...formik.values, users });
	};

	return (
		<DialogWithIcon
			icon={<GroupIcon fontSize="large" color="primary" />}
			title={t('register.add-user.popup.title')}
			content={
				<form>
					{formik.values.users.map((user, i) => {
						return (
							<div key={i}>
								<Box mb={3} mt={3} display="flex" justifyContent="space-between" alignItems="center">
									<Typography color={'primary'}>
										{t('register.add-user.popup.user')}
										{i + 1}
									</Typography>
									{i !== 0 ? (
										<DeleteIcon onClick={() => onChangeUsers('delete')} className={classes.trashIcon} />
									) : null}
								</Box>

								<ContactDetailsFieldsTemplate
									firstName={`users.${i}.firstName`}
									lastName={`users.${i}.lastName`}
									email={`users.${i}.email`}
									valueFirstName={formik.values.users[i].firstName}
									valueLastName={formik.values.users[i].lastName}
									valueEmail={formik.values.users[i].email}
									touched={formik.touched?.users ? formik.touched?.users[i] : ({} as unknown)}
									errors={
										formik.errors?.users && formik.touched?.users ? formik.errors?.users[i] : ({} as unknown)
									}
									formik={formik}
								/>
							</div>
						);
					})}

					<Button
						startIcon={<AddIcon fontSize="small" />}
						color="primary"
						type="button"
						onClick={() => onChangeUsers('add')}
					>
						{t('register.add-user.popup.add-user-button')}
					</Button>
				</form>
			}
			open={showModal}
			onClose={handleClose}
			actionsContent={
				<>
					<Button
						type="button"
						color="primary"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => {
							handleClose();
							setShowNoUserAddModal(true);
						}}
					>
						{t('shared.cancel')}
					</Button>
					<Button
						color="primary"
						variant="contained"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => formik.handleSubmit()}
					>
						{t('shared.validate')}
					</Button>
				</>
			}
		/>
	);
};

export default AddUsersModal;
