// react
import React from 'react';
import CustomEvent from '@components/customer/event';
import { ProductListForResellerCustomer } from './ProductListForResellerCustomer';
import { useUserHelper } from '@core/hooks/useUserHelper';
/**
 * Products pages - handle reseller or customer component page display
 */
export const Products = (): JSX.Element => {
	const { isReseller } = useUserHelper();
	return isReseller ? <ProductListForResellerCustomer /> : <CustomEvent />;
};
