import { createStyles, makeStyles } from '@mui/styles';

export const useInputStyle = makeStyles(() =>
	createStyles({
		date: {
			'&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
				display: 'none',
				'-webkit-appearance': 'none',
			},
		},
	})
);
