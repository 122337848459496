// react
import React, { useEffect, useState } from 'react';
// Types
import UsersTable from '@components/seller/user/partials/users-table';
import {
	useAddRestrictedProductsToContractsMutation,
	useRemoveRestrictedProductsFromContractsMutation,
} from '../../../generated/graphql';
import { Box, Button, CircularProgress, Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountsWithContracts } from '@core/hooks/useGetAccounts';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { COMPETITORS_BU } from '@core/hooks/useUserHelper';

const filter = [
	{ key: 'status', value: 'validated', comparison: FILTER_COMPARISON.EQUAL },
	{ key: 'business_unit', value: COMPETITORS_BU, comparison: FILTER_COMPARISON.EQUAL },
];

export const CustomerSelection = (): JSX.Element => {
	const { id: productSku } = useParams();
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { data, refetch, loading, error } = useGetAccountsWithContracts(filter);
	const [addRestrictedProducts, { data: addData, error: addError }] = useAddRestrictedProductsToContractsMutation();
	const [removeRestrictedProducts, { data: removeData, error: removeError }] =
		useRemoveRestrictedProductsFromContractsMutation();

	useEffect(() => {
		if (data) {
			setSelectedUsers(
				data.accounts.filter(({ productList }) => productList.includes(productSku)).map(({ id }) => id)
			);
		}
	}, [data]);

	useEffect(() => {
		if (addData) {
			refetch();
		}
	}, [addData]);

	useEffect(() => {
		if (removeData) {
			refetch();
		}
	}, [removeData]);

	useEffect(() => {
		if (removeError) {
			enqueueSnackbar(t('customer-select.remove-error'), { variant: 'error' });
		}
	}, [removeError]);

	useEffect(() => {
		if (addError) {
			enqueueSnackbar(t('customer-select.add-error'), { variant: 'error' });
		}
	}, [addError]);

	useEffect(() => {
		if (error) {
			enqueueSnackbar(t('error'), { variant: 'error' });
		}
	}, [error]);

	const handleChange = (customerId: string): void => {
		const index = data.accounts.findIndex(({ id }) => id === customerId);
		const productList = data.accounts[index].productList;

		if (productList.includes(productSku)) {
			removeRestrictedProducts({
				variables: {
					customer_id: customerId,
					restricted_product_ids: [productSku],
				},
			});
		} else {
			addRestrictedProducts({
				variables: {
					customer_id: customerId,
					restricted_product_ids: [productSku],
				},
			});
		}
	};

	if (loading) {
		return (
			<Box sx={{ p: 5, display: 'flex', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Box pt={5}>
			<Typography variant="h1" color="primary" sx={{ mb: 4 }}>
				{t('customer-selection.title')}
			</Typography>
			<Container>
				<Typography variant="h2" sx={{ mb: 3 }}>
					{t('customer-selection.subtitle')}
				</Typography>
				<UsersTable
					accounts={data.accounts}
					pagination={data.pagination}
					refetch={refetch}
					enableInteraction
					values={selectedUsers}
					onChange={handleChange}
				/>
				<Box py={4} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
					<Button variant="outlined" color="primary" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
						{t('shared.back')}
					</Button>
				</Box>
			</Container>
		</Box>
	);
};
