// react
import React, { FC } from 'react';
// types
import { ProductDetailsProps } from './types';
// styles
import { useProductDetailStyle } from './styles';
// Utils
import { generatePath, Link } from 'react-router-dom';
// Component
import { Box, Button, Grid, Typography, Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ROUTES_URI } from '../../app/router/routes';
import UsersTable from '@components/seller/user/partials/users-table';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import AddIcon from '@mui/icons-material/Add';
import { BrokenImage } from '@mui/icons-material';
import { formatResponsePagination } from '@core/utils/format-response-pagination';
import { useGetAccountsWithContracts } from '@core/hooks/useGetAccounts';

export const ProductDetails: FC<ProductDetailsProps> = ({
	name,
	img,
	description,
	id,
	sku,
	restrictedProduct,
	specifications,
	conditioning,
	loading,
}) => {
	const classes = useProductDetailStyle();
	const { t } = useTranslation('acs', { useSuspense: false });

	const filter = [
		{ key: 'status', value: 'validated', comparison: FILTER_COMPARISON.EQUAL },
		// { key: 'contracts.restricted_products ', value: sku, comparison: FILTER_COMPARISON.IN },
	];

	const { data, refetch } = useGetAccountsWithContracts(filter);
	const accounts = data?.accounts.filter(({ productList }) => productList.includes(sku)) || [];

	return (
		<Box className={classes.root}>
			<Box mb={2} display="flex" alignItems="center">
				<Link to={generatePath(ROUTES_URI.PRODUCTS)} className={classes.backButton}>
					<ArrowBackIosIcon fontSize="large" />
				</Link>
				<Typography variant="h1" color="primary">
					{loading ? <Skeleton variant="text" width={150} /> : name}
				</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item>
					{loading ? (
						<Skeleton variant="rectangular" width={100} height={100} />
					) : img ? (
						<Box display="flex" width={100} height={100} justifyContent="center" alignItems="center">
							<img className={classes.img} src={img} alt="name" />
						</Box>
					) : (
						<Box display="flex" width={100} height={100} justifyContent="center" alignItems="center">
							<BrokenImage fontSize="large" />
						</Box>
					)}
				</Grid>
				<Grid item xs>
					<Box mb={2}>
						<Typography variant="h6" gutterBottom>
							{t('product-edition.input.description')}
						</Typography>
						<Typography>{loading ? <Skeleton variant="rectangular" height={50} /> : description}</Typography>
					</Box>
					{specifications && (
						<Box mb={2}>
							<Typography variant="h6" gutterBottom>
								{t('product-edition.input.specification')}
							</Typography>
							<Typography>
								{loading ? <Skeleton variant="rectangular" height={50} /> : specifications}
							</Typography>
						</Box>
					)}

					<Box display="flex" mb={2}>
						{conditioning?.map((cond, index) => (
							<Box key={index} p={2} className={classes.conditioning}>
								{cond}
							</Box>
						))}
					</Box>
				</Grid>
			</Grid>
			<Box display="flex" justifyContent="flex-end" mb={restrictedProduct ? 8 : 4}>
				<Button
					component={Link}
					to={generatePath(ROUTES_URI.PRODUCTS_EDIT, { id })}
					variant="contained"
					color="primary"
					startIcon={<EditIcon />}
				>
					{t('shared.update')}
				</Button>
			</Box>
			{restrictedProduct && (
				<>
					<Typography variant="h2" gutterBottom>
						<span className={classes.tableTitle}>{t('product-detail.add.users')}</span>
					</Typography>
					<UsersTable
						accounts={accounts}
						pagination={formatResponsePagination({
							current_page: 1,
							last_page: true,
							total_pages: 1,
							total_result: 0,
							result_count: 0,
						})}
						refetch={refetch}
					/>
					<Box mt={3} display="flex" justifyContent="flex-end">
						<Button
							component={Link}
							to={generatePath(ROUTES_URI.CUSTOMER_SELECTION, { id: sku })}
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
						>
							{t('register.add-user.popup.title')}
						</Button>
					</Box>
				</>
			)}
		</Box>
	);
};
