import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { Header, useUserContext } from '@tmds-io/kore';
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLng, LanguageEnum, updateLanguage } from '../../../../i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { useApolloError } from '@core/services/apollo';
import { AccessTime } from '@mui/icons-material';
import { useUserHelper } from '@core/hooks/useUserHelper';
import { useErrorNotification } from '@core/hooks/useErrorNotification';

const AuthGuard: FunctionComponent = ({ children }) => {
	const { id, login, status, logout } = useUserContext();
	const { isSeller } = useUserHelper();
	const { t } = useTranslation('acs', { useSuspense: false });
	const [currentLanguage, setCurrentLanguage] = useState<LanguageEnum>(getLng());
	const { error } = useApolloError();

	const handleChangeLanguage = (lng: LanguageEnum): void => {
		setCurrentLanguage(lng);
		updateLanguage(lng);
	};

	if (id && status === 'new' && !isSeller) {
		return (
			<Router>
				<Header appName="Racing-shop" currentLanguage={currentLanguage} onChangeLanguage={handleChangeLanguage} />
				<Box p={5} display="flex" justifyContent="center" alignItems="center">
					<Typography sx={{ mt: 10, pt: 5 }}>{t('auth-guard.user-waiting-validation')}</Typography>
				</Box>
				<Box display="flex" justifyContent="center" py={1}>
					<Button variant="outlined" onClick={logout}>
						{t('profile.logout')}
					</Button>
				</Box>
			</Router>
		);
	}

	if (!id) {
		login();
		return null;
	}

	return !error ? <>{children}</> : <ErrorComp errCode={error}>{children}</ErrorComp>;
};

const ErrorComp: FC<{ errCode: number }> = ({ errCode, children }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { logout } = useUserContext();
	const sendErrorNotification = useErrorNotification();

	useEffect(() => {
		if (errCode && errCode !== 401) {
			sendErrorNotification('error.' + errCode, 'Network error');
		}
	}, [errCode]);

	if (errCode === 401) {
		return (
			<Box width={300} p={6} m="auto">
				<Card>
					<Box display="flex" justifyContent="center" alignItems="center" pt={2}>
						<AccessTime sx={{ fontSize: 40 }} />
					</Box>
					<CardContent>
						<Typography textAlign="center">{t('error.' + errCode)}</Typography>
					</CardContent>
					<CardActions sx={{ justifyContent: 'center' }}>
						<Button size="small" onClick={logout}>
							{t('shared.login')}
						</Button>
					</CardActions>
				</Card>
			</Box>
		);
	} else {
		return <>{children}</>;
	}
};

export default AuthGuard;
