// react
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomPaper } from '@components/CustomerPaper';
import { getShipmentLabel } from '@components/ResellerShippingCard/resellerShippingCard.core';

// Components properties
interface ResellerShippingCardProps {
	priceGroup: string;
	incoterm: string;
	shippingCondition: string;
	accountResponsible: string;
}

/**
 * ResellerShippingCard components -
 */
export const ResellerShippingCard: FC<ResellerShippingCardProps> = ({
	shippingCondition,
	priceGroup,
	incoterm,
	accountResponsible,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	return (
		<CustomPaper>
			<Typography variant="h3" sx={{ mb: 3 }}>
				{t('reseller-customer.add-page.business-payment')}
			</Typography>
			<Box pl={2}>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('reseller-customer.add-page.price-group')}</strong> : {priceGroup}
				</Typography>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('reseller-customer.add-page.incoterm')}</strong> : {incoterm}
				</Typography>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('reseller-customer.add-page.shipping-condition')}</strong> :{' '}
					{getShipmentLabel(shippingCondition, t)}
				</Typography>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('reseller-customer.add-page.account-responsible')}</strong> : {accountResponsible}
				</Typography>
			</Box>
		</CustomPaper>
	);
};
