import { useEffect, useMemo, useRef } from 'react';
import {
	GroupListingRow,
	UseGroupsListing,
	UseGroupsListingBase,
	UseGroupsListingLazy,
	UseGroupsListingProps,
} from '@core/hooks/group/useGroupsListing/useGroupsListing.type';
import {
	GetGroupContractsQuery,
	GetGroupsStoresQuery,
	useGetGroupContractsLazyQuery,
	useGetGroupsStoresLazyQuery,
} from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useCurrentLanguage } from '@core/utils/language.utils';

const formatData = (
	data: GetGroupsStoresQuery['paginatedStores']['stores'],
	contractData: GetGroupContractsQuery['contracts']['contracts'] = [],
	t: (val: string) => string,
	currentLanguage: string
): GroupListingRow[] =>
	data.map(({ id, inventories, title, data }) => ({
		id,
		name: t(title[currentLanguage]),
		sap: data?.sap_price_list,
		entryScore: data?.entry_score || 0,
		numberOfUsers: getGroupContracts(contractData, id),
		numberOfProducts: inventories?.length || 0,
	}));

const getGroupContracts = (
	contractsData: GetGroupContractsQuery['contracts']['contracts'],
	storeId: string
): number => {
	return contractsData.filter(({ stores }) => stores?.includes(storeId)).length;
};

const useGroupsListingBase = (props?: UseGroupsListingProps): UseGroupsListingBase => {
	const currentLanguage = useCurrentLanguage();
	const { t } = useTranslation('acs', { useSuspense: false });
	const [fetchStores, { data: groupData, error }] = useGetGroupsStoresLazyQuery({ fetchPolicy: 'network-only' });
	const [fetchContracts, { data: contractData, error: contractError }] = useGetGroupContractsLazyQuery({
		fetchPolicy: 'network-only',
	});

	const handleFetch = (props?: UseGroupsListingProps): void => {
		void fetchStores({ variables: props.stores });
		void fetchContracts({ variables: props.contracts });
	};

	const result = useRef<UseGroupsListingBase>({
		loading: true,
		fetch: handleFetch,
	});

	useEffect(() => {
		if (error || contractError) {
			result.current = { error: true, loading: true, fetch: handleFetch };
		} else if (groupData && contractData) {
			result.current = {
				data: formatData(groupData.paginatedStores.stores, contractData.contracts.contracts, t, currentLanguage),
				fetch: handleFetch,
				loading: false,
			};
		}
	}, [groupData, error, contractData, contractData]);

	useEffect(() => {
		if (!props?.lazy) {
			handleFetch(props);
		}
	}, []);

	return result.current;
};

export const useGroupsListing = (props?: UseGroupsListingProps): UseGroupsListing => {
	const { data, loading, error, fetch } = useGroupsListingBase(props);
	return useMemo(() => ({ data, loading, error, refetch: fetch }), [data, error, loading]);
};

export const useGroupsListingLazy = (props?: UseGroupsListingProps): UseGroupsListingLazy => {
	const { data, loading, error, fetch } = useGroupsListingBase({ ...props, lazy: true });
	return useMemo(() => [fetch, { data, loading, error }], [data, error, loading]);
};
