import React, { FC } from 'react';
import {
	Button,
	Card,
	FormControl,
	FormGroup,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { ArrowRight as ArrowRightIcon, CalendarToday as DateIcon } from '@mui/icons-material';
import { ORDER_STATUS } from '@components/shared/order/enums/order-status.enum';
import { useInputStyle } from '@core/styles/input';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { OrderFilterValues } from '@components/shared/order/types/ordersFilter.type';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { Filter } from '../../../../generated/graphql';
import { useUserHelper } from '@core/hooks/useUserHelper';

interface OrderFilterProps {
	onSubmit: (filters: Filter[]) => void;
}

const formatValueToFilter = (filters: Partial<OrderFilterValues>): Filter[] => {
	const filterList = Object.keys(filters);
	return filterList.reduce((addFilters, filter) => {
		let currentFilter: Filter;
		switch (filter) {
			case 'startDate':
				if (!filters.startDate) {
					return addFilters;
				}
				currentFilter = {
					comparison: FILTER_COMPARISON.GREATER_EQUAL,
					key: 'created_at.timestamp',
					value: new Date(filters.startDate).toISOString(),
				};
				break;
			case 'endDate':
				if (!filters.endDate) {
					return addFilters;
				}
				currentFilter = {
					comparison: FILTER_COMPARISON.LESS_EQUAL,
					key: 'created_at.timestamp',
					value: new Date(filters.endDate).toISOString(),
				};
				break;
			case 'status':
				currentFilter = {
					comparison: FILTER_COMPARISON.EQUAL,
					key: 'status',
					value: filters.status,
				};
				break;
			case 'customerName':
				currentFilter = {
					comparison: FILTER_COMPARISON.LIKE,
					key: 'customer_company',
					value: '%' + filters.customerName + '%',
				};
				break;
			case 'businessUnit':
				if (filters.businessUnit) {
					currentFilter = {
						comparison: FILTER_COMPARISON.EQUAL,
						key: 'business_unit',
						value: filters.businessUnit,
					};
				} else {
					return addFilters;
				}
				break;
			default:
				return addFilters;
		}
		return [...addFilters, currentFilter];
	}, [] as Filter[]);
};

const OrdersFilter: FC<OrderFilterProps> = ({ onSubmit }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const clsInput = useInputStyle();

	const { isSeller } = useUserHelper();

	const formik = useFormik<OrderFilterValues>({
		initialValues: { startDate: '', endDate: '', status: ORDER_STATUS.NO_STATUS, customerName: '', businessUnit: '' },
		onSubmit: (values) => {
			type OrderKey = keyof typeof values;
			const result = Object.keys(values).reduce((filter, key) => {
				if (values[key as OrderKey] !== ORDER_STATUS.NO_STATUS) {
					return { ...filter, [key]: values[key as OrderKey] };
				}
				return filter;
			}, {} as Record<string, string>);
			onSubmit(formatValueToFilter(result));
		},
	});

	return (
		<Card sx={{ p: 2, mb: 3 }}>
			{isSeller && (
				<FormGroup sx={{ mb: 3 }}>
					<TextField
						name="customerName"
						fullWidth
						label={t('shared.name')}
						value={formik.values.customerName}
						onChange={formik.handleChange}
					/>
				</FormGroup>
			)}

			<Grid container spacing={3} alignItems="center">
				<Grid item lg={3}>
					<TextField
						fullWidth
						name="startDate"
						label={t('track-details.order-date-from')}
						onChange={formik.handleChange}
						value={formik.values.startDate}
						type="date"
						inputProps={{ className: clsInput.date }}
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position="start">
									<DateIcon />
								</InputAdornment>
							),
							color: 'secondary',
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item lg={3}>
					<TextField
						fullWidth
						name="endDate"
						label={t('track-details.order-date-to')}
						onChange={formik.handleChange}
						value={formik.values.endDate}
						type="date"
						inputProps={{ className: clsInput.date }}
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<InputAdornment position="start">
									<DateIcon />
								</InputAdornment>
							),
							color: 'secondary',
						}}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item lg={3}>
					<FormControl>
						<InputLabel id="order-status-label" sx={{ ml: -2, pt: 2 }}>
							{t('orders.status')}
						</InputLabel>
						<Select
							name="status"
							variant="standard"
							onChange={formik.handleChange}
							value={formik.values.status}
							labelId="order-status-label"
							size="medium"
							fullWidth
							sx={{ minWidth: 150, pt: 1 }}
						>
							{[
								{ label: t('shared.none'), value: ORDER_STATUS.NO_STATUS },
								{ label: t('order.new'), value: ORDER_STATUS.CREATED },
								{ label: t('order.confirmed'), value: ORDER_STATUS.CONFIRMED },
								{ label: t('order.waiting_for_payment'), value: ORDER_STATUS.WAITING_FOR_PAYMENT },
								{
									label: t('order.waiting_for_payment_reception'),
									value: ORDER_STATUS.WAITING_FOR_PAYMENT_RECEPTION,
								},
								{ label: t('order.paid'), value: ORDER_STATUS.PAID },
								{ label: t('order.invoiced'), value: ORDER_STATUS.INVOICED },
								{ label: t('order.cancelled'), value: ORDER_STATUS.CANCELLED },
							].map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				{isSeller && (
					<Grid item lg={3}>
						<FormControl>
							<InputLabel id="order-bu-label" sx={{ ml: -2, pt: 2 }} shrink>
								{t('orders.business-unit')}
							</InputLabel>
							<Select
								name="businessUnit"
								variant="standard"
								onChange={formik.handleChange}
								value={formik.values.businessUnit}
								labelId="order-bu-label"
								size="medium"
								fullWidth
								displayEmpty
								sx={{ minWidth: 150, pt: 1 }}
							>
								{[
									{ label: t('shared.all'), value: '' },
									{ label: t('shared.bu-races'), value: 'races' },
									{ label: t('shared.bu-resellers'), value: 'resellers' },
								].map(({ label, value }) => (
									<MenuItem key={value} value={value}>
										{label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				)}

				<Grid item lg={3} sx={{ textAlign: isSeller ? 'left' : 'right' }}>
					<Button
						color="primary"
						variant="contained"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => formik.handleSubmit()}
					>
						{t('shared.filter')}
					</Button>
				</Grid>
			</Grid>
		</Card>
	);
};

export default OrdersFilter;
