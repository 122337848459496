import { Store } from '../../generated/graphql';

interface UseRaceDate {
	getFirstRaceDate: (storeList: Store[]) => number;
	getLastRaceDate: (storeList: Store[]) => number;
}

const getFirstRaceDateForChampionship = (storeList: Store[]): number => {
	if (storeList.length) {
		const store = [...storeList]
			.sort((storeA, storeB) =>
				new Date(storeA.visible_from).getTime() > new Date(storeB.visible_from).getTime() ? -1 : 1
			)
			.pop();
		return new Date(store.visible_from).getTime();
	}
	return 0;
};

const getLastRaceDateForChampionship = (storeList: Store[]): number => {
	if (storeList.length) {
		const store = [...storeList]
			.sort((storeA, storeB) =>
				new Date(storeA.visible_to).getTime() > new Date(storeB.visible_to).getTime() ? 1 : -1
			)
			.pop();
		return new Date(store.visible_to).getTime();
	}
	return 0;
};

export const useRaceDate = (): UseRaceDate => ({
	getFirstRaceDate: getFirstRaceDateForChampionship,
	getLastRaceDate: getLastRaceDateForChampionship,
});
