// react
import React from 'react';
import { Members as BaseMembers } from '../seller/Members';
import {
	COMPETITOR_ADMIN,
	COMPETITOR_USER,
	RESELLER_ADMIN,
	RESELLER_USER,
	useUserHelper,
} from '@core/hooks/useUserHelper';

export const Members = (): JSX.Element => {
	const { isReseller } = useUserHelper();
	return (
		<BaseMembers
			employeeRole={isReseller ? RESELLER_USER : COMPETITOR_USER}
			adminRole={isReseller ? RESELLER_ADMIN : COMPETITOR_ADMIN}
		/>
	);
};
