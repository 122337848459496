import { createStyles, makeStyles } from '@mui/styles';

export const useTypographyStyle = makeStyles(() =>
	createStyles({
		uppercase: {
			textTransform: 'uppercase',
		},
		lowercase: {
			textTransform: 'lowercase',
		},
		capitalize: {
			textTransform: 'capitalize',
		},
		textTransFormInitial: {
			textTransform: 'initial',
		},
		bold: {
			fontWeight: 'bold',
		},
		normal: {
			fontWeight: 'normal',
		},
		lineClamp: {
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 3,
			overflow: 'hidden',
		},
	})
);
