// React
import React, { ChangeEvent, FunctionComponent, useState } from 'react';
// Form
import { useFormik } from 'formik';
import { bankDetailsFormSchema } from './bankDetailsForm.schema';
// Modals
import AskAddUserModal from '../modals/AskAddUserModal/ask-add-user-modal';
import NoFileUploadModal from '../modals/no-file-upload-modal';
import FinalModal from '../modals/final-modal';
import NoUserAddModal from '../modals/no-user-add-modal';
import AddUsersModal from '../modals/AddUsersModal/add-users-modal';
// Types
import { FormDataType } from '../shared/types/formData.type';
// Utils
import { useTranslation } from 'react-i18next';
import { uploadMedia } from '@core/services/media';
import { useSnackbar } from 'notistack';
// Mui
import { FormGroup, TextField, Button, Box, Typography } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
// Kore
import { checkIban, FileInput, IbanField } from '@tmds-io/kore';

const BankDetailsFForm: FunctionComponent<FormDataType> = ({ formData }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const { enqueueSnackbar } = useSnackbar();

	const formik = useFormik({
		initialValues: {
			IBAN: '',
			swiftCode: '',
			file: '',
		},
		validationSchema: bankDetailsFormSchema,
		onSubmit: (values) => {
			formData.account.bank_account = {
				iban: values.IBAN,
				swift: values.swiftCode,
				rib: values.file,
			};

			setShowAskAddUserModal(true);
		},
		validate: (values) => {
			const isValid = checkIban(values.IBAN);

			if (!isValid) {
				return {
					IBAN: t('register.bank-details.invalid-iban'),
				};
			}
			return {};
		},
	});

	const uploadRib = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
		if (evt.target.files[0].type !== 'application/pdf') {
			formik.setFieldError('file', t('shared.pdf-required'));
			return;
		}

		const result = await uploadMedia(evt.target.files[0]);

		if (result) {
			formik.setFieldValue('file', `!${result.key}`);
		} else {
			enqueueSnackbar('The file is not uploaded', { variant: 'error' });
		}
	};

	const [showAskAddUserModal, setShowAskAddUserModal] = useState(false);
	const [showNoFileModal, setShowNoFileModal] = useState(false);
	const [showNoUserAddModal, setShowNoUserAddModal] = useState(false);
	const [showAddUsersModal, setShowAddUsersModal] = useState(false);
	const [showFinalModal, setShowFinalModal] = useState(false);

	return (
		<>
			<Box mb={3}>
				<Typography variant="h3" align="center" color="primary">
					{t('register.bank-details.form.title')}
				</Typography>
			</Box>

			<Typography sx={{ mb: 3 }} paragraph>
				{t('register.bank-details.form.details')}
			</Typography>

			<Typography sx={{ mb: 3 }} paragraph>
				{t('register.bank-details.form.subtitle')}
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<Box mb={3}>
					<IbanField
						error={formik.touched.IBAN && !!formik.errors.IBAN}
						name="IBAN"
						required
						label={t('register.bank-details.form.iban')}
						value={formik.values.IBAN}
						helperText={formik.touched.IBAN && formik.errors.IBAN}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
				</Box>

				<Box mb={3}>
					<FormGroup>
						<TextField
							error={formik.touched.swiftCode && !!formik.errors.swiftCode}
							name="swiftCode"
							required
							label={t('register.bank-details.form.swift-code')}
							value={formik.values.swiftCode}
							helperText={formik.touched.swiftCode && formik.errors.swiftCode}
							onChange={formik.handleChange}
						/>
					</FormGroup>
				</Box>

				<Box mb={3}>
					<p>{t('register.bank-details.form.upload-details')}*</p>
					<Box display="flex" alignItems="center">
						<FileInput
							error={formik.touched.file && !!formik.errors.file}
							name="file"
							accept="application/pdf"
							variant="outlined"
							color="primary"
							label={t('register.bank-details.form.upload-input')}
							helperText={formik.touched.file && formik.errors.file}
							onChange={uploadRib}
						/>
						{formik.values.file && !formik.errors.file ? <DoneIcon color="success" /> : null}
					</Box>
				</Box>

				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Button
						onClick={() => {
							setShowNoFileModal(true);
						}}
						type="button"
						startIcon={<ArrowRightIcon fontSize="small" />}
					>
						{t('shared.skip')}
					</Button>
					<Button
						disabled={!formik.isValid || !formik.dirty}
						color="primary"
						variant="contained"
						type="submit"
						startIcon={<ArrowRightIcon fontSize="small" />}
					>
						{t('shared.validate')}
					</Button>
				</Box>
			</form>

			<NoFileUploadModal
				showModal={showNoFileModal}
				setShowModal={setShowNoFileModal}
				setShowAddUserModal={setShowAskAddUserModal}
			/>
			<AskAddUserModal
				showModal={showAskAddUserModal}
				setShowModal={setShowAskAddUserModal}
				setShowAddUsersModal={setShowAddUsersModal}
				setShowNoUserAddModal={setShowNoUserAddModal}
			/>
			<AddUsersModal
				showModal={showAddUsersModal}
				setShowModal={setShowAddUsersModal}
				setShowNoUserAddModal={setShowNoUserAddModal}
				formData={formData}
			/>

			<NoUserAddModal
				showModal={showNoUserAddModal}
				setShowModal={setShowNoUserAddModal}
				setShowFinalModal={setShowFinalModal}
				formData={formData}
			/>
			<FinalModal showModal={showFinalModal} setShowModal={setShowFinalModal} />
		</>
	);
};

export default BankDetailsFForm;
