// React
import React, { FunctionComponent } from 'react';
// Utils
import { useTranslation } from 'react-i18next';
// Type
import { RegisterModalPropsType } from './registerModal.type';
// Kore
import { DialogWithIcon } from '@tmds-io/kore';
// Mui
import { ArrowRight as ArrowRightIcon, Check as CheckIcon } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';

const FinalModal: FunctionComponent<RegisterModalPropsType> = ({ setShowModal, showModal }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const handleClose = () => {
		setShowModal(false);
		const loginUrl = window._env_.HERMES_LOGIN;
		window.location.href = `${loginUrl}/login`;
	};

	return (
		<DialogWithIcon
			icon={<CheckIcon fontSize="large" color="primary" />}
			title={t('register.final.popup.title')}
			content={
				<>
					<Typography paragraph>{t('register.final.popup.body')}</Typography>
					<Typography paragraph>{t('register.final.popup.body2')}</Typography>
					<Typography paragraph>
						<b>{t('register.final.popup.body3')}</b>
					</Typography>
				</>
			}
			open={showModal}
			onClose={handleClose}
			actionsContent={
				<>
					<Button
						startIcon={<ArrowRightIcon fontSize="small" />}
						color="primary"
						variant="contained"
						onClick={handleClose}
					>
						{t('register.final.popup.footer-action')}
					</Button>
				</>
			}
		/>
	);
};

export default FinalModal;
