import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useEventHeaderDetailsStyle = makeStyles((theme: Theme) =>
	createStyles({
		img: {
			width: '100%',
			height: '300px',
		},
		date: {
			'&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
				display: 'none',
				'-webkit-appearance': 'none',
			},
		},
		successColor: {
			color: theme.palette.success.main,
		},
		errorColor: {
			color: theme.palette.error.main,
		},
		eventNameLabel: {
			background: 'rgba(255, 255, 255, 0.8)',
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			height: theme.spacing(4),
			zIndex: 2,
		},
		imgPreview: {
			transform: 'translate(-50%, -50%)',
			position: 'absolute',
			top: 'calc(50% + 20px)',
			left: '50%',
			display: 'block',
		},
		eventUpload: {
			background: 'rgba(255, 255, 255, 0.8)',
			position: 'absolute',
			zIndex: 1,
			top: 0,
			left: 0,
			right: 0,
			height: '300px',
		},
		flag: {
			width: '25px',
			marginRight: '1rem',
		},
		cardDescription: {
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 3,
			overflow: 'hidden',
		},
		cardTitle: {
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
			'-webkit-line-clamp': 1,
			overflow: 'hidden',
		},
	})
);
