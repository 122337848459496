import React, { FC } from 'react';
import { GetProductDetailsQuery, useGetProductDetailsQuery } from '../../../generated/graphql';

import { ProductDetails as ProductDetailComp } from './product-details';
import { ProductDetailsProps } from '@components/seller/product-details/types';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { getMediaUrl } from '@core/services/media';
import { Loader } from '@components/shared/loader';

const formatData = (
	id: string,
	loading: boolean,
	lang: string,
	dataResponse?: GetProductDetailsQuery
): ProductDetailsProps => {
	if (loading || !dataResponse) {
		return { loading: loading as true, id };
	}
	const { description, title, data, image, variations, restricted_product, sku } = dataResponse.product;

	return {
		id,
		name: title[lang],
		sku,
		description: description[lang],
		specifications: data?.specifications?.[lang] || '',
		customerList: [],
		restrictedProduct: restricted_product,
		img: image && getMediaUrl(image),
		conditioning: variations?.map((variation) => variation.options?.[0].value.split('_').join('x')) || [],
		loading: loading as false,
	};
};

export const ProductDetails: FC = () => {
	const params = useParams();
	const { data, loading } = useGetProductDetailsQuery({ variables: { id: params.id } });
	const currentLanguage = i18next.language || 'en';

	if (loading) {
		return <Loader />;
	}

	return <ProductDetailComp {...formatData(params.id, loading, currentLanguage, data)} />;
};
