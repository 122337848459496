import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { CartContextType, CartProduct } from '@core/services/cart/cart.type';
import {
	QuoteLineFieldsFragment,
	useGetQuoteProductsLazyQuery,
	useAddQuoteLineMutation,
	useDeleteQuoteLineMutation,
	useUpdateQuoteLineMutation,
	ProfileQuoteFieldsFragment,
	GetQuoteQuery,
	UpdateQuoteLineMutation,
	AddQuoteLineMutation,
	DeleteQuoteLineMutation,
	QuoteProductFieldsFragment,
	useGetQuoteLazyQuery,
	useConvertQuoteToOrdersMutation,
	useUpdateQuoteMutation,
} from '../../../generated/graphql';
import { getCurrentQuoteLine } from '@core/services/cart/cart.core';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { getMediaUrl } from '@core/services/media';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';
import { useErrorNotification } from '@core/hooks/useErrorNotification';

export const CartContext = createContext<CartContextType>(null);

export const getCurrentQuote = (
	action: { name: string; data?: ProfileQuoteFieldsFragment },
	getData?: GetQuoteQuery,
	addData?: AddQuoteLineMutation,
	updateData?: UpdateQuoteLineMutation,
	deleteData?: DeleteQuoteLineMutation
): ProfileQuoteFieldsFragment => {
	let data;
	switch (action.name) {
		case 'add':
			data = addData?.addQuoteLine;
			break;
		case 'update':
			data = updateData?.updateQuoteLine;
			break;
		case 'delete':
			data = deleteData?.deleteQuoteLine;
			break;
		default:
			data = getData?.getQuote;
			break;
	}
	return data || action?.data;
};
export const formatProduct = (
	productsData: QuoteProductFieldsFragment[],
	quote: ProfileQuoteFieldsFragment
): CartProduct[] => {
	return quote.quote_lines.map(
		({ inventory_id, parent_sku, sku: prodSku, store_id, title, store_title, quantity, price }) => {
			const product = productsData.find(({ sku }) => sku === parent_sku);
			const variation = product?.variations.find(({ sku }) => sku === prodSku);
			const [incrementVolume, volume] = variation?.options?.[0].value.split('_').map((val) => +val) || [0, 0];

			return {
				inventoryId: inventory_id,
				sku: parent_sku,
				storeId: store_id,
				title,
				description: store_title,
				quantity,
				image: product?.image && getMediaUrl(product.image),
				price,
				volume,
				incrementVolume,
				qtyIncrement: variation?.qty_increment,
			};
		}
	);
};

export const CartProvider: React.FC<{ disabled?: boolean }> = ({ children, disabled }) => {
	const [getQuote, { data: quoteData, refetch }] = useGetQuoteLazyQuery({ fetchPolicy: 'network-only' });
	const [updateQuoteLine, { data: newQuoteAfterUpdate, error: updateQuoteLineError }] = useUpdateQuoteLineMutation();
	const [addQuoteLine, { data: newQuoteAfterAdd, error: addError }] = useAddQuoteLineMutation();
	const [deleteQuoteLine, { data: newQuoteAfterDelete }] = useDeleteQuoteLineMutation();
	const [getQuoteProducts, { data: quoteProductsData }] = useGetQuoteProductsLazyQuery();
	const lastAction = useRef<{ name: string; data?: ProfileQuoteFieldsFragment }>({ name: 'get' });
	const [convertQuoteToOrders, { data: orderData }] = useConvertQuoteToOrdersMutation();
	const [updateQuote, { error: updateQuoteError }] = useUpdateQuoteMutation();
	const sendNotificationError = useErrorNotification();
	const { t } = useTranslation('acs', { useSuspense: false });
	const navigate = useNavigate();

	useEffect(() => {
		if (orderData) {
			lastAction.current = { ...lastAction.current, name: 'refetch' };
			refetch().then(() => {
				navigate(generatePath(ROUTES_URI.PAYMENT, { id: orderData.quoteToOrders[0].id }));
			});
		}
	}, [orderData]);

	const currentQuote = useMemo(
		() => getCurrentQuote(lastAction.current, quoteData, newQuoteAfterAdd, newQuoteAfterUpdate, newQuoteAfterDelete),
		[quoteData, newQuoteAfterAdd, newQuoteAfterUpdate, newQuoteAfterDelete]
	);

	if (currentQuote) {
		lastAction.current = { ...lastAction.current, data: currentQuote };
	}

	const products =
		quoteProductsData && currentQuote ? formatProduct(quoteProductsData.products.products, currentQuote) : [];

	const add = (product: CartProduct): void => {
		lastAction.current = { ...lastAction.current, name: 'add' };
		void addQuoteLine({
			variables: {
				quote_id: currentQuote.id,
				quote_line: {
					inventory_id: product.inventoryId,
					sku: product.sku,
					quantity: product.qtyIncrement * product.quantity,
				},
			},
		});
	};

	const update = (quoteLine: QuoteLineFieldsFragment, quantity: number): void => {
		lastAction.current = { ...lastAction.current, name: 'update' };
		const { id, inventory_id, sku, item_qty_increment } = quoteLine;
		void updateQuoteLine({
			variables: {
				quote_id: currentQuote.id,
				line_id: id,
				quote_line: { inventory_id, sku, quantity: +(quantity * item_qty_increment).toFixed(2) },
			},
		});
	};

	const remove = (quoteLine: QuoteLineFieldsFragment): void => {
		lastAction.current = { ...lastAction.current, name: 'delete' };
		void deleteQuoteLine({
			variables: {
				quote_id: currentQuote.id,
				line_id: quoteLine.id,
			},
		});
	};

	const handleAdd = (product: CartProduct): void => {
		const currentQuoteLine = getCurrentQuoteLine(currentQuote.quote_lines, product.inventoryId);
		if (currentQuoteLine) {
			update(
				currentQuoteLine,
				+(product.quantity + currentQuoteLine.quantity / currentQuoteLine.item_qty_increment).toFixed(2)
			);
		} else {
			add(product);
		}
	};

	const handleUpdate = (inventoryId: string, quantity: number): void => {
		const currentQuoteLine = getCurrentQuoteLine(currentQuote.quote_lines, inventoryId);
		if (currentQuoteLine) {
			update(
				{
					...currentQuoteLine,
					quantity: +(currentQuoteLine.quantity / currentQuoteLine.item_qty_increment + quantity).toFixed(2),
				},
				quantity
			);
		}
	};

	const handleRemove = (inventoryId: string): void => {
		const currentQuoteLine = getCurrentQuoteLine(currentQuote.quote_lines, inventoryId);
		if (currentQuoteLine) {
			remove(currentQuoteLine);
		}
	};

	const handleValidate = async (comment?: string): Promise<void> => {
		await updateQuote({
			variables: {
				id: currentQuote.id,
				confirm: true,
			},
		});

		void convertQuoteToOrders({
			variables: { quote_id: currentQuote.id, comment },
		});
	};

	useEffect(() => {
		if (currentQuote?.quote_lines && currentQuote.quote_lines.length) {
			getQuoteProducts({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.IN,
							key: 'sku',
							value: currentQuote.quote_lines.map(({ parent_sku }) => parent_sku),
						},
					],
				},
			});
		}
	}, [currentQuote?.quote_lines]);

	useEffect(() => {
		if (addError || updateQuoteLineError || updateQuoteError) {
			console.error(addError || updateQuoteLineError || updateQuoteError);
			const [notificationMessage, sentryMessage] = addError
				? ['cart.add-error', 'Fail add product to cart']
				: updateQuoteLineError
				? ['cart.update-product-qty-error', 'Fail update quote line']
				: ['cart.update-quote-error', 'Fail update quote'];
			sendNotificationError(t(notificationMessage), sentryMessage);
		}
	}, [addError, updateQuoteLineError, updateQuoteError]);

	useEffect(() => {
		if (!disabled) {
			getQuote();
		}
	}, [disabled]);

	return (
		<CartContext.Provider
			value={{
				products,
				add: handleAdd,
				update: handleUpdate,
				remove: handleRemove,
				validate: handleValidate,
				total: currentQuote?.total || 0,
				totalQuantity: currentQuote?.quote_lines.reduce((sum, quoteLine) => {
					return +(sum + quoteLine.quantity).toFixed(2);
				}, 0),
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export const useCartContext = (): CartContextType => useContext(CartContext);
