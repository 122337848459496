import { Group, UseGroupEditionCreateGroup } from './useGroupEdition.type';
import { useGroupEditionCreateStore } from './useGroupEditionCreateStore';
import { useGroupEditionCreateInventory } from '@core/hooks/group/useGroupEdition/useGroupEditionCreateInventory';
import { GroupProduct } from '@core/hooks/group/group.type';
import { useMemo, useState } from 'react';

interface State {
	loading: boolean;
	error: boolean;
	data?: Group;
}

export const useGroupEditionCreateGroup = (): UseGroupEditionCreateGroup => {
	const [createGroup, { error: groupError, loading: groupLoading }] = useGroupEditionCreateStore();
	const [createInventory, { error: inventoryError, loading: inventoryLoading }] = useGroupEditionCreateInventory();
	const [state, setState] = useState<State>({ loading: false, error: false, data: undefined });

	const handleCreateGroup = async (
		group: Omit<Group, 'id'>,
		productList: GroupProduct[],
		defaultProductList: string[]
	): Promise<void> => {
		setState({ loading: true, error: false, data: undefined });
		const groupData = await createGroup(group, productList, defaultProductList);

		if (groupData) {
			const promiseList = productList.map((product) => createInventory(product, groupData.id, product.price));

			Promise.all(promiseList)
				.then(() => {
					setState({ loading: false, error: false, data: groupData });
				})
				.catch(() => {
					setState({ loading: false, error: true, data: undefined });
				});
		}
	};

	return useMemo(
		() => [
			handleCreateGroup,
			{
				data: state.data,
				error: groupError || inventoryError || state.error,
				loading: groupLoading || inventoryLoading || state.loading,
			},
		],
		[groupError, inventoryError, groupLoading, inventoryLoading, state]
	);
};
