export const getShipmentLabel = (code: string, t: (s: string) => string): string => {
	switch (code.toUpperCase()) {
		case 'YA':
			return t('shipment.ya');
		case 'YB':
			return t('shipment.yb');
		case 'YC':
			return t('shipment.yc');
		default:
			return code;
	}
};
export const getShipmentTransportLabel = (code: string): string => {
	switch (code.toUpperCase()) {
		case 'Y1':
			return 'delivery-selector.meansOfConveying-ground';
		case 'Y6':
			return 'delivery-selector.meansOfConveying-sea';
		case 'Y7':
			return 'delivery-selector.meansOfConveying-air';
		default:
			return code;
	}
};
