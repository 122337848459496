import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import UserKeyValueDetail from '../user/partials/users-details-colums';
import ChampionshipsFormModal from '../user/modals/championships-form-modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Grid,
	Typography,
	Card,
	Button,
	CardContent,
	IconButton,
	Link as MuiLink,
	InputLabel,
	Select,
	FormControl,
	MenuItem,
	TextField,
	List,
	ListItemText,
	ListItem,
} from '@mui/material';
import {
	ArrowRight as ArrowRightIcon,
	Save as SaveIcon,
	Delete as DeleteIcon,
	GetApp as DownloadIcon,
	Add as AddIcon,
	Edit,
	Cancel,
	ArrowForward,
} from '@mui/icons-material';
import { checkIban, Dialog, FileInput, FILTER_COMPARISON, IbanField, IntraCommunityVatEditor } from '@tmds-io/kore';
import { useFormik } from 'formik';
import {
	AccountInput,
	GetSellerEventsQuery,
	useGetSellerEventsQuery,
	useRefuseAccountUpdateMutation,
	useValidateAccountUpdateMutation,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { getMediaUrl, uploadMedia } from '@core/services/media';
import i18next from 'i18next';
import { Loader } from '@components/shared/loader';
import { DetailsProps, UserAccountFormValues } from '@components/seller/user-details/details.type';
import { userAccountFormSchema } from './update-user-account';
import { getCountryList } from '@components/seller/event/partials/country-list';

const UserDetails: FC<DetailsProps> = ({
	userAccount,
	customerContract = [],
	onSubmitContractUpdate,
	onValidate,
	onSubmitUserAccountUpdate,
	status,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const currentLanguage = i18next.language || 'en';
	const editMode = useRef<boolean>(false);
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const refuseReason = useRef<string>('');
	const [validationRefuseModalOpen, setValidationRefuseModalOpen] = useState(false);
	const [editRib, setEditRib] = useState(false);
	const [uploadAgainRib, setUploadAgainRib] = useState<boolean>(false);
	const [events, setEvents] = useState<Array<{ id: string; title: Record<string, string>; status: string }>>([]);

	const { data: eventsData } = useGetSellerEventsQuery({
		variables: {
			filter: [
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'business_unit', value: 'races' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'parent_id', value: '' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'status', value: 'active' },
			],
		},
		fetchPolicy: 'network-only',
	});

	const { enqueueSnackbar } = useSnackbar();

	const [validateAccountUpdate] = useValidateAccountUpdateMutation({
		onCompleted: () => {
			enqueueSnackbar(t('user-details.validate-account-update-success'), { variant: 'success' });
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		},
		onError: () => {
			enqueueSnackbar(t('user-details.validate-account-update-fail'), { variant: 'error' });
		},
	});
	const [refuseAccountUpdate] = useRefuseAccountUpdateMutation({
		onCompleted: () => {
			enqueueSnackbar(t('user-details.refuse-account-update-success'), { variant: 'success' });
		},
		onError: () => {
			enqueueSnackbar(t('user-details.refuse-account-update-fail'), { variant: 'error' });
		},
	});

	const handleUpdateUserEvents = (newEvents: GetSellerEventsQuery['paginatedStores']['stores']): void => {
		setEvents((currentEvents) => [...newEvents, ...currentEvents]);
		setShowModal(false);
		onSubmitContractUpdate([...customerContract, ...newEvents.map(({ id }) => id)]);
	};

	useEffect(() => {
		if (eventsData?.paginatedStores.stores && events.length === 0) {
			const selectedEvents = [
				...eventsData.paginatedStores.stores.filter((event) => customerContract.includes(event.id)),
			];
			setEvents(selectedEvents);
		}
	}, [customerContract, eventsData]);
	const userAccountForm = useFormik<UserAccountFormValues>({
		initialValues: {
			account: userAccount as AccountInput,
		},
		validationSchema: userAccountFormSchema,
		onSubmit: (values) => {
			onSubmitUserAccountUpdate(values.account);
		},
		validate: (values) => {
			if (values.account.bank_account.iban) {
				const isValid = checkIban(values.account.bank_account.iban);

				if (!isValid) {
					return {
						account: {
							bank_account: {
								iban: t('register.bank-details.invalid-iban'),
							},
						},
					};
				}
			}

			return {};
		},
	});

	const uploadRib = async (evt: ChangeEvent<HTMLInputElement>): Promise<void> => {
		const result = await uploadMedia(evt.target.files[0]);

		if (result) {
			void userAccountForm.setFieldValue('account.bank_account.rib', `!${result.key}`);
		} else {
			enqueueSnackbar('The file is not uploaded', { variant: 'error' });
		}
	};

	const handleValidateAccount = (): void => {
		void validateAccountUpdate({
			variables: {
				id: userAccount.id,
			},
		});
	};

	const handleRefuseAccountUpdate = (): void => {
		void refuseAccountUpdate({
			variables: {
				id: userAccount.id,
				params: [refuseReason.current],
			},
		});
	};

	const deleteEvent = (id: string) => {
		const newEvents = [...events.filter((event) => event.id !== id)];
		setEvents(newEvents);
		onSubmitContractUpdate(newEvents.map(({ id }) => id));
	};

	const handleCancelRibUpdate = (): void => {
		setEditRib(false);
		userAccountForm.setFieldValue(
			'account.bank_account.iban',
			userAccountForm.initialValues.account.bank_account.iban
		);
	};

	const handleReasonUpdate = (evt: ChangeEvent<HTMLInputElement>): void => {
		refuseReason.current = evt.target.value;
	};

	const handleSave = () => {
		userAccountForm.submitForm();
	};

	const handleChangeAdditionalVat = (data: { isValid: boolean; values: string[] }): void => {
		if (data.isValid) {
			userAccountForm.setFieldValue('account.additional_vat', data.values);
			userAccountForm.setFieldValue('account.additional_vat', data.values);
		} else {
			userAccountForm.setFieldValue('account.additional_vat', []);
			userAccountForm.setFieldError('account.additional_vat', 'Vat error');
		}
	};

	const handleOpenRefuseModal = () => {
		setValidationRefuseModalOpen(true);
	};

	const handleCloseRefuseModal = () => {
		setValidationRefuseModalOpen(false);
	};

	useEffect(() => {
		if (userAccount) {
			editMode.current = !editMode.current;
		}
	}, [userAccount]);

	if (!userAccount) {
		return <Loader />;
	}

	const countryList = useMemo(
		() => [
			<MenuItem key={0} value="">
				<em>{t('shared.none')}</em>
			</MenuItem>,
			...getCountryList(),
		],
		[]
	);

	return (
		<>
			{userAccount?.pending_update && (
				<Box p={2} display="flex" justifyContent="flex-end">
					<Button sx={{ mr: 2 }} variant="contained" onClick={handleValidateAccount}>
						{t('user-details.validate-account-update')}
					</Button>
					<Button variant="outlined" onClick={handleOpenRefuseModal}>
						{t('user-details.refuse-account-update')}
					</Button>
				</Box>
			)}
			<Box mb={4}>
				<Typography variant="h1" color="primary">
					{t('user-details.title')} : {userAccount?.owner?.data?.first_name} {userAccount?.owner?.data?.last_name}
				</Typography>
			</Box>

			<Box mb={4}>
				<Typography variant="h2" color="secondary">
					{t('user-details.sub-title')}
				</Typography>
			</Box>

			<Grid container spacing={3} justifyContent="center" alignItems="flex-start">
				<Grid item xs={12} sm={6}>
					<Box mb={3}>
						<Card>
							<CardContent>
								<Typography paragraph variant="h6" color="primary">
									<b>{t('user-details.identification-number')}</b>
								</Typography>
								<UserKeyValueDetail
									editMode={editMode.current}
									label={t('user-details.sap-number')}
									detailKey={'account.edi_reference'}
									onChange={userAccountForm.handleChange}
									error={
										userAccountForm.touched?.account?.edi_reference &&
										userAccountForm.errors?.account?.edi_reference
									}
									onCancel={userAccountForm.setFieldValue}
									detailValue={userAccountForm.values.account.edi_reference}
									editable
								/>
								<Box mt={3}>
									<Typography paragraph variant="h6" color="primary">
										<b>{t('user-details.enterprise')}</b>
									</Typography>

									<Box>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.social-reason')}
											detailKey={'account.name'}
											error={userAccountForm.touched?.account?.name && userAccountForm.errors?.account?.name}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.name}
											pending={userAccount?.pending_update?.account?.name}
										/>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.vat-number')}
											detailKey={'account.vat'}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.vat}
											error={userAccountForm.touched?.account?.vat && userAccountForm.errors?.account?.vat}
											pending={userAccount?.pending_update?.account?.vat}
										/>
										<Grid container>
											<Grid item>
												<IntraCommunityVatEditor
													valueList={userAccountForm.initialValues.account.additional_vat || []}
													label={t('user-details.intra-community-vat')}
													onChange={handleChangeAdditionalVat}
													buttonText={t('register.business.form.add-vat')}
													invalidMessage={t('kore.intra-com-vat-invalid')}
												/>
											</Grid>
											{userAccount?.pending_update?.account?.additional_vat?.length ? (
												<>
													<Grid item alignItems="center">
														<Box display="flex" height="100%" alignItems="center">
															<ArrowForward />
														</Box>
													</Grid>
													<Grid item>
														<Box display="flex" height="100%" alignItems="center">
															<List dense>
																{userAccount?.pending_update?.account?.additional_vat.map((vat) => {
																	return (
																		<ListItem key={vat}>
																			<ListItemText>{vat}</ListItemText>
																		</ListItem>
																	);
																})}
															</List>
														</Box>
													</Grid>
												</>
											) : null}
										</Grid>

										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.company-number')}
											detailKey={'account.companyNumber'}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.companyNumber}
											error={
												userAccountForm.touched?.account?.companyNumber &&
												userAccountForm.errors?.account?.companyNumber
											}
											pending={userAccount?.pending_update?.account?.companyNumber}
										/>

										<FormControl fullWidth sx={{ mb: 3 }}>
											<InputLabel id="country">{t('user-details.country')}</InputLabel>
											<Select
												labelId="country"
												name="account.address.country"
												variant="standard"
												value={userAccountForm.values.account?.address?.country}
												onChange={userAccountForm.handleChange}
											>
												{countryList}
											</Select>
										</FormControl>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.address')}
											detailKey={'account.address.street'}
											error={
												userAccountForm.touched?.account?.address?.street &&
												userAccountForm.errors?.account?.address?.street
											}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.address?.street}
											pending={userAccount?.pending_update?.account?.address?.street}
										/>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.zip-code')}
											detailKey={'account.address.zip'}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											error={
												userAccountForm.touched?.account?.address?.zip &&
												userAccountForm.errors?.account?.address?.zip
											}
											detailValue={userAccountForm.values.account.address?.zip}
											pending={userAccount?.pending_update?.account?.address?.zip}
										/>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.city')}
											detailKey={'account.address.city'}
											error={
												userAccountForm.touched?.account?.address?.city &&
												userAccountForm.errors?.account?.address?.city
											}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.address?.city}
											pending={userAccount?.pending_update?.account?.address?.city}
										/>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('user-details.phone')}
											detailKey={'account.phone'}
											error={
												userAccountForm.touched?.account?.phone && userAccountForm.errors?.account?.phone
											}
											onChange={userAccountForm.handleChange}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account.phone}
											pending={userAccount?.pending_update?.account?.phone}
										/>
									</Box>
								</Box>
							</CardContent>
						</Card>
					</Box>
				</Grid>

				<Grid item container xs={12} sm={6} spacing={3}>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Typography paragraph variant="h6" color="primary">
									{t('user-details.contact-details')}
								</Typography>

								<UserKeyValueDetail
									editMode={editMode.current}
									editable
									label={t('user-details.first-name')}
									detailKey={'account.billing_contact.firstname'}
									error={
										userAccountForm.touched?.account?.billing_contact?.firstname &&
										userAccountForm.errors?.account?.billing_contact?.firstname
									}
									onChange={userAccountForm.handleChange}
									onCancel={userAccountForm.setFieldValue}
									detailValue={userAccountForm.values.account?.billing_contact.firstname}
									pending={userAccount?.pending_update?.account?.billing_contact?.firstname}
								/>
								<UserKeyValueDetail
									editMode={editMode.current}
									editable
									label={t('user-details.last-name')}
									detailKey={'account.billing_contact.lastname'}
									error={
										userAccountForm.touched?.account?.billing_contact?.lastname &&
										userAccountForm.errors?.account?.billing_contact?.lastname
									}
									onChange={userAccountForm.handleChange}
									onCancel={userAccountForm.setFieldValue}
									detailValue={userAccountForm.values.account?.billing_contact.lastname}
									pending={userAccount?.pending_update?.account?.billing_contact?.lastname}
								/>
								<UserKeyValueDetail
									editMode={editMode.current}
									label={t('user-details.email')}
									detailKey={'account.billing_contact.mail'}
									detailValue={userAccountForm.values.account?.billing_contact?.mail}
									pending={userAccount?.pending_update?.account?.billing_contact?.mail}
								/>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Typography paragraph variant="h6" color="primary">
									<Box component="b" mb={3}>
										{t('user-details.event')} :
									</Box>
								</Typography>

								<Box component="ul" pl={2}>
									{events?.map((event) => (
										<Box key={event.id} display="flex" component="li" justifyContent="space-between">
											<Typography>• {event.title[currentLanguage]}</Typography>

											<IconButton onClick={() => deleteEvent(event.id)} size="small">
												<DeleteIcon color="primary" />
											</IconButton>
										</Box>
									))}
								</Box>

								<Button
									variant="outlined"
									startIcon={<AddIcon fontSize="small" />}
									color="primary"
									onClick={() => setShowModal(true)}
								>
									{t('user-details.add-event')}
								</Button>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<CardContent>
								<Typography paragraph variant="h6" color="primary">
									<b>{t('user-details.iban')} :</b>
								</Typography>

								<Grid container spacing={2} sx={{ mb: 3 }}>
									<Grid item xs={12}>
										{!editRib ? (
											<Box display="flex" alignItems="center">
												<UserKeyValueDetail
													editMode={editMode.current}
													label={t('IBAN')}
													detailKey={'account.bank_account.iban'}
													onCancel={userAccountForm.setFieldValue}
													detailValue={userAccountForm.values.account?.bank_account?.iban}
													onChange={userAccountForm.handleChange}
													error={
														userAccountForm.touched?.account?.bank_account?.iban &&
														userAccountForm.errors?.account?.bank_account?.iban
													}
													pending={userAccount?.pending_update?.account?.bank_account?.iban}
												/>
												<IconButton sx={{ ml: 2, mb: 2 }} onClick={() => setEditRib(true)} size="small">
													<Edit />
												</IconButton>
											</Box>
										) : (
											<Box display="flex" alignItems="center">
												<IbanField
													label="IBAN"
													InputProps={{ disableUnderline: true }}
													name="account.bank_account.iban"
													error={
														userAccountForm.touched?.account?.bank_account?.iban &&
														!!userAccountForm.errors?.account?.bank_account?.iban
													}
													value={userAccountForm.values.account?.bank_account?.iban}
													onChange={userAccountForm.handleChange}
													onBlur={userAccountForm.handleBlur}
													helperText={
														userAccountForm.touched?.account?.bank_account?.iban &&
														userAccountForm.errors?.account?.bank_account?.iban
													}
												/>
												<IconButton sx={{ ml: 2 }} onClick={handleCancelRibUpdate} size="small">
													<Cancel />
												</IconButton>
											</Box>
										)}
									</Grid>
									<Grid item xs={12}>
										<UserKeyValueDetail
											editMode={editMode.current}
											editable
											label={t('Swift')}
											detailKey={'account.bank_account.swift'}
											onCancel={userAccountForm.setFieldValue}
											detailValue={userAccountForm.values.account?.bank_account?.swift}
											onChange={userAccountForm.handleChange}
											error={
												userAccountForm.touched?.account?.bank_account?.swift &&
												userAccountForm.errors?.account?.bank_account?.swift
											}
											pending={userAccount?.pending_update?.account?.bank_account?.swift}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={3}>
									<Grid item>
										{userAccountForm.values.account.bank_account?.rib && !uploadAgainRib ? (
											<>
												<Button
													component={MuiLink}
													href={getMediaUrl(userAccountForm.values.account.bank_account?.rib)}
													download
													target="_blank"
													rel="noreferrer"
													startIcon={<DownloadIcon />}
													color="primary"
													variant="contained"
												>
													{t('user-details.download-iban')}
												</Button>

												<IconButton onClick={() => setUploadAgainRib(true)}>
													<Edit />
												</IconButton>
											</>
										) : (
											<>
												<Typography paragraph color="primary">
													{t('user-details.iban-info')}
												</Typography>

												<FileInput
													name="file"
													variant="contained"
													color="primary"
													label={t('user-details.upload-iban')}
													onChange={uploadRib}
												/>
											</>
										)}
									</Grid>
									{userAccount?.pending_update?.account?.bank_account?.rib ? (
										<>
											<Grid item>
												<ArrowForward />
											</Grid>
											<Grid item>
												<Typography> {t('user-details.new-rib')}</Typography>
												<Button
													sx={{ mt: 2 }}
													component={MuiLink}
													href={getMediaUrl(userAccount?.pending_update?.account?.bank_account?.rib)}
													download
													target="_blank"
													rel="noreferrer"
													startIcon={<DownloadIcon />}
													color="primary"
													variant="contained"
												>
													{t('user-details.download-iban')}
												</Button>
											</Grid>
										</>
									) : null}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>

			<Box display="flex" justifyContent="space-between" my={4}>
				<Button onClick={() => navigate(-1)} startIcon={<ArrowRightIcon fontSize="small" />} color="primary">
					{t('shared.back')}
				</Button>
				<Box display="flex" justifyContent="space-between">
					<Button
						variant="contained"
						startIcon={<SaveIcon fontSize="small" />}
						color="primary"
						onClick={handleSave}
					>
						{t('shared.save')}
					</Button>
					{status === 'new' && (
						<Box ml={3}>
							<Button
								onClick={onValidate}
								variant="outlined"
								startIcon={<ArrowRightIcon fontSize="small" />}
								color="primary"
								disabled={!userAccount.edi_reference}
							>
								{t('shared.validate')}
							</Button>
						</Box>
					)}
				</Box>
			</Box>

			{eventsData && (
				<ChampionshipsFormModal
					showModal={showModal}
					setShowModal={setShowModal}
					events={eventsData.paginatedStores.stores}
					onSubmit={handleUpdateUserEvents}
					eventsIds={events?.map((value) => value.id)}
				/>
			)}
			<Dialog
				open={validationRefuseModalOpen}
				onClose={handleCloseRefuseModal}
				content={
					<Box sx={{ minWidth: 400 }}>
						<TextField
							fullWidth
							label={t('user-details.modal.refuse-account-update-placeholder')}
							variant="standard"
							multiline
							onChange={handleReasonUpdate}
							rows={2}
							maxRows={4}
							placeholder={t('user-details.modal.refuse-account-update-placeholder')}
						/>
					</Box>
				}
				actionsContent={
					<>
						<Button variant="outlined" onClick={handleCloseRefuseModal}>
							{t('shared.cancel')}
						</Button>
						<Button variant="contained" onClick={handleRefuseAccountUpdate}>
							{t('user-details.modal.refuse-account-update-refuse')}
						</Button>
					</>
				}
				title={t('user-details.refuse-account-update')}
			/>
		</>
	);
};

export default UserDetails;
