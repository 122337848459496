import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPropsType } from '@components/shared/modal/types/modal-props.type';
import { ArrowRight as ArrowRightIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { DialogWithIcon } from '@tmds-io/kore';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDeleteStoreMutation } from '../../../../generated/graphql';

const EventDeleteConfirmationModal: FC<ModalPropsType<boolean> & { eventName: string; trackId: string }> = ({
	onClose,
	open,
	eventName,
	trackId,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const [deleteTrack, { data, error }] = useDeleteStoreMutation();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		if (data) {
			enqueueSnackbar(t('event-delete-modal.delete-success'), { variant: 'success' });
		}
		if (error) {
			enqueueSnackbar(t('event-delete-modal.delete-error'), { variant: 'error' });
		}
		if (error || data) {
			onClose(true);
		}
	}, [error, data]);

	return (
		<DialogWithIcon
			icon={<DeleteIcon fontSize="large" color="primary" />}
			title={t('register.bank-details.popup.title')}
			text={`${t('event.delete-modal-body')} ${eventName}?`}
			open={open}
			onClose={() => onClose(false)}
			actionsContent={
				<>
					<Button
						variant="outlined"
						startIcon={<ArrowRightIcon fontSize="small" />}
						color="primary"
						onClick={() => onClose(false)}
					>
						{t('shared.cancel')}
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							deleteTrack({ variables: { store_id: trackId } });
						}}
					>
						{t('shared.remove')}
					</Button>
				</>
			}
		/>
	);
};

export default EventDeleteConfirmationModal;
