import { useDeleteGroupInventoryMutation } from '../../../../generated/graphql';
import { UseGroupEditionDeleteInventory } from './useGroupEdition.type';
import { useMemo } from 'react';

export const useGroupEditionDeleteInventory = (): UseGroupEditionDeleteInventory => {
	const [deleteInventory, { data, error, loading }] = useDeleteGroupInventoryMutation();

	const handleDeleteInventory = async (inventoryId: string): Promise<boolean | undefined> => {
		const result = await deleteInventory({
			variables: {
				id: inventoryId,
			},
		});

		return !!result?.data?.deleteInventory;
	};

	return useMemo(
		() => [
			handleDeleteInventory,
			{
				data: !!data?.deleteInventory,
				error: !!error,
				loading,
			},
		],
		[data, error, loading]
	);
};
