import React, { ChangeEvent, FC, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useTrackProductsStyle } from '../styles/track-products.style';
import CatalogProductsTemplate from '@components/seller/event/partials/catalog-products-template';
import OrderedProductsTemplate from '@components/seller/event/partials/ordered-products-template';
import { RaceProductsPropsType } from '@components/seller/event/types/race-products-props.type';
import { Box, Card, FormGroup, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { ProductTypeTabFilter } from '@components/shared/product-type-tab-filter';

const RaceProductsPage: FC<RaceProductsPropsType> = ({
	onPriceChange,
	products,
	onFilterChange,
	title,
	isCatalog,
	onSearchSubmit,
	onProductRemove,
	onProductAdd,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const classes = useTrackProductsStyle();
	const search = useRef<string | undefined>();

	const Template = isCatalog ? CatalogProductsTemplate : OrderedProductsTemplate;
	const templateProps = !isCatalog ? { onPriceChange, onClick: onProductRemove } : { onClick: onProductAdd };

	const handleSearchUpdate = (evt: ChangeEvent<HTMLInputElement>): void => {
		if (evt.target.value === '' && evt.target.value !== search.current) {
			onSearchSubmit('');
		}
		search.current = evt.target.value;
	};

	return (
		<Box mb={4}>
			<Box mb={2}>
				<Typography variant="h3" color="primary">
					{t(title)}
				</Typography>
			</Box>

			<FormGroup sx={{ mb: 4 }}>
				<TextField
					placeholder={t('shared.search')}
					name="search"
					onChange={handleSearchUpdate}
					onKeyUp={(evt) => evt.code === 'Enter' && onSearchSubmit(search.current)}
					InputProps={{
						disableUnderline: true,
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => onSearchSubmit(search.current)}>
									<SearchIcon color="primary" />
								</IconButton>
							</InputAdornment>
						),
						color: 'secondary',
					}}
				/>
			</FormGroup>

			<ProductTypeTabFilter onSelect={onFilterChange} />

			<Card square className={classes.productsHolder}>
				{products.map((product, index) => (
					<Template key={index} product={product} {...templateProps} />
				))}
			</Card>
		</Box>
	);
};

export default RaceProductsPage;
