// react
import React, { FC } from 'react';
import { Tabs, Tab, AppBar } from '@mui/material';
import {
	Apps as AppsIcon,
	LocalGasStation as LocalGasStationIcon,
	MoreHoriz as MoreHorizIcon,
	Opacity as OpacityIcon,
} from '@mui/icons-material';
import { ProductType } from '../../../../types/product.types';
import { GroupProductType } from '@core/hooks/group/group.type';

// Components properties
interface ProductTabsProps {
	productTypeList: GroupProductType[];
	value: number;
	onChange: (id: string) => void;
}

const getIcon = (name: string) => {
	switch (name.trim()) {
		case ProductType.ALL:
			return <AppsIcon />;
		case ProductType.FUEL:
			return <LocalGasStationIcon />;
		case ProductType.LUBRICANT:
			return <OpacityIcon />;
		case ProductType.OTHER:
			return <MoreHorizIcon />;
		default:
			return <MoreHorizIcon />;
	}
};

/**
 * ProductTabs components -
 */
export const ProductTabs: FC<ProductTabsProps> = ({ productTypeList, value, onChange }) => {
	const handleClick = (id: string) => () => {
		onChange(id);
	};

	return (
		<AppBar position="static" color="transparent">
			<Tabs variant="fullWidth" value={value}>
				{productTypeList
					.sort((a, b) => (a.name < b.name ? -1 : 0))
					.map(({ id, name }, index) => (
						<Tab key={id + index} icon={getIcon(name)} onClick={handleClick(id)} color="primary" />
					))}
			</Tabs>
		</AppBar>
	);
};
