import React, { FC, FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter, useLocation, matchPath, generatePath } from 'react-router-dom';
import { useUserContext } from '@tmds-io/kore';
import {
	getCustomerRoutes,
	getCustomerAdminRoutes,
	PublicRoutes,
	ROUTES_URI,
	getSellerRoutes,
	getSellerAdminRoutes,
} from './routes';
import Header from '../../shared/common/header/header';
import { useTranslation } from 'react-i18next';
import { Box, Container, styled, Typography } from '@mui/material';
import { getLng } from '../../../i18n';
import { CartProvider } from '@core/services/cart/cart.context';
import { useTealiumPageView } from '@core/tealium';
import { Helmet } from 'react-helmet-async';
import { Footer } from '@components/shared/Footer';
import { useDebugContext } from '@core/debug/debugContext';
import { useUserHelper } from '@core/hooks/useUserHelper';

const RedirectAlReadyLogged: FC = ({ children }) => {
	const { id } = useUserContext();

	useLayoutEffect(() => {
		if (id) {
			window.location.href = window.location.protocol + '//' + window.location.host;
		}
	}, [id]);

	return <>{children}</>;
};

export const PublicRouter: FunctionComponent = () => {
	getLng();

	return (
		<BrowserRouter>
			<RedirectAlReadyLogged>
				<Container>
					<Routes>
						{PublicRoutes.map((menu, k) => {
							const Component = menu.component;
							return <Route key={k} path={menu?.path} element={<Component />} />;
						})}
					</Routes>
				</Container>
			</RedirectAlReadyLogged>
		</BrowserRouter>
	);
};

const BASE_TITLE = 'TEAFS';

export const HeadInfo = ({ isSeller }: { isSeller: boolean }): JSX.Element => {
	const [title, setTitle] = useState<string>(BASE_TITLE);
	const location = useLocation();
	const { debug } = useDebugContext();
	const { isReseller } = useUserHelper();
	const sellerAdminRoutes = getSellerAdminRoutes(debug);
	const customerAdminRoutes = getCustomerAdminRoutes(isReseller);

	useEffect(() => {
		const activeRoute = (isSeller ? sellerAdminRoutes : customerAdminRoutes).find(({ path }) =>
			matchPath(path, location.pathname)
		);

		let headerInfo: { title: string } | undefined;
		switch (activeRoute?.path) {
			case ROUTES_URI.HOME:
				headerInfo = { title: 'Home' };
				break;
			case ROUTES_URI.PRODUCTS:
				headerInfo = { title: 'Products' };
				break;
			case ROUTES_URI.PRODUCTS_DETAILS:
				headerInfo = { title: `Product details` };
				break;
			case ROUTES_URI.PRODUCTS_CREATE:
				headerInfo = { title: `Product creation` };
				break;
			case ROUTES_URI.CUSTOMER_SELECTION:
				headerInfo = { title: `Product add restricted users` };
				break;
			case ROUTES_URI.PRODUCTS_EDIT:
				headerInfo = { title: `Product edition` };
				break;
			case ROUTES_URI.EVENT_DETAILS_DEFAULT:
				headerInfo = { title: `Event details` };
				break;
			case ROUTES_URI.EVENT_DETAILS:
				headerInfo = { title: `Event race details` };
				break;
			case ROUTES_URI.EVENTS:
				headerInfo = { title: 'Events' };
				break;
			case ROUTES_URI.EVENT_ADD:
				headerInfo = { title: 'Event creation' };
				break;
			case ROUTES_URI.EVENT_EDIT:
				headerInfo = { title: 'Event edition' };
				break;
			case ROUTES_URI.ORDERS:
				headerInfo = { title: 'Orders' };
				break;
			case ROUTES_URI.ORDERS_DETAILS:
				headerInfo = { title: 'Orders details' };
				break;
			case ROUTES_URI.USERS:
				headerInfo = { title: 'Users' };
				break;
			case ROUTES_URI.USER_DETAILS:
				headerInfo = { title: `Users details` };
				break;
			case ROUTES_URI.PROFILE:
				headerInfo = { title: 'Profile' };
				break;
			case ROUTES_URI.PROFILE_DETAILS:
				headerInfo = { title: 'Profile details' };
				break;
			case ROUTES_URI.PROFILE_PAYMENT:
				headerInfo = { title: 'Profile payment details' };
				break;
			case ROUTES_URI.PROFILE_EDIT:
				headerInfo = { title: 'Profile edition' };
				break;
			case ROUTES_URI.INFOS:
				headerInfo = { title: 'User informations' };
				break;
			case ROUTES_URI.CART:
				headerInfo = { title: 'Cart' };
				break;
			case ROUTES_URI.MEMBERS:
				headerInfo = { title: 'Members' };
				break;
			case generatePath(ROUTES_URI.GROUPS):
				headerInfo = { title: 'Groups' };
				break;
			case ROUTES_URI.GROUP_CREATE:
				headerInfo = { title: 'Groups creation' };
				break;
			case ROUTES_URI.GROUP_EDIT:
				headerInfo = { title: 'Groups edition' };
				break;
			case ROUTES_URI.GROUP_DETAILS:
				headerInfo = { title: 'Groups details' };
				break;
			case ROUTES_URI.PAYMENT:
				headerInfo = { title: 'Payment' };
				break;
			case ROUTES_URI.CALLBACK_PAYMENT_SUCCESS:
				headerInfo = { title: 'Payment success' };
				break;
			case ROUTES_URI.CALLBACK_PAYMENT_FAILURE:
				headerInfo = { title: 'Payment fail' };
				break;
			case ROUTES_URI.TRACK_ADD:
				headerInfo = { title: 'Event add race' };
				break;
			case ROUTES_URI.TRACK_EDIT:
				headerInfo = { title: 'Event edit race' };
				break;
			default:
				break;
		}
		setTitle(headerInfo ? `${headerInfo.title} - ${BASE_TITLE}` : BASE_TITLE);
	}, [location.pathname]);

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>
		</>
	);
};

const MainContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	paddingTop: 118,
	paddingBottom: 5,
	minHeight: 'calc(100% - 80px)',
	[theme.breakpoints.down('sm')]: {
		minHeight: 'calc(100% - 120px)',
	},
}));

export const PrivateRouter: FunctionComponent = () => {
	const { t } = useTranslation('acs', { useSuspense: true });
	const { debug } = useDebugContext();
	const { isSeller, isAdmin, isReseller } = useUserHelper();
	const isSellerAdmin = isAdmin && isSeller;
	const isCustomerAdmin = !isSeller && isAdmin;
	const sellerRoutes = getSellerRoutes(debug);
	const sellerAdminRoutes = getSellerAdminRoutes(debug);
	const customerRoutes = getCustomerRoutes(isReseller);
	const customerAdminRoutes = getCustomerAdminRoutes(isReseller);

	useTealiumPageView();

	return (
		<CartProvider disabled={isSeller}>
			<HeadInfo isSeller={isSeller} />
			<Header
				items={
					isSellerAdmin
						? sellerAdminRoutes
						: isSeller
						? sellerRoutes
						: isCustomerAdmin
						? customerAdminRoutes
						: customerRoutes
				}
				isSeller={isSeller}
			/>
			<MainContainer>
				<Container sx={{ pt: 4 }}>
					<Routes>
						{(isSellerAdmin
							? sellerAdminRoutes
							: isSeller
							? sellerRoutes
							: isCustomerAdmin
							? customerAdminRoutes
							: customerRoutes
						).map((menu, k) => {
							const Component = menu.component as React.ElementType;
							return <Route key={k} path={menu?.path} element={<Component />} />;
						})}
						<Route
							path="/"
							element={
								<Navigate to={isSeller ? generatePath(ROUTES_URI.USERS) : generatePath(ROUTES_URI.PRODUCTS)} />
							}
						/>
						<Route
							path="*"
							element={
								<Box display="flex" justifyContent="center" alignItems="center" p={5}>
									<Typography>{t('page-not-found')}</Typography>
								</Box>
							}
						/>
					</Routes>
				</Container>
			</MainContainer>
			<Container>
				<Footer />
			</Container>
		</CartProvider>
	);
};
