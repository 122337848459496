import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useProductEditionStyle = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			minWidth: 240,
			marginBottom: theme.spacing(3),
		},
	})
);
