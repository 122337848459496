// react
import React, { FC } from 'react';
import { ActionsTab, FILTER_COMPARISON } from '@tmds-io/kore';
import {
	formatActionList,
	formatCategoryToProductType,
} from '@components/shared/product-type-tab-filter/product-type-tab-filter.core';
import { useTranslation } from 'react-i18next';
import { useGetProductTypeQuery } from '../../../generated/graphql';

export const ProductTypeTabFilter: FC<{
	onSelect: (id: string) => void;
}> = ({ onSelect }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	// hooks
	const { data, loading } = useGetProductTypeQuery({
		variables: { filter: [{ comparison: FILTER_COMPARISON.LIKE, key: 'name', value: 'productType' }] },
	});
	const productTypeList = (!loading && formatCategoryToProductType(data)) || [];

	const actions = formatActionList(onSelect, t, productTypeList);
	return <ActionsTab actions={actions} />;
};
