import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import EventForm from '@components/seller/event/partials/event-form';
import { generatePath, Navigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ROUTES_URI } from '../../app/router/routes';
import { useAddEventMutation } from '../../../generated/graphql';
import { EventFormValues } from '@components/seller/event/types/event-form-props.type';
import { v4 as uuidv4 } from 'uuid';

const EventCreate: FunctionComponent = () => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const [addEvent, { data }] = useAddEventMutation();

	if (data) {
		return <Navigate to={generatePath(ROUTES_URI.TRACK_ADD, { id: data?.createStore.id })} />;
	}

	const onSubmit = (values: EventFormValues) => {
		void addEvent({
			variables: {
				data: {
					code: uuidv4(),
					name: values.eventName,
					title: {
						en: values.eventName,
						fr: values.eventNameFR || values.eventName,
					},
					hidden: values.hidden,
					business_unit: 'races',
					status: 'active',
					data: {
						mandatory_rib: values.mandatoryRib,
					},
				},
			},
		});
	};

	return (
		<>
			<Box mb={4}>
				<Typography variant="h1" color="primary">
					{t('event.create-title')}
				</Typography>
			</Box>

			<EventForm type="new" onSubmit={onSubmit} />
		</>
	);
};

export default EventCreate;
