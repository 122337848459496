// react
import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { Loader } from '@components/shared/loader';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';

// AsyncContent component properties
interface AsyncContentProps<T> {
	/** Loading status */
	loading: boolean;
	/** Error message */
	error?: ApolloError;
	/** Async data */
	data?: T;
	/** children props */
	children: (data: T) => ReactElement | null;
}

/**
 * AsyncContent component, handle async content fetch with apollo-client
 */
export const AsyncContent = <T,>({ loading, error, data, children }: AsyncContentProps<T>): ReactElement | null => {
	const { t } = useTranslation('acs');

	return error ? (
		<Box height="100%" display="flex" justifyContent="center" alignItems="center" p={3}>
			<Typography color="primary">{t('error.cannot-fetch-data')}</Typography>
		</Box>
	) : loading ? (
		<Box height="100%" display="flex" justifyContent="center" alignItems="center" p={3}>
			<Loader />
		</Box>
	) : data ? (
		children(data)
	) : null;
};
