export const uploadMedia = async (file: File, name?: string): Promise<{ key: string } | undefined> => {
	try {
		const formData = new FormData();
		formData.append('file', file);
		if (name) {
			formData.append('name', name);
		}
		const result = await fetch(`${window._env_.API_MEDIA}/upload`, {
			method: 'POST',
			body: formData,
			credentials: 'include',
		});
		if (result.ok) {
			return await result.json();
		}
		throw Error(`Network error: ${result.status} - ${result.statusText}`);
	} catch (error) {
		console.error('Upload file fail', error);
		return undefined;
	}
};

export const getMediaUrl = (value: string): string => {
	const [, token] = value.split(',');
	return `${window._env_.API_MEDIA}/download?token=${token}`;
};
