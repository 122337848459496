// React
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
// Utils
import { useTranslation } from 'react-i18next';
// Types
import { RegisterModalPropsType } from '../registerModal.type';
// Kore
import { DialogWithIcon } from '@tmds-io/kore';
// Mui
import { ArrowRight as ArrowRightIcon, Group as GroupIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

const AskAddUserModal: FunctionComponent<
	RegisterModalPropsType & {
		setShowNoUserAddModal: Dispatch<SetStateAction<boolean>>;
		setShowAddUsersModal: Dispatch<SetStateAction<boolean>>;
	}
> = ({ setShowModal, showModal, setShowNoUserAddModal, setShowAddUsersModal }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const handleClose = () => setShowModal(false);
	return (
		<DialogWithIcon
			icon={<GroupIcon fontSize="large" color="primary" />}
			title={t('register.ask-add-user.popup.title')}
			text={t('register.ask-add-user.popup.body')}
			open={showModal}
			onClose={handleClose}
			actionsContent={
				<>
					<Button
						color="primary"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => {
							handleClose();
							setShowNoUserAddModal(true);
						}}
					>
						{t('shared.no')}
					</Button>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => {
							handleClose();
							setShowAddUsersModal(true);
						}}
					>
						{t('register.ask-add-user.popup.footer-action')}
					</Button>
				</>
			}
		/>
	);
};

export default AskAddUserModal;
