// react
import React, { FC, useMemo } from 'react';
import Details from './details';
import { useParams } from 'react-router-dom';
import {
	AccountInput,
	useGetAccountOwnerQuery,
	useGetSellerCustomerEventsQuery,
	useUpdateAccountMutation,
	useUpdateCustomerEventsMutation,
	useValidateCustomerAccountMutation,
} from '../../../generated/graphql';
import { Loader } from '@components/shared/loader';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from '@mui/material';

const UserDetails: FC = () => {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const requestCallback = useMemo(
		() => ({
			onCompleted: () =>
				enqueueSnackbar(t('user-details.success-update'), {
					variant: 'success',
				}),
			onError: () => {
				enqueueSnackbar(t('user-details.error-update'), {
					variant: 'error',
				});
			},
		}),
		[]
	);
	const { id } = useParams();
	const { data, loading } = useGetAccountOwnerQuery({ variables: { id }, fetchPolicy: 'no-cache' });
	const { data: customerContract, loading: loadingContract } = useGetSellerCustomerEventsQuery({
		fetchPolicy: 'no-cache',
		variables: { id },
	});
	const [validate, { data: accountValidatedData }] = useValidateCustomerAccountMutation(requestCallback);
	const [updateUserAccount, { data: accountUpdatedData }] = useUpdateAccountMutation(requestCallback);
	const [updateCustomerEvents] = useUpdateCustomerEventsMutation({
		onError: () =>
			enqueueSnackbar(t('user-details.error-events'), {
				variant: 'error',
			}),
	});

	const account = accountUpdatedData?.updateAccount || data?.account;

	if (account) {
		account.bank_account = {
			iban: account.bank_account.iban || '',
			rib: account.bank_account.rib || '',
			swift: account.bank_account.swift || '',
		};
	}
	const pendingValidation = data?.account?.pending_update?.account;

	const status =
		account?.status === 'validated' || accountValidatedData?.validateCustomerAccount.status === 'validated'
			? 'validated'
			: 'new';

	if (loading || loadingContract) {
		return <Loader />;
	}

	const handleUpdateCustomerContract = (storeIds: string[]) => {
		updateCustomerEvents({
			variables: {
				storeIds: storeIds,
				customerId: id,
			},
		});
	};

	const handleUpdateUserAccount = (values: Partial<AccountInput>) => {
		const { edi_reference, name, address, vat, companyNumber, phone, bank_account, billing_contact, additional_vat } =
			values;
		updateUserAccount({
			variables: {
				id,
				params: {
					edi_reference,
					name,
					address: {
						city: address.city,
						country: address.country,
						street: address.street,
						zip: address.zip,
					},
					vat,
					additional_vat,
					companyNumber,
					phone,
					billing_contact: {
						firstname: billing_contact.firstname,
						lastname: billing_contact.lastname,
					},
					bank_account: {
						rib: bank_account.rib || '',
						iban: bank_account.iban || '',
						swift: bank_account.swift || '',
					},
				},
				paths: [
					'edi_reference',
					'name',
					'address',
					'vat',
					'additional_vat',
					'companyNumber',
					'phone',
					'bank_account',
					'billing_contact',
				],
			},
		});
	};

	const handleUserValidation = () => {
		validate({
			variables: { id },
		});
	};

	return (
		<Box>
			{pendingValidation ? <Alert severity="warning">{t('customer.user-profile.pending-validation')} </Alert> : null}
			<Details
				status={status}
				userAccount={account}
				customerContract={customerContract?.customerContract?.stores || []}
				onValidate={handleUserValidation}
				onSubmitContractUpdate={handleUpdateCustomerContract}
				onSubmitUserAccountUpdate={handleUpdateUserAccount}
				pendingValidation={!!pendingValidation}
			/>
		</Box>
	);
};

export default UserDetails;
