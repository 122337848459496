import { StoreProductVariationsPriceFieldsFragment } from '../../../../generated/graphql';

export const formatEventProduct = (
	data: StoreProductVariationsPriceFieldsFragment,
	inventories: { id: string; product_sku: string }[]
): (StoreProductVariationsPriceFieldsFragment & { inventoryId: string }) | null => {
	const inventory = inventories.find(({ product_sku }) => product_sku === data.variation.sku);
	if (inventory) {
		return { ...data, inventoryId: inventory.id };
	}
	return null;
};
