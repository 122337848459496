import { useMemo } from 'react';
import { Group, UseGroupEditionUpdateStore } from './useGroupEdition.type';
import { useUpdateGroupStoreMutation } from '../../../../generated/graphql';
import { formatStoreForGroupEdition } from './groupEdition.core';

import { useCurrentLanguage } from '@core/utils/language.utils';
import { GroupProduct } from '@core/hooks/group/group.type';

export const useGroupEditionUpdateStore = (): UseGroupEditionUpdateStore => {
	const [updateStore, { data, error, loading }] = useUpdateGroupStoreMutation();
	const currentLanguage = useCurrentLanguage();

	const handleUpdateStore = async (
		{ id, title, sapPriceList, entryScore, referringCustomer }: Group,
		productList: GroupProduct[],
		defaultProductList: string[]
	): Promise<Group | undefined> => {
		const result = await updateStore({
			variables: {
				store_id: id,
				data: {
					title,
					code: title.en,
					hidden: false,
					name: title.en,
					data: {
						entry_score: entryScore,
						sap_price_list: sapPriceList,
						default_products_sku: defaultProductList,
						referring_customer: referringCustomer,
					},
				},
				paths: [
					'title.en',
					'title.fr',
					'data.entry_score',
					'data.sap_price_list',
					'data.default_products_sku',
					'data.referring_customer',
				],
			},
		});
		if (result.data?.updateStore) {
			return formatStoreForGroupEdition(result.data.updateStore, currentLanguage);
		}

		return;
	};

	return useMemo(
		() => [
			handleUpdateStore,
			{
				data: data?.updateStore ? formatStoreForGroupEdition(data.updateStore, currentLanguage) : undefined,
				error: !!error,
				loading,
			},
		],
		[data, error, loading]
	);
};
