import { OrderFieldsFragment } from '../../../generated/graphql';
import { getMediaUrl } from '@core/services/media';
import { OrderProductsTableProps } from '@components/shared/order/types/order-product-table.type';
import { Order } from '@components/shared/order/types/orders.type';
import { useCurrentLanguage } from '@core/utils/language.utils';

export const formatOrder = (order: OrderFieldsFragment): OrderProductsTableProps => {
	const currentLanguage = useCurrentLanguage();

	return {
		profile: order.customer.owner.profile_data,
		championshipName: order.parent_store_title[currentLanguage],
		raceName: order.order_lines[0].store_title[currentLanguage],
		orderReference: order.reference,
		orderEdiRef: order.edi_reference,
		paymentStatus: order.status,
		customerEdiRef: order.customer_edi_reference,
		orderId: order.id,
		orderProducts: order.order_lines.map((line) => ({
			orderId: order.id,
			sku: line.sku,
			name: line.title[currentLanguage],
			quantity: +line.quantity_ordered.toFixed(2),
			chargedQuantity: line.quantity_shipped,
			invoiceQuantity: line.quantity_invoiced,
			price: line.price,
			total: line.row_total,
			image: line.image && getMediaUrl(line.image),
			paymentStatus: line.status,
			invoiceIdList: line.additional_data?.id_docs || [],
		})),
	};
};

export const formatOrders = (orders: OrderFieldsFragment[], currentLanguage: string): Order[] => {
	return orders.map(
		({
			id,
			reference,
			created_at,
			status,
			store_title,
			parent_store_title,
			edi_reference,
			order_lines,
			customer_company,
			customer_edi_reference,
			total,
			user_first_name,
			user_last_name,
		}) => {
			return {
				id,
				reference,
				ediReference: edi_reference,
				creationDate: created_at,
				event: parent_store_title[currentLanguage],
				race: store_title[currentLanguage],
				companyName: customer_company,
				companyRef: customer_edi_reference,
				status,
				volume: order_lines.reduce((volume, line) => {
					return line.quantity_ordered;
				}, 0),
				total: total,
				firstname: user_first_name,
				lastname: user_last_name,
			};
		}
	);
};
