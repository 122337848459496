// React
import React from 'react';
// Mui
import { Box, Grid, IconButton } from '@mui/material';
// Types
import { Cell } from '@tmds-io/kore';
import { GetProfilesQuery } from '../../generated/graphql';
import { Member } from './members.type';
// Form
import * as Yup from 'yup';
// Components
import { ButtonConfirm } from '@components/ButtonConfirm';
import { Delete, Edit } from '@mui/icons-material';

/**
 * Format member data from api for members page
 * @param data - api data
 * @param t - translate method
 */
export const formatMembers = (data: GetProfilesQuery, t: (val: string) => string): Member[] => {
	return data.profiles.map(({ id, profile_data, membership }, index) => {
		const mbr = membership.find(({ roles }) => roles.length);
		const roles = mbr?.roles;
		return {
			id,
			memberId: mbr.id,
			name: index.toString(),
			firstName: profile_data.first_name,
			lastName: profile_data.last_name,
			email: profile_data.mail,
			role: roles?.length ? t('members.page.' + roles[0].code) : undefined,
			roleCode: roles?.length ? roles[0].code : undefined,
		};
	});
};

/**
 * Get members table head cells configuration
 * @param t - translation function
 * @param onDelete - delete method for remove members
 * @param openWarningModal - open warning modal when user remove is own profile
 * @param onEditRole
 * @param userId
 */
export const getHeadCells = (
	t: (val: string) => string,
	onDelete: (id: string) => void,
	openWarningModal: () => void,
	onEditRole: (data: Member) => void,
	userId: string
): Cell<Member>[] => [
	{
		id: 'lastName',
		numeric: false,
		disablePadding: false,
		label: t('user-details.last-name'),
	},
	{
		id: 'firstName',
		numeric: false,
		disablePadding: false,
		label: t('user-details.first-name'),
	},
	{
		id: 'email',
		numeric: false,
		disablePadding: false,
		label: t('user-details.email'),
	},
	{
		id: 'role',
		numeric: false,
		disablePadding: false,
		label: t('shared.role'),
		endElement: (rowIndex, _, data) => (
			<Box position="relative">
				<Box sx={{ position: 'absolute', right: '-8px', top: 'calc(50% - 8px)', transform: 'translateY(-50%)' }}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<IconButton onClick={() => onEditRole(data[rowIndex])} size="small">
								<Edit color="primary" />
							</IconButton>
						</Grid>
						<Grid item>
							<ButtonConfirm
								onClick={data[rowIndex].id === userId ? openWarningModal : undefined}
								onConfirm={() => onDelete(data[rowIndex].name)}
								buttonGroupSx={{ position: 'absolute', right: 0, top: 0, zIndex: 2 }}
							>
								<IconButton size="small">
									<Delete color="primary" fontSize="small" />
								</IconButton>
							</ButtonConfirm>
						</Grid>
					</Grid>
				</Box>
			</Box>
		),
	},
];

/**
 * Return validation schema with transltion for member add form
 * @param t - translate method
 */
export const getSchema = (t: (value: string) => string): ReturnType<typeof Yup.object> =>
	Yup.object().shape({
		firstName: Yup.string().required(t('form.firstName.required')),
		lastName: Yup.string().required(t('form.lastName.required')),
		role: Yup.string().required(t('form.role.required')),
		email: Yup.string().email(t('form.email.invalid')).required(t('form.email.required')),
	});
