import {
	CustomerAccountFieldsFragment,
	GetGroupsStoresQuery,
	useGetCustomerAccountOwnerLazyQuery,
	useGetGroupsStoresLazyQuery,
} from '../../../generated/graphql';
import { DataHookLazy } from '../../../types/dataHook.type';
import { CustomerData } from '@core/hooks/resellerCustomer/useResellerCustomerSapData';
import { useEffect, useMemo, useState } from 'react';
import { FILTER_COMPARISON } from '@tmds-io/kore';

const formatData = (
	data: CustomerAccountFieldsFragment,
	groups: GetGroupsStoresQuery['paginatedStores']['stores']
): CustomerData => {
	return {
		businessName: data.name,
		businessAddress: {
			city: data.address.city,
			country: data.address.country,
			address: data.address.street,
		},
		deliveryPoints: data.delivery_points.map(({ address, edi_reference, name }) => ({
			name,
			city: address.city,
			address: address.street,
			country: address.country,
			shipTo: edi_reference,
		})),
		payments: {
			accountResponsible: data.data.account_responsible,
			incoterm: data.data.incoterm,
			priceGroup: data.data.priceGroup || groups[0]?.data?.sap_price_list,
			shippingCondition: data.data.shipping_condition,
		},
		group: groups?.length
			? {
					title: groups[0].title,
					id: groups[0].id,
			  }
			: undefined,
	};
};

export const useResellerCustomerData = (): DataHookLazy<CustomerData, string> => {
	const [getCustomerAccount, { data, error }] = useGetCustomerAccountOwnerLazyQuery();
	const [getGroup, { data: groupData, error: groupError }] = useGetGroupsStoresLazyQuery();

	const [localState, setLocalState] = useState({ data: undefined, loading: false, error: false });

	const handleGetCustomerAccount = (id: string) => {
		setLocalState({ data: undefined, loading: true, error: false });
		getCustomerAccount({
			variables: {
				id,
			},
		}).then((result) => {
			void getGroup({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.EQUAL,
							key: '_key',
							value: result.data.account.contracts[0].stores[0],
						},
					],
				},
			});
		});
	};

	useEffect(() => {
		if (data && groupData) {
			setLocalState({
				data: formatData(data.account, groupData.paginatedStores.stores),
				loading: false,
				error: false,
			});
		}
	}, [data, groupData]);

	useEffect(() => {
		if (error || groupError) {
			setLocalState({ data: undefined, loading: false, error: true });
		}
	}, [error, groupError]);

	return useMemo(() => {
		return [handleGetCustomerAccount, localState];
	}, [localState]);
};
