// React
import React, { FC } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import {
	Store,
	useGetSellerEventsQuery,
	useGetStoreChampWithChildrenLazyQuery,
} from '../../../../../generated/graphql';
// Constants
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { ModalEditEvents } from '@components/customer/event/partials/modal-edit-events/modal-edit-events';
import { ModalEditEventsHocPropsType } from '@components/customer/event/partials/modal-edit-events/types/modal-edit-events-props.type';
import { formatEventsForModalEdit } from '@components/customer/event/utils';
// Components

/**
 * Event Hoc fetch data from api and pass it to Events display components
 */
const ModalEditEventsHoc: FC<ModalEditEventsHocPropsType> = ({ open, filter, onClose }) => {
	// Hooks
	const { t } = useTranslation('acs', { useSuspense: false });
	useGetStoreChampWithChildrenLazyQuery();
	const { data, loading, error } = useGetSellerEventsQuery({
		variables: {
			filter: [
				{ comparison: FILTER_COMPARISON.EQUAL, value: 'races', key: 'business_unit' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'parent_id', value: '' },
				{ comparison: FILTER_COMPARISON.EQUAL, key: 'status', value: 'active' },
				{ comparison: FILTER_COMPARISON.NOT_EQUAL, key: 'hidden', value: true },
			],
		},
	});
	const errorMessage: string | undefined = error && t('customer.modal-edit-events.fetch-events-error');

	return (
		<ModalEditEvents
			open={open}
			onClose={onClose}
			events={
				data?.paginatedStores?.stores
					? formatEventsForModalEdit(data.paginatedStores.stores as Store[], filter)
					: []
			}
			error={errorMessage}
			loading={loading}
		/>
	);
};

export default ModalEditEventsHoc;
