import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalPropsType } from '@components/shared/modal/types/modal-props.type';
import { useFormik } from 'formik';
import { Box, Button, FormGroup, InputAdornment, Typography } from '@mui/material';
import { Dialog } from '@tmds-io/kore';
import { TextField } from '@mui/material';
import { Euro } from '@mui/icons-material';

const EventPriceModificationModal: FC<
	ModalPropsType & {
		price: number;
		onSubmit: (price: number) => void;
	}
> = ({ onClose, open, price, onSubmit }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const formik = useFormik({
		initialValues: {
			price: price,
		},
		onSubmit: (values) => {
			onSubmit(+values.price);
			onClose();
		},
	});

	return (
		<Dialog
			title={t('register.bank-details.popup.title')}
			open={open}
			onClose={onClose}
			actionsContent={
				<>
					<Button variant="outlined" type="button" color="primary" onClick={onClose}>
						{t('shared.cancel')}
					</Button>
					<Button
						color="primary"
						variant="contained"
						type="button"
						onClick={() => {
							formik.handleSubmit();
						}}
					>
						{t('shared.validate')}
					</Button>
				</>
			}
			content={
				<form noValidate>
					<Box mb={4} mt={2}>
						<Typography paragraph>{t('event.price-modification-body')}</Typography>
					</Box>

					<FormGroup>
						<TextField
							name="price"
							type="number"
							label={t('event.price-modification-input')}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<InputAdornment position="start">
										<Euro />
									</InputAdornment>
								),
								color: 'secondary',
							}}
							onChange={formik.handleChange}
							value={formik.values.price}
						/>
					</FormGroup>
				</form>
			}
		/>
	);
};

export default EventPriceModificationModal;
