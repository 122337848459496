export const countries = [
	{
		position: 1,
		id: 4,
		code: 'AF',
		cio: 'AFG',
		nameFr: 'Afghanistan',
		nameEn: 'Afghanistan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 2,
		id: 8,
		code: 'AL',
		cio: 'ALB',
		nameFr: 'Albanie',
		nameEn: 'Albania',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 3,
		id: 12,
		code: 'DZ',
		cio: 'DZA',
		nameFr: 'Algérie',
		nameEn: 'Algeria',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 4,
		id: 20,
		code: 'AD',
		cio: 'AND',
		nameFr: 'Andorre',
		nameEn: 'Andorra',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 5,
		id: 24,
		code: 'AO',
		cio: 'AGO',
		nameFr: 'Angola',
		nameEn: 'Angola',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 6,
		id: 28,
		code: 'AG',
		cio: 'ATG',
		nameFr: 'Antigua-et-Barbuda',
		nameEn: 'Antigua and Barbuda',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 7,
		id: 32,
		code: 'AR',
		cio: 'ARG',
		nameFr: 'Argentine',
		nameEn: 'Argentina',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 8,
		id: 51,
		code: 'AM',
		cio: 'ARM',
		nameFr: 'Arménie',
		nameEn: 'Armenia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 9,
		id: 36,
		code: 'AU',
		cio: 'AUS',
		nameFr: 'Australie',
		nameEn: 'Australia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 10,
		id: 40,
		code: 'AT',
		cio: 'AUT',
		nameFr: 'Autriche',
		nameEn: 'Austria',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 11,
		id: 31,
		code: 'AZ',
		cio: 'AZE',
		nameFr: 'Azerbaïdjan',
		nameEn: 'Azerbaijan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 12,
		id: 44,
		code: 'BS',
		cio: 'BHS',
		nameFr: 'Bahamas',
		nameEn: 'Bahamas',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 13,
		id: 48,
		code: 'BH',
		cio: 'BHR',
		nameFr: 'Bahreïn',
		nameEn: 'Bahrain',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 14,
		id: 50,
		code: 'BD',
		cio: 'BGD',
		nameFr: 'Bangladesh',
		nameEn: 'Bangladesh',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 15,
		id: 52,
		code: 'BB',
		cio: 'BRB',
		nameFr: 'Barbade',
		nameEn: 'Barbados',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 16,
		id: 112,
		code: 'BY',
		cio: 'BLR',
		nameFr: 'Biélorussie',
		nameEn: 'Belarus',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 17,
		id: 56,
		code: 'BE',
		cio: 'BEL',
		nameFr: 'Belgique',
		nameEn: 'Belgium',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 18,
		id: 84,
		code: 'BZ',
		cio: 'BLZ',
		nameFr: 'Bélize',
		nameEn: 'Belize',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 19,
		id: 204,
		code: 'BJ',
		cio: 'BEN',
		nameFr: 'Bénin',
		nameEn: 'Benin',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 20,
		id: 64,
		code: 'BT',
		cio: 'BTN',
		nameFr: 'Bhoutan',
		nameEn: 'Bhutan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 21,
		id: 68,
		code: 'BO',
		cio: 'BOL',
		nameFr: 'Bolivie',
		nameEn: 'Bolivia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 22,
		id: 70,
		code: 'BA',
		cio: 'BIH',
		nameFr: 'Bosnie-Herzégovine',
		nameEn: 'Bosnia and Herzegovina',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 23,
		id: 72,
		code: 'BW',
		cio: 'BWA',
		nameFr: 'Botswana',
		nameEn: 'Botswana',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 24,
		id: 76,
		code: 'BR',
		cio: 'BRA',
		nameFr: 'Brésil',
		nameEn: 'Brazil',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 25,
		id: 96,
		code: 'BN',
		cio: 'BRN',
		nameFr: 'Brunéi Darussalam',
		nameEn: 'Brunei Darussalam',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 26,
		id: 100,
		code: 'BG',
		cio: 'BGR',
		nameFr: 'Bulgarie',
		nameEn: 'Bulgaria',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 27,
		id: 854,
		code: 'BF',
		cio: 'BFA',
		nameFr: 'Burkina Faso',
		nameEn: 'Burkina Faso',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 28,
		id: 108,
		code: 'BI',
		cio: 'BDI',
		nameFr: 'Burundi',
		nameEn: 'Burundi',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 29,
		id: 132,
		code: 'CV',
		cio: 'CPV',
		nameFr: 'Cap-Vert',
		nameEn: 'Cabo Verde',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 30,
		id: 116,
		code: 'KH',
		cio: 'KHM',
		nameFr: 'Cambodge',
		nameEn: 'Cambodia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 31,
		id: 120,
		code: 'CM',
		cio: 'CMR',
		nameFr: 'Cameroun',
		nameEn: 'Cameroon',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 32,
		id: 124,
		code: 'CA',
		cio: 'CAN',
		nameFr: 'Canada',
		nameEn: 'Canada',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 33,
		id: 140,
		code: 'CF',
		cio: 'CAF',
		nameFr: 'Centrafrique',
		nameEn: 'Central African Republic',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 34,
		id: 148,
		code: 'TD',
		cio: 'TCD',
		nameFr: 'Tchad',
		nameEn: 'Chad',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 35,
		id: 152,
		code: 'CL',
		cio: 'CHL',
		nameFr: 'Chili',
		nameEn: 'Chile',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 36,
		id: 156,
		code: 'CN',
		cio: 'CHN',
		nameFr: 'Chine',
		nameEn: 'China',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 37,
		id: 170,
		code: 'CO',
		cio: 'COL',
		nameFr: 'Colombie',
		nameEn: 'Colombia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 38,
		id: 174,
		code: 'KM',
		cio: 'COM',
		nameFr: 'Comores',
		nameEn: 'Comoros',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 39,
		id: 180,
		code: 'CD',
		cio: 'COD',
		nameFr: 'Congo (République démocratique du)',
		nameEn: 'Congo (the Democratic Republic of the)',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 40,
		id: 178,
		code: 'CG',
		cio: 'COG',
		nameFr: 'Congo',
		nameEn: 'Congo',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 41,
		id: 184,
		code: 'CK',
		cio: 'COK',
		nameFr: 'Cook (Îles)',
		nameEn: 'Cook Islands',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 42,
		id: 188,
		code: 'CR',
		cio: 'CRI',
		nameFr: 'Costa Rica',
		nameEn: 'Costa Rica',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 43,
		id: 384,
		code: 'CI',
		cio: 'CIV',
		nameFr: "Côte d'Ivoire",
		nameEn: "Côte d'Ivoire",
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 44,
		id: 191,
		code: 'HR',
		cio: 'HRV',
		nameFr: 'Croatie',
		nameEn: 'Croatia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 45,
		id: 800,
		code: 'HK',
		cio: 'HKG',
		nameFr: 'Hong Kong',
		nameEn: 'Hong Kong',
		france: false,
		europe: false,
		overseas: true,
	},
	// {
	// 	position: 45,
	// 	id: 192,
	// 	code: 'CU',
	// 	cio: 'CUB',
	// 	nameFr: 'Cuba',
	// 	nameEn: 'Cuba',
	// 	france: false,
	// 	europe: false,
	// 	overseas: true
	// },
	{
		position: 46,
		id: 196,
		code: 'CY',
		cio: 'CYP',
		nameFr: 'Chypre',
		nameEn: 'Cyprus',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 47,
		id: 203,
		code: 'CZ',
		cio: 'CZE',
		nameFr: 'Tchéquie',
		nameEn: 'Czechia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 48,
		id: 208,
		code: 'DK',
		cio: 'DNK',
		nameFr: 'Danemark',
		nameEn: 'Denmark',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 49,
		id: 262,
		code: 'DJ',
		cio: 'DJI',
		nameFr: 'Djibouti',
		nameEn: 'Djibouti',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 50,
		id: 212,
		code: 'DM',
		cio: 'DMA',
		nameFr: 'Dominique',
		nameEn: 'Dominica',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 51,
		id: 214,
		code: 'DO',
		cio: 'DOM',
		nameFr: 'Dominicaine (République)',
		nameEn: 'Dominican Republic',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 52,
		id: 218,
		code: 'EC',
		cio: 'ECU',
		nameFr: 'Équateur',
		nameEn: 'Ecuador',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 53,
		id: 818,
		code: 'EG',
		cio: 'EGY',
		nameFr: 'Égypte',
		nameEn: 'Egypt',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 54,
		id: 222,
		code: 'SV',
		cio: 'SLV',
		nameFr: 'Salvador',
		nameEn: 'El Salvador',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 55,
		id: 226,
		code: 'GQ',
		cio: 'GNQ',
		nameFr: 'Guinée équatoriale',
		nameEn: 'Equatorial Guinea',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 56,
		id: 232,
		code: 'ER',
		cio: 'ERI',
		nameFr: 'Érythrée',
		nameEn: 'Eritrea',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 57,
		id: 233,
		code: 'EE',
		cio: 'EST',
		nameFr: 'Estonie',
		nameEn: 'Estonia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 58,
		id: 748,
		code: 'SZ',
		cio: 'SWZ',
		nameFr: 'Eswatini',
		nameEn: 'Eswatini',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 59,
		id: 231,
		code: 'ET',
		cio: 'ETH',
		nameFr: 'Éthiopie',
		nameEn: 'Ethiopia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 60,
		id: 242,
		code: 'FJ',
		cio: 'FJI',
		nameFr: 'Fidji',
		nameEn: 'Fiji',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 61,
		id: 246,
		code: 'FI',
		cio: 'FIN',
		nameFr: 'Finlande',
		nameEn: 'Finland',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 62,
		id: 250,
		code: 'FR',
		cio: 'FRA',
		nameFr: 'France',
		nameEn: 'France',
		france: true,
		europe: false,
		overseas: false,
	},
	{
		position: 63,
		id: 266,
		code: 'GA',
		cio: 'GAB',
		nameFr: 'Gabon',
		nameEn: 'Gabon',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 64,
		id: 270,
		code: 'GM',
		cio: 'GMB',
		nameFr: 'Gambie',
		nameEn: 'Gambia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 65,
		id: 268,
		code: 'GE',
		cio: 'GEO',
		nameFr: 'Géorgie',
		nameEn: 'Georgia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 66,
		id: 276,
		code: 'DE',
		cio: 'DEU',
		nameFr: 'Allemagne',
		nameEn: 'Germany',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 67,
		id: 288,
		code: 'GH',
		cio: 'GHA',
		nameFr: 'Ghana',
		nameEn: 'Ghana',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 68,
		id: 300,
		code: 'GR',
		cio: 'GRC',
		nameFr: 'Grèce',
		nameEn: 'Greece',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 69,
		id: 308,
		code: 'GD',
		cio: 'GRD',
		nameFr: 'Grenade',
		nameEn: 'Grenada',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 70,
		id: 320,
		code: 'GT',
		cio: 'GTM',
		nameFr: 'Guatémala',
		nameEn: 'Guatemala',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 71,
		id: 324,
		code: 'GN',
		cio: 'GIN',
		nameFr: 'Guinée',
		nameEn: 'Guinea',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 72,
		id: 624,
		code: 'GW',
		cio: 'GNB',
		nameFr: 'Guinée-Bissao',
		nameEn: 'Guinea-Bissau',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 73,
		id: 328,
		code: 'GY',
		cio: 'GUY',
		nameFr: 'Guyana',
		nameEn: 'Guyana',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 74,
		id: 332,
		code: 'HT',
		cio: 'HTI',
		nameFr: 'Haïti',
		nameEn: 'Haiti',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 75,
		id: 336,
		code: 'VA',
		cio: 'VAT',
		nameFr: 'Vatican',
		nameEn: 'Holy See',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 76,
		id: 340,
		code: 'HN',
		cio: 'HND',
		nameFr: 'Honduras',
		nameEn: 'Honduras',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 77,
		id: 348,
		code: 'HU',
		cio: 'HUN',
		nameFr: 'Hongrie',
		nameEn: 'Hungary',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 78,
		id: 352,
		code: 'IS',
		cio: 'ISL',
		nameFr: 'Islande',
		nameEn: 'Iceland',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 79,
		id: 356,
		code: 'IN',
		cio: 'IND',
		nameFr: 'Inde',
		nameEn: 'India',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 80,
		id: 360,
		code: 'ID',
		cio: 'IDN',
		nameFr: 'Indonésie',
		nameEn: 'Indonesia',
		france: false,
		europe: false,
		overseas: true,
	},
	// {
	// 	position: 81,
	// 	id: 364,
	// 	code: 'IR',
	// 	cio: 'IRN',
	// 	nameFr: 'Iran',
	// 	nameEn: 'Iran (Islamic Republic of)',
	// 	france: false,
	// 	europe: false,
	// 	overseas: true
	// },
	{
		position: 82,
		id: 368,
		code: 'IQ',
		cio: 'IRQ',
		nameFr: 'Irak',
		nameEn: 'Iraq',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 83,
		id: 372,
		code: 'IE',
		cio: 'IRL',
		nameFr: 'Irlande',
		nameEn: 'Ireland',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 84,
		id: 376,
		code: 'IL',
		cio: 'ISR',
		nameFr: 'Israël',
		nameEn: 'Israel',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 85,
		id: 380,
		code: 'IT',
		cio: 'ITA',
		nameFr: 'Italie',
		nameEn: 'Italy',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 86,
		id: 388,
		code: 'JM',
		cio: 'JAM',
		nameFr: 'Jamaïque',
		nameEn: 'Jamaica',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 87,
		id: 392,
		code: 'JP',
		cio: 'JPN',
		nameFr: 'Japon',
		nameEn: 'Japan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 88,
		id: 400,
		code: 'JO',
		cio: 'JOR',
		nameFr: 'Jordanie',
		nameEn: 'Jordan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 89,
		id: 398,
		code: 'KZ',
		cio: 'KAZ',
		nameFr: 'Kazakhstan',
		nameEn: 'Kazakhstan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 90,
		id: 404,
		code: 'KE',
		cio: 'KEN',
		nameFr: 'Kénya',
		nameEn: 'Kenya',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 91,
		id: 296,
		code: 'KI',
		cio: 'KIR',
		nameFr: 'Kiribati',
		nameEn: 'Kiribati',
		france: false,
		europe: false,
		overseas: true,
	},
	// {
	// 	position: 92,
	// 	id: 408,
	// 	code: 'KP',
	// 	cio: 'PRK',
	// 	nameFr: 'Corée du Nord',
	// 	nameEn: 'Korea (the Democratic People\'s Republic of)',
	// 	france: false,
	// 	europe: false,
	// 	overseas: true
	// },
	{
		position: 93,
		id: 410,
		code: 'KR',
		cio: 'KOR',
		nameFr: 'Corée du Sud',
		nameEn: 'Korea (the Republic of)',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 94,
		id: 414,
		code: 'KW',
		cio: 'KWT',
		nameFr: 'Koweït',
		nameEn: 'Kuwait',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 95,
		id: 417,
		code: 'KG',
		cio: 'KGZ',
		nameFr: 'Kirghizstan',
		nameEn: 'Kyrgyzstan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 96,
		id: 418,
		code: 'LA',
		cio: 'LAO',
		nameFr: 'Laos',
		nameEn: "Lao People's Democratic Republic",
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 97,
		id: 428,
		code: 'LV',
		cio: 'LVA',
		nameFr: 'Lettonie',
		nameEn: 'Latvia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 98,
		id: 422,
		code: 'LB',
		cio: 'LBN',
		nameFr: 'Liban',
		nameEn: 'Lebanon',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 99,
		id: 426,
		code: 'LS',
		cio: 'LSO',
		nameFr: 'Lésotho',
		nameEn: 'Lesotho',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 100,
		id: 430,
		code: 'LR',
		cio: 'LBR',
		nameFr: 'Libéria',
		nameEn: 'Liberia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 101,
		id: 434,
		code: 'LY',
		cio: 'LBY',
		nameFr: 'Libye',
		nameEn: 'Libya',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 102,
		id: 438,
		code: 'LI',
		cio: 'LIE',
		nameFr: 'Liechtenstein',
		nameEn: 'Liechtenstein',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 103,
		id: 440,
		code: 'LT',
		cio: 'LTU',
		nameFr: 'Lituanie',
		nameEn: 'Lithuania',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 104,
		id: 442,
		code: 'LU',
		cio: 'LUX',
		nameFr: 'Luxembourg',
		nameEn: 'Luxembourg',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 105,
		id: 450,
		code: 'MG',
		cio: 'MDG',
		nameFr: 'Madagascar',
		nameEn: 'Madagascar',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 106,
		id: 454,
		code: 'MW',
		cio: 'MWI',
		nameFr: 'Malawi',
		nameEn: 'Malawi',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 107,
		id: 458,
		code: 'MY',
		cio: 'MYS',
		nameFr: 'Malaisie',
		nameEn: 'Malaysia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 108,
		id: 462,
		code: 'MV',
		cio: 'MDV',
		nameFr: 'Maldives',
		nameEn: 'Maldives',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 109,
		id: 466,
		code: 'ML',
		cio: 'MLI',
		nameFr: 'Mali',
		nameEn: 'Mali',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 110,
		id: 470,
		code: 'MT',
		cio: 'MLT',
		nameFr: 'Malte',
		nameEn: 'Malta',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 111,
		id: 584,
		code: 'MH',
		cio: 'MHL',
		nameFr: 'Marshall (Îles)',
		nameEn: 'Marshall Islands',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 112,
		id: 478,
		code: 'MR',
		cio: 'MRT',
		nameFr: 'Mauritanie',
		nameEn: 'Mauritania',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 113,
		id: 480,
		code: 'MU',
		cio: 'MUS',
		nameFr: 'Maurice',
		nameEn: 'Mauritius',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 114,
		id: 484,
		code: 'MX',
		cio: 'MEX',
		nameFr: 'Mexique',
		nameEn: 'Mexico',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 115,
		id: 583,
		code: 'FM',
		cio: 'FSM',
		nameFr: 'Micronésie',
		nameEn: 'Micronesia (Federated States of)',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 116,
		id: 498,
		code: 'MD',
		cio: 'MDA',
		nameFr: 'Moldavie',
		nameEn: 'Moldova (the Republic of)',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 117,
		id: 492,
		code: 'MC',
		cio: 'MCO',
		nameFr: 'Monaco',
		nameEn: 'Monaco',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 118,
		id: 496,
		code: 'MN',
		cio: 'MNG',
		nameFr: 'Mongolie',
		nameEn: 'Mongolia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 119,
		id: 499,
		code: 'ME',
		cio: 'MNE',
		nameFr: 'Monténégro',
		nameEn: 'Montenegro',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 120,
		id: 504,
		code: 'MA',
		cio: 'MAR',
		nameFr: 'Maroc',
		nameEn: 'Morocco',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 121,
		id: 508,
		code: 'MZ',
		cio: 'MOZ',
		nameFr: 'Mozambique',
		nameEn: 'Mozambique',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 122,
		id: 104,
		code: 'MM',
		cio: 'MMR',
		nameFr: 'Birmanie',
		nameEn: 'Myanmar',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 123,
		id: 516,
		code: 'NA',
		cio: 'NAM',
		nameFr: 'Namibie',
		nameEn: 'Namibia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 124,
		id: 520,
		code: 'NR',
		cio: 'NRU',
		nameFr: 'Nauru',
		nameEn: 'Nauru',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 125,
		id: 524,
		code: 'NP',
		cio: 'NPL',
		nameFr: 'Népal',
		nameEn: 'Nepal',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 126,
		id: 528,
		code: 'NL',
		cio: 'NLD',
		nameFr: 'Pays-Bas',
		nameEn: 'Netherlands',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 127,
		id: 554,
		code: 'NZ',
		cio: 'NZL',
		nameFr: 'Nouvelle-Zélande',
		nameEn: 'New Zealand',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 128,
		id: 558,
		code: 'NI',
		cio: 'NIC',
		nameFr: 'Nicaragua',
		nameEn: 'Nicaragua',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 129,
		id: 562,
		code: 'NE',
		cio: 'NER',
		nameFr: 'Niger',
		nameEn: 'Niger',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 130,
		id: 566,
		code: 'NG',
		cio: 'NGA',
		nameFr: 'Nigéria',
		nameEn: 'Nigeria',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 131,
		id: 570,
		code: 'NU',
		cio: 'NIU',
		nameFr: 'Niue',
		nameEn: 'Niue',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 132,
		id: 807,
		code: 'MK',
		cio: 'MKD',
		nameFr: 'Macédoine du Nord',
		nameEn: 'North Macedonia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 133,
		id: 578,
		code: 'NO',
		cio: 'NOR',
		nameFr: 'Norvège',
		nameEn: 'Norway',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 134,
		id: 512,
		code: 'OM',
		cio: 'OMN',
		nameFr: 'Oman',
		nameEn: 'Oman',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 135,
		id: 586,
		code: 'PK',
		cio: 'PAK',
		nameFr: 'Pakistan',
		nameEn: 'Pakistan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 136,
		id: 585,
		code: 'PW',
		cio: 'PLW',
		nameFr: 'Palaos',
		nameEn: 'Palau',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 137,
		id: 591,
		code: 'PA',
		cio: 'PAN',
		nameFr: 'Panama',
		nameEn: 'Panama',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 138,
		id: 598,
		code: 'PG',
		cio: 'PNG',
		nameFr: 'Papouasie-Nouvelle-Guinée',
		nameEn: 'Papua New Guinea',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 139,
		id: 600,
		code: 'PY',
		cio: 'PRY',
		nameFr: 'Paraguay',
		nameEn: 'Paraguay',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 140,
		id: 604,
		code: 'PE',
		cio: 'PER',
		nameFr: 'Pérou',
		nameEn: 'Peru',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 141,
		id: 608,
		code: 'PH',
		cio: 'PHL',
		nameFr: 'Philippines',
		nameEn: 'Philippines',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 142,
		id: 616,
		code: 'PL',
		cio: 'POL',
		nameFr: 'Pologne',
		nameEn: 'Poland',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 143,
		id: 620,
		code: 'PT',
		cio: 'PRT',
		nameFr: 'Portugal',
		nameEn: 'Portugal',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 144,
		id: 634,
		code: 'QA',
		cio: 'QAT',
		nameFr: 'Qatar',
		nameEn: 'Qatar',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 145,
		id: 642,
		code: 'RO',
		cio: 'ROU',
		nameFr: 'Roumanie',
		nameEn: 'Romania',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 146,
		id: 643,
		code: 'RU',
		cio: 'RUS',
		nameFr: 'Russie',
		nameEn: 'Russian Federation',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 147,
		id: 646,
		code: 'RW',
		cio: 'RWA',
		nameFr: 'Rwanda',
		nameEn: 'Rwanda',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 148,
		id: 659,
		code: 'KN',
		cio: 'KNA',
		nameFr: 'Saint-Christophe-et-Niévès',
		nameEn: 'Saint Kitts and Nevis',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 149,
		id: 662,
		code: 'LC',
		cio: 'LCA',
		nameFr: 'Sainte-Lucie',
		nameEn: 'Saint Lucia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 150,
		id: 670,
		code: 'VC',
		cio: 'VCT',
		nameFr: 'Saint-Vincent-et-les-Grenadines',
		nameEn: 'Saint Vincent and the Grenadines',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 151,
		id: 882,
		code: 'WS',
		cio: 'WSM',
		nameFr: 'Samoa',
		nameEn: 'Samoa',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 152,
		id: 674,
		code: 'SM',
		cio: 'SMR',
		nameFr: 'Saint-Marin',
		nameEn: 'San Marino',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 153,
		id: 678,
		code: 'ST',
		cio: 'STP',
		nameFr: 'Sao Tomé-et-Principe',
		nameEn: 'Sao Tome and Principe',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 154,
		id: 682,
		code: 'SA',
		cio: 'SAU',
		nameFr: 'Arabie saoudite',
		nameEn: 'Saudi Arabia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 155,
		id: 686,
		code: 'SN',
		cio: 'SEN',
		nameFr: 'Sénégal',
		nameEn: 'Senegal',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 156,
		id: 688,
		code: 'RS',
		cio: 'SRB',
		nameFr: 'Serbie',
		nameEn: 'Serbia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 157,
		id: 690,
		code: 'SC',
		cio: 'SYC',
		nameFr: 'Seychelles',
		nameEn: 'Seychelles',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 158,
		id: 694,
		code: 'SL',
		cio: 'SLE',
		nameFr: 'Sierra Leone',
		nameEn: 'Sierra Leone',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 159,
		id: 702,
		code: 'SG',
		cio: 'SGP',
		nameFr: 'Singapour',
		nameEn: 'Singapore',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 160,
		id: 703,
		code: 'SK',
		cio: 'SVK',
		nameFr: 'Slovaquie',
		nameEn: 'Slovakia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 161,
		id: 705,
		code: 'SI',
		cio: 'SVN',
		nameFr: 'Slovénie',
		nameEn: 'Slovenia',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 162,
		id: 90,
		code: 'SB',
		cio: 'SLB',
		nameFr: 'Salomon',
		nameEn: 'Solomon Islands',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 163,
		id: 706,
		code: 'SO',
		cio: 'SOM',
		nameFr: 'Somalie',
		nameEn: 'Somalia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 164,
		id: 710,
		code: 'ZA',
		cio: 'ZAF',
		nameFr: 'Afrique du Sud',
		nameEn: 'South Africa',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 165,
		id: 728,
		code: 'SS',
		cio: 'SSD',
		nameFr: 'Soudan du Sud',
		nameEn: 'South Sudan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 166,
		id: 724,
		code: 'ES',
		cio: 'ESP',
		nameFr: 'Espagne',
		nameEn: 'Spain',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 167,
		id: 144,
		code: 'LK',
		cio: 'LKA',
		nameFr: 'Sri Lanka',
		nameEn: 'Sri Lanka',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 168,
		id: 729,
		code: 'SD',
		cio: 'SDN',
		nameFr: 'Soudan',
		nameEn: 'Sudan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 169,
		id: 740,
		code: 'SR',
		cio: 'SUR',
		nameFr: 'Suriname',
		nameEn: 'Suriname',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 170,
		id: 752,
		code: 'SE',
		cio: 'SWE',
		nameFr: 'Suède',
		nameEn: 'Sweden',
		france: false,
		europe: true,
		overseas: false,
	},
	{
		position: 171,
		id: 756,
		code: 'CH',
		cio: 'CHE',
		nameFr: 'Suisse',
		nameEn: 'Switzerland',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 172,
		id: 760,
		code: 'SY',
		cio: 'SYR',
		nameFr: 'Syrie',
		nameEn: 'Syrian Arab Republic',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 173,
		id: 762,
		code: 'TJ',
		cio: 'TJK',
		nameFr: 'Tadjikistan',
		nameEn: 'Tajikistan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 174,
		id: 834,
		code: 'TZ',
		cio: 'TZA',
		nameFr: 'Tanzanie',
		nameEn: 'Tanzania, the United Republic of',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 175,
		id: 764,
		code: 'TH',
		cio: 'THA',
		nameFr: 'Thaïlande',
		nameEn: 'Thailand',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 176,
		id: 626,
		code: 'TL',
		cio: 'TLS',
		nameFr: 'Timor oriental',
		nameEn: 'Timor-Leste',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 177,
		id: 768,
		code: 'TG',
		cio: 'TGO',
		nameFr: 'Togo',
		nameEn: 'Togo',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 178,
		id: 776,
		code: 'TO',
		cio: 'TON',
		nameFr: 'Tonga',
		nameEn: 'Tonga',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 179,
		id: 780,
		code: 'TT',
		cio: 'TTO',
		nameFr: 'Trinité-et-Tobago',
		nameEn: 'Trinidad and Tobago',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 180,
		id: 788,
		code: 'TN',
		cio: 'TUN',
		nameFr: 'Tunisie',
		nameEn: 'Tunisia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 181,
		id: 792,
		code: 'TR',
		cio: 'TUR',
		nameFr: 'Turquie',
		nameEn: 'Turkey',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 182,
		id: 795,
		code: 'TM',
		cio: 'TKM',
		nameFr: 'Turkménistan',
		nameEn: 'Turkmenistan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 183,
		id: 798,
		code: 'TV',
		cio: 'TUV',
		nameFr: 'Tuvalu',
		nameEn: 'Tuvalu',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 184,
		id: 800,
		code: 'UG',
		cio: 'UGA',
		nameFr: 'Ouganda',
		nameEn: 'Uganda',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 185,
		id: 804,
		code: 'UA',
		cio: 'UKR',
		nameFr: 'Ukraine',
		nameEn: 'Ukraine',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 186,
		id: 784,
		code: 'AE',
		cio: 'ARE',
		nameFr: 'Émirats arabes unis',
		nameEn: 'United Arab Emirates',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 187,
		id: 826,
		code: 'GB',
		cio: 'GBR',
		nameFr: 'Royaume-Uni',
		nameEn: 'United Kingdom',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 188,
		id: 840,
		code: 'US',
		cio: 'USA',
		nameFr: 'États-Unis',
		nameEn: 'United States of America',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 189,
		id: 858,
		code: 'UY',
		cio: 'URY',
		nameFr: 'Uruguay',
		nameEn: 'Uruguay',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 190,
		id: 860,
		code: 'UZ',
		cio: 'UZB',
		nameFr: 'Ouzbékistan',
		nameEn: 'Uzbekistan',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 191,
		id: 548,
		code: 'VU',
		cio: 'VUT',
		nameFr: 'Vanuatu',
		nameEn: 'Vanuatu',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 192,
		id: 862,
		code: 'VE',
		cio: 'VEN',
		nameFr: 'Vénézuéla',
		nameEn: 'Venezuela (Bolivarian Republic of)',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 193,
		id: 704,
		code: 'VN',
		cio: 'VNM',
		nameFr: 'Vietnam',
		nameEn: 'Viet Nam',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 194,
		id: 887,
		code: 'YE',
		cio: 'YEM',
		nameFr: 'Yémen',
		nameEn: 'Yemen',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 195,
		id: 894,
		code: 'ZM',
		cio: 'ZMB',
		nameFr: 'Zambie',
		nameEn: 'Zambia',
		france: false,
		europe: false,
		overseas: true,
	},
	{
		position: 196,
		id: 716,
		code: 'ZW',
		cio: 'ZWE',
		nameFr: 'Zimbabwé',
		nameEn: 'Zimbabwe',
		france: false,
		europe: false,
		overseas: true,
	},
];
