// react
import React, { ChangeEvent, FC, useRef } from 'react';
// Types
import { EventDetailModalPropsType } from '../event/types/event-detail-modal-props.type.';
import { Dialog, NumberRangeInput } from '@tmds-io/kore';
import { Box, Button, Grid, Typography } from '@mui/material';
import { BrokenImage, AddShoppingCart } from '@mui/icons-material';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useEventDetailModalStyle } from '@components/customer/event/styles/event-detail-modal.style';
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';

export const ProductDetailModal: FC<EventDetailModalPropsType> = ({
	open,
	product,
	onClose,
	disableActions,
	onAddProductToCart,
}) => {
	const currentLanguage = i18next.language || 'en';
	const classes = useEventDetailModalStyle();
	const { t } = useTranslation('acs', { useSuspense: false });
	const value = useRef<number>(1);

	const handleChange = (evt: ChangeEvent<HTMLInputElement> | string) => {
		if (typeof evt === 'string') {
			value.current = +evt;
		} else {
			value.current = +evt.target.value;
		}
	};

	const handleAddProductToCart = () => {
		onAddProductToCart(product.id, value.current);
	};

	return (
		<Dialog
			title={product.title[currentLanguage]}
			open={open}
			onClose={onClose}
			maxWidth={'md'}
			fullWidth
			content={
				<>
					<Grid container spacing={2}>
						<Grid item justifyContent="center" alignItems="center">
							<Box width={100} height={100} display="flex" justifyContent="center" alignItems="center">
								{product.image ? (
									<img className={classes.image} src={product.image} alt="name" />
								) : (
									<BrokenImage fontSize="large" />
								)}
							</Box>
						</Grid>
						<Grid item xs>
							<Box mb={2}>
								<Typography variant="h6" gutterBottom>
									{t('product-edition.input.description')}
								</Typography>
								<Typography>{product.description[currentLanguage]}</Typography>
							</Box>
							<Box mb={2}>
								<Typography variant="h6" gutterBottom>
									{t('product-edition.input.specification')}
								</Typography>
								<Typography>{product.specifications[currentLanguage]}</Typography>
							</Box>
							<Box
								display="flex"
								alignItems="center"
								justifyContent={disableActions ? 'space-between' : undefined}
							>
								<Box display="flex" mb={2} mr={1}>
									{product.conditioning?.map((cond, index) => (
										<Box key={index} p={2} className={classes.conditioning}>
											{cond}
										</Box>
									))}
								</Box>
								{!disableActions ? (
									<>
										<Box mr={2} mt={-2}>
											<NumberRangeInput name="product" onChange={handleChange} value={value.current} />
										</Box>
										<Box>
											<Button
												variant="outlined"
												color="primary"
												startIcon={<AddShoppingCart />}
												onClick={handleAddProductToCart}
											>
												{t('shared.add')}
											</Button>
										</Box>
									</>
								) : (
									<Box>
										<Button
											variant="outlined"
											color="primary"
											component={Link}
											to={generatePath(ROUTES_URI.PRODUCTS_EDIT, { id: product.id })}
										>
											{t('shared.edit')}
										</Button>
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
				</>
			}
		/>
	);
};
