import {
	ConditioningRowType,
	SaleAreaPrice,
} from '@components/seller/product-edition/partials/conditioning-section/partials/conditioning-row/conditioning-props.type';
import { EventProductEditFieldsFragment, SaleAreaFieldsFragment, Price } from '../../../../../generated/graphql';

export const formatProductVariationsToConditioning = (
	saleAreaList: SaleAreaFieldsFragment[],
	productVariations?: EventProductEditFieldsFragment[]
): ConditioningRowType[] => {
	return productVariations?.map(({ variation, prices }, index) => {
		const [multiplier = 0, quantity = 0] = variation?.options?.[0].value.split('_') || [];
		return {
			id: variation.id,
			index,
			sap: variation.sku,
			multiplier: +multiplier,
			quantity: +quantity,
			saleAreaPriceList: formatPricesToSaleAreaWithValue(saleAreaList, prices),
		};
	});
};

const formatPricesToSaleAreaWithValue = (
	saleAreaList: SaleAreaFieldsFragment[],
	prices: Omit<Price, 'created_at' | 'updated_at'>[]
): SaleAreaPrice[] => {
	return saleAreaList.map((saleArea) => {
		const saleAreaPrice = prices?.find(
			({ sale_area_id, inventory_id }) => sale_area_id === saleArea.id && !inventory_id
		);
		return {
			...saleArea,
			value: saleAreaPrice?.price,
			priceId: saleAreaPrice?.id,
		};
	});
};
