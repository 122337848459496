import { ProductsColumns } from '@components/seller/event/partials/race-products/race-products.type';
import { ProductEvent } from '@components/seller/event/types/product.type';

export const getProductsColumns = (
	allProducts: ProductEvent[],
	currentProductListIds: string[],
	otherProductListIds: string[]
): ProductsColumns => {
	return {
		selectedProducts: currentProductListIds?.length
			? allProducts.filter(({ sku }) => currentProductListIds.includes(sku))
			: [],
		availableProducts:
			currentProductListIds?.length || otherProductListIds?.length
				? allProducts.filter(
						({ sku }) => !currentProductListIds.includes(sku) && !otherProductListIds.includes(sku)
				  )
				: allProducts,
	};
};
