// Core
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
// types
import { LegendProps } from '@components/seller/product/types/products.type';
import { ProductType } from '../../../types/product.types';
// Utils
import { useTranslation } from 'react-i18next';
// Routing
import { Link, useParams } from 'react-router-dom';
import { ROUTES_URI } from '../../app/router/routes';
//Components
import { Box, Button, Container, Grid, IconButton, TextField, Typography } from '@mui/material';
import { ProductCard } from '@tmds-io/kore';
import SearchIcon from '@mui/icons-material/Search';
// Assets
import AddIcon from '@mui/icons-material/Add';
// Services
import { getFilter } from './services/product.service';
import { useGetCategoryProductsLazyQuery, useGetProductsLazyQuery } from '../../../generated/graphql';
import { getProductList } from './services/product.service';
import { useLegendStyle, useProductsStyle } from '@components/seller/product/styles/product.style';
import clsx from 'clsx';
import { useTextFieldStyle } from '@core/styles/textfield';
import { ProductTypeTabFilter } from '@components/shared/product-type-tab-filter';
import { Loader } from '@components/shared/loader';
import { FilterAppBar } from '@components/ProductFilter/FilterAppBar';
// Constants
const legendList: LegendProps[] = [
	{ color: 'primary', text: 'products.legend.default-product' },
	{ color: 'grey', text: 'products.legend.restricted-access-product' },
];

/**
 * Display colored squared with text
 * @param color - hexa or string color
 * @param text - Text to display after square
 * @param className - class for override css
 */
const Legend: FC<LegendProps> = ({ color, text, className }) => {
	const { t } = useTranslation('acs', { useSuspense: true });
	const classes = useLegendStyle();
	return (
		<Box display="flex" alignItems="center" className={className} mr={4}>
			<div className={clsx(classes.square, color)} />
			<Typography>{t(text)}</Typography>
		</Box>
	);
};

/**
 * Product listing component, connected to store for get products
 */
export const Products: FC = () => {
	const classes = useProductsStyle();
	const textFieldCls = useTextFieldStyle();
	// Translation
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	// States
	const [filter, setFilter] = useState<string>(ProductType.ALL);
	// Ref
	const search = useRef<string>('');
	// Router
	const params = useParams();

	// hooks
	const [getProducts, { data: products, loading: loadingProduct }] = useGetProductsLazyQuery({
		fetchPolicy: 'cache-and-network',
	});
	const [getCategoryProducts, { data: productFromCategory, loading: loadingProductCategory }] =
		useGetCategoryProductsLazyQuery();
	const loading = loadingProduct || loadingProductCategory;
	const productList = getProductList(filter, products, productFromCategory, search.current);

	/**
	 * Set current search value in ref for prevent rerender
	 */
	const handleSearchUpdate = (event: ChangeEvent<HTMLInputElement>): void => {
		search.current = event.target.value;
	};

	/**
	 * Submit search when press Enter
	 */
	const handleKeyPressSubmit = (event: React.KeyboardEvent<HTMLInputElement>): void => {
		if (event.key === 'Enter') {
			handleSearchSubmit();
		}
	};

	/**
	 * Handle search bar and filter for search product
	 */
	const handleSearchSubmit = (): void => {
		if (filter === ProductType.ALL) {
			getProducts(
				search.current
					? { variables: { filter: getFilter(search.current), sort: { keys: ['created_at'], sort: -1 } } }
					: { variables: { sort: { keys: ['created_at'], sort: 1 } } }
			);
		} else {
			getCategoryProducts({ variables: { id: filter } });
		}
	};

	// Update filter state when url params is updated
	useEffect(() => {
		let ignore = false;
		if (params?.filter && !ignore) {
			setFilter(params.filter as ProductType);
		}
		return () => {
			ignore = true;
		};
	}, [params]);

	// Send search when filter change
	useEffect(() => {
		handleSearchSubmit();
	}, [filter]);

	return (
		<Box position="relative">
			<FilterAppBar position="fixed" elevation={0}>
				<ProductTypeTabFilter onSelect={setFilter} />
			</FilterAppBar>

			<Container>
				<Box pt={'80px'}>
					<TextField
						className={textFieldCls.light}
						fullWidth
						color="secondary"
						placeholder={t('products.search-input.placeholder')}
						onChange={handleSearchUpdate}
						onKeyPress={handleKeyPressSubmit}
						InputProps={{
							disableUnderline: true,
							endAdornment: (
								<IconButton size="small" onClick={handleSearchSubmit} disabled={loading}>
									<SearchIcon color="primary" />
								</IconButton>
							),
						}}
					/>
				</Box>
				<Box position="relative" pb={8}>
					<Box display="flex" py={2}>
						{legendList.map((legendProps) => (
							<Legend key={legendProps.text} className="legend" {...legendProps} />
						))}
					</Box>
					<div className="product-list">
						{loading && <Loader />}
						{!loading && productList ? (
							<Grid container spacing={2}>
								{productList.length ? (
									productList.map((productProps, index) => (
										<Grid
											item
											container
											key={productProps.name + index}
											justifyContent="center"
											alignItems="center"
											sm={12}
											md={4}
										>
											<ProductCard
												sx={{
													width: '100%',
													'& .MuiTypography-h6': {
														color: productProps.restricted ? 'text.primary' : 'primary.main',
													},
												}}
												{...productProps}
											/>
										</Grid>
									))
								) : (
									<Box display="flex" justifyContent="center" alignItems="center" p={4} width="100%">
										<p>{t('products.no-product-found')}</p>
									</Box>
								)}
							</Grid>
						) : null}
					</div>
					<Box className={classes.fabWrapper} justifyContent="flex-end">
						<Button
							color="primary"
							variant="contained"
							component={Link}
							to={ROUTES_URI.PRODUCTS_CREATE}
							startIcon={<AddIcon fontSize="large" />}
							aria-label={t('products.button.add-product')}
						>
							{t('products.button.add-product')}
						</Button>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};
