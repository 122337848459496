// react
import React, { FC } from 'react';
import { Box, Button, TextField } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { useDocumentUploadStyle } from '@components/seller/product-edition/partials/document-upload/styles/document-upload.style';
import { EditionRowProps } from '@components/seller/product-edition/partials/document-upload/types/document-upload-props.type';
import { useTextFieldStyle } from '@core/styles/textfield';
import { useTranslation } from 'react-i18next';

export const EditionRow: FC<EditionRowProps> = ({ onSubmit, onCancel, TextFieldProps }) => {
	const { t } = useTranslation('acs', { useSuspense: true });

	const classes = useDocumentUploadStyle();
	const clsTxtField = useTextFieldStyle();
	return (
		<Box display="flex" alignItems="center" className={classes.documentRow}>
			<Box display="flex" alignItems="center">
				<DescriptionIcon className="mr-2" fontSize="large" />
				<TextField
					InputProps={{ className: clsTxtField.light }}
					variant="outlined"
					size="small"
					name="title"
					{...TextFieldProps}
				/>

				<Button sx={{ ml: 2 }} variant="contained" color="primary" size="small" onClick={onSubmit}>
					{t('product-edition.btn.save')}
				</Button>
				<Button sx={{ ml: 2 }} variant="outlined" color="primary" size="small" onClick={onCancel}>
					{t('shared.cancel')}
				</Button>
			</Box>
		</Box>
	);
};
