import { useCreateInventoryMutation } from '../../../../generated/graphql';
import { GroupProduct } from '@core/hooks/group/group.type';
import { GroupInventory, UseGroupEditionCreateInventory } from './useGroupEdition.type';
import { useMemo } from 'react';
import { formatInventoryForGroupEdition } from './groupEdition.core';

export const useGroupEditionCreateInventory = (): UseGroupEditionCreateInventory => {
	const [createInventory, { data, error, loading }] = useCreateInventoryMutation();

	const handleCreateInventory = async (
		{ sku, parentSku }: GroupProduct,
		groupId: string
	): Promise<GroupInventory | undefined> => {
		const result = await createInventory({
			variables: {
				params: {
					business_unit: 'resellers',
					product_sku: sku,
					parent_sku: parentSku,
					store_id: groupId,
					restricted_product: true,
				},
			},
		});

		if (result?.data?.createInventory) {
			return formatInventoryForGroupEdition(result.data.createInventory);
		}
	};

	return useMemo(
		() => [
			handleCreateInventory,
			{
				data: data?.createInventory ? formatInventoryForGroupEdition(data.createInventory) : undefined,
				error: !!error,
				loading,
			},
		],
		[data, error, loading]
	);
};
