import * as Yup from 'yup';

export const userAccountFormSchema = Yup.object().shape({
	account: Yup.object().shape({
		edi_reference: Yup.string().required(),
		name: Yup.string().required(),
		// vat: Yup.string().required(),
		companyNumber: Yup.string().required(),
		address: Yup.object().shape({
			country: Yup.string().required(),
			street: Yup.string().required(),
			zip: Yup.string().required(),
			city: Yup.string().required(),
		}),
		phone: Yup.string().required(),
		bank_account: Yup.object().shape({
			rib: Yup.string().nullable(),
			iban: Yup.string().nullable(),
			swift: Yup.string().nullable(),
		}),
		billing_contact: Yup.object().shape({
			firstname: Yup.string().required(),
			lastname: Yup.string().required(),
		}),
	}),
});
