import { UseUserProductsEdition } from './useUserProductsEdition.type';
import {
	useGetAccountOwnerQuery,
	useGetSellerCustomerEventsQuery,
	useGetStoreProductVariationsWithPricesLazyQuery,
} from '../../../../generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { formatProductForGroupEdition } from '@core/hooks/group/useGroupEdition/groupEdition.core';
import { useParams } from 'react-router-dom';

export const useUserProductsEdition = (): UseUserProductsEdition => {
	const { id } = useParams();

	const currentLanguage = useCurrentLanguage();
	const { data: customerContract, error: contractError } = useGetSellerCustomerEventsQuery({
		fetchPolicy: 'no-cache',
		variables: { id },
	});
	const groupId = useRef<string>();
	const { data: accountData, error: accountError } = useGetAccountOwnerQuery({
		variables: { id },
		fetchPolicy: 'no-cache',
	});
	const [localState, setLocalState] = useState<UseUserProductsEdition>({
		data: undefined,
		loading: true,
		error: true,
	});

	const [getAvailableProducts, { data: availableProductsData, error: availableProductsError }] =
		useGetStoreProductVariationsWithPricesLazyQuery({ fetchPolicy: 'no-cache' });

	useEffect(() => {
		if (customerContract?.customerContract) {
			groupId.current = customerContract.customerContract.stores.find((id) => id);
			void getAvailableProducts({
				variables: {
					store_id: groupId.current,
				},
			});
		}
	}, [customerContract]);

	useEffect(() => {
		if (availableProductsData && accountData) {
			const owner = accountData.account.owner?.data || { first_name: accountData.account.name, last_name: '' };
			setLocalState({
				data: {
					user: { userName: `${owner.first_name} ${owner.last_name}` },
					availableProducts: formatProductForGroupEdition(
						availableProductsData.storeProductVariationsWithPrices,
						currentLanguage
					),
					selectedProductIds:
						customerContract.customerContract.restricted_products
							?.map((sku) => {
								return availableProductsData.storeProductVariationsWithPrices.find(
									(data) => sku === data.variation.sku
								)?.variation.id;
							})
							.filter((a) => !!a) || [],
					groupId: groupId.current,
				},
				error: false,
				loading: false,
			});
		}
	}, [availableProductsData, accountData]);

	useEffect(() => {
		if (availableProductsError || accountError || contractError) {
			setLocalState({ data: undefined, error: true, loading: false });
		}
	}, [availableProductsError, accountError, contractError]);

	return localState;
};
