// React
import React, { ReactNode, useState } from 'react';
// Shared components
import ContactDetailsForm from '@components/Register/ContactDetailsForm/ContactDetailsForm';
import { DotConnector, useRegisterStyles } from '@components/Register/shared/styles/registration.style';
import BusinessForm from '@components/Register/BusinessForm/BusinessForm';
import ChampionshipsForm from '@components/Register/ChampionshipsForm/ChampionshipsForm';
import BankDetailsFForm from '@components/Register/BankDetailsForm/BankDetailsF-form';
import { DotStepIcon } from '@components/Register/DotStepIcon';

// Mui
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { Step, StepLabel, Stepper, Button, Typography, Box, Card, CardContent } from '@mui/material';

// Utils
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// kore
import { ErrorFallback } from '@tmds-io/kore';
// types
import { SignUpInput } from '../generated/graphql';

const formData: SignUpInput = { account: undefined, owner: undefined };

const getStepComponent = (step: number, handleNext: () => void): ReactNode => {
	switch (step) {
		case 0:
			return <ContactDetailsForm formData={formData} next={handleNext} />;
		case 1:
			return <BusinessForm formData={formData} next={handleNext} />;
		case 2:
			return <ChampionshipsForm formData={formData} next={handleNext} />;
		case 3:
			return <BankDetailsFForm formData={formData} next={handleNext} />;
	}
};

export const Register = (): JSX.Element => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const currentLanguage = i18next.language || 'en';
	const resellerLinks = {
		en: 'https://acs.totalenergies.com/en/racing-range/become-reseller',
		fr: 'https://acs.totalenergies.com/fr/gamme-competition/devenir-revendeur',
	};

	const [userType, setUserType] = useState<string>('');
	const classes = useRegisterStyles();

	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = (): void => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	return (
		<>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Card className={classes.registrationCard} square={true}>
					<CardContent>
						{!userType && (
							<>
								<Box mb={3}>
									<Typography variant="h3" align={'center'} color={'primary'}>
										{t('register.title')}
									</Typography>
								</Box>

								<Box mb={4}>
									<Typography align="center">{t('register.subtitle')}</Typography>
								</Box>
								<Box mb={1}>
									<Button
										fullWidth
										variant="outlined"
										size="large"
										color="primary"
										onClick={() => setUserType('T')}
										startIcon={<ArrowRightIcon />}
									>
										{t('register.customer')}
									</Button>
								</Box>
								<Box mb={4}>
									<Typography variant="subtitle2" align="center">
										{t('register.customer-subtitle')}
									</Typography>
								</Box>

								<Button
									sx={{ mb: 1 }}
									component="a"
									href={resellerLinks[currentLanguage as 'en' | 'fr']}
									fullWidth
									variant="outlined"
									size="large"
									color="primary"
									startIcon={<ArrowRightIcon fontSize="small" />}
								>
									{t('register.seller')}
								</Button>

								<Box mb={1}>
									<Typography variant="subtitle2" align="center">
										{t('register.seller-subtitle')}
									</Typography>
								</Box>
							</>
						)}

						{userType === 'T' && (
							<>
								<Stepper
									className={classes.root}
									alternativeLabel
									activeStep={activeStep}
									connector={<DotConnector />}
								>
									<Step>
										<StepLabel StepIconComponent={DotStepIcon} />
									</Step>
									<Step>
										<StepLabel StepIconComponent={DotStepIcon} />
									</Step>
									<Step>
										<StepLabel StepIconComponent={DotStepIcon} />
									</Step>
									<Step>
										<StepLabel StepIconComponent={DotStepIcon} />
									</Step>
								</Stepper>

								{getStepComponent(activeStep, handleNext)}
							</>
						)}
					</CardContent>
				</Card>
			</ErrorBoundary>
		</>
	);
};
