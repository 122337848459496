// react
import React, { FC, useEffect, useState } from 'react';
import { Box, Checkbox, Container, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { TransferList } from '@components/seller/event/partials/transfert-list/transfer-list';
import { RaceProductsType } from '@components/seller/event/partials/race-products/race-products.type';
import { useTranslation } from 'react-i18next';

export const RaceProducts: FC<RaceProductsType> = ({
	defaultProducts,
	optionalProducts,
	onAddDefaultProduct,
	onRemoveDefaultProduct,
	onAddOptionalProduct,
	onRemoveOptionalProduct,
	haveOptionalProducts: haveOptProd,
	onPriceChange,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const [haveOptionalProducts, setHaveOptionalProducts] = useState<boolean>(haveOptProd);
	const hideOptionalProductsDisabled = Boolean(haveOptionalProducts && optionalProducts.selectedProducts.length);

	useEffect(() => {
		setHaveOptionalProducts(haveOptProd);
	}, [haveOptProd]);

	const handleShowOptionalTransferList = () => {
		if (!haveOptionalProducts) {
			setHaveOptionalProducts(true);
		} else if (haveOptionalProducts && !hideOptionalProductsDisabled) {
			setHaveOptionalProducts(false);
		}
	};

	return (
		<Container>
			<Box mb={3}>
				<Typography variant="h2">{t('track-details.mandatory-products')}</Typography>
			</Box>

			<TransferList
				products={defaultProducts}
				onAddProduct={onAddDefaultProduct}
				onRemoveProduct={onRemoveDefaultProduct}
				onPriceChange={onPriceChange}
			/>

			<Box mb={4}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								name="optionalProducts"
								onChange={handleShowOptionalTransferList}
								checked={haveOptionalProducts}
								color="primary"
								disabled={hideOptionalProductsDisabled}
							/>
						}
						label={<Typography variant="h2">{t('track-details.optional-products')}</Typography>}
					/>
				</FormGroup>
			</Box>

			<TransferList
				sx={{ display: haveOptionalProducts ? 'block' : 'none' }}
				products={optionalProducts}
				onAddProduct={onAddOptionalProduct}
				onRemoveProduct={onRemoveOptionalProduct}
				onPriceChange={onPriceChange}
				rightColumnTitle={'track-details.selected-products'}
			/>
		</Container>
	);
};
