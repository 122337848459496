// React
import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Type
import { RaceFormValues } from '@components/seller/event/types/race-form-props.type';
// gql
import {
	useGetEventForTrackAddEditQuery,
	useGetSalesAreaQuery,
	useGetSellerTrackQuery,
	useUpdateTrackMutation,
} from '../../../generated/graphql';
// Components
import RaceForm from '@components/seller/event/partials/race-form';
// Utils
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getMediaUrl, uploadMedia } from '@core/services/media';
import { getValidationSchema } from '@components/seller/event/utils/get-validation-schema';
import { Loader } from '@components/shared/loader';

/**
 * Edit track component - Fetch track data and display track form edit
 */
const EditTrack: FC = () => {
	// Hooks
	const { id = '0', trackId = '0' } = useParams();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { data: eventData } = useGetEventForTrackAddEditQuery({ variables: { id } });
	const { data: salesAreaData } = useGetSalesAreaQuery({ variables: {} });
	const [updateTrack, { data: trackUpdated, error: updateTrackError }] = useUpdateTrackMutation();

	const { enqueueSnackbar } = useSnackbar();
	const {
		data: trackData,
		loading: trackLoading,
		error: trackError,
	} = useGetSellerTrackQuery({ variables: { id: trackId } });
	// Constants
	const currentLanguage = i18next.language || 'en';
	const event = eventData?.store;
	const salesAreaList = salesAreaData?.salesArea.sales_area;
	const track = trackUpdated ? trackUpdated.updateStore : trackData ? trackData.store : undefined;
	const validationSchema = salesAreaList && getValidationSchema(salesAreaList, track);

	useEffect(() => {
		if (trackUpdated) {
			enqueueSnackbar(t('track-edit.update-success'), { variant: 'success' });
		}
	}, [trackUpdated]);

	/**
	 * Handle form submit - format data for api and save data with gql hook
	 * @param values
	 */
	const handleFormSubmit = async (values: RaceFormValues): Promise<void> => {
		const uploadedMedia = values.file ? await uploadMedia(values.file) : undefined;
		await updateTrack({
			variables: {
				id: trackId,
				data: {
					name: values.raceName,
					title: {
						fr: values.raceName,
						en: values.raceName,
					},
					code: values.raceName,
					hidden: false,
					data: {
						storage_location: values.storageLocation,
						first_reminder: values.firstReminder,
						second_reminder: values.secondReminder,
						city: values.city,
					},
					edi_ref: values.shipTo,
					image: uploadedMedia ? `!${uploadedMedia.key}` : track.image,
					order_from: new Date(values.orderFrom),
					order_to: new Date(values.orderTo),
					visible_from: new Date(values.visibleFrom),
					visible_to: new Date(values.visibleTo),
					sale_area_id: values.salesAreaId,
					sale_area_info: values.salesArea,
					business_unit: 'races',
				},
				paths: [
					'name',
					'title.fr',
					'title.en',
					'data.need_pilot',
					'data.storage_location',
					'data.first_reminder',
					'data.second_reminder',
					'data.city',
					'edi_ref',
					'order_from',
					'order_to',
					'image',
					'product_variations',
					'visible_from',
					'visible_to',
					'sale_area_id',
					'sale_area_info',
				],
			},
		});
	};

	useEffect(() => {
		if (trackError || updateTrackError) {
			console.error(trackError || updateTrackError);
			enqueueSnackbar(t('track-edit.error-track-fetch'), { variant: 'error' });
		}
	}, [trackError, updateTrackError]);

	if (!track || trackLoading) {
		return <Loader />;
	}

	return event && salesAreaList && track ? (
		<RaceForm
			event={{ id, name: event.title[currentLanguage] }}
			onSubmit={handleFormSubmit}
			track={track}
			preview={track?.image ? getMediaUrl(track?.image) : undefined}
			saleAreas={salesAreaList}
			validationSchema={validationSchema}
			initialValues={{
				raceName: track?.name ?? '',
				shipTo: track?.edi_ref ?? '',
				storageLocation: track?.data?.storage_location ?? '',
				firstReminder: track?.data?.first_reminder ?? 1,
				secondReminder: track?.data?.second_reminder ?? 1,
				file: null,
				orderFrom: track?.order_from?.substring(0, 10) ?? '',
				orderTo: track?.order_to?.substring(0, 10) ?? '',
				visibleFrom: track?.visible_from?.substring(0, 10) ?? '',
				visibleTo: track?.visible_to?.substring(0, 10) ?? '',
				salesArea: track?.sale_area_info ?? '',
				salesAreaId: track?.sale_area_id ?? '',
				city: track?.data?.city ?? '',
			}}
		/>
	) : (
		<Loader />
	);
};

export default EditTrack;
