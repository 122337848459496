import React, { FormEvent, FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { RaceFormPropsType, RaceFormValues } from '@components/seller/event/types/race-form-props.type';
import {
	Box,
	Container,
	FormControl,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	TextField,
	Typography,
	Button,
	Select,
	FormLabel,
	FormControlLabel,
	RadioGroup,
	Radio,
	InputAdornment,
	Card,
	IconButton,
	FormHelperText,
	Alert,
} from '@mui/material';
import { useEventHeaderDetailsStyle } from '@components/shared/event/styles/event-details.style';
import { NumberRangeInput } from '@tmds-io/kore';
import {
	ChevronLeft as ChevronLeftIcon,
	CalendarToday as DateIcon,
	ArrowRight as ArrowRightIcon,
	Save as SaveIcon,
} from '@mui/icons-material';
import { getCountryList } from '@components/seller/event/partials/country-list';
import { RaceImageEdit } from '@components/seller/event/partials/race-image-edit';
import { ROUTES_URI } from '../../../app/router/routes';
import { sortSaleArea } from '@core/utils/sortSaleArea';
import { useRaceProducts } from '@core/hooks/event/useRaceProducts';
import { Loader } from '@components/shared/loader';
import { useSnackbar } from 'notistack';
import { RaceProducts } from './race-products';

const RaceForm: FunctionComponent<RaceFormPropsType> = ({
	track,
	event,
	onSubmit,
	initialValues,
	validationSchema,
	saleAreas,
	preview,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const isEdit = !!initialValues.raceName;
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const classes = useEventHeaderDetailsStyle();
	const {
		request: [getRaceProducts, { data: raceProducts, error: raceProductsError }],
		refetchs: refetchRaceProducts,
	} = useRaceProducts(track?.id);

	const countryList = useMemo(
		() => [
			<MenuItem key={0} value="">
				<em>{t('shared.none')}</em>
			</MenuItem>,
			...getCountryList(),
		],
		[]
	);

	const sortedSaleAreas = useRef([...saleAreas].sort(sortSaleArea));

	const formik = useFormik<RaceFormValues>({
		initialValues: initialValues,
		validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
			formik.resetForm({ values });
		},
	});

	const handleImageChange = (evt: FormEvent<HTMLInputElement>): void => {
		formik.setFieldValue('file', evt.currentTarget.files[0]);
	};

	const handleCancelImageChange = () => {
		formik.setFieldValue('file', null);
	};

	useEffect(() => {
		if (track) {
			getRaceProducts(track.id);
		}
	}, [track]);

	useEffect(() => {
		if (raceProductsError) {
			enqueueSnackbar(t('track-details.error-fetch-race-products'), { variant: 'error' });
		}
	}, [raceProductsError]);

	const formLocked = isEdit && !raceProducts?.inventories;

	return (
		<>
			<Box>
				<Box className={classes.eventNameLabel}>
					<Container>
						<Box display="flex" alignItems="center">
							<IconButton onClick={() => navigate(-1)} size="small">
								<ChevronLeftIcon color="secondary" fontSize="large" />
							</IconButton>
							<Typography>
								<Box fontWeight="bold" component="span" ml={1} fontSize="18px">
									{event.name}
								</Box>
							</Typography>
						</Box>
					</Container>
				</Box>

				<Box position="relative">
					<RaceImageEdit
						onChange={handleImageChange}
						onCancel={handleCancelImageChange}
						preview={preview}
						formikState={formik}
					/>
					<Card square>
						<Box p={3} pl={8} pr={8}>
							<form>
								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} lg={4}>
										<FormGroup>
											<TextField
												name="raceName"
												label={t('track-details.race-name')}
												type="text"
												onChange={formik.handleChange}
												value={formik.values.raceName}
												error={formik.touched.raceName && !!formik.errors.raceName}
												helperText={formik.touched.raceName && formik.errors.raceName}
												InputLabelProps={{
													shrink: true,
												}}
												placeholder={t('track-details.race-name')}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} sm={6} lg={4}>
										<FormGroup>
											<TextField
												label={t('track-details.ship-to')}
												name="shipTo"
												type="text"
												onChange={formik.handleChange}
												value={formik.values.shipTo}
												error={formik.touched.shipTo && !!formik.errors.shipTo}
												helperText={formik.touched.shipTo && formik.errors.shipTo}
												InputLabelProps={{
													shrink: true,
												}}
												placeholder={t('track-details.ship-to')}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} sm={6} lg={4}>
										<FormGroup>
											<TextField
												label={t('track-details.storage-location')}
												name="storageLocation"
												type="text"
												onChange={formik.handleChange}
												value={formik.values.storageLocation}
												error={formik.touched.storageLocation && !!formik.errors.storageLocation}
												helperText={formik.touched.storageLocation && formik.errors.storageLocation}
												InputLabelProps={{
													shrink: true,
												}}
												placeholder={t('track-details.storage-location')}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>
									<Grid item xs={12} md={6} lg={4}>
										<FormGroup>
											<TextField
												label={t('track-details.city')}
												name="city"
												type="text"
												onChange={formik.handleChange}
												value={formik.values.city}
												error={formik.touched.city && !!formik.errors.city}
												helperText={formik.touched.city && formik.errors.city}
												InputLabelProps={{
													shrink: true,
												}}
												placeholder={t('track-details.city')}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>
									<Grid item sm={12} md={6} lg={4}>
										<FormGroup>
											<FormControl
												error={formik.touched.salesArea && !!formik.errors.salesArea}
												variant="standard"
											>
												<InputLabel shrink id="salesArea">
													{t('track-details.delivery-place')}
												</InputLabel>
												<Select
													label={t('track-details.delivery-place')}
													labelId="salesArea"
													name="salesArea"
													size="medium"
													sx={{ minHeight: 40 }}
													displayEmpty
													variant="standard"
													// input={<OutlinedInput notched label={t('track-details.delivery-place')} />}
													title={t('track-details.delivery-place')}
													onChange={formik.handleChange}
													value={formik.values.salesArea}
													disabled={formLocked || !!raceProducts?.inventories.length}
												>
													{countryList}
												</Select>
												{formik.touched.salesArea && formik.errors.salesArea && (
													<FormHelperText error={formik.touched.salesArea && !!formik.errors.salesArea}>
														{formik.touched.salesArea && formik.errors.salesArea}
													</FormHelperText>
												)}
											</FormControl>
										</FormGroup>
									</Grid>
									<Grid item sm={12} md={6} lg={4}>
										<FormControl error={formik.touched.salesAreaId && !!formik.errors.salesAreaId}>
											<FormLabel>
												<small>{t('track-details.delivery-zone')}</small>
											</FormLabel>
											<RadioGroup
												aria-label="salesAreaId"
												name="salesAreaId"
												onChange={formik.handleChange}
												value={formik.values.salesAreaId}
											>
												<Box display="flex" alignItems="center">
													{sortedSaleAreas.current
														.map((saleGroup) => ({
															value: saleGroup.id,
															label: saleGroup.name.charAt(0).toUpperCase() + saleGroup.name.slice(1),
														}))
														.map(({ value, label }) => (
															<FormControlLabel
																key={label}
																value={value}
																control={
																	<Radio
																		color="primary"
																		disabled={formLocked || !!raceProducts?.inventories.length}
																	/>
																}
																label={label}
															/>
														))}
												</Box>
												{formik.touched.salesAreaId && formik.errors.salesAreaId && (
													<FormHelperText error={true}>{formik.errors.salesAreaId}</FormHelperText>
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								<Box mb={3} />

								<Grid container spacing={3}>
									<Grid item xs={12} sm={6} lg={2}>
										<FormGroup>
											<TextField
												name="visibleFrom"
												label={t('track-details.race-start')}
												onChange={formik.handleChange}
												value={formik.values.visibleFrom}
												type="date"
												error={formik.touched.visibleFrom && !!formik.errors.visibleFrom}
												helperText={formik.touched.visibleFrom && formik.errors.visibleFrom}
												inputProps={{ className: classes.date }}
												InputProps={{
													disableUnderline: true,
													endAdornment: (
														<InputAdornment position="start">
															<DateIcon />
														</InputAdornment>
													),
													color: 'secondary',
												}}
												InputLabelProps={{
													shrink: true,
												}}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>

									<Grid item xs={12} sm={6} lg={2}>
										<FormGroup>
											<TextField
												name="visibleTo"
												label={t('track-details.race-end')}
												type="date"
												onChange={formik.handleChange}
												value={formik.values.visibleTo}
												error={formik.touched.visibleTo && !!formik.errors.visibleTo}
												helperText={formik.touched.visibleTo && formik.errors.visibleTo}
												inputProps={{ className: classes.date }}
												InputProps={{
													disableUnderline: true,

													endAdornment: (
														<InputAdornment position="start">
															<DateIcon />
														</InputAdornment>
													),
													color: 'secondary',
												}}
												InputLabelProps={{
													shrink: true,
												}}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>

									<Grid item xs={12} sm={6} lg={2}>
										<FormGroup>
											<TextField
												name="orderFrom"
												label={t('track-details.pre-orders-start')}
												type="date"
												onChange={formik.handleChange}
												value={formik.values.orderFrom}
												error={formik.touched.orderFrom && !!formik.errors.orderFrom}
												helperText={formik.touched.orderFrom && formik.errors.orderFrom}
												inputProps={{ className: classes.date }}
												InputProps={{
													disableUnderline: true,

													endAdornment: (
														<InputAdornment position="start">
															<DateIcon />
														</InputAdornment>
													),
													color: 'secondary',
												}}
												InputLabelProps={{
													shrink: true,
												}}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>

									<Grid item xs={12} sm={6} lg={2}>
										<FormGroup>
											<TextField
												name="orderTo"
												label={t('track-details.pre-orders-end')}
												type="date"
												onChange={formik.handleChange}
												value={formik.values.orderTo}
												error={formik.touched.orderTo && !!formik.errors.orderTo}
												helperText={formik.touched.orderTo && formik.errors.orderTo}
												inputProps={{ className: classes.date }}
												InputProps={{
													disableUnderline: true,
													endAdornment: (
														<InputAdornment position="start">
															<DateIcon />
														</InputAdornment>
													),
													color: 'secondary',
												}}
												InputLabelProps={{
													shrink: true,
												}}
												disabled={formLocked}
											/>
										</FormGroup>
									</Grid>

									<Grid item sm={12} md>
										<Box display="flex" mt={1}>
											<NumberRangeInput
												name="firstReminder"
												label={t('track-details.first-reminder')}
												error={formik.touched.firstReminder && !!formik.errors.firstReminder}
												helperText={formik.touched.firstReminder && formik.errors.firstReminder}
												onChange={formik.handleChange('firstReminder')}
												value={formik.values.firstReminder}
												disabled={formLocked}
											/>
											<NumberRangeInput
												name="secondReminder"
												error={formik.touched.secondReminder && !!formik.errors.secondReminder}
												helperText={formik.touched.secondReminder && formik.errors.secondReminder}
												label={t('track-details.second-reminder')}
												onChange={formik.handleChange('secondReminder')}
												value={formik.values.secondReminder}
												disabled={formLocked}
											/>
										</Box>
									</Grid>
								</Grid>
							</form>
						</Box>
					</Card>
				</Box>
				<Box mt={2} display="flex" justifyContent="flex-end">
					<Button
						variant="contained"
						color="primary"
						startIcon={<SaveIcon fontSize="small" />}
						onClick={() => formik.handleSubmit()}
						disabled={formLocked}
					>
						{t('shared.save')}
					</Button>
				</Box>
			</Box>

			<Box pt={4}>
				{track &&
				formik.values.salesArea === formik.initialValues.salesArea &&
				formik.values.salesAreaId === formik.initialValues.salesAreaId ? (
					raceProducts ? (
						<RaceProducts
							raceId={track.id}
							raceProducts={raceProducts}
							refetchRaceProducts={refetchRaceProducts}
							salesAreaId={track.sale_area_id}
						/>
					) : (
						<Loader />
					)
				) : (
					track && (
						<Box mb={2}>
							<Alert severity="info">{t('track-details.waiting-save')}</Alert>
						</Box>
					)
				)}
				<Container>
					<Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
						<Button
							component={Link}
							to={generatePath(ROUTES_URI.EVENT_DETAILS_DEFAULT, { id: event.id })}
							color="primary"
							startIcon={<ArrowRightIcon fontSize="small" />}
						>
							{t('shared.back')}
						</Button>
					</Box>
				</Container>
			</Box>
		</>
	);
};
export default RaceForm;
