import React, { FC, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import RaceForm from '@components/seller/event/partials/race-form';
import { useNavigate } from 'react-router-dom';
import { getValidationSchema } from '@components/seller/event/utils/get-validation-schema';
import { uploadMedia } from '@core/services/media';
import {
	useAddRaceCategoryMutation,
	useCreateTrackMutation,
	useGetEventForTrackAddEditQuery,
	useGetSalesAreaQuery,
} from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { ROUTES_URI } from '../../app/router/routes';
import i18next from 'i18next';
import { Loader } from '@components/shared/loader';
import { useSnackbar } from 'notistack';
import { RaceFormValues } from '@components/seller/event/types/race-form-props.type';
import { v4 as uuidv4 } from 'uuid';

const AddTrack: FC = () => {
	// hooks
	const { id } = useParams();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { data: salesAreaData } = useGetSalesAreaQuery({ variables: {} });
	const { data: eventData } = useGetEventForTrackAddEditQuery({ variables: { id } });
	const salesAreaList = salesAreaData?.salesArea.sales_area;
	const [addTrack, { data: createdTrackData }] = useCreateTrackMutation();
	const [addRaceDefaultProductCategory, { data: defaultProductCategory }] = useAddRaceCategoryMutation();
	const [addRaceOptionalProductCategory, { data: optionalProductCategory }] = useAddRaceCategoryMutation();
	// Constants
	const currentLanguage = i18next.language || 'en';
	const event = eventData?.store;
	const createdTrack = createdTrackData?.createStore;

	/**
	 * Create optional product sub category
	 */
	useEffect(() => {
		if (createdTrack) {
			enqueueSnackbar(t('track-edit.update-success'), { variant: 'success' });

			void addRaceDefaultProductCategory({
				variables: {
					params: {
						name: 'optional',
						title: {
							fr: t('track-details.optional-products'),
							en: t('track-details.optional-products'),
						},
						store_id: createdTrack.id,
					},
				},
			});
			void addRaceOptionalProductCategory({
				variables: {
					params: {
						name: 'default',
						title: {
							fr: t('track-details.catalog-products'),
							en: t('track-details.catalog-products'),
						},
						store_id: createdTrack.id,
					},
				},
			});
		}
	}, [createdTrack]);

	/**
	 * Handle redirect after track creation
	 */
	useEffect(() => {
		if (defaultProductCategory && optionalProductCategory) {
			navigate(generatePath(ROUTES_URI.TRACK_EDIT, { id, trackId: createdTrack.id }));
		}
	}, [defaultProductCategory, optionalProductCategory]);

	const onSubmit = async (values: RaceFormValues): Promise<void> => {
		const uploadedMedia = await uploadMedia(values.file);
		await addTrack({
			variables: {
				data: {
					parent_id: id,
					name: values.raceName,
					title: {
						fr: values.raceName,
						en: values.raceName,
					},
					hidden: false,
					code: uuidv4(),
					data: {
						storage_location: values.storageLocation,
						first_reminder: values.firstReminder,
						second_reminder: values.secondReminder,
						city: values.city,
					},
					status: 'active',
					edi_ref: values.shipTo,
					image: `!${uploadedMedia.key}`,
					order_from: new Date(values.orderFrom),
					order_to: new Date(values.orderTo),
					visible_from: new Date(values.visibleFrom),
					visible_to: new Date(values.visibleTo),
					sale_area_id: values.salesAreaId,
					sale_area_info: values.salesArea,
					business_unit: 'races',
				},
			},
		});
	};

	const validationSchema = salesAreaList && getValidationSchema(salesAreaList);

	return event && salesAreaList ? (
		<RaceForm
			event={{ id, name: event.title[currentLanguage] }}
			onSubmit={onSubmit}
			saleAreas={salesAreaList}
			validationSchema={validationSchema}
			initialValues={{
				raceName: '',
				shipTo: '',
				storageLocation: '',
				firstReminder: 1,
				secondReminder: 7,
				file: null,
				orderFrom: '',
				orderTo: '',
				visibleFrom: '',
				visibleTo: '',
				salesArea: '',
				salesAreaId: '',
				city: '',
			}}
		/>
	) : (
		<Loader />
	);
};

export default AddTrack;
