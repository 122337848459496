import { useEffect, useMemo } from 'react';
import {
	GetSellerEventForEditQuery,
	GetTracksForSellerEventForEditQuery,
	useGetSellerEventForEditLazyQuery,
	useGetTracksForSellerEventForEditLazyQuery,
	useUpdateEventMutation,
} from '../../../generated/graphql';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { EventFormValues } from '@components/seller/event/types/event-form-props.type';

type UseEventEdit = {
	data: {
		event: GetSellerEventForEditQuery['store'];
		updatedEvent?: GetSellerEventForEditQuery['store'];
		races: GetTracksForSellerEventForEditQuery['paginatedStores']['stores'];
	};
	refetchRaces: () => void;
	updateEvent: (values: EventFormValues) => void;
	loading: boolean;
	error: ApolloError;
};

export const useEventEdit = (): UseEventEdit => {
	const { id } = useParams();

	const [getEvent, { data: eventData, loading: loadingEvent, error: errorEvent }] = useGetSellerEventForEditLazyQuery({
		fetchPolicy: 'no-cache',
		variables: { id },
	});
	const [getRaces, { data: racesData, loading: loadingRaces, error: errorRaces, refetch: refetchRaces }] =
		useGetTracksForSellerEventForEditLazyQuery({
			fetchPolicy: 'no-cache',
			variables: {
				filter: [{ key: 'parent_id', comparison: FILTER_COMPARISON.EQUAL, value: id }],
			},
		});
	const [update, { data: updatedEventData }] = useUpdateEventMutation();

	const handleUpdate = (values: EventFormValues): void => {
		update({
			variables: {
				store_id: id,
				data: {
					name: values.eventName,
					title: {
						en: values.eventName,
						fr: values.eventNameFR || values.eventName,
					},
					hidden: values.hidden,
					code: values.eventName,
					business_unit: 'races',
					data: {
						mandatory_rib: values.mandatoryRib,
					},
				},
				paths: ['title.en', 'title.fr', 'hidden', 'data.mandatory_rib'],
			},
		});
	};

	const event = eventData?.store;
	const updatedEvent = updatedEventData?.updateStore;
	const races = racesData?.paginatedStores.stores;
	const loading = loadingEvent || loadingRaces;
	const error = errorEvent || errorRaces;

	useEffect(() => {
		getEvent();
		getRaces();
	}, []);

	return useMemo(
		() => ({ data: { event, updatedEvent, races }, loading, error, updateEvent: handleUpdate, refetchRaces }),
		[eventData?.store, racesData?.paginatedStores, updatedEventData?.updateStore]
	);
};
