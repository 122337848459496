import i18n, { Resource, ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nFetch from 'i18next-fetch-backend';
import merge from 'deepmerge';

import { en as enApp, fr as frApp } from './app';

interface ResourceJSon {
	[resource: string]: string;
}

interface IterativeObj {
	[key: string]: IterativeObj | string;
}

const getObjectWithPath = (path: string, value: string): IterativeObj => {
	return path
		.split('.')
		.reverse()
		.reduce((finalObject, currentKey) => {
			if (!Object.keys(finalObject).length) {
				return { ...finalObject, [currentKey]: value };
			}
			return { [currentKey]: { ...finalObject } };
		}, {});
};

const formatJsonToResourceKey = (obj: ResourceJSon): ResourceKey =>
	Object.entries(obj).reduce((obj, [path, value]) => {
		const pathObject = getObjectWithPath(path, value);
		return merge(obj, pathObject);
	}, {});

const resources: Resource = {
	en: {
		acs: formatJsonToResourceKey({ ...enApp }),
	},
	fr: {
		acs: formatJsonToResourceKey({ ...frApp }),
	},
};

i18n.on('languageChanged', (language) => localStorage.setItem('language', language));

export const languagesList = Object.keys(resources);

export enum LanguageEnum {
	FR = 'fr',
	EN = 'en',
}
/**
 * Get local language and remove if not available
 */
export const getLng = (): LanguageEnum => {
	const params = new URL(window.document.location.href).searchParams;
	const urlLang = params.get('lang');
	if (urlLang) {
		localStorage.setItem('language', urlLang.split('-')[0].toLowerCase());
	}

	const lngLocal = localStorage.getItem('language');
	const lang = lngLocal && languagesList.find((lang) => lang === lngLocal);
	if (lang) {
		return lang as LanguageEnum;
	} else if (lngLocal) {
		localStorage.removeItem('language');
	}
	return LanguageEnum.EN;
};

export const updateLanguage = (lng: LanguageEnum): void => {
	localStorage.setItem('language', lng);
	i18n.changeLanguage(lng);
};

i18n
	.use(i18nFetch)
	.use(initReactI18next)
	.init({
		resources,
		lng: getLng(),
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
