// react
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
// Utils
import { useTranslation } from 'react-i18next';
// Type
import { RegisterModalPropsType } from './registerModal.type';
// Kore
import { DialogWithIcon } from '@tmds-io/kore';
// Mui
import { Button, Typography } from '@mui/material';
import { ArrowRight as ArrowRightIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';

const NoFileUploadModal: FunctionComponent<
	RegisterModalPropsType & { setShowAddUserModal: Dispatch<SetStateAction<boolean>> }
> = ({ setShowModal, showModal, setShowAddUserModal }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	const handleClose = () => setShowModal(false);
	return (
		<DialogWithIcon
			icon={<AccessTimeIcon fontSize="large" color="primary" />}
			content={
				<Typography paragraph>
					<b>{t('register.bank-details.popup.body')}</b>
					{t('register.bank-details.popup.body2')}
				</Typography>
			}
			onClose={handleClose}
			open={showModal}
			title={t('register.bank-details.popup.title')}
			actionsContent={
				<>
					<Button
						startIcon={<ArrowRightIcon fontSize="small" />}
						variant="contained"
						color="primary"
						onClick={handleClose}
					>
						{t('register.bank-details.popup.footer-action')}
					</Button>
					<Button
						color="primary"
						startIcon={<ArrowRightIcon fontSize="small" />}
						onClick={() => {
							handleClose();
							setShowAddUserModal(true);
						}}
					>
						{t('shared.yes')}
					</Button>
				</>
			}
		/>
	);
};

export default NoFileUploadModal;
