import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypographyStyle } from '@core/styles/typography';
import { Button, Card, Grid, Typography } from '@mui/material';
import OrderProductsTable from '@components/shared/order/partials/order-products-table';
import { OrderProductsTableProps } from '@components/shared/order/types/order-product-table.type';
import { format } from 'date-fns';
import { useDateUtils } from '@core/utils/date.util';
import { useCancelOrderMutation, useSendThirdPartMessageMutation } from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ButtonConfirm } from '@components/ButtonConfirm';
import { useUserHelper } from '@core/hooks/useUserHelper';
import { ORDER_STATUS } from '@components/shared/order/enums/order-status.enum';

type OrderDetailsProps = {
	orderId: string;
	orderReference: string;
	isRaces: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	orderData?: Record<string, any>;
	orderSap: string;
	orderDate: string;
	orderPrice: number;
	orderStatus: string;
	orderProductTable: OrderProductsTableProps;
	onUpdate: () => void;
};

const OrderDetails: FunctionComponent<OrderDetailsProps> = ({
	orderId,
	orderReference,
	orderProductTable,
	orderDate,
	isRaces,
	orderData,
	orderPrice,
	onUpdate,
	orderSap,
}) => {
	const { isSeller } = useUserHelper();
	const { t } = useTranslation('acs', { useSuspense: false });
	const clsTypography = useTypographyStyle();
	const { enqueueSnackbar } = useSnackbar();
	const [forceConfirmOrder, { data: orderSentData, loading: forceConfirmOrderLoading }] =
		useSendThirdPartMessageMutation();
	const [cancelOrder, { data: orderCancelled, loading: orderCancelledLoading }] = useCancelOrderMutation();
	const { dateFormat } = useDateUtils();
	const handleSendToSap = (id: string): void => {
		forceConfirmOrder({
			variables: {
				code: 'push_orders',
				data: {
					order_ids: [id],
				},
				need_response: true,
				timeout: 30,
			},
		});
	};

	const handleCancelOrder = (id: string): void => {
		cancelOrder({
			variables: {
				id,
			},
		});
	};

	useEffect(() => {
		if (orderSentData || orderCancelled) {
			onUpdate();
			enqueueSnackbar(t('orders.order-updated'), { variant: 'success' });
		}
	}, [orderSentData || orderCancelled]);

	return (
		<>
			<Typography variant="h2" sx={{ mb: 4, pt: 4 }} className={clsTypography.normal}>
				{t('order.number')} n° {orderReference}
			</Typography>

			<Card square sx={{ backgroundColor: 'whitesmoke', p: 2 }}>
				<Grid container spacing={3}>
					<Grid item lg={4}>
						<Typography color="primary">{t('order.placed-on')} :</Typography>
						<Typography>{format(new Date(orderDate), dateFormat)}</Typography>
					</Grid>

					<Grid item lg={4}>
						<Typography color="primary">{t('order.purchases-included')} :</Typography>
						<Typography>{orderPrice}€</Typography>
					</Grid>

					<Grid item lg={4}>
						<Typography color="primary">{t('order.customer')} :</Typography>
						<Typography>
							{orderProductTable.profile.first_name} {orderProductTable.profile.last_name}
							{` <${orderProductTable.profile.mail}>`}
							<br />
							{'SAP: '}
							{orderProductTable.customerEdiRef}
						</Typography>
					</Grid>
					{isSeller && !orderSap ? (
						<>
							{(isRaces ||
								(!isRaces && orderProductTable.paymentStatus !== ORDER_STATUS.WAITING_FOR_PAYMENT)) && (
								<Grid item>
									<ButtonConfirm onConfirm={() => handleSendToSap(orderId)}>
										<Button variant="contained" disabled={forceConfirmOrderLoading}>
											{t('orders.push-to-sap')}
										</Button>
									</ButtonConfirm>
								</Grid>
							)}

							<Grid item>
								<Button
									disabled={orderCancelledLoading}
									onClick={() => handleCancelOrder(orderId)}
									variant="outlined"
								>
									{t('shared.cancel')}
								</Button>
							</Grid>
						</>
					) : null}
				</Grid>
			</Card>

			<Typography variant="h2" sx={{ mb: 4, mt: 4 }} className={clsTypography.normal}>
				{t('shared.details')}
			</Typography>

			<OrderProductsTable {...orderProductTable} orderData={orderData} />
		</>
	);
};

export default OrderDetails;
