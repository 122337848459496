// react
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomPaper } from '@components/CustomerPaper';

// Components properties
interface ResellerBusinessCardProps {
	name: string;
	street?: string;
	country: string;
	city: string;
}

/**
 * ResellerBusinessCard components -
 */
export const ResellerBusinessCard: FC<ResellerBusinessCardProps> = ({ name, city, country, street }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	return (
		<CustomPaper>
			<Typography variant="h3" sx={{ mb: 3 }}>
				{t('reseller-customer.add-page.business-details')}
			</Typography>
			<Box pl={2}>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('shared.name')}</strong> : {name}
				</Typography>
				{street && (
					<Typography sx={{ mb: 2 }}>
						<strong>{t('user-details.address')}</strong> : {street}
					</Typography>
				)}
				<Typography sx={{ mb: 2 }}>
					<strong>{t('shared.country')}</strong> : {country}
				</Typography>
				<Typography sx={{ mb: 2 }}>
					<strong>{t('shared.city')}</strong> : {city}
				</Typography>
			</Box>
		</CustomPaper>
	);
};
