// React
import React from 'react';
// Mui
import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import {
	ChevronRight as ChevronRightIcon,
	AccountCircleOutlined as ProfileIcon,
	PowerSettingsNew as LogoutIcon,
	AccountBalance,
} from '@mui/icons-material';
// Hooks
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@tmds-io/kore';
// Routing
import { generatePath, Link } from 'react-router-dom';
import { ROUTES_URI } from '@components/app/router/routes';

/**
 * Profile page
 */
export const Profile = (): JSX.Element => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const { logout } = useUserContext();

	const handleLogout = () => {
		logout();
	};

	const profileCategories = [
		{
			icon: <ProfileIcon color="inherit" />,
			title: 'customer.user-profile.business',
			link: generatePath(ROUTES_URI.PROFILE_DETAILS),
		},
		{
			icon: <AccountBalance color="inherit" />,
			title: 'customer.user-profile.payments',
			link: generatePath(ROUTES_URI.PROFILE_PAYMENT),
		},
		// {
		// 	icon: <TeamsIcon color="inherit" />,
		// 	title: 'customer.user-profile.business',
		// 	component: <TeamsPanel account={accountData?.account as Account} />,
		// },
		// {
		// 	icon: <UsersIcon color="inherit" />,
		// 	title: 'customer.user-profile.users',
		// 	component: <UsersPanel account={accountData?.account as Account} />,
		// },
	];

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
				<Typography variant="h1">{t('customer.user-profile.title')}</Typography>
				<Button variant="outlined" size="large" startIcon={<LogoutIcon />} color="primary" onClick={handleLogout}>
					{t('profile.logout')}
				</Button>
			</Box>

			<Box maxWidth={600} m={'auto'}>
				{profileCategories.map((category, index) => (
					<Card key={'profile-category' + index} square>
						<Box
							display="flex"
							alignItems="center"
							p={1}
							pl={2}
							pr={2}
							justifyContent="space-between"
							alignContent="center"
						>
							<Box display="flex" alignItems="center">
								{category.icon}
								<Box ml={3}>
									<Typography>
										<Box fontWeight="bold" component="span">
											{t(category.title)}
										</Box>
									</Typography>
								</Box>
							</Box>

							<IconButton size="small" component={Link} to={category.link}>
								<ChevronRightIcon color="secondary" fontSize="large" />
							</IconButton>
						</Box>
					</Card>
				))}
			</Box>
		</>
	);
};
