import React, { FunctionComponent, useEffect } from 'react';
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { ORDER_STATUS } from '@components/shared/order/enums/order-status.enum';
import { useTranslation } from 'react-i18next';
import { useTypographyStyle } from '@core/styles/typography';
import { ArrowRight as ArrowRightIcon, GetApp as DownloadIcon } from '@mui/icons-material';
import { generatePath, useNavigate } from 'react-router-dom';
import { ROUTES_URI } from '../../../app/router/routes';
import { OrderProduct, OrderProductsTableProps } from '@components/shared/order/types/order-product-table.type';
import { useGetThirdPartDataMutation } from '../../../../generated/graphql';
import { useUserHelper } from '@core/hooks/useUserHelper';
import { useSnackbar } from 'notistack';
import { downloadFile } from '@core/utils/file';
import { getShipmentLabel, getShipmentTransportLabel } from '@components/ResellerShippingCard';

const OrderProductsTable: FunctionComponent<OrderProductsTableProps> = ({
	raceName,
	championshipName,
	orderEdiRef,
	orderProducts,
	paymentStatus,
	orderId,
	orderData,
}) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();
	const { isReseller, isSeller } = useUserHelper();
	const clsTypography = useTypographyStyle();
	const navigate = useNavigate();
	const [getProforma, { data: proformaData, error: proformaError, loading: proformaLoading }] =
		useGetThirdPartDataMutation();
	const [getInvoice, { data: invoiceData, error: invoiceError, loading: invoiceLoading }] =
		useGetThirdPartDataMutation();

	const handlePayment = () => {
		navigate(generatePath(ROUTES_URI.PAYMENT, { id: orderId }));
	};

	const handleProforma = (order_id: string) => () => {
		getProforma({
			variables: {
				code: 'proforma',
				data: {
					order_id,
				},
				timeout: 30,
				needResponse: true,
			},
		});
	};

	const handleInvoice = (order: OrderProduct) => () => {
		order.invoiceIdList.forEach((document_id) => {
			getInvoice({
				variables: {
					code: 'invoice',
					data: {
						document_id,
					},
					timeout: 30,
					needResponse: true,
				},
			});
		});
	};

	useEffect(() => {
		if (proformaData?.sendThirdPartMessage.data && !proformaData.sendThirdPartMessage.failed) {
			downloadFile(proformaData.sendThirdPartMessage.data.file, 'proforma', 'pdf');
		}
	}, [proformaData]);

	useEffect(() => {
		if (invoiceData?.sendThirdPartMessage.data && !invoiceData.sendThirdPartMessage.failed) {
			downloadFile(invoiceData.sendThirdPartMessage.data.file, 'invoice', 'pdf');
		}
	}, [invoiceData]);

	useEffect(() => {
		if (
			(invoiceData?.sendThirdPartMessage && !invoiceData.sendThirdPartMessage.failed) ||
			(proformaData?.sendThirdPartMessage && !proformaData.sendThirdPartMessage.failed)
		) {
			enqueueSnackbar(t('order-products.dl-success'), { variant: 'info' });
		}
	}, [invoiceData, proformaData]);

	useEffect(() => {
		if (invoiceError || invoiceData?.sendThirdPartMessage.failed) {
			console.error('Fail download invoice %s', orderId);
			enqueueSnackbar(t('order-products.invoice-error'), { variant: 'error' });
		}
	}, [invoiceError, invoiceData?.sendThirdPartMessage.failed]);

	useEffect(() => {
		if (proformaError || proformaData?.sendThirdPartMessage.failed) {
			console.error('Fail download proforma %s', orderId);
			enqueueSnackbar(t('order-products.proforma-error'), { variant: 'error' });
		}
	}, [proformaError, proformaData?.sendThirdPartMessage.failed]);

	return (
		<>
			<Card square sx={{ p: 3, mb: 3 }}>
				<Grid container spacing={3} sx={{ mb: 3 }}>
					{!isReseller ? (
						<Grid item lg={4}>
							<Typography className={clsTypography.bold}>
								{t(isSeller && !championshipName ? 'shared.group' : 'track-details.race-name')} :{' '}
								<span className={clsTypography.normal}>{raceName}</span>
							</Typography>
							{championshipName ? (
								<Typography className={clsTypography.bold}>
									{t('events.name')} : <span className={clsTypography.normal}>{championshipName}</span>
								</Typography>
							) : null}
						</Grid>
					) : null}

					{orderData?.shipping_information && (isReseller || (isSeller && !championshipName)) ? (
						<>
							<Grid item lg={4}>
								<Typography>
									<strong>{t('delivery-selector.preferredDeliveryMethod')}</strong>:{' '}
									{getShipmentLabel(orderData.shipping_information.carrier_code, t)}
								</Typography>
							</Grid>
							<Grid item lg={4}>
								<Typography>
									<strong>{t('delivery-selector.deliveryPoint')}</strong>:{' '}
									{orderData.shipping_information.delivery_point}
								</Typography>
							</Grid>
							{orderData.shipping_information.desired_shipping_date && (
								<Grid item lg={4}>
									<Typography>
										<strong>{t('delivery-selector.collectionDate')}</strong>:{' '}
										{orderData.shipping_information.desired_shipping_date}
									</Typography>
								</Grid>
							)}
							<Grid item lg={4}>
								<Typography>
									<strong>{t('delivery-selector.meansOfConveying')}</strong>:{' '}
									{t(getShipmentTransportLabel(orderData.shipping_information.method_code))}
								</Typography>
							</Grid>
						</>
					) : null}

					<Grid item lg={4}>
						<Typography className={clsTypography.bold}>
							{t('order.supplier-order-number')} :{' '}
							<span className={clsTypography.normal}>{orderEdiRef || '---'}</span>
						</Typography>
					</Grid>

					<Grid item lg={4}>
						<Typography className={clsTypography.bold}>
							{t('order.payment-status')} :{' '}
							<Box
								component="span"
								color={paymentStatus === ORDER_STATUS.WAITING_FOR_PAYMENT ? 'red' : 'inherit'}
								className={clsTypography.normal}
							>
								{t('order.' + paymentStatus)}
							</Box>
						</Typography>
					</Grid>
				</Grid>

				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('event-details.total-products')}</Typography>
							</TableCell>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('shared.quantity')}</Typography>
							</TableCell>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('order.quantity-charge')}</Typography>
							</TableCell>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('order.quantity-facture')}</Typography>
							</TableCell>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('order.unit-price')}</Typography>
							</TableCell>
							<TableCell>
								<Typography className={clsTypography.uppercase}>{t('cart.page.total')}</Typography>
							</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{orderProducts.map((order) => (
							<TableRow key={order.sku}>
								<TableCell>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<img width="30px" src={order.image} alt="" />
										<Typography sx={{ ml: 2 }}>{order.name}</Typography>
									</Box>
								</TableCell>
								<TableCell>{order.quantity}L</TableCell>
								<TableCell>{order.chargedQuantity}L</TableCell>
								<TableCell>{order.invoiceQuantity}L</TableCell>
								<TableCell>{order.price}€</TableCell>
								<TableCell>{order.total}€</TableCell>
								<TableCell>
									<Box display="flex">
										{[
											ORDER_STATUS.CONFIRMED,
											ORDER_STATUS.PAID,
											ORDER_STATUS.WAITING_FOR_PAYMENT_RECEPTION,
											ORDER_STATUS.INVOICED,
										].includes(paymentStatus as ORDER_STATUS) ? (
											<>
												<Button
													sx={{
														display: proformaLoading ? undefined : 'none',
														mr: paymentStatus === ORDER_STATUS.INVOICED ? 2 : 0,
													}}
													startIcon={<CircularProgress size={16} />}
													fullWidth
													color="primary"
													disabled={proformaLoading}
												>
													{t('order.proforma')}
												</Button>
												<Button
													sx={{
														display: proformaLoading ? 'none' : undefined,
														mr: paymentStatus === ORDER_STATUS.INVOICED ? 2 : 0,
													}}
													fullWidth
													color="primary"
													startIcon={<DownloadIcon />}
													onClick={handleProforma(order.orderId)}
													disabled={proformaLoading}
												>
													{t('order.proforma')}
												</Button>
											</>
										) : null}
										{paymentStatus === ORDER_STATUS.INVOICED && order.invoiceIdList.length ? (
											<>
												<Button
													startIcon={<CircularProgress size={16} />}
													fullWidth
													color="primary"
													disabled={invoiceLoading}
													sx={{ display: invoiceLoading ? undefined : 'none' }}
												>
													{t('order.invoice')}
												</Button>
												<Button
													startIcon={<DownloadIcon />}
													fullWidth
													color="primary"
													disabled={invoiceLoading}
													onClick={handleInvoice(order)}
													sx={{ display: invoiceLoading ? 'none' : undefined }}
												>
													{t('order.invoice')}
												</Button>
											</>
										) : null}
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Card>
			{!isSeller && paymentStatus === ORDER_STATUS.WAITING_FOR_PAYMENT && (
				<Box textAlign="right">
					<Button variant="contained" startIcon={<ArrowRightIcon />} color="primary" onClick={handlePayment}>
						{t('shared.pay')}
					</Button>
				</Box>
			)}
		</>
	);
};

export default OrderProductsTable;
