import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useTrackProductsStyle = makeStyles((theme: Theme) =>
	createStyles({
		productsHolder: {
			width: '100%',
			background: theme.palette.background.paper,
			border: '1px solid #e8e8e8',
			overflowY: 'scroll',
			height: '500px',
		},
	})
);
