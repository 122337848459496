import { useUserContext } from '@tmds-io/kore';

type PlatformUserBase = {
	isReseller: boolean;
	isAdmin: boolean;
	isCompetitor: boolean;
	isSeller: boolean;
};

type ResellerBase = PlatformUserBase & {
	isReseller: true;
	isAdmin: boolean;
	isCompetitor: false;
	isSeller: false;
};

type CompetitorBase = PlatformUserBase & {
	isReseller: false;
	isAdmin: boolean;
	isCompetitor: true;
	isSeller: false;
};

type TeafsUserBase = PlatformUserBase & {
	isReseller: false;
	isAdmin: boolean;
	isCompetitor: false;
	isSeller: true;
};

type UnknownUser = PlatformUserBase & {
	isReseller: false;
	isAdmin: false;
	isCompetitor: false;
	isSeller: false;
};

type ResellerAdmin = ResellerBase & { isAdmin: true };
type Reseller = ResellerBase & { isAdmin: false };
type CompetitorAdmin = CompetitorBase & { isAdmin: true };
type Competitor = CompetitorBase & { isAdmin: false };
type TeafsUserAdmin = TeafsUserBase & { isAdmin: true };
type TeafsUser = TeafsUserBase & { isAdmin: false };

export type PlatformUser =
	| ResellerAdmin
	| Reseller
	| Competitor
	| CompetitorAdmin
	| TeafsUserAdmin
	| TeafsUser
	| UnknownUser;

export const RESELLER_ADMIN = 'reseller_admin';
export const RESELLER_USER = 'reseller_employee';
export const COMPETITOR_ADMIN = 'customer_admin';
export const COMPETITOR_USER = 'customer_employee';
export const TEAFS_ADMIN = 'seller_admin';
export const TEAFS_USER = 'seller_employee';
export const COMPETITORS_BU = 'races';
export const RESELLERS_BU = 'resellers';

export const useUserHelper = (): PlatformUser => {
	const { businessUnit, roles } = useUserContext();
	const isAdmin = roles.includes(RESELLER_ADMIN) || roles.includes(COMPETITOR_ADMIN) || roles.includes(TEAFS_ADMIN);
	const isSeller = roles.includes(TEAFS_ADMIN) || roles.includes(TEAFS_USER);
	const isCompetitor = businessUnit === COMPETITORS_BU && !isSeller;
	const isReseller = businessUnit === RESELLERS_BU && !isSeller;

	if (isReseller) {
		return {
			isReseller: true,
			isCompetitor: false,
			isSeller: false,
			isAdmin,
		};
	} else if (isCompetitor) {
		return {
			isReseller: false,
			isCompetitor: true,
			isSeller: false,
			isAdmin,
		};
	} else if (isSeller) {
		return {
			isReseller: false,
			isCompetitor: false,
			isSeller: true,
			isAdmin,
		};
	} else {
		return {
			isReseller: false,
			isSeller: false,
			isCompetitor: false,
			isAdmin: false,
		};
	}
};
