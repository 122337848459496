// React
import React, { FC } from 'react';
// Components
import { Box, Card, Grid, Typography } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
// Utils
import { getMediaUrl } from '@core/services/media';
import { useTypographyStyle } from '@core/styles/typography';
import i18next from 'i18next';
// Types
import { ProductListProps } from '@components/seller/event-details/types/product-list-props.type';

/**
 * Seller product list component, display race products for administration
 * @param allProducts
 * @param filterProducts
 * @param title
 * @param titleColorPrimary
 * @constructor
 */
export const ProductList: FC<ProductListProps> = ({ allProducts, filterProducts, title, titleColorPrimary }) => {
	const currentLanguage = i18next.language || 'en';
	const clsTypography = useTypographyStyle();

	return (
		<>
			<Box mb={3}>
				<Typography variant="h2" color={titleColorPrimary ? 'primary' : undefined}>
					{title}
				</Typography>
			</Box>

			<Grid container spacing={3}>
				{allProducts.length
					? allProducts
							.filter(({ variation }) => filterProducts?.includes(variation.sku))
							.map(({ product, variation, price }) => {
								const [increment, quantity] = variation.options[0].value.split('_');
								return (
									<Grid key={product.id} item sm={6} lg={4}>
										<Card square>
											<Box p={2}>
												<Grid container justifyContent="space-between">
													<Grid container item xs>
														<Box
															sx={{
																display: 'flex',
																alignItems: 'center',
																justifyContent: 'center',
																width: 64,
																height: 64,
															}}
														>
															{product.image ? (
																<img
																	style={{ maxHeight: '100%', maxWidth: '100%' }}
																	src={getMediaUrl(product.image)}
																	alt=""
																/>
															) : (
																<BrokenImage />
															)}
														</Box>
														<Box ml={2} sx={{ maxWidth: 200 }}>
															<Typography className={clsTypography.lineClamp}>
																<small>{product.categories?.[0].title[currentLanguage] || 'N/A'}</small>
															</Typography>
															<Typography>
																<b>{product.title[currentLanguage]}</b>
															</Typography>
															<Typography>
																<sub>
																	{(
																		(price.custom_price || price.price) *
																		+increment *
																		+quantity
																	).toFixed(2)}
																	{product?.currency === 'EUR' ? '€' : product?.currency}
																</sub>
															</Typography>
														</Box>
													</Grid>
													<Grid item>
														<Typography>
															<b>
																{increment}x{quantity}
																{product.qty_unit}
															</b>
														</Typography>
														<Typography>
															<small>
																{price.custom_price || price.price}
																{product?.currency === 'EUR' ? '€' : product?.currency}/L
															</small>
														</Typography>
													</Grid>
												</Grid>
											</Box>
										</Card>
									</Grid>
								);
							})
					: null}
			</Grid>
		</>
	);
};
