// React
import React, { FunctionComponent } from 'react';
// Types
import { FormDataType } from '../shared/types/formData.type';
// shared
import { BusinessForm as Form } from '@components/shared/BusinessForm';
// gql
import { AccountInput } from '../../../generated/graphql';

const BusinessForm: FunctionComponent<FormDataType> = ({ formData, next }) => {
	const handleSubmit = (account: AccountInput) => {
		formData.account = account;
		next();
	};
	return <Form onSubmit={handleSubmit} />;
};

export default BusinessForm;
