import {
	Filter,
	GetCustomersQuery,
	GetCustomersQueryVariables,
	Limit,
	Sort,
	useGetCustomersLazyQuery,
	useGetCustomersWithContractProductsQuery,
} from '../../generated/graphql';
import {
	formatCustomerAccounts,
	formatCustomerAccountsWithContractProducts,
} from '@core/utils/format-customer-accounts';
import { formatResponsePagination } from '@core/utils/format-response-pagination';
import { UserTableAccountRow } from '@components/seller/user/types/user-table-props.type';
import { ResponsePagination } from '@core/types/response-pagination';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { ApolloError } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import * as Apollo from '@apollo/client';

type AccountsData = {
	accounts: UserTableAccountRow[];
	pagination: ResponsePagination;
};
export type AccountsWithContractProductsData = {
	accounts: (UserTableAccountRow & { productList: string[]; contractId: string; stores: string[] })[];
	pagination: ResponsePagination;
};
type AccountsRefetch = (
	variables?: Partial<GetCustomersQueryVariables>
) => Promise<ApolloQueryResult<GetCustomersQuery>>;

interface ResultState {
	data: AccountsData | undefined;
	refetch: AccountsRefetch;
	loading: boolean;
	error: ApolloError;
	getCustomers: (options?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) => void;
}

export const useGetAccounts = (filter?: Filter[], limit?: Limit, sort?: Sort): Omit<ResultState, 'getCustomers'> => {
	return useGetAccountsBase(filter, limit, sort);
};
export const useGetAccountsLazy = (
	filter?: Filter[],
	limit?: Limit,
	sort?: Sort
): [
	getCustomers: (options?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) => void,
	data: Omit<ResultState, 'getCustomers'>
] => {
	const { getCustomers, ...others } = useGetAccountsBase(filter, limit, sort, true);
	return [getCustomers, others];
};

export const useGetAccountsBase = (
	filter?: Filter[],
	limit?: Limit,
	sort?: Sort,
	lazy?: boolean
): ResultState & {
	getCustomers: (options?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) => void;
} => {
	const [getCustomers, { data, refetch, loading, error }] = useGetCustomersLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const [result, setResult] = useState<ResultState>({
		data: undefined,
		refetch,
		loading: true,
		error,
		getCustomers,
	});

	useEffect(() => {
		if (!lazy) {
			void getCustomers({ variables: { filter, limit, sort } });
		}
	}, [filter, limit, sort]);

	useEffect(() => {
		if (data) {
			setResult({
				refetch,
				loading,
				error,
				data: {
					accounts: formatCustomerAccounts(data.customerAccounts.accounts),
					pagination: formatResponsePagination(data.customerAccounts.pagination),
				},
				getCustomers,
			});
		}
	}, [data]);

	return result;
};

export const useGetAccountsWithContracts = (
	filter?: Filter[],
	limit?: Limit,
	sort?: Sort
): {
	data: AccountsWithContractProductsData | undefined;
	refetch: AccountsRefetch;
	loading: boolean;
	error: ApolloError;
} => {
	const { data, refetch, loading, called, error } = useGetCustomersWithContractProductsQuery({
		variables: { filter, limit, sort },
		fetchPolicy: 'no-cache',
	});

	const accounts = data && formatCustomerAccountsWithContractProducts(data.customerAccounts.accounts);
	const pagination = data && formatResponsePagination(data.customerAccounts.pagination);

	return useMemo(
		() => ({ data: accounts && pagination && { accounts, pagination }, refetch, loading: loading || !called, error }),
		[data]
	);
};
