import { OrderFieldsFragment } from '../../../generated/graphql';
import { Order } from '@components/shared/order/types/orders.type';

export const formatOrders = (orders: OrderFieldsFragment[], currentLanguage: string): Order[] => {
	return orders.map(
		({
			id,
			reference,
			quantity_ordered,
			created_at,
			edi_reference,
			status,
			store_title,
			parent_store_title,
			customer_company,
			customer_edi_reference,
			total,
			user_first_name,
			user_last_name,
		}) => {
			return {
				id,
				reference,
				ediReference: edi_reference,
				creationDate: created_at,
				event: parent_store_title[currentLanguage],
				race: store_title[currentLanguage],
				companyName: customer_company,
				companyRef: customer_edi_reference,
				lastname: user_last_name,
				firstname: user_first_name,
				status,
				volume: quantity_ordered,
				total: total,
			};
		}
	);
};
