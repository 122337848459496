// react
import React, { FC } from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Components properties
interface CustomerInvitationProps {
	title: string;
	loading: boolean;
	onSubmit: (values: CustomerFormValues) => void;
}

export interface CustomerFormValues {
	firstName: string;
	lastName: string;
	email: string;
}

const validationSchema = Yup.object().shape({
	firstName: Yup.string().required('firstname-not-defined'),
	lastName: Yup.string().required('lastname-not-defined'),
	email: Yup.string().email('email-invalid').required('email-not-defined'),
});

/**
 * CustomerInvitation components -
 */
export const CustomerInvitation: FC<CustomerInvitationProps> = ({ title, onSubmit, loading }) => {
	const { t } = useTranslation('acs', { useSuspense: false });
	const form = useFormik<CustomerFormValues>({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
		},
		onSubmit: (values) => {
			onSubmit(values);
			form.resetForm();
		},
		validationSchema,
	});

	return (
		<Paper sx={{ p: 4 }}>
			<Typography variant="h2" sx={{ mb: 2 }}>
				{title}
			</Typography>
			<TextField
				fullWidth
				sx={{ display: 'block', mb: 3 }}
				label={t('user-details.first-name')}
				value={form.values.firstName}
				name="firstName"
				onChange={form.handleChange}
				error={form.touched.firstName && !!form.errors.firstName}
				helperText={form.touched.firstName && t(form.errors.firstName)}
			/>
			<TextField
				fullWidth
				sx={{ display: 'block', mb: 3 }}
				label={t('user-details.last-name')}
				value={form.values.lastName}
				name="lastName"
				onChange={form.handleChange}
				error={form.touched.lastName && !!form.errors.lastName}
				helperText={form.touched.lastName && t(form.errors.lastName)}
			/>
			<TextField
				fullWidth
				sx={{ display: 'block', mb: 3 }}
				label={t('user-details.email')}
				value={form.values.email}
				name="email"
				type="email"
				onChange={form.handleChange}
				error={form.touched.email && !!form.errors.email}
				helperText={form.touched.email && t(form.errors.email)}
			/>

			<Box mt={2} display="flex" justifyContent="flex-end">
				<Button color="primary" variant="contained" onClick={() => form.handleSubmit()} disabled={loading}>
					{t('members.page.send-invitation')}
				</Button>
			</Box>
		</Paper>
	);
};
