import {
	useGetCustomerProductsLazyQuery,
	useGetGroupCustomerStoreQuery,
	useGetRaceInventoriesLazyQuery,
} from '../../../generated/graphql';
import { UseResellerCustomerProductList } from './useResellerCustomerProductList.type';
import { formatEventProduct } from '@components/customer/event/event-details/format-event-product';
import { useEffect, useState } from 'react';
import { FILTER_COMPARISON } from '@tmds-io/kore';
import { useCartContext } from '@core/services/cart/cart.context';
import { CartProduct } from '@core/services/cart/cart.type';

// const formatOrderProduct = (
// 	order: OrderFieldsFragment,
// 	storeId: string,
// 	inventories: { id: string; product_sku: string }[] = []
// ): CartProduct[] => {
// 	return order.order_lines
// 		.filter(({ sku }) => inventories.find(({ product_sku }) => product_sku === sku).id)
// 		.map(({ sku, image, title, quantity_ordered, quantity_increment, price }) => ({
// 			quantity: +(quantity_ordered / quantity_increment).toFixed(),
// 			price,
// 			volume: quantity_ordered,
// 			incrementVolume: 0,
// 			image: image,
// 			sku,
// 			title,
// 			description: { en: '', fr: '' },
// 			storeId,
// 			inventoryId: inventories.find(({ product_sku }) => product_sku === sku).id,
// 			qtyIncrement: quantity_increment,
// 		}));
// };

export const useResellerCustomerProductList = (): UseResellerCustomerProductList => {
	const { data: storeData, error: storeError } = useGetGroupCustomerStoreQuery({
		fetchPolicy: 'no-cache',
		variables: {},
	});
	const [localState, setLocalState] = useState<UseResellerCustomerProductList>({
		data: undefined,
		loading: true,
		error: false,
	});
	// const { id } = useUserContext();
	const { add } = useCartContext();
	const [getInventories, { data: inventoriesData, error: inventoriesError }] = useGetRaceInventoriesLazyQuery();
	// const { data: lastOrderData } = useGetOrdersQuery({
	// 	variables: {
	// 		filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'customer_id', value: id }],
	// 		limit: { page_number: 1, count: 1 },
	// 		sort: { keys: ['created_at'], sort: -1 },
	// 	},
	// });
	const [getProducts, { data, error }] = useGetCustomerProductsLazyQuery();
	const storeId = storeData?.customerStore[0]?.store?.id;

	useEffect(() => {
		if (storeId) {
			void getProducts({ variables: { store_ids: [storeId] } });
			void getInventories({
				variables: {
					filter: [
						{
							comparison: FILTER_COMPARISON.EQUAL,
							key: 'store_id',
							value: storeData?.customerStore[0]?.store?.id,
						},
					],
				},
			});
		}
	}, [storeData]);

	const handleAddLastOrderToCart = (cartProductList: CartProduct[]): void => {
		cartProductList.forEach((cartProduct) => add(cartProduct));
	};

	useEffect(() => {
		if (data?.customerProducts && inventoriesData?.inventories?.inventories?.length) {
			setLocalState({
				data: {
					products: data.customerProducts
						.map((data) => formatEventProduct(data, inventoriesData.inventories.inventories))
						.sort((a, b) => (a.variation.sku > b.variation.sku ? -1 : 1)),
					storeId: storeId,
					addLastOrder: handleAddLastOrderToCart,
					lastOrder: undefined,
				},
				loading: false,
				error: false,
			});
		}
	}, [data?.customerProducts, inventoriesData?.inventories?.inventories]);

	useEffect(() => {
		if (error || storeError || inventoriesError) {
			setLocalState({ data: undefined, error: true, loading: false });
		}
	}, [error, storeError, inventoriesError]);

	return localState;
};
