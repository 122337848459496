// react
import React, { FC, useState } from 'react';
// Types
import { EventsPropsType } from './types/events-props.type';
// Component
import { Box, Button, Typography, Skeleton } from '@mui/material';
import { EventBox } from '@components/shared/components/EventBox';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ModalEditEvents from '@components/customer/event/partials/modal-edit-events';
import EditIcon from '@mui/icons-material/Edit';
import TimelapseIcon from '@mui/icons-material/Timelapse';
// Utils
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// Styles
import { useTypographyStyle } from '@core/styles/typography';
import { generatePath } from 'react-router-dom';
// Constants
import { ROUTES_URI } from '../../app/router/routes';
import { Store, useRemoveCustomerEventsMutation, useUpdateCustomerEventsMutation } from '../../../generated/graphql';
import { useUserContext } from '@tmds-io/kore';
import { isPast } from 'date-fns';
import { useRaceDate } from '@core/utils/useRaceDate';

const Info = ({ noRace, noOrder }: { noRace: boolean; noOrder: boolean }) => {
	const { t } = useTranslation('acs', { useSuspense: false });

	if (noRace) {
		return (
			<Box display="flex">
				<TimelapseIcon sx={{ mr: 1 }} /> <Typography>{t('customer.events.event-not-yet-available')}</Typography>
			</Box>
		);
	} else if (noOrder) {
		return (
			<Box display="flex">
				<ErrorOutlineIcon sx={{ mr: 1 }} color="error" />{' '}
				<Typography color="error">{t('customer.events.no-event-order')}</Typography>
			</Box>
		);
	}
	return null;
};

/**
 * Customer event listing component display user's available event
 */
export const Events: FC<EventsPropsType> = ({ events = [], loading, refetch }) => {
	// Styles
	const clsTypography = useTypographyStyle();
	// Hooks
	const { t } = useTranslation('acs', { useSuspense: false });
	const [updateCustomerEvents] = useUpdateCustomerEventsMutation();
	const [removeCustomerEvents] = useRemoveCustomerEventsMutation();
	const { id } = useUserContext();
	const { getLastRaceDate } = useRaceDate();
	// State
	const [openEditEventsModal, setOpenEditEventsModal] = useState<boolean>(false);
	const [eventsDisabledByUser, setEventsDisabledByUser] = useState<string[]>(events.map((event) => event.id));
	const currentLanguage = i18next.language || 'en';

	const handleOpenEditEventsModal = () => {
		setOpenEditEventsModal(true);
	};

	const handleCloseEditEventsModal = (idList?: string[]) => {
		if (idList) {
			if (idList.length) {
				void updateCustomerEvents({
					variables: { customerId: id, storeIds: idList },
				});
			} else {
				removeCustomerEvents({
					variables: { customerId: id, storeIds: eventsDisabledByUser },
				});
			}
			setEventsDisabledByUser(idList);

			refetch();
		}
		setOpenEditEventsModal(false);
	};

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h1" className={clsTypography.textTransFormInitial}>
					{t('customer.events.select-event')}
				</Typography>
				<Button variant="outlined" startIcon={<EditIcon />} color="primary" onClick={handleOpenEditEventsModal}>
					{t('shared.edit')}
				</Button>
			</Box>
			<Box mt={2}>
				<Typography variant="h2" color="primary" sx={{ mb: 2 }}>
					{t('events.incoming')}
				</Typography>
				{loading ? (
					new Array(4)
						.fill(true)
						.map((_, index) => <Skeleton key={index} sx={{ mb: 2 }} variant="rectangular" height={70} />)
				) : events.length ? (
					events
						.filter(({ status, children }) => {
							return status === 'active' && children.length
								? !isPast(new Date(getLastRaceDate(children as Store[])))
								: true;
						})
						.map(({ id, title, noRace, noOrder }) => (
							<EventBox
								key={id}
								name={title[currentLanguage]}
								eventLink={generatePath(ROUTES_URI.EVENT_DETAILS_DEFAULT, { id })}
								info={<Info noOrder={noOrder} noRace={noRace} />}
							/>
						))
				) : (
					<Box pt={2} mb={4} justifyContent="center" width="100%">
						<Typography variant="h5" color="textSecondary" align="center">
							{t('customer.events.no-events-available')}
						</Typography>
					</Box>
				)}
			</Box>
			<Box mt={2}>
				<Typography variant="h2" color="primary" sx={{ mb: 2 }}>
					{t('events.passed')}
				</Typography>
				{loading ? (
					new Array(4)
						.fill(true)
						.map((_, index) => <Skeleton key={index} sx={{ mb: 2 }} variant="rectangular" height={70} />)
				) : events.length ? (
					events
						.filter(({ status, children }) => {
							return status === 'active' && children.length
								? isPast(new Date(getLastRaceDate(children as Store[])))
								: true;
						})
						.map(({ id, title }) => (
							<EventBox
								key={id}
								name={title[currentLanguage]}
								eventLink={generatePath(ROUTES_URI.EVENT_DETAILS_DEFAULT, { id })}
							/>
						))
				) : (
					<Box pt={2} mb={4} justifyContent="center" width="100%">
						<Typography variant="h5" color="textSecondary" align="center">
							{t('customer.events.no-events-available')}
						</Typography>
					</Box>
				)}
			</Box>
			<Typography
				sx={{ pl: 2, cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}
				onClick={handleOpenEditEventsModal}
			>
				{t('customer.events.info-link')}
			</Typography>
			<ModalEditEvents
				open={openEditEventsModal}
				onClose={handleCloseEditEventsModal}
				filter={eventsDisabledByUser}
			/>
		</>
	);
};
