// React
import React, { FunctionComponent } from 'react';
// types
import { ContactDetailsFieldsTemplateType } from './contactDetailsFieldsTemplate.type';
// Utils
import { useTranslation } from 'react-i18next';
// Mui
import { FormGroup, TextField, Grid, Box } from '@mui/material';

export const ContactDetailsFieldsTemplate: FunctionComponent<ContactDetailsFieldsTemplateType> = ({
	firstName,
	lastName,
	email,
	valueFirstName,
	valueLastName,
	valueEmail,
	errors,
	touched,
	formik,
}) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	return (
		<>
			<Box mb={3}>
				<Grid container spacing={2}>
					<Grid item sm={12} lg={6}>
						<FormGroup>
							<TextField
								error={touched ? touched.firstName && !!errors?.firstName : !!errors?.firstName}
								name={firstName}
								required
								label={t('user-details.first-name')}
								value={valueFirstName}
								helperText={touched ? touched.firstName && errors?.firstName : errors?.firstName}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</Grid>

					<Grid item sm={12} lg={6}>
						<FormGroup>
							<TextField
								error={touched ? touched.lastName && !!errors?.lastName : !!errors?.lastName}
								name={lastName}
								required
								label={t('user-details.last-name')}
								value={valueLastName}
								helperText={touched ? touched.lastName && errors?.lastName : !!errors?.lastName}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</Grid>
				</Grid>
			</Box>
			<FormGroup sx={{ mb: 3 }}>
				<TextField
					error={touched ? touched.email && !!errors?.email : !!errors?.email}
					name={email}
					required
					type="email"
					label={t('register.contact-details.form.email')}
					value={valueEmail}
					helperText={touched ? touched.email && errors?.email : errors?.email}
					onChange={formik.handleChange}
				/>
			</FormGroup>
		</>
	);
};
