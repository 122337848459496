import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { EventFormPropsType, EventFormValues } from '@components/seller/event/types/event-form-props.type';
import { Box, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';
import { CreateOutlined as CreateIcon, Save as SaveIcon } from '@mui/icons-material';
import TranslationTextField from '@components/shared/TranslationTextField';
import * as Yup from 'yup';
import { AddRace } from '@components/AddRace';

const schema = Yup.object().shape({
	eventName: Yup.string().required(),
	eventNameFR: Yup.string(),
	mandatoryRib: Yup.boolean(),
	hidden: Yup.boolean(),
});

const EventForm: FunctionComponent<EventFormPropsType> = ({ onSubmit, type, children, values }) => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});

	const formik = useFormik<EventFormValues>({
		initialValues: values
			? values
			: {
					eventName: '',
					eventNameFR: '',
					mandatoryRib: false,
					hidden: false,
			  },
		onSubmit: onSubmit,
		validationSchema: schema,
	});
	return (
		<div>
			<FormGroup sx={{ mb: 3 }}>
				<TranslationTextField
					fullWidth
					direction="bottom"
					label={t('event.name')}
					name="eventName"
					onChange={formik.handleChange}
					values={{ en: formik.values.eventName, fr: formik.values.eventNameFR }}
					error={!!formik.errors.eventName && formik.touched.eventName}
					helperText={formik.touched.eventName && formik.errors.eventName}
				/>
			</FormGroup>

			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							name="mandatoryRib"
							onChange={formik.handleChange}
							checked={formik.values.mandatoryRib}
							color="primary"
						/>
					}
					label={t<string>('event.restore-vat')}
				/>
			</FormGroup>

			<Box sx={{ mb: 3 }}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								name="hidden"
								onChange={formik.handleChange}
								checked={formik.values.hidden}
								color="primary"
							/>
						}
						label={t('event.invisible') as string}
					/>
				</FormGroup>
			</Box>

			{children}

			{type === 'new' ? (
				<Box textAlign="right">
					<Button
						color="primary"
						variant="contained"
						type="submit"
						onClick={formik.submitForm}
						startIcon={<CreateIcon fontSize="medium" />}
					>
						{t('event.add-blanc')}
					</Button>
				</Box>
			) : (
				<Box display="flex" alignItems="center" justifyContent="space-between">
					<AddRace />

					<Button
						color="primary"
						variant="contained"
						startIcon={<SaveIcon fontSize="medium" />}
						type="submit"
						onClick={formik.submitForm}
					>
						{t('shared.save')}
					</Button>
				</Box>
			)}
		</div>
	);
};

export default EventForm;
