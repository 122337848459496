// React
import React, { ReactElement } from 'react';
// Mui
import { Check } from '@mui/icons-material';
import { StepIconProps } from '@mui/material';
// Styles
import clsx from 'clsx';
import { useDotStepIconStyles } from '@components/Register/DotStepIcon/dotStepIcon.style';

export const DotStepIcon = (props: StepIconProps): ReactElement => {
	const classes = useDotStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
		</div>
	);
};
