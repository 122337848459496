import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { ErrorFallback, useUserContext } from '@tmds-io/kore';
import * as Yup from 'yup';
import { ErrorBoundary } from 'react-error-boundary';

import { Box, FormControl, TextField, Button } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { useUpdateProfileMutation } from '../../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ROUTES_URI } from '../../../app/router/routes';

const ProfileForm: FC = () => {
	const { t } = useTranslation('acs', {
		useSuspense: false,
	});
	const { firstname, lastname, email, phone, profileId, refetch } = useUserContext();
	const { enqueueSnackbar } = useSnackbar();
	const [updateProfile, { data: profileUpdated }] = useUpdateProfileMutation();
	const fieldList = [
		{
			label: t('user-details.email'),
			name: 'email',
			type: 'email',
			disabled: true,
		},
		{
			label: t('user-details.first-name'),
			name: 'firstname',
			type: 'text',
		},
		{
			label: t('user-details.last-name'),
			name: 'lastname',
			type: 'text',
		},
		{
			label: t('user-details.phone'),
			name: 'phone',
			type: 'tel',
		},
	];
	const validateSchema = Yup.object().shape({
		firstname: Yup.string().required(t('firstname-not-defined')),
		lastname: Yup.string().required(t('lastname-not-defined')),
		email: Yup.string().email(t('email-invalid')).required(t('email-not-defined')),
	});

	useEffect(() => {
		if (profileUpdated) {
			refetch();
			enqueueSnackbar(t('profile.update-success'), { variant: 'success' });
		}
	}, [profileUpdated]);

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<div>
				<Formik
					enableReinitialize={true}
					initialValues={{
						lastname: lastname ?? '',
						firstname: firstname ?? '',
						email: email ?? '',
						phone: phone ?? '',
					}}
					validationSchema={validateSchema}
					onSubmit={({ lastname, firstname, phone }) => {
						const data = {
							first_name: firstname,
							last_name: lastname,
							phone,
						};
						updateProfile({
							variables: {
								params: {
									profile: {
										id: profileId,
										data,
									},
									paths: ['data.first_name', 'data.last_name', 'data.phone'],
								},
							},
						});
					}}
				>
					{({ errors, values, touched, handleChange, handleSubmit }) => {
						type OrderKey = keyof typeof values;
						return (
							<form noValidate onSubmit={handleSubmit}>
								{fieldList.map(({ name, ...props }) => (
									<Box mb={4} key={name}>
										<FormControl fullWidth>
											<TextField
												{...props}
												name={name}
												value={values[name as OrderKey]}
												error={touched[name as OrderKey] && !!errors[name as OrderKey]}
												helperText={touched[name as OrderKey] && errors[name as OrderKey]}
												onChange={handleChange}
											/>
										</FormControl>
									</Box>
								))}
								<Box display="flex" justifyContent="flex-end">
									<Button
										sx={{ mr: 2 }}
										variant="contained"
										color="primary"
										type="submit"
										startIcon={<ArrowRightIcon />}
									>
										{t('profile.actions.update')}
									</Button>
									<Button
										component={Link}
										to={generatePath(ROUTES_URI.USERS)}
										variant="outlined"
										color="primary"
										startIcon={<ArrowRightIcon />}
									>
										{t('shared.cancel')}
									</Button>
								</Box>
							</form>
						);
					}}
				</Formik>
			</div>
		</ErrorBoundary>
	);
};

export default ProfileForm;
