import { useCurrentLanguage } from '@core/utils/language.utils';
import * as Sentry from '@sentry/react';
import { captureMessage } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import React from 'react';

export const useErrorNotification = (): ((message: string, sentryErrorMessage: string) => void) => {
	const currentLanguage = useCurrentLanguage();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();

	const handleReport = (sentryErrorMessage: string) => (): void => {
		Sentry.showReportDialog({
			eventId: Sentry.lastEventId() || captureMessage(`User report: ${sentryErrorMessage}`),
			lang: currentLanguage,
		});
	};

	return (message: string, sentryErrorMessage: string) => {
		enqueueSnackbar(t(message), {
			variant: 'error',
			action: (
				<Button color="inherit" size="small" onClick={handleReport(sentryErrorMessage)}>
					{t('error.report')}
				</Button>
			),
		});
	};
};
