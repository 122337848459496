// react
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { generatePath, Params, useParams } from 'react-router-dom';
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Typography,
	FormControlLabel,
	Checkbox,
	FormGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FILTER_COMPARISON, useUserContext } from '@tmds-io/kore';
import { useResellerCustomerSapData } from '@core/hooks/resellerCustomer/useResellerCustomerSapData';
import { Save, Search } from '@mui/icons-material';
import { useDebugContext } from '@core/debug/debugContext';
import { useCurrentLanguage } from '@core/utils/language.utils';
import { useCreateResellerCustomer } from '@core/hooks/resellerCustomer/useCreateResellerCustomer';
import { ROUTES_URI } from '@components/app/router/routes';
import { useGetAccountsLazy } from '@core/hooks/useGetAccounts';
import { CustomerFormValues, CustomerInvitation } from '@components/customer/CustomerInvitation';
import {
	useAddCustomerOwnerMutation,
	useUpdateAccountMutation,
	useUpdateContractMutation,
	useValidateCustomerAccountMutation,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import { useResellerCustomerData } from '@core/hooks/resellerCustomer/useResellerCustomerData';
import { ResellerBusinessCard } from '@components/ResellerBusinessCard/ResellerBusinessCard';
import { ResellerShippingCard } from '@components/ResellerShippingCard/ResellerShippingCard';
import { CustomPaper } from '@components/CustomerPaper';
import { useGetNoGroup } from '@core/hooks/resellerCustomer/useGetNoGroup';
import { ResellerDeliveryList } from '@components/ResellerDeliveryList';

type PageParams = 'actionOrId' | 'id';

type State = 'create' | 'edit';

const getState = (params: Params<PageParams>): State => {
	const { id = null } = params;
	if (id) {
		return 'edit';
	} else {
		return 'create';
	}
};

const DELIVERY_POINT_PAGE_COUNT = 4;

/**
 * ResellerCustomer components -
 */
export const ResellerCustomer = (): JSX.Element => {
	const { debug } = useDebugContext();
	const params = useParams<PageParams>();
	const { id: sellerId } = useUserContext();
	// translation
	const currentLanguage = useCurrentLanguage();
	const { t } = useTranslation('acs', { useSuspense: false });
	const { enqueueSnackbar } = useSnackbar();
	// Fetch data hooks
	const [getUserSapData, { data: userSapData, error: userSapError, loading: userSapLoading }] =
		useResellerCustomerSapData();
	const [getUserDataForEdit, { data: userDataForEdit }] = useResellerCustomerData();
	const [getUserAccountData, { data: userData }] = useGetAccountsLazy();
	// Update hooks
	const [updateAccount, { data: accountUpdated, error: accountUpdateFail }] = useUpdateAccountMutation();
	const [createCustomer, { data: userCreateData, error: userCreateError }] = useCreateResellerCustomer();
	const [updateContract, { data: contractUpdated, error: contractUpdateFail }] = useUpdateContractMutation();
	const [getNoGroup, { data: noGroupData }] = useGetNoGroup();
	const [addCustomerOwner, { data: customerOwnerData, error: customerOwnerError, loading: customerOwnerLoading }] =
		useAddCustomerOwnerMutation();
	const [useCustomPrice, setUseCustomerPrice] = useState<boolean>(false);
	const [validate] = useValidateCustomerAccountMutation({
		onCompleted: (data) => {
			if (data) {
				enqueueSnackbar(t('reseller-customer.add-page.user-validated'), { variant: 'success' });
			}
		},
		onError: () => {
			enqueueSnackbar(t('reseller-customer.add-page.user-validation-fail'), { variant: 'error' });
		},
	});

	// Constant
	const minLimit = useRef<number>(0);
	const search = useRef<string>('');
	const [searchError, setSearchError] = useState<string | undefined>();
	const testId = useRef<string>('287666');

	const handleChangeLimit = (numOrEvt: ChangeEvent<HTMLInputElement>): void => {
		minLimit.current = +numOrEvt.target.value;
	};

	const handleUpdateLimit = () => {
		if (state === 'edit') {
			void updateContract({
				variables: {
					id: userData.accounts[0].contractId,
					params: {
						min_quote_orderable_qty: minLimit.current,
						seller_id: sellerId,
						customer_id: userData.accounts[0].id,
					},
					paths: ['min_quote_orderable_qty'],
				},
			});
		}
	};

	const state = getState(params);

	useEffect(() => {
		if (state === 'edit') {
			getUserAccountData({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: '_key', value: params.id }],
				},
			});
			getUserDataForEdit(params.id);
		} else {
			getNoGroup();
		}
	}, [state]);

	const handleSapSearch = () => {
		setSearchError(undefined);
		getUserSapData(search.current);
	};

	const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>): void => {
		search.current = evt.target.value;
	};
	const userInfos = state === 'create' ? userSapData : userDataForEdit;

	const handleCreateUser = () => {
		if (userInfos) {
			if (!userInfos.group) {
				userInfos.group = noGroupData;
			}
			createCustomer({
				customerData: userInfos,
				ediRef: search.current,
				limit: minLimit.current,
				deliveryPointList: userInfos.deliveryPoints.map(({ shipTo, city, address, country, name }) => ({
					ediReference: shipTo,
					address: {
						city,
						country,
						street: address,
					},
					name,
				})),
				payments: userInfos.payments,
				useCustomPrice,
			});
		}
	};

	const handleInvitation = (values: CustomerFormValues): void => {
		void addCustomerOwner({
			variables: {
				account_id: userData.accounts[0].id,
				params: {
					fisrt_name: values.firstName,
					last_name: values.lastName,
					mail: values.email,
				},
			},
		});
	};

	const handleValidateAccount = () => {
		void validate({
			variables: {
				id: params.id,
			},
		});
	};

	const toggleUseCustomPrice = () => {
		if (state === 'edit') {
			void updateAccount({
				variables: {
					id: params.id,
					params: {
						data: {
							use_custom_price: !useCustomPrice,
						},
					},
					paths: ['data.use_custom_price'],
				},
			});
		}
		setUseCustomerPrice((s) => !s);
	};

	const creationEnable =
		(state === 'create' && userInfos && !userData?.accounts.length) ||
		(state === 'create' && !userInfos && !userSapError);

	useEffect(() => {
		if (userSapError || userCreateError) {
			setSearchError(t('reseller-customer.add-page.search-error'));
		}
	}, [userSapError, userCreateError]);

	useEffect(() => {
		if (userCreateData) {
			window.location.href = generatePath(ROUTES_URI.CUSTOMER_EDIT, { id: userCreateData.id });
		}
	}, [userCreateData]);

	useEffect(() => {
		if (state === 'create' && userInfos) {
			getUserAccountData({
				variables: {
					filter: [{ comparison: FILTER_COMPARISON.EQUAL, key: 'edi_reference', value: search.current }],
				},
			});
		}
	}, [state, userInfos?.businessName]);

	useEffect(() => {
		if (accountUpdated) {
			enqueueSnackbar(t('reseller-customer.add-page.account-updated'), { variant: 'success' });
		}
	}, [accountUpdated]);

	useEffect(() => {
		if (accountUpdateFail) {
			enqueueSnackbar(t('reseller-customer.add-page.account-update-fail'), { variant: 'error' });
		}
	}, [accountUpdateFail]);

	useEffect(() => {
		if (customerOwnerData) {
			enqueueSnackbar(t('reseller-customer.add-page.invitation-sent'), { variant: 'success' });
		}
	}, [customerOwnerData]);

	useEffect(() => {
		if (customerOwnerError) {
			enqueueSnackbar(t('reseller-customer.add-page.invitation-error'), { variant: 'error' });
		}
	}, [customerOwnerError]);

	useEffect(() => {
		if (contractUpdated) {
			enqueueSnackbar(t('reseller-customer.add-page.update-contract-success'), { variant: 'success' });
		}
	}, [contractUpdated]);

	useEffect(() => {
		if (contractUpdateFail) {
			enqueueSnackbar(t('reseller-customer.add-page.update-contract-error'), { variant: 'error' });
		}
	}, [contractUpdateFail]);

	useEffect(() => {
		if (userData) {
			setUseCustomerPrice(!!userData?.accounts?.[0]?.data?.use_custom_price);
		}
	}, [userData]);

	useEffect(() => {}, [userSapData?.group]);

	return (
		<div>
			<Typography color="primary" variant="h1" sx={{ mb: 3 }}>
				{t(state === 'edit' ? 'reseller-customer.add-page.edit-title' : 'reseller-customer.add-page.new-title')}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<CustomPaper>
						<Typography variant="h3" sx={{ mb: 2 }} color="primary">
							{t('reseller-customer.add-page.paper-sap-title')}
						</Typography>
						{state === 'edit' ? (
							<Typography>
								{t('reseller-customer.add-page.textfield-sap-label')}: {userData?.accounts?.[0]?.edi_reference}
							</Typography>
						) : (
							<TextField
								helperText={searchError}
								error={!!searchError}
								InputProps={{
									endAdornment: userSapLoading ? (
										<CircularProgress size={35} />
									) : (
										<IconButton onClick={handleSapSearch}>
											<Search />
										</IconButton>
									),
								}}
								onChange={handleSearchChange}
								sx={{ width: '100%' }}
								label={t('reseller-customer.add-page.textfield-sap-label')}
							/>
						)}
						{state === 'create' && !creationEnable && !userSapError ? (
							<Alert sx={{ mt: 2 }} severity="warning">
								{t('reseller-customer.add-page.alert-account-already-exist')}
							</Alert>
						) : null}

						<Box>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox checked={useCustomPrice} onClick={toggleUseCustomPrice} />}
									label={t('reseller-customer.add-page.use-customer-price') as string}
								/>
							</FormGroup>
						</Box>
					</CustomPaper>
				</Grid>
				<Grid item xs={12} sm={6}>
					<CustomPaper>
						<Typography variant="h3" sx={{ mb: 2 }} color="primary">
							{t('reseller-customer.add-page.paper-parameter-title')}
						</Typography>
						{userInfos && (
							<>
								<Typography sx={{ mb: 2 }}>
									<strong>{t('shared.group')}</strong>:{' '}
									{userInfos.group?.title[currentLanguage] || (
										<Box component="span" color="primary.main">
											N/A
										</Box>
									)}
								</Typography>
							</>
						)}
						<Box mt={1}>
							{typeof userData?.accounts?.[0]?.limit !== 'undefined' || state === 'create' ? (
								<>
									<TextField
										name="limit"
										defaultValue={userData?.accounts?.[0]?.limit || 0}
										label={t('reseller-customer.add-page.input-parameter-label')}
										onChange={handleChangeLimit}
										InputProps={{
											endAdornment:
												state === 'edit' ? (
													<IconButton onClick={handleUpdateLimit} color="primary">
														<Save />
													</IconButton>
												) : null,
										}}
									/>
								</>
							) : null}
						</Box>
					</CustomPaper>
				</Grid>
				{userInfos && (
					<>
						<Grid item xs={12} sm={6}>
							<ResellerBusinessCard
								city={userInfos.businessAddress.city}
								country={userInfos.businessAddress.country}
								street={userInfos.businessAddress.address}
								name={userInfos.businessName}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<ResellerShippingCard {...userInfos.payments} />
						</Grid>
					</>
				)}
			</Grid>
			{userInfos && (
				<>
					<Typography sx={{ mt: 3, mb: 2 }} variant="h3">
						{t('reseller-customer.add-page.delivery-point')}
					</Typography>
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
							sm={userInfos.deliveryPoints.length <= DELIVERY_POINT_PAGE_COUNT ? 6 : 12}
							container
							spacing={userInfos.deliveryPoints.length <= DELIVERY_POINT_PAGE_COUNT ? 2 : 0}
						>
							<ResellerDeliveryList
								deliveryPointList={userInfos.deliveryPoints}
								pageCount={DELIVERY_POINT_PAGE_COUNT}
							/>
						</Grid>
						{state === 'edit' ? (
							<Grid item xs={12} sm={6}>
								<CustomerInvitation
									title={t('reseller-customer.add-page.invitation')}
									onSubmit={handleInvitation}
									loading={customerOwnerLoading}
								/>
							</Grid>
						) : null}
					</Grid>
				</>
			)}
			{debug ? (
				<Alert sx={{ mt: 3 }} severity="info">
					User sap number: {testId.current}
				</Alert>
			) : null}
			{creationEnable && (
				<Box pt={3} display="flex" justifyContent="flex-end">
					<Button color="primary" variant="contained" onClick={handleCreateUser}>
						{t('shared.create')}
					</Button>
				</Box>
			)}
			{state === 'edit' && userData && userData?.accounts?.[0]?.status !== 'validated' ? (
				<Box pt={3} display="flex" justifyContent="flex-end">
					<Button color="primary" variant="contained" onClick={handleValidateAccount}>
						{t('shared.validate')}
					</Button>
				</Box>
			) : null}
		</div>
	);
};
